import { Stack, Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";

const ProductFilter = (props: any) => {
  const { product, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");

  dispatch(
    myProductActions.setQuery(
      `?${type && `type=${type}&${brand && `brand=${brand}&`}`}`
    )
  );
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
    }
  };

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(auth?.token, product?.query || "?")(dispatch);
    auth?.token &&
      getAllAttributesAction(auth?.token, product?.query || "?")(dispatch);
  }, [auth, dispatch, product?.query]);
  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>

          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={product.isFetching}
                disabled={product.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  //   console.log("::", value);
                  !value && setType("");
                  value && setType(value);
                }}
              />
              {type && (
                <Select
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.attributes?.brands}
                  onChange={(value: any) => {
                    console.log("::", value);
                    !value && setBrand("");
                    value && setBrand(value);
                  }}
                />
              )}
            </Stack>
          )}
          {/* <h1 className="text-gray-400">
            Available Product{product?.all?.result > 1 && "s"}:{" "}
            {product.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              product?.all?.result
            )}
          </h1> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default ProductFilter;
