import React from 'react';
import { Table } from 'antd';
import SettingButton from '../../../../pages/dashboard/setting/SettingButton';



const ManageCustomerTable = (props:any) => {
  const columns= [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone 1',
      dataIndex: 'phone',
    },
    {
      title: 'FAX',
      dataIndex: 'fax',
    },
    {
      title: ' ',
      dataIndex: 'data',
      render :()=>(
          <>
          <button className='text-[#605BFF] border border-[#605BFF] px-3 py-1 rounded-md' onClick={props?.open}>View</button>
          </>
      )
    },
  ];
  
  const data= [
    {
      key: '1',
      id:"787300121",
      type: 'Individual',
      name:"Strutika Suzan Garfinkle",
      phone:"0787300121",
      email:"y@gmail.com",
      fax:""
    },
    {
      key: '2',
      id:"787300122",
      type: 'Individual',
      unit:"Solange Mukakarara",
      phone:"0787300121",
      email:"ya@gmail.com",
      fax:"0"
    },
    {
      key: '3',
      id:"787300122",
      type: 'Individual',
      unit:"Solange Mukakarara",
      phone:"0787300121",
      email:"ya@gmail.com",
      fax:"0"
    },
    {
      key: '4',
      id:"787300122",
      type: 'Individual',
      unit:"Solange Mukakarara",
      phone:"0787300121",
      email:"ya@gmail.com",
      fax:"0"
    },
    {
      key: '5',
      id:"787300122",
      type: 'Corperate',
      unit:"Kivu Akagera Real Estates",
      phone:"0787300121",
      email:"ya@gmail.com",
      fax:"0"
    },
    
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default ManageCustomerTable;