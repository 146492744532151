import React from 'react'
const PurchaseDetails = () => {
  return (
    <div className='text-black px-4'>
        <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2 ">
                  <div className='flex gap-20 mt-1'>
                    <div>
                      <p className="text-[#030229B2] text-sm mt-4">Item Code:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      CA2CTXU0000021
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Item Name:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      MTN IKOSORA+ H5032U (32GB+2)
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Invoice ID:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      3,684
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Supplier ID:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      100019148
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Suplier Name:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      MTN RWANDACELL PLC
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Purchase Date:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      24-04-2024
                      </p>
                    
                    </div>
                    <div>
                      <p className="text-[#030229B2] text-sm mt-4"> Unit Price:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      RWF 65,600
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Purchase Qty:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      13440
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Purchase Price:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      RWF 881,884,000
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">VAT:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      0
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">D/C Amount:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      0
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Total Price:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      RWF 881,664,000
                      </p>
                    
                    </div>
                  </div>
        </div>
        </div>
  )
}

export default PurchaseDetails