import { useEffect, useState } from "react";
import EbmButton from "./EbmButton";
import { Form, Input } from "antd";
import { Flex, Tag, Select } from "antd";
import { getEbmSelectOptionAction } from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import { addEbmSalesAction } from "../store/SalesManagement/actions";
import { SyncOutlined } from "@ant-design/icons";

const CheckInformation = (props: any) => {
  const { auth, ebmGeneral,ebmSales } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [paymentMode, setPaymentMode] = useState("");
  const [refundCode, setRefundCode] = useState("");
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    setRefundCode(value)
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);
  const RefundReason = ebmGeneral?.selectOption["Refund Reason"]?.map(
    (el: any) => ({
      value: el?.value,
      label: el?.label
    })
  );
  const handleChange = async(tag: string, checked: boolean) => {
    setPaymentMode(tag);
    auth?.token &&
    await(paymentMode &&  (addEbmSalesAction(auth?.token, {
        paymentTypeCode: paymentMode,
        cartId: props?.data?.data?._id,
      })(dispatch)));
    props?.setShowCheck(true);
  };
  const onFinish =async (values: any) => {
    console.log(values);
    await (addEbmSalesAction(auth?.token, {
      refundReasonCode: refundCode,
      cartId: props?.data?.data?._id,
    })(dispatch));
    // props?.onClose()
  };

  useEffect(() => {
    form.setFieldsValue({
      phone: props?.data.data?.customer?.phone,
      refundCode:refundCode,
    })},
      [refundCode])
  return (
    <>
      <div className="pxs-4 py-6 w-full overflow-y-auto pb-10">
        {!props?.showCheck ? (
          <div>
            <Form
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              style={{ maxWidth: "100%" }}
            >
              <div className="p-2 bg-[#F4F6FA] rounded-md">
                <h1 className="text-[#030229] text-sm pb-4">
                  The customer Phone or TIN Number
                </h1>
                <Form.Item name="phone" rules={[{ required: true }]}>
                  <Input className="w-52 h-10" />
                </Form.Item>
              </div>
              <div>
                <h1 className="text-[#030229] text-sm py-4">Payment Mode</h1>
              </div>
              <Flex gap={4} wrap align="center" className="w-full">
                {ebmGeneral?.selectOption["Payment Type"]?.map((data: any) => {
                  return (
                    <Tag.CheckableTag
                      key={data?.value}
                      checked={paymentMode.includes(data?.value)}
                      onChange={(checked) => handleChange(data?.value, checked)}
                      className="border border-[#605BFF80] bg-[#605BFF0D] hover:bg-[#605BFF80] text-[#030229] hover:text-white w-[9rem] h-14 flex justify-center items-center rounded-md"
                    >
                      <p className="text-[13px]"> {data?.label}</p>
                    </Tag.CheckableTag>
                  );
                })}
              </Flex>
            </Form>
            {ebmSales?.isFetching &&  <Tag icon={<SyncOutlined spin />} className="w-1/2 m-auto mt-5">
        processing
      </Tag>}
          </div>
        ) : (
          <div>
            <h1 className="p-3 bg-[#F4F6FA] rounded-md text-[#030229] text-sm">
              Refund Reason Information
            </h1>
            <Form
              form={form}
              name="control-hooks"
              layout="vertical"
              onFinish={onFinish}
              style={{ maxWidth: "100%" }}
            >
              <div className="flex gap-5 w-full">
                <Form.Item
                  name="refundCode"
                  label={
                    <span className="text-[#030229] text-[12px] pt-2">
                      Refund Code
                    </span>
                  }
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Input className="h-12 " />
                </Form.Item>
                <Form.Item
                  name="Select Refund Reason"
                  label={
                    <span className="text-[#030229] text-[12px] pt-2">
                      Select Refund Reason
                    </span>
                  }
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Select
                    showSearch
                    placeholder="Select "
                    optionFilterProp="label"
                    className="h-12"
                    onChange={onChange}
                    onSearch={onSearch}
                    options={RefundReason}
                  />
                </Form.Item>
              </div>
              <Form.Item className="w-1/2 m-auto">
                <EbmButton
                  textColor="white"
                  btnName={ ebmSales?.isFetching ? <Tag icon={<SyncOutlined spin />} >
                  
                </Tag>:"Confirm"}
                  btnBgColor="[#605BFF]"
                  btnWidth="full"
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};
export default CheckInformation;
