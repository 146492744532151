import HttpRequest from "../../../../utils/HttpRequest";
import { SERVER_URL } from "../../../../utils/constants";

export const getPriceLists = async (token: string, query: any) => {
    return HttpRequest.get(`${SERVER_URL}/pricelist${query}`, token);
  };
  export const getPriceListItem = async (token: string, query: any) => {
    return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
  };
  export const getSinglePriceListItem = async (token: string, itemId: any) => {
    return HttpRequest.get(`${SERVER_URL}/pricelistitem/one/${itemId}`, token);
  };