import HttpRequest from "../../../../utils/HttpRequest";
import { SERVER_URL } from "../../../../utils/constants";

export const getEbmSale = async (token: string, query: any) => {
    return HttpRequest.get(`${SERVER_URL}/cartItem/ebm${query}`, token);
  };
export const getEbmCartItemServices = async (token: string, id: any) => {
    return HttpRequest.get(`${SERVER_URL}/cart/one/${id}`, token);
  };
export const getAllEbmSalesServices = async (token: string, query?: any) => {
    return HttpRequest.get(`${SERVER_URL}/ebm/transaction${query}`, token);
  };
  export const addSalesService = async (data: any, token: string) => {
    return HttpRequest.post(`${SERVER_URL}/ebm/addSalesToEBM`,data, token);
  };