import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Select, Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import { BsDownload, BsPrinter } from "react-icons/bs";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { GoPlus } from "react-icons/go";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import ManageSalesTable from "../components/tables/ManageSalesTable";
import type { RadioChangeEvent, SelectProps } from "antd";
import SalesDetails from "../components/SalesDetails";
import SalesRegistrationForm from "../components/forms/SalesRegistrationForm";
import FilterSales from "../components/filter/filterSales";
import LoadingCard from "../components/LoadingCard";
import { useDispatch, useSelector } from "react-redux";
import TwoBergeSalesCard from "../components/cards/TwoBargeSalesCard";
import { getAllEbmSalesAction } from "../store/SalesManagement/actions";
import { getEbmSelectOptionAction } from "../store/action";
import EbmSalesFilter from "../components/filter/ebmSalesFilter";
import { getNameByCode } from "../utils/ConvertCodeToName";
import { getAllEbmSale_CSVAction } from "../../../store/csvDownload/actions";
import EbmExportButton from "../components/EbmExportButton";
import { EbmSalesHeaders } from "../../../components/csvHeaders/Headers";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
type SelectCommonPlacement = SelectProps["placement"];
const SalesManagement = (props: any) => {
  const { ebmSales, auth, ebmGeneral, CSV, layout } = useSelector(
    (state: any) => state
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [placement, SetPlacement] = useState<SelectCommonPlacement>("topLeft");
  const [brandData, setBrandData] = useState<any>(null);
  const [itemForm, setItemForm] = useState(false);
  const [loading, setLoading] = useState(ebmSales?.isFetching);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [saleDetails, setSaleDetails] = useState("");
  const dispatch = useDispatch();

  const currentStatus =
    ebmGeneral?.selectOption &&
    getNameByCode(ebmGeneral?.selectOption["Sale Status"], props?.data?.label);
  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = (data: any) => {
    setOpenDrawer(true);
    setSaleDetails(data);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const showItemForm = () => {
    setItemForm(true);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  useEffect(() => {
    auth?.token && getEbmSelectOptionAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);
  useEffect(() => {
    auth?.token && getAllEbmSalesAction(auth?.token, "?")(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllEbmSale_CSVAction(
          auth?.token,
          `?limit=${ebmSales?.allSales?.total}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      id: el?.cartId,
      name: el?.customer?.name,
      date: el?.paidOn?.slice(0, 10),
      item: el?.list?.map((d: any) => d?.warehouseProduct?.product?.model),
      vat: el?.list?.map(
        (d: any) =>
          ebmGeneral?.selectOption &&
          getNameByCode(
            ebmGeneral?.selectOption["Taxation Type"],
            d?.priceProduct?.product?.ebm?.taxTyCd
          )
      ),
      qauntity: el?.list?.length,
      price: el?.list?.map((d: any) => d?.payment?.amount),
      amount: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      sales: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      data: el,
    };
  });
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-semibold text-[#030229]">
            Sale Information Details
          </h1>
        }
        component={
          <div>
            <div>
              <SalesDetails data={saleDetails} />
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Sales Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmExportButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
            csvHeaders={EbmSalesHeaders}
            csvData={csvData}
            filename={`Ebm Sales.csv`}
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <CustomButton
          title={"Sales Report"}
          icon={<BsDownload color="#FB923C" />}
          bgColor="white"
        />
        <div className="flex gap-5">
          <h1 className="pt-3">From</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
          <h1 className="pt-3">To</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        <TwoIconsCard
          amount={"RWF 303,406.78"}
          title={"Total VAT Amount"}
          name={"Increased than last month"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<NewIcons />}
        />
        <TwoIconsCard
          amount={"RWF 1,771,056,800.00"}
          title={"Total Sales Amount"}
          name={"Increased than last month"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<AmountIcons />}
        />
        <TwoIconsCard
          amount={"20"}
          title={"Total Customers"}
          name={"20 new customers added!"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<CustomerAmountIcons />}
        />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <EbmSalesFilter
            setSelectedStatus={setSelectedStatus}
            data={
              ebmGeneral?.selectOption &&
              ebmGeneral?.selectOption["Sale Status"]
            }
          />
          <Search
            placeholder="Search by invoice ID"
            onSearch={onSearch}
            style={{ width: "200px" }}
          />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Sales Registration
              </h1>
            }
            component={
              <div className="px-4 h-[78vh] w-full overflow-y-auto">
                <div className="mts-5 w-full">
                  <FilterSales
                    open={isModalOpen}
                    setBrandData={setBrandData}
                    setItemForm={setItemForm}
                  />
                  {loading ? (
                    <LoadingCard />
                  ) : (
                    <div className="mt-2 w-full">
                      {!itemForm && brandData && (
                        <div className="flex flex-wrap">
                          <TwoBergeSalesCard
                            data={ebmSales?.sale?.data}
                            open={showItemForm}
                          />
                        </div>
                      )}

                      {itemForm && brandData && (
                        <SalesRegistrationForm onClose={handleOk} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            }
            width={1200}
          />
        </div>
        <div className="mt-5">
          <ManageSalesTable open={showDrawer} />
        </div>
      </div>
    </div>
  );
};

export default SalesManagement;
