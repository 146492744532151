import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin } from "antd";
import React, { useState } from "react";
import {
  getOneUserAction,
  updateUserActions,
} from "../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPinActions } from "../../store/auth/authActions";
import { authActions } from "../../store/auth";
const ChangePin = ({
  userData,
  onCancel,
}: {
  userData: any;
  onCancel: any;
}) => {
  const { auth, setting } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const ChangeNewPin = (e: any) => {
    setNewPin(e.target.value);
  };
  const ConfirmPin = (e: any) => {
    setConfirmPin(e.target.value);
    
  };
  const updatePin = async (values: any) => {
   auth?.token && await updateUserPinActions(
      { ...values },
      auth?.token
      )(dispatch);
    await dispatch(authActions.setIsFetching(false));
    form.resetFields();
    // handleOk()
  };
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%" }}
        onFinish={updatePin}
      >
        <Form.Item
          name="email"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Email
            </span>
          }
          initialValue={userData?.email}
          className="w-full"
        >
          <Input disabled className="pl-4 h-[42px] w-[100%] text-base" />
        </Form.Item>
        <Form.Item
          name="currentPin"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Current PIN
            </span>
          }
          className="w-full"
        >
          <Input.Password
          autoComplete="new-password"
            placeholder="Please enter your old PIN"
            className="pl-4 h-[42px] w-[100%] "
          />
        </Form.Item>
        <Form.Item
      name="newPin"
      label={
        <span className="text-[#030229] pl-1 text-sm font-normal">
          New PIN
        </span>
      }
      className="w-full"
      rules={[
        { required: true, message: "Please input new Pin!" },
        {
          min: 5,
          message: "Pin should be at least 5 characters long",
        },
        {
          pattern: /^[0-9]*$/,
          message: "Pin must contain numbers only.",
        },
      ]}
    >
      <Input.Password className="pl-4 h-[42px] w-[100%] " onChange={ChangeNewPin}/>
      
    </Form.Item>
    {newPin.length > 5 && (
          <p className="text-[#ff000079] pb-2">
            Pin should not greater than 5 digits
          </p>
        )}
    <Form.Item
      label={
        <span className="text-[#030229] pl-1 text-sm font-normal">
          Confirm PIN
        </span>
      }
      className="w-full"
      name="confirm"
      rules={[{ required: true, message: 'Please Confirm new PIN' }]}
      // initialValue={""}
    >
      <Input.Password className="pl-4 h-[42px] w-[100%] "  onChange={ConfirmPin}/>
    </Form.Item>
    {confirmPin.length > 0 && newPin != confirmPin && (
          <p className="text-[#ff000079] pb-4">
            New and Confirm Password are not match
          </p>
        )}
        <div className="ml-8">
          <button
            type="submit"
            disabled={
              !(newPin.length >0 && newPin.length <= 5 && (newPin === confirmPin))
            }
            className={` border w-[402px] bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {setting.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
export default ChangePin;
