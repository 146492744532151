import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import ProductPurchaseOrderTable from "../../../components/tables/productPurchaseOrderTable";

const ProductPurchaseOrder = (props: any) => {
  console.log("data from table", props);
  return (
    <>
      <div className="text-black p-5 mt-3 space-y-2 h-[22rem] overflow-y-auto ">
        {/* {!purchaseData && (
          <div className="p-5 bg-white rounded-lg space-y-6">
            <PurchaseOrderDetailsSkeleton />
          </div>
        )} */}
        {/* {purchaseData && ( */}
        <div className="p-s5 bg-white rounded-lg ">
          <div className="flex justify-between font-semibold text-lg ">
            {/* <div> */}
            <p className="text-[#030229] ">Purchase Order</p>
            <p className="text-[#605BFF] text-lg">
              {props?.data?.warehouseItem?.po?.pOrderID}
            </p>
            {/* </div> */}
            <p className=" py-1 rounded-[5px] text-sm ">
              Status:
              <Tag
                color={searchValue(props?.data?.status)}
                className="px-7  ml-3 text-base"
              >
                {props?.data?.status}
              </Tag>
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center pt-5">
            <div className="grid grid-cols-2 gap-x-10 mb-2">
              <p className=" text-sm">Supplier Name</p>
              <p className="text-[#030229B2] text-sm">
                {props?.data?.warehouseItem?.po?.supplier?.name}
              </p>
              <p className="text-sm">Supplier Location</p>
              <p>{props?.data?.warehouseItem?.po?.supplier?.address}</p>
              <p className="text-sm">Supplier Contact</p>
              <p className="text-[#030229B2] text-sm">
                {props?.data?.warehouseItem?.po?.supplier?.phone}
              </p>
            </div>
            {/* <div className="grid grid-cols-2 gap-x-10">
                <p className="text-sm">Delivery Deadline</p>
                <p className="text-[#030229B2] text-sm">30.july.2024</p>
              </div> */}
          </div>
        </div>
        <ProductPurchaseOrderTable data={props} />
      </div>
    </>
  );
};
export default ProductPurchaseOrder;
