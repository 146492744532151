import { LoadingButton } from "@mui/lab";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { revertSalesAction } from "../../store/pos/actions";
import { updateRepairStatusAction } from "../../store/repair/actions";

const SwapDeviceForm = (props: any) => {
  const { auth, pos, repairs } = useSelector((state: any) => state);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const revertComments = {
      comments: {
        description: `Swap Device with IMEI: ${props?.data?.imei} >> ${values?.comment}`,
        createdBy: auth?.user?._id,
      },
    };
    const respo = await revertSalesAction(
      auth?.token,
      `?cartItemId=${props?.data?.cartItemId}&serialNumber=${props?.data?.imei}`,
      revertComments
    )(dispatch);
    if (respo) {
      await updateRepairStatusAction(auth?.token, props?.data?.key, {
        status: "swap",
      })(dispatch);
    }
    form.resetFields();
    props?.onClose();
  };

  return (
    <div className="relative">
      <Form
        form={form}
        layout="vertical"
        name="register"
        onFinish={onFinish}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
      >
        <div className="mb-4">
          <h1 className="xpb-4 font-semibold text-xl">{`Swap Devices `}</h1>
          <span className="text-gray-500 text-sm">{`IMEI: ${props?.data?.imei}`}</span>
        </div>

        <Form.Item
          name="comment"
          label={<span className="text-lg">Reason</span>}
          rules={[
            {
              required: true,
              message: "Reason is required!",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "60%" }}
            loading={pos?.isFetching || repairs?.isFetching}
          >
            Save
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SwapDeviceForm;
