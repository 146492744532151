import { Checkbox, Divider, notification } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loginAction } from "../store/auth/authActions";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ForceLogoFull from "../assets/icons/Force_Logo.svg";
// import ForceLogo from "../assets/icons/dashboard/Samphone_Logo.svg";
import { ReactComponent as ForceLogo } from "../assets/icons/dashboard/Samphone_Logo.svg";
import { authActions } from "../store/auth";
// import ForceLogoFull from "../assets/icons/Force_Logo_SVG.svg";

export default function App() {
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async (e?: any) => {
    loginAction({ email, password })(dispatch);
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    auth?.token && navigate("/dashboard");

    !auth?.isAuth &&
      auth.error &&
      notification.warning({ message: auth?.error?.error });
  }, [auth, navigate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleLogin();
  };
  return (
    <div className="h-screen font-exo py-4 text-black flex justify-center flex-1 lg:flex-none flex-col bg-slate-100">
      <div className="m-auto w-[100%] md:w-[450px] flex flex-col justify-center space-y-4 relative">
        <form onSubmit={handleSubmit}>
          <div className="bg-slate-50 bg-opacity-50 px-[10%] pt-[7%] space-y-4 shadow rounded-lg">
            {/* <img src={ForceLogo} alt="" className=" h-10 mx-auto w-60  xml-1" /> */}
            <ForceLogo className="w-36 text-sm absolute top-0" />
            <section className="pt-12">
              <div className="font-doublebold text-lg xl:text-xl">
                Hello Samphone heroes, 👋🏿
              </div>
              <div className="text-gray-300 text-sm xl:text-lg capitalize">
                Enter the information provided to you
              </div>
            </section>
            <div className="">
              <label className="block mb-1 text-md font-light ">Email</label>
              <input
                className="w-full border border-gray-200 bg-white rounded-xl px-3 py-1 focus:outline-none"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-1 text-md font-light font-sans ">
                Password
              </label>
              <div className="relative bg-white">
                <input
                  className="border w-full border-white bg-white rounded-xl px-3 py-1 focus:outline-none"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                />
                <button
                  className="absolute top-0 right-0 p-2 rounded-lg text-sm"
                  onClick={handlePasswordToggle}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
            <div className="flex justify-between">
              <Checkbox className="text-left font-bold">Remember me</Checkbox>
              <p
                className=" place-content-end font-bold text-sm cursor-pointer text-blue-400"
                onClick={() => navigate("/reset/password")}
              >
                Forgot password
              </p>
            </div>
            <div className="flex justify-between">
              <LoadingButton
                type="submit"
                className="w-full h-8 !rounded-xl !bg-[#7F66FA] !text-white"
                loading={auth?.isFetching}
              >
                Login
              </LoadingButton>
            </div>
            <div className="text-center text-gray-300 text-sm uppercase py-2">
              version 0.2.1
            </div>
          </div>
          <Divider children={"Or"} />
          <div className="">
            <LoadingButton
              className="w-full h-12 text-white !rounded-xl !bg-white !bg-opacity-50"
              onClick={() => {
                navigate("/login/scan");
              }}
              loading={false}
            >
              Login By Scan
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
}
