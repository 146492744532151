import { Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

import { useDispatch, useSelector } from "react-redux";
import PosProductGrid from "../../../components/grids/priceList/PosProductGrid";
import { getPricelistAction } from "../../../store/pos/actions";
import ProductFilter from "../../../components/filters/ProductFiltering";
import { PaginationControlled } from "../../../components/filters/Paginate";

const PageView = (props: any) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  useEffect(() => {
    auth?.token &&
      auth?.user?.shop?.channel &&
      getPricelistAction(
        auth?.token,
        `?channel=${auth?.user?.shop?.channel}&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth?.token, auth?.user?.shop?.channel, dispatch, limit, page]);

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2 pb-2 justify-between mt-4 flex">
        <DetailsHeaderActionBar
          pageName="Price List"
          goBack={goBack}
          title=" "
        />
      </div>
      <ProductFilter />
      {/*  */}
      <Stack spacing={1}>
        {pos?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {[1, 2, 3, 4, 1, 2, 3, 4, 2, 3, 4, 1, 2, 3, 4].map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!pos.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            {pos?.priceList?.status === 200 && (
              <PosProductGrid data={pos?.priceList?.data} />
            )}
          </div>
        )}
      </Stack>
      <Stack
        alignItems={"center"}
        sx={{ pt: 4, pb: 4 }}
        spacing={2}
        direction="row"
        justifyContent={"end"}
      >
        <PaginationControlled
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.priceList?.total}
          count={Math.ceil(pos?.priceList?.total / limit)}
        />
      </Stack>
    </Stack>
  );
};

export default PageView;
