import React, { useState } from "react";
import DangerButton from "../buttons/DangerButton";
import PrimaryButton from "../buttons/PrimaryButton";
import ProductDetailsModal from "../Modals/ProductDetailsModal";
import { Carousel, Image } from "antd";

const SidePanelDetails = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [visible, setVisible] = useState(false);

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (props?.simpleDetails) {
      setDataToupdate({ ...props?.simpleDetails, specs: props?.specsData });
    }
  }, [props?.simpleDetails, props?.specsData]);

  console.log(props);
  return (
    <div>
      <div className="flex flex-col justify-center items-center rounded-lg px-2 bg-white w-[380px] space-y-6">
        <div className="-mt-10">
          <div className="flex items-center justify-center bg-slate-200 w-[140px] h-[140px] rounded-full">
            <div className="flex items-center justify-center bg-slate-300 w-[125px] h-[125px] rounded-full">
              <Carousel
                autoplay
                className="bg-white  w-[110px] h-[110px] rounded-full overflow-clip flex  justify-center items-center "
              >
                {!props?.simpleDetails?.logo &&
                  props?.simpleDetails?.images?.map((img: any) => (
                    <div className="bg-white  w-[110px] h-[110px] rounded-full overflow-clip flex  justify-center items-center p-2 ">
                      <Image
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          display: "block",
                          objectFit: "contain",
                        }}
                        preview={{ visible: false }}
                        src={img}
                        alt="description"
                        onClick={() => setVisible(true)}
                      />
                    </div>
                  ))}
                {props?.simpleDetails?.logo && (
                  <div>
                    <Image
                      className="h-auto w-[100%] rounded-lg m-auto"
                      src={props?.simpleDetails?.logo}
                      alt="description"
                    />
                  </div>
                )}
              </Carousel>
            </div>
          </div>
          <div className="text-center font-semibold">
            <p>{props?.simpleDetails?.model}</p>
            <p>{props?.simpleDetails?.brand}</p>
          </div>
        </div>
        <div className="flex flex-col flex-wrap rounded-md bg-gray-100 p-4 w-full">
          {props?.specsData &&
            props?.specsData.map((item: any) => (
              <div className="h-fit divide-y divide-gray-200">
                <p className="font-bold pb-3">{item?.label}</p>
                <div className="flex flex-wrap h-fit w-full py-3">
                  {item?.value?.map((data: any, _index: number) => (
                    <span className="font-semibold px-4 py-2 mr-2 my-1 rounded-md bg-slate-200 space-x-2">
                      {data}
                    </span>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-center gap-2">
          <PrimaryButton name="Edit" onClick={handleOnEdit} />
          <DangerButton name="Delete" onClick={props?.handleClickDelete} />
        </div>
        <span className="text-center uppercase font-medium pb-4">
          {props?.simpleDetails?.type}
        </span>
      </div>
      {/* product modal here */}
      {
        <ProductDetailsModal
          // component={<props.component />}
          component={
            <props.UpdateForm
              data={dataToUpdate}
              // id={dataToUpdate}
              dataToUpdate={dataToUpdate}
              action={"update"}
              onCancel={handleOnCancel}
            />
          }
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
        />
      }

      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props?.simpleDetails?.images?.map((img: any) => (
            <Image src={img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default SidePanelDetails;
