import React from "react";
import { Col, Divider, Drawer, Row, Image } from "antd";
interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const SubscriptionDrower: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
}> = ({ open, onClose, data }) => {
  console.log("drower data", data);
  return (
    <>
      <Drawer
        width={500}
        placement="right"
        closable={false}
        onClose={() => onClose()}
        open={open}
        style={{ color: "black" }}
      >
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Customer Details
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Name"
              content={data && data?.data?.customer?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Telephone"
              content={data && data?.data?.customer?.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Email"
              content={data && data?.data?.customer?.email}
            />
          </Col>
        </Row>
        <Divider />
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Address Details
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Country"
              content={
                data && data?.data?.customer?.subscriptionInfo?.address?.country
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Provence"
              content={
                data &&
                data?.data?.customer?.subscriptionInfo?.address?.provence
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="District"
              content={
                data &&
                data?.data?.customer?.subscriptionInfo?.address?.district
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Sector"
              content={
                data && data?.data?.customer?.subscriptionInfo?.address?.sector
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Cell"
              content={
                data && data?.data?.customer?.subscriptionInfo?.address?.cell
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Village"
              content={
                data && data?.data?.customer?.subscriptionInfo?.address?.village
              }
            />
          </Col>
        </Row>
        <Divider />
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Witness Details
        </p>
        <Row>
          <Col span={24}>
            {data &&
              data?.data?.customer?.subscriptionInfo?.witnessInfo?.map(
                (el: any) => (
                  <>
                    <div className="grid grid-cols-2 items-center w-full">
                      <p className="font-semibold text-sm w-[20%]">Name</p>
                      <span className="w-[80%]">{el?.name}</span>
                      <p className="font-semibold text-sm w-2/5">Telephone</p>
                      <span className="w-3/5">{el?.phone}</span>
                      <p className="font-semibold text-sm w-2/5">
                        Relationship
                      </p>
                      <span className="w-3/5">{el?.relationship}</span>
                    </div>
                    <Divider />
                  </>
                )
              )}
          </Col>
        </Row>
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Documents
        </p>
        <Row>
          <Col span={24}>
            <div className="flex space-x-4 items-center w-full">
              <div className="flex flex-col space-y-1">
                <h1 className="text-lg font-bold">Natinal ID</h1>
                <Image
                  width={100}
                  src={
                    data &&
                    data?.data?.customer?.subscriptionInfo?.nationalIDDoc
                  }
                />
              </div>
              <div className="flex flex-col space-y-1">
                <h1 className="text-lg font-bold">Momo Statement</h1>
                <Image
                  width={100}
                  src={
                    data &&
                    data?.data?.customer?.subscriptionInfo?.MomoStatementDoc
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
        <p
          className="site-description-item-profile-p font-bold text-lg"
          style={{ marginBottom: 10 }}
        >
          Device
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Type"
              content={data && data?.data?.list[0]?.priceProduct?.product?.type}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Brand"
              content={
                data && data?.data?.list[0]?.priceProduct?.product?.brand
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Model" content={data && data?.model} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Specification"
              content={data && data?.specification}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="IMEI" content={data && data?.imei} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Amount"
              content={data && data?.unpaidAmount}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Shop" content={data && data?.shop} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Agent" content={data && data?.agent} />
          </Col>
        </Row>
        <Divider />
      </Drawer>
    </>
  );
};

export default SubscriptionDrower;
