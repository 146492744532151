import { Divider, Table } from "antd";
import React, { useState } from "react";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";
import { getNameByCode } from "../utils/ConvertCodeToName";
import CustomButton from "./buttons/CustomButton";
import EbmButton from "./EbmButton";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BsPrinter } from "react-icons/bs";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";

interface DataType {
  key: React.Key;
  itemname: string;
  itemcode: string;
  quantity: number;
  unityprice: number;
  amountprice: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Item Name",
    dataIndex: "itemname",
    key: "itemname",
  },
  {
    title: "Item Code",
    dataIndex: "itemcode",
    key: "itemcode",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Unity Price (Rwf)",
    dataIndex: "unityprice",
    key: "unityprice",
  },
  {
    title: "Amount / Price (Rwf)",
    dataIndex: "amountprice",
    key: "amountprice",
  },
];

const SalesDetails = (props: any) => {
  const { ebmGeneral } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentStatus =
    ebmGeneral?.selectOption &&
    getNameByCode(
      ebmGeneral?.selectOption["Sale Status"],
      props?.data?.ebmSaleStatus
    );
  const showModal = () => {
    setIsModalOpen(true);
  };

  const invoiceInfo = [
    {
      label: "Invoice Number",
      value:
        props?.data?.cartId?.slice(0, 4) + "-" + props?.data?.cartId?.slice(4),
    },
    {
      label: "Invoice Date",
      value: "12/05/2023",
    },
    {
      label: "Due Date",
      value: "12/05/2023",
    },
  ];

  const productData = props?.data?.list?.map((el: any) => {
    const specs = el?.warehouseProduct?.product?.specs?.map((i: any) => {
      return i?.label?.toUpperCase() + ":" + i?.value?.at(0);
    });
    return {
      key: el?._id,
      itemname: el?.warehouseProduct?.product?.model + " " + specs,
      itemcode: el?.warehouseProduct?.product?.ebm?.itemClsCd,
      quantity: 13440,
      unityprice: 65000,
      amountprice: el?.payment?.amount?.toLocaleString(),
    };
  });

  return (
    <div className="text-[#030229] px-4">
      <div className="flex justify-between  mb-16">
        <div className="bg-[#F4F6FA] flex justify-center items-center gap-1 px-[.25rem] py-[.5rem] rounded-md">
          {" "}
          <h1 className="text-[.8rem] text-black ">Current status :</h1>
          <CustomButton
            title={currentStatus}
            textColor="white"
            bgColor="green-500"
          />
        </div>
        <div className="flex gap-5">
          <EbmButton
            textColor="black"
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="gray-50"
          />
          <SettingButton
            btnName={"Print Receipt"}
            icon={<BsPrinter color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="font-semibold text-lg">INVOICE</div>
        <div className="flex flex-col w-[13rem] text-[.7rem]">
          {invoiceInfo?.map((info: any, index: any) => (
            <div key={index} className="flex justify-between">
              <p className="font-medium">{info?.label}</p>
              <p>{info?.value}</p>
            </div>
          ))}
        </div>
      </div>
      <Divider dashed />
      <div className="flex justify-between font-normal">
        <div className="flex flex-col gap-1 text-[.8rem]">
          <p>SAMPHONE RWANDA Ltd</p>
          <p>Kigali City, Nyarugenge</p>
          <p>TEL : +250788860792</p>
          <p>EMAIL : jmizero@samphone.com</p>
          <p>TIN : 112620773</p>
          <p> CASHIER : SAMPHONE RWANDA Ltd(112620773)</p>
        </div>
        <div className="flex flex-col gap-1 text-[.8rem] w-[13rem]">
          <p>CLIENT TIN : +{props?.data?.customer?.phone}</p>
          <p>CLIENT NAME : {props?.data?.customer?.name}</p>
        </div>
      </div>
      <Divider dashed />
      <div>
        <div className="font-semibold text-base mb-5">Products</div>
        <Table
          columns={columns}
          dataSource={productData}
          pagination={false}
          size="small"
        />
      </div>
      <div className="flex flex-col w-[14rem] text-[.8rem] gap-2 float-right my-8">
        <div className="flex justify-between">
          <p className="font-normal">Subtotal</p>
          <p className="font-semibold">881,884,000 RWF</p>
        </div>
        <div className="flex justify-between">
          <p className="font-normal">Total A-EX</p>
          <p className="font-semibold">881,884,000 RWF</p>
        </div>
        <div className="flex justify-between">
          <p className="font-normal">Total B-18%</p>
          <p className="font-semibold">0.00</p>
        </div>
        <div className="flex justify-between">
          <p className="font-normal">Total Tax</p>
          <p className="font-semibold">0.00</p>
        </div>
        <div className="flex justify-between">
          <p className="font-semibold">Total Amount</p>
          <p className="font-semibold">881,884,000 RWF</p>
        </div>
      </div>
      <Divider dashed />
    </div>
  );
};
export default SalesDetails;
