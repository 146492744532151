import react, { useState } from "react";
import SearchBar from "@mkyy/mui-search-bar";
import * as cgIcons from "react-icons/cg";
import * as bsIcons from "react-icons/bs";
import * as ioIcons from "react-icons/io";
import * as riIcons from "react-icons/ri";
import * as tbIcons from "react-icons/tb";
import * as siIcons from "react-icons/si";
import { ReactComponent as IntegrationIcon } from "../../../assets/icons/tabler_plug-connected.svg";
import { ReactComponent as peapleIcon } from "../../../assets/icons/pepicons-pencil_people.svg";
import { ReactComponent as securityIcon } from "../../../assets/icons/carbon_security.svg";
import { ReactComponent as channelIcon } from "../../../assets/icons/Channel icon 1.svg";
import { ReactComponent as PreferencesIcon } from "../../../assets/icons/Group.svg";
import SideBar from "./SideBar";

interface stateProps {
  active?: any;
  setActive?: any;
}
const SettingSideBar = ({ active, setActive }: stateProps) => {
  const [textFieldValue, setTextFieldValue] = useState("");
  const handleSearch = (labelOptionValue: any) => {
    //...
    console.log(labelOptionValue);
  };
  const styles = {
    customShadow: {
      boxShadow: "0px 2px 24px 0px rgba(15, 15, 71, 0.07)",
    },
  };

  return (
    <>
      <div className="p-5">
        <SearchBar
          style={styles.customShadow}
          value={textFieldValue}
          onChange={(newValue) => setTextFieldValue(newValue)}
          onSearch={handleSearch}
          className="xl:block hidden"
        />
      </div>
      <SideBar
        icon={cgIcons.CgProfile}
        iconColor={`${active === "profile" ? "#605BFF" : "#0F0F47"} `}
        tittle="Profile"
        tittleColor={`${active === "profile" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Details about your personal information"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("profile")}
      />
      <SideBar
        icon={riIcons.RiShieldLine}
        iconFill={`${active === "company" ? "#605BFF" : "#0F0F47"} `}
        tittle="Your Company"
        tittleColor={`${active === "company" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Details about your company information"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("company")}
      />
      <SideBar
        icon={tbIcons.TbPlugConnected}
        iconFill="none"
        iconColor={`${active === "integration" ? "#605BFF" : "#0F0F47"} `}
        tittle="Integrations"
        tittleColor={`${active === "integration" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Supercharge your workflow"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("integration")}
      />
      <SideBar
        icon={peapleIcon}
        iconFill={`${active === "users" ? "#605BFF" : "#0F0F47"} `}
        tittle="User Management"
        tittleColor={`${active === "users" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Manage your team members"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("users")}
      />
      <SideBar
        icon={securityIcon}
        iconFill={`${active === "channel" ? "#605BFF" : "#0F0F47"} `}
        tittle="Channels"
        tittleColor={`${active === "channel" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Customize your channel"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("channel")}
      />
      <SideBar
        icon={channelIcon}
        iconFill={`${active === "security" ? "#605BFF" : "#0F0F47"} `}
        tittle="Privacy and Security"
        tittleColor={`${active === "security" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Customize your channel"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("security")}
      />
      <SideBar
        icon={PreferencesIcon}
        iconFill={`${active === "preferences" ? "#605BFF" : "#0F0F47"} `}
        tittle="Preferences"
        tittleColor={`${active === "preferences" ? "[#605BFF]" : "[#0F0F47]"} `}
        content="Customize your color"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("preferences")}
      />
      <SideBar
        icon={ioIcons.IoIosNotificationsOutline}
        iconFill={`${active === "notification" ? "#605BFF" : "#0F0F47"} `}
        tittle="Notifications"
        tittleColor={`${
          active === "notification" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Customize your notifications"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("notification")}
      />
      <SideBar
        icon={siIcons.SiLogseq}
        iconFill={`${active === "systemLogs" ? "#605BFF" : "#0F0F47"} `}
        tittle="System Logs"
        tittleColor={`${
          active === "systemLogs" ? "[#605BFF]" : "[#0F0F47]"
        } `}
        content="Details about perfomed Activities"
        contentColor="[#0F0F47B2]"
        onClick={() => setActive("systemLogs")}
      />
    </>
  );
};
export default SettingSideBar;
