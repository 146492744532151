import { notification } from "antd";
import { myProformaActions } from ".";
import {
  createServiceProforma,
  getAllServiceProforma,
  addOneItemServiceProforma,
  getProformaItemService,
  updateProforomaItemService,
  getSelectedProformaItemsService,
} from "./services";


export const createProformaAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await createServiceProforma(data, token);
      const resAll = await getAllServiceProforma(token, "");

      // console.log("::::>>>>>>>::", res);
      if (res?.status === 201) {
        dispatch(myProformaActions.setNew(res));
        dispatch(myProformaActions.setAll(resAll));
        dispatch(myProformaActions.setIsFetching(false));
        notification.success({message:"Thank You! Your Quotation has been sent to SAMPHONE!"})
        return true;
      }
      else{
        notification.warning({message:"We have received your Quotation Thank You!"})
      }
      dispatch(myProformaActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProformasAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await getAllServiceProforma(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myProformaActions.setAll(res));
        dispatch(myProformaActions.setIsFetching(false));
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedProformaAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await getAllServiceProforma(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200 && res?.data.length === 1) {
        dispatch(myProformaActions.setSelected(res?.data[0]));
        dispatch(myProformaActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFQ ID Is Not Exist!" });
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemToSelectedProformaAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await addOneItemServiceProforma(id, data, token);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        const resSelected = await getAllServiceProforma(
          token,
          `?quotationId=${res?.data.quotationId}`
        );
        dispatch(myProformaActions.setSelected(resSelected?.data[0]));
        dispatch(myProformaActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFQ ID Is Not Exist!" });
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProformaItemAction = (token: string, quotId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await getProformaItemService(token, quotId);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myProformaActions.setSelected(res?.data));
        dispatch(myProformaActions.setIsFetching(false));
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateProformaAction = (id: string, data: any, token: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await updateProforomaItemService(id, data, token);
      const profItemRes = await getProformaItemService(token, data?.quotId);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        // dispatch(myProformaActions.setSelected(res));
        // dispatch(myProformaActions.setAll(resAll));
        dispatch(myProformaActions.setSelected(profItemRes?.data));
        dispatch(myProformaActions.setIsFetching(false));
        notification.success({message:"Added To P.O!"})
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllSelectedProformasAction = (token: string, quotation: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProformaActions.setIsFetching(true));
      const res = await getSelectedProformaItemsService(token, quotation);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myProformaActions.setAll(res));
        dispatch(myProformaActions.setIsFetching(false));
      }
      dispatch(myProformaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};