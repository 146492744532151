import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../utils/converter";

export const settingDropDownRoutes = [
  {
    caption: "Overview",
    path: "/setting",
    onClick: () => {
      console.log("Overview:00");
    },
  },
  {
    caption: "Manage Users",
    path: "/setting/employee",
    onClick: () => {
      console.log("sale:00");
    },
  },
  {
    caption: "Vault",
    path: "/setting/vault",
  },
  {
    caption: "Terms & Conditions",
    path: "/setting/terms&conditions",
  },
];

const HrRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  const user = getUser();

  const allowedRoles = ["admin", "hr", "dev"];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role || user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/setting",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Manage Users",
      path: "/setting/employee",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];

  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Settings".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={9}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default HrRoutes;
