import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Form,
  Row,
  Col,
  notification,
  Input,
  DatePicker,
  DatePickerProps,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { updateRepairStatusAction } from "../../store/repair/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const RepairDelivererOutForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, repairs } = useSelector((state: any) => state);
  const [date, setDate] = useState("");

  const onFinish = async (values: any) => {
    await updateRepairStatusAction(auth?.token, props?.completedDataId?.key, {
      delivererOut: {
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        company: values?.company,
        date: date,
      },
      status: "transit-out",
      isNotify: true,
      notification: {
        action: "Repair Completed",
        role: ["admin", "sales-supervisor", "sales-agent", "technician"],
        message: `${props?.completedDataId?.model} Repaired Succesfully`,
        title: "Repair Completed",
      },
    })(dispatch);
    notification.success({ message: "Well Done :)" });
    form.resetFields();
    props?.onCancel();
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current < moment().startOf("day")) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="">
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="font-bold text-2xl text-gray-900 pb-4 pl-16">
                {`Deliverer for ${props?.completedDataId?.model}`}
              </h1>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="name"
                  style={{ width: 350 }}
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="Telephone"
                  style={{ width: 350 }}
                  rules={[
                    {
                      required: true,
                      message: "Telephone is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Email" style={{ width: 350 }}>
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="company"
                  label="Company"
                  style={{ width: 350 }}
                  rules={[
                    {
                      required: true,
                      message: "Company is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item name="date" label="Date">
                  <DatePicker
                    onChange={onChangeDate}
                    style={{ width: 240 }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="w-2/5 flex items-center justify-between mx-20"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 300 }}
                  loading={repairs.isFetching}
                >
                  Submit
                </LoadingButton>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default RepairDelivererOutForm;
