import React from "react";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import { useNavigate } from "react-router-dom";

const PurchaseOrderCard = (props: any) => {
  const navigate = useNavigate();
  return (
    <div
      className="xw-[23%] w-72 bg-white text-black p-2 rounded-md space-y-6 transform hover:-translate-y-2 duration-200 hover:shadow-md"
      onClick={() => navigate(`details/${props?.data?._id}`)}
    >
      <div>
        <div className="flex justify-between w-full">
          <div>
            <h1>{props?.data?.supplier}</h1>
            <p className="text-xs" style={{ color: "blue" }}>
              {props?.data?.poId}
            </p>
          </div>
          <div>
            <Tag
              color={searchValue(props?.data?.poStatus)}
              style={{ minWidth: "4rem", textAlign: "center" }}
            >
              {props?.data?.poStatus}
            </Tag>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <p className="text-xs">{props?.data?.deliveryDeadline}</p>
        <p className="text-xs font-bold">
          {props?.data?.totalAmount?.toLocaleString()} {" Rwf"}
        </p>
      </div>
    </div>
  );
};

export default PurchaseOrderCard;
