import React from "react";
import { Table, Tag } from "antd";
import HoverDropDown from "../DropDown/HoverDropDown";
import type { MenuProps } from "antd";
import { GrFormView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import { SlEye } from "react-icons/sl";

const PurchaseTable = (props: any) => {
  const { auth, ebmPurchase, ebmGeneral } = useSelector((state: any) => state);
  //   const items: MenuProps['items'] = [
  //     // {
  //     //     label: <div className='flex gap-2'><GrFormView size={25}/><span className="text-sm pt-1">View Details</span></div>,
  //     //     key: '0',
  //     //     onClick: () => props?.open(),
  //     // },
  //     {
  //         label: <div className='flex gap-3'><FiEdit  size={22}/><span className="text-sm pt-1">Modify</span></div>,
  //         key: '1',
  //         onClick: () => props?.modalOpen(),
  //     },
  //     {
  //         label: <div className='flex gap-2'><MdDeleteOutline  size={25}/><span className="text-sm pt-1">Delete</span></div>,
  //         key: '3',
  //     },
  // ];
  const createMenuItems = (record: any): MenuProps["items"] => [
    {
      label: (
        <div className="flex gap-2">
          <SlEye size={20} className="pt-1" />
          <span className="text-sm pt-1">View Details</span>
        </div>
      ),
      key: "0",
      onClick: () => props?.open(record),
    },
    {
      label: (
        <div className="flex gap-3" onClick={() => props?.modalOpen(record)}>
          <FiEdit size={20} className="pt-1 opacity-80" />
          <span className="text-sm pt-1">Modify</span>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="flex gap-2">
          <MdDeleteOutline size={22} className="pt-1 opacity-80" />
          <span className="text-sm pt-1">Delete</span>
        </div>
      ),
      key: "3",
    },
  ];

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "id",
    },
    {
      title: "Supplier",
      dataIndex: "name",
    },
    {
      title: "Supply Date",
      dataIndex: "date",
    },
    {
      title: "Accept Date",
      dataIndex: "acceptDate",
    },
    {
      title: "Purchase Code",
      dataIndex: "tin",
    },
    {
      title: "Purchase Amount",
      dataIndex: "price",
    },
    {
      title: "VAT",
      dataIndex: "vat",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (record: any) => {
        const value = ebmGeneral?.selectOption
          ? getNameByCode(ebmGeneral?.selectOption["Purchase Status"], record)
          : "";
        return (
          <>
            <Tag
              className={`w-[8rem] text-center ${value === "Approved"
                  ? "text-[#0FA91E] border border-[#0FA91E40] bg-[#0FA91E0D]"
                  : value === "Wait for Approval"
                    ? "text-[#030229B2] border border-[#03022940] bg-[#0302290D]"
                    : value === "Cancel Requested"
                      ? "text-[#FA3659] border border-[#EB461240] bg-[#EB46120D]"
                      : value === "Canceled"
                        ? "text-[#FA3659] border border-[#FA3659]"
                        : value === "Refunded"
                          ? "text-[#FB923C] border border-[#FB923C40] bg-[#FB923C0D]"
                          : value === "Transfered"
                            ? "text-[#F19F1D80] border border-[#F19F1D80]"
                            : ""
                } rounded-full py-2 font-normal text-[12px]`}
            >
              {value}
            </Tag>
          </>
        );
      },
    },
    {
      title: " ",
      dataIndex: "data",
      render: (record: any) => (
        <>
          <HoverDropDown items={createMenuItems(record)} />
        </>
      ),
    },
  ];
  const data = ebmPurchase?.all?.itemList
    ? ebmPurchase?.all?.itemList?.map((el: any) => {
      console.log("el", el);
      return {
        key: el?._id,
        id: el?.deliveryID?.slice(3, 9)?.replace("-", ""),
        name: el?.listOfItems?.map((d: any) => d?.po?.supplier?.name),
        date: el?.listOfItems?.map((d: any) =>
          d?.po?.supplier?.createdAt?.slice(0, 10)
        ),
        acceptDate: el?.pOrderID?.quotation?.confirmationDate?.slice(0, 10),
        qauntity: "2",
        tin: el?.listOfItems?.map((d: any) => d?.po?.supplier?.tin),
        price: el?.listOfItems?.reduce(
          (accumulator: any, item: any) =>
            accumulator + item?.purchaseItem?.priceUnit,
          ""
        ),
        vat: 0,
        status: el?.ebmPurchaseStatus,
        data: el,
      };
    })
    : ebmPurchase?.all
      ? ebmPurchase?.all?.map((el: any) => {
        return {
          key: el?._id,
          id: el?.deliveryID?.slice(3, 9)?.replace("-", ""),
          name: el?.listOfItems?.map((d: any) => d?.po?.supplier?.name),
          date: el?.listOfItems?.map((d: any) =>
            d?.po?.supplier?.createdAt?.slice(0, 10)
          ),
          acceptDate: el?.pOrderID?.quotation?.confirmationDate?.slice(0, 10),
          qauntity: "2",
          tin: el?.listOfItems?.map((d: any) => d?.po?.supplier?.tin),
          price: el?.listOfItems?.reduce(
            (accumulator: any, item: any) =>
              accumulator + item?.purchaseItem?.priceUnit,
            ""
          ),
          vat: 0,
          status: el?.ebmPurchaseStatus,
          data: el,
        };
      })
      : [];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        loading={ebmPurchase?.isFetching}
      />
    </>
  );
};

export default PurchaseTable;
