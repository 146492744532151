import { notification } from "antd";
import { mySupplierActions } from ".";
import {
  createServiceSupplier,
  getAllServiceSupplier,
  updateServiceSupplier,
  deleteServiceSupplier,
  getOneServiceSupplierById,
  getAllOtherServicesSupplier,
  getAllSupplierByBrandService,
} from "./services";

export const createSupplierAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await createServiceSupplier(data, token);
      const resAll = await getAllServiceSupplier(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(mySupplierActions.setNew(res));
        dispatch(mySupplierActions.setAll(resAll));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneSupplierAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getOneServiceSupplierById(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(mySupplierActions.setSelected(res?.data));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllSuppliersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getAllServiceSupplier(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(mySupplierActions.setAll(res));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSupplierActions = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await deleteServiceSupplier(itemId, token);
      // console.log(" delete res", res)
      if (res?.status === 200) {
        const resAll = await getAllServiceSupplier(token, "");
        // dispatch(mySupplierActions.setAll(res));
        dispatch(mySupplierActions.setAll(resAll));
        dispatch(mySupplierActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSupplierActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await updateServiceSupplier(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllServiceSupplier(token, "");
        dispatch(mySupplierActions.setUpdated(res));
        dispatch(mySupplierActions.setSelected(res?.data));
        dispatch(mySupplierActions.setAll(resAll));
        dispatch(mySupplierActions.setIsFetching(false));
        dispatch(mySupplierActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllSuppliersByBrandAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getAllSupplierByBrandService(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(mySupplierActions.setSuppliersByBrand(res));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//  --------------------------------------->> Manage other releted data <<---------------------------------------------------------
export const getAllQuotationsSuppliersAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getAllOtherServicesSupplier(
        token,
        "quotSupplier",
        query
      );
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(mySupplierActions.setQuotations(res.data));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllPOsSuppliersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getAllOtherServicesSupplier(token, "purchase", query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(mySupplierActions.setPurchaseOrders(res.data));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// ---- Not yet used --------------------------------
export const getAllIbmInvoicesSuppliersAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySupplierActions.setIsFetching(true));
      const res = await getAllOtherServicesSupplier(
        token,
        "quotSupplier",
        query
      );
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        // dispatch(mySupplierActions.setQuotations(res.data));
        dispatch(mySupplierActions.setIsFetching(false));
      }
      dispatch(mySupplierActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
