import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { LineChart } from "react-chartkick";
import { useSelector } from "react-redux";
import DashboardMinimalCard from "../DashboardMinimalCard";
import NoSale from "../NoSale";
import {
  calculatePercentageChange,
  convertArrayToObjectNew,
  convertResultsRevenueToObject,
} from "../../../utils/converter";
import DashboardMinimalCardForQtySold from "../DashboardMinimalCardForQtySold";
import DashboardIkosoraMainCard from "../DashboardIkosoraMainCard";
import CustomSkeleton from "../../skeleton/CustomSkeleton";

const DashboardTopSection = (props: any) => {
  const { dashboard, layout } = useSelector((state: any) => state);
  const toDayDate = new Date().toISOString().slice(0, 10);
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let previousDay = currentDate.toISOString().split("T")[0];

  const data = [
    {
      name: toDayDate === props?.enteredDate ? "Today" : props?.enteredDate,
      data: convertArrayToObjectNew(
        dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueByHour
      ),
      color: "#2943D6",
    },
    {
      name:
        previousDay === props?.compareDate ? "Yesterday" : props?.compareDate,
      data: convertArrayToObjectNew(
        dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate
          ?.revenueByHour
      ),
      color: "#C4C4C4",
    },
  ];

  const dataByRange = [
    {
      name: `${props?.enteredDate} - ${props?.compareDate}`,
      data: convertResultsRevenueToObject(
        dashboard?.revenueAndQtyStats?.data?.revenue?.currentRevenue?.results
      ),
      color: "#2943D6",
    },
  ];

  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  const todayRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate?.revenueTotal;

  const todayRevenueByRange =
    dashboard?.revenueAndQtyStats?.data?.revenue?.currentRevenue
      ?.allTotalRevenue ?? 0;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );
  const getTotalRevenueFromQuantitySold = () => {
    return (
      dashboard?.quantitySoldStats?.data
        ?.map((item: any) => item?.currentRevenue)
        ?.reduce(
          (total: number, currentRevenue: number) => (total += currentRevenue),
          0
        ) || 0
    );
  };

  const totalReceived = (obj: any) => {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === "number") {
        sum += obj[key];
      }
    }
    return sum;
  };
  //
  return (
    <div className="flex flex-col w-full 2xl:flex-row  h-fit mx-auto mt-4 gap-y-2 gap-x-4">
      <div className="flex-1 flex  2xl:w-[65%] flex-col w-full gap-2 relative">
        <div className=" flex-auto bg-white p-2 max-h-[432px] rounded-md">
          <p className="text-gray-400 capitalize xmb-2">Total Revenue</p>
          {dashboard?.isFetching ? (
            <CustomSkeleton />
          ) : (
            <>
              {layout?.dashboardPreview === "day" ? (
                <p className="text-2xl font-semibold">
                  {dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate
                    ? "RWF " +
                      dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueTotal?.toLocaleString()
                    : "RWF 0"}
                </p>
              ) : (
                <p className="text-2xl font-semibold">
                  {"RWF " + todayRevenueByRange?.toLocaleString() ?? 0}
                </p>
              )}
            </>
          )}

          {dashboard?.isFetching ? (
            <CustomSkeleton />
          ) : (
            <>
              {layout?.dashboardPreview === "day" && (
                <p className="text-gray-400 capitalize">
                  {dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate
                    ? "RWF " +
                      dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate?.revenueTotal?.toLocaleString()
                    : "RWF " +
                        getTotalRevenueFromQuantitySold()?.toLocaleString() ??
                      0}
                </p>
              )}
            </>
          )}

          {toDayDate === props?.enteredDate ? (
            <div
              className={`${
                layout?.dashboardPreview === "range" ? "hidden" : "absolute"
              } top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : percentageChange.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : percentageChange.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : percentageChange.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage + "%"}
              </span>
            </div>
          ) : (
            <div
              className={`${
                layout?.dashboardPreview === "range" ? "hidden" : "absolute"
              } top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : percentageChange.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : percentageChange.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : percentageChange.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage + "%"}
              </span>
            </div>
          )}

          {layout?.dashboardPreview === "day" ? (
            <LineChart
              data={data}
              prefix="RWF "
              thousands=","
              xtitle="Hours"
              // height={"85%"}
              loading="Loading..."
              options={lineOptions}
              legend={true}
            />
          ) : (
            <LineChart
              data={dataByRange}
              prefix="RWF "
              thousands=","
              xtitle="Days"
              //             // height={"85%"}
              loading="Loading..."
              options={lineOptions}
              legend={true}
            />
          )}
        </div>
        {!props?.hasIkosora && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <DashboardMinimalCardForQtySold
              title="quantity sold"
              firstDate={props?.enteredDate}
              secondDate={props?.compareDate}
            />
            <DashboardMinimalCard
              title="Data Activation"
              firstDate={props?.enteredDate}
              secondDate={props?.compareDate}
            />
          </div>
        )}
      </div>

      {/* Number Of Sales */}
      {!props?.hasIkosora ? (
        <NoSale />
      ) : (
        <div className="grid md:grid-cols-2 grid-cols-1 2xl:w-1/2 gap-4">
          <DashboardIkosoraMainCard
            title="Total In Warehaouse"
            currentTotalQty={dashboard?.ikosoraStockStats?.data
              ?.at(0)
              ?.status?.in?.toLocaleString()}
          />
          <DashboardIkosoraMainCard
            title="Total In Shop"
            currentTotalQty={dashboard?.ikosoraStockStats?.data
              ?.at(0)
              ?.status?.out?.toLocaleString()}
          />
          <DashboardIkosoraMainCard
            title="Total Received"
            currentTotalQty={totalReceived(
              dashboard?.ikosoraStockStats?.data?.at(0)?.status
            )}
          />
          <DashboardIkosoraMainCard
            title="Total Sold"
            currentTotalQty={dashboard?.ikosoraStockStats?.data
              ?.at(0)
              ?.status?.sold?.toLocaleString()}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardTopSection;
