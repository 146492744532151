import React from "react";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useSelector } from "react-redux";

const ThreeDotDropdown = (props: any) => {
  const { auth } = useSelector((state: any) => state);

  let items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <IconButton
          aria-label="fingerprint"
          color="primary"
          size="small"
          onClick={() => props?.onUpdate()}
        >
          <BorderColorIcon /> Edit
        </IconButton>
      ),
    },
    {
      key: "2",
      label: (
        <>
          {["admin", "finance", "finance-manager", "dev"].includes(
            auth?.user?.role?.toLowerCase()
          ) && (
            <IconButton
              aria-label="fingerprint"
              color="error"
              size="small"
              onClick={() => props?.onDelete()}
            >
              <DeleteIcon /> Delete
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <BsThreeDotsVertical color="black" />
    </Dropdown>
  );
};

export default ThreeDotDropdown;
