import React from "react";
import { Button, Skeleton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackButton from "../../buttons/BackButton";
import { Modal } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomSkeleton from "../../skeleton/CustomSkeleton";

const DetailsHeaderActionBar: React.FC<{
  title?: string;
  pageName?: string;
  modalComponent?: any;
  goBack?: any;
  buttonsComponent?: any;
  modelTitle?: string;
  isModalOpen?: boolean;
  handleCancel?: any;
  setIsModalOpen?: any;
  isNotAddButton?: boolean;
  item?: string;
  goForward?: any;
  showButton?: boolean;
  isLoading?: boolean;
}> = ({
  title,
  pageName,
  modalComponent,
  goBack,
  buttonsComponent,
  modelTitle,
  isModalOpen,
  handleCancel,
  setIsModalOpen,
  isNotAddButton,
  item,
  goForward,
  showButton,
  isLoading,
}) => {
  const navigate = useNavigate();
  const showModal = () => {
    !goForward && setIsModalOpen(true);
    goForward && navigate(goForward);
  };
  return (
    <div className="flex justify-between mb-2 pt-2 ">
      <div className="space-x-1 font-bold">
        <div className="flex items-center flex-row gap-2">
          <div className="border border-gray-600 rounded-full">
            <BackButton onClick={goBack || (() => navigate(-1))} />
          </div>
          <span className="capitalize">{pageName}</span>
          {isLoading ? (
            <CustomSkeleton />
          ) : (
            <Stack direction={"row"} spacing={1} className="capitalize">
              <span>/</span>
              {!title && <Skeleton variant="text" sx={{ width: 130 }} />}
              {title && <span>{title}</span>}
            </Stack>
          )}
        </div>
      </div>

      <div>{buttonsComponent && <div>{buttonsComponent}</div>}</div>
      {showButton && (
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={showModal}
          sx={{ minWidth: 250 }}
        >
          Add New {item}
        </Button>
      )}

      <Modal
        title={modelTitle}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">{modalComponent}</div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsHeaderActionBar;
