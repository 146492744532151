import React from "react";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import "./styles.css";
import Dropdown from "../../../../components/Dropdown";
import { useSelector } from "react-redux";
const OverviewChart = () => {
  const { overView } = useSelector((state: any) => state);
  const options = [
    { label: "Quarterly", value: "Quarterly" },
    { label: "Quarterly1", value: "Quarterly1" },
    { label: "Quarterly2", value: "Quarterly2" },
  ];
  const statistics = overView.all.data;
  console.log("stats", statistics);
  const data = statistics.map((statistic: any) => ({
    name: statistic?.name?.substring(0, 3),
    value: statistic?.value,
  }));

  return (
    <div className="bg-[#fff] w-[60%] h-[28rem] p-8">
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <div className="text-[#0F0F47] font-bold text-2xl">Overview</div>
          <div className="text-[#0F0F47] font-extralight text-sm">
            Monthly Earning
          </div>
          <div className="text-[#0F0F47] font-bold text-xl">RWF 2,571,000</div>
        </div>
        <div>
          <div className="flex flex-row items-center rounded-md">
            <Dropdown defaultTab="Quarterly" options={options} />
          </div>{" "}
        </div>{" "}
      </div>

      <BarChart
        width={730}
        height={300}
        data={data}
        margin={{
          top: 20,
          bottom: 5,
          left: 4,
        }}
        barSize={45}
      >
        <XAxis
          dataKey="name"
          scale="point"
          padding={{ left: 25, right: 25 }}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "9px" }}
        />

        <Tooltip />
        <Bar dataKey="value" className="fill-[#605BFF]" shape={<CustomBar />} />
      </BarChart>
    </div>
  );
};

const CustomBar = (props: any) => {
  const { fill, x, y, width, height, payload } = props;

  const borderRadius = 8;
  const value = payload.value;
  const fillColor = value > 9800 ? "#605BFF" : "#F2EFFF";
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
        fill={fillColor}
        // fill={fill}
      />
    </g>
  );
};

export default OverviewChart;
