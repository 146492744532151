import { notification } from "antd";
import { myTrackActions } from ".";
import {
  getAllPackageTrackService,
  getAllTrackActivitiesService,
} from "./services";

export const getAllPackageTrackAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllPackageTrackService(token, query);
      if (res?.status === 200) {
        dispatch(myTrackActions.setAll(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTrackActivitiesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllTrackActivitiesService(token, query);
      console.log(res?.status, "muri redux turebe ibyo aribyo ");
      if (res?.status === 200) {
        dispatch(myTrackActions.settrActivities(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPackageTrackIdAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllPackageTrackService(token, query);

      if (res?.status === 200) {
        dispatch(myTrackActions.setSelected(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      if (res.status === 400) {
        notification.error(res?.error);
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
