import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber, Tooltip } from "antd";
import {
  createSubOrderItemAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import {
  generateDurationArray,
  searchFuction,
} from "../../../../utils/converter";
import { getAllChannelAction } from "../../../../store/channel/actions";
import { updateOneAccountAction } from "../../../../store/account/actions";

const { Option } = Select;

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { auth, orders, channel, account } = useSelector((state: any) => state);
  const [productFilter, setProductFilter] = useState([]);
  const dispatch = useDispatch();

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  const onFinish = async (values: any) => {
    if (auth?.token) {
      await createSubOrderItemAction(auth.token, {
        ...values,
        order: props?.orderId,
      })(dispatch);
      await getOneSubsOrderAction(auth?.token, props?.orderId)(dispatch);
      form?.resetFields();
    }
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  const filteredDataWithB2BType = channel?.priceListItems?.data?.filter(
    (item: any) =>
      item?.subscriptionPlan?.some(
        (plan: any) => plan?.subscriptionType === "B2B"
      )
  );

  const foundDataWithB2BType = productFilter?.filter((item: any) =>
    item?.subscriptionPlan?.some(
      (plan: any) => plan?.subscriptionType === "B2B"
    )
  );

  const handleSelectedChannel = (values: any) => {
    if (values) {
      props?.setChannelId(values);
    } else {
      props?.setChannelId("");
    }
  };

  const handleChangeDuration = async (values: any) => {
    if (auth?.token && values && orders?.selected?.account?._id) {
      await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        {
          duration: values,
        }
      )(dispatch);
      await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  const handleChangePlanType = async (values: any) => {
    if (values && orders?.selected?.account?._id && auth?.token) {
      await updateOneAccountAction(
        auth?.token,
        orders?.selected?.account?._id,
        {
          planType: values,
        }
      )(dispatch);
      await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  const durations = generateDurationArray();

  return (
    <Stack spacing={0}>
      <Paper elevation={0} className="xpl-4 pr-4 pt-2 pb-2">
        <Form
          form={form}
          name="register"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{}}
          style={{ maxWidth: "100%" }}
          scrollToFirstError
        >
          <div className="space-x-1.5 grid grid-cols-2">
            <Form.Item
              name="chnnl"
              label={<span className="font-medium text-sm">Channel</span>}
              style={{ marginBottom: "30px" }}
            >
              <Select
                showSearch
                allowClear
                loading={channel.isFetching}
                disabled={channel.isFetching}
                style={{ width: 350, height: 45 }}
                placeholder="Channels"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={handleSelectedChannel}
              >
                {channel?.all?.data?.map((channel: any) => {
                  return (
                    <Option key={channel._id} value={channel._id}>
                      {channel?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="product"
              label={<span className="font-medium text-sm">Model</span>}
              rules={[
                {
                  required: true,
                  message: "Model is required!",
                },
              ]}
              style={{ width: 300 }}
            >
              <Select
                showSearch
                onSearch={(value: any) =>
                  searchFuction(
                    value,
                    channel?.priceListItems?.data,
                    (option: any, val) =>
                      option?.product?.model
                        ?.toLowerCase()
                        .includes(val.toLowerCase()),
                    setProductFilter
                  )
                }
                allowClear
                loading={channel.isFetching}
                disabled={channel.isFetching}
                style={{ width: 350, height: 45 }}
                placeholder="Select By Model"
                optionFilterProp="children"
                filterOption={false}
              >
                {productFilter.length > 0
                  ? foundDataWithB2BType?.map((pro: any) => {
                      return (
                        <Option key={pro._id} value={pro._id}>
                          <Tooltip
                            placement="rightTop"
                            title={`${pro?.subscriptionPlan[0]?.monthlyInstalment?.toLocaleString()} RWF`}
                          >
                            <span className="w-full">
                              <p className="-mb-3">{pro?.product?.model}</p>
                              <div className="text-xs text-gray-400 pt-2">
                                {pro?.specification
                                  ?.slice(2, 6)
                                  ?.map((spec: any) => {
                                    if (spec[1] && !["Brand"].includes(spec[0]))
                                      return spec.join(": ");
                                  })
                                  .join(", ")}
                              </div>
                            </span>
                          </Tooltip>
                        </Option>
                      );
                    })
                  : filteredDataWithB2BType &&
                    filteredDataWithB2BType?.map((pro: any) => {
                      return (
                        <Option key={pro._id} value={pro._id}>
                          <Tooltip
                            placement="rightTop"
                            title={`${pro?.subscriptionPlan[0]?.monthlyInstalment?.toLocaleString()} RWF`}
                          >
                            <span className="w-full">
                              <p className="-mb-3">{pro?.product?.model}</p>
                              <div className="text-xs text-gray-400 pt-2">
                                {pro?.specification
                                  ?.slice(2, 6)
                                  ?.map((spec: any) => {
                                    if (spec[1] && !["Brand"].includes(spec[0]))
                                      return spec.join(": ");
                                  })
                                  .join(", ")}
                              </div>
                            </span>
                          </Tooltip>
                        </Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </div>

          <div className="space-x-1.5 grid grid-cols-3">
            <Form.Item
              name="plnD"
              label={<span className="font-medium text-sm">Plan Duration</span>}
            >
              <Select
                showSearch
                allowClear
                loading={account.isFetching || channel.isFetching}
                disabled={account.isFetching || channel.isFetching}
                style={{ width: 220, height: 45 }}
                placeholder="Duration"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={handleChangeDuration}
                defaultValue={orders?.selected?.account?.duration}
              >
                {durations?.map((duration: any, index: number) => {
                  return (
                    <Option key={index} value={duration}>
                      {duration}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="plnT"
              label={
                <span className="font-medium text-sm">Installment Plan</span>
              }
            >
              <Select
                showSearch
                allowClear
                loading={account.isFetching || channel.isFetching}
                disabled={account.isFetching || channel.isFetching}
                style={{ width: 220, height: 45 }}
                placeholder="Plan Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={handleChangePlanType}
                defaultValue={orders?.selected?.account?.planType}
              >
                {["monthly", "weekly", "daily"]?.map(
                  (plan: any, index: number) => {
                    return (
                      <Option key={index} value={plan} className="capitalize">
                        {plan}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="quantity"
              label={<span className="font-medium text-sm">Quantity</span>}
              style={{ marginBottom: "30px" }}
              rules={[
                {
                  required: true,
                  message: "Quantity is required!",
                },
              ]}
            >
              <InputNumber
                min={1}
                max={10000000}
                style={{ width: 230, height: 45 }}
                // onChange={onChange}
              />
            </Form.Item>
          </div>

          <LoadingButton
            type="submit"
            variant="contained"
            className="w-32"
            loading={orders?.newOrderIsFetching}
          >
            Add Item
          </LoadingButton>
        </Form>
      </Paper>
    </Stack>
  );
};

export default PageView;
