import React from "react";
import * as convert from "../../assets/data/productExport";
import StreamCard from "./StreamCard";

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportChannelData(
      d.name,
      d.address,
      d.email,
      d.isActive,
      d.contact,
      d?.isEnabled,
      d
    )
  );

  return data?.map((d: any) => d?.isEnabled && <StreamCard data={d} />);
};

export default App;
