import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import ProductForm from "../../../components/forms/NewProductForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import StockProductTable from "./Tables/StockProductTable";
import {
  deleteProductsAction,
  getOneProductAction,
} from "../../../store/product/actions";
import ProductDetailsTable from "../../../components/tables/ProductDetailsTable";
import { Stack } from "@mui/material";
import ProductStoreFilter from "../../../components/filters/ProductStoreFiltering";
import SupplierByBrandTable from "../../../components/tables/SupplierByBrandTable";

type TAB = {
  title: string;
  component: any;
};

const tabs: TAB[] = [
  {
    title: "Sales",
    component: (
      <div>
        Small statistiques of how this product is being saled
        {/* <ProductSalesDetailsTable /> */}
      </div>
    ),
  },
  {
    title: `Stock`,
    component: (
      <Stack direction={"column"} style={{ position: "relative" }}>
        <Stack direction={"row"} spacing={2}>
          <ProductStoreFilter />
        </Stack>
        <StockProductTable />
      </Stack>
    ),
  },
  {
    title: "Prices",
    component: (
      <Stack direction={"column"} style={{ position: "relative" }}>
        <ProductDetailsTable />
      </Stack>
    ),
  },
  {
    title: "Suppliers",
    component: (
      <Stack direction={"column"} style={{ position: "relative" }}>
        <SupplierByBrandTable />
      </Stack>
    ),
  },
];

const ProductDetails = (props: any) => {
  const { productId } = useParams();
  const { auth, product } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deleteProductsAction(auth?.token, {
        selectedIds: [product?.selected?._id],
      })(dispatch));
    navigate("/inventory/pr");
  };

  const handleClickEdit = () => {
    console.log("editing a product....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productId) {
        console.log("fetching....");
        auth?.token &&
          (await getOneProductAction(auth?.token, productId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productId, auth, dispatch]);
  return (
    <div>
      <DetailsPage
        UpdateForm={ProductForm}
        pageItemId={productId}
        specsData={product.selected && product.selected?.specs}
        tabs={tabs}
        pageName={"Products"}
        title={product?.selected?.model}
        simpleDetails={product && product.selected}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={product.isFetching}
        onCancel={handleCancels}
        itemName="Product"
      />
    </div>
  );
};

export default ProductDetails;
