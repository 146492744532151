import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "ebmGeneral",
  initialState: {
    isFetching: false,
    ebmOverview: null,
    newItem:null,
    allItems:null,
    query:null,
    attributes:null,
    allProducts:null,
    selectOption:null,
    ebmItem:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setEbmOverview(state, action) {
      state.ebmOverview = action.payload;
    },
    setAllItems(state, action) {
      state.allItems = action.payload;
    },
    setSelectOption(state, action) {
      state.selectOption = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setAllProducts(state, action) {
      state.allProducts = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    seEbmItem(state, action) {
      state.ebmItem = action.payload;
    },
    setNewItem(state, action) {
      state.newItem = action.payload;
    },
  },
});

export const ebmActions = mySlice.actions;

export default mySlice.reducer;
