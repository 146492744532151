import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../components/details/setting/DetailsPage";
import EmployeeForm from "../../../components/forms/NewEmployeeForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { Modal } from "antd";
import {
  deleteUserActions,
  getOneUserAction,
} from "../../../store/setting/actions";
import AgentTransactionTable from "../../../components/tables/AgentTranctionsTable";
import AgentShopsTable from "../../../components/tables/AgentShopsTable";
import DeactivateForm from "../../../components/forms/DeactivateForm";
import AgentNosalesTable from "../../../components/tables/AgentNosalesTable";

type TAB = {
  title: string;
  component: any;
};

const EmployeeDetails = (props: any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const showDeactivateModal = () => {
    setOpenModel(true);
  };

  const cancelDeactivateModal = () => {
    setOpenModel(false);
  };

  const tabs: TAB[] = [
    {
      title: "Sales",
      component: <AgentTransactionTable />,
    },
    {
      title: "Shop",
      component: <AgentShopsTable />,
    },
    {
      title: "No Sales",
      component: <AgentNosalesTable />,
    },
  ];

  const deleteEmploye = async () => {
    if (auth?.token) {
      const res = await deleteUserActions(
        id as string,
        { isDeleted: "true" },
        auth?.token
      )(dispatch);
      res && navigate("/setting/employee");
    }
  };

  const handleClickEdit = () => {
    console.log("editing a supplier....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    auth?.token && getOneUserAction(id as string, auth?.token)(dispatch);
  }, [id, auth?.token, dispatch]);

  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide">
      <DetailsPage
        UpdateForm={EmployeeForm}
        pageItemId={id}
        tabs={tabs}
        pageName={"Employee"}
        title={setting?.selected?.names}
        simpleDetails={setting && setting.selected}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
        showDeactivateModal={showDeactivateModal}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteEmploye}
        isLoading={setting.isFetching}
        onCancel={handleCancels}
        itemName="Employee"
      />
      <Modal
        open={openModel}
        onCancel={cancelDeactivateModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <DeactivateForm onCancel={cancelDeactivateModal} />
      </Modal>
    </div>
  );
};

export default EmployeeDetails;
