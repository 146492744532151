import HeaderComponent from "../../../../components/HeaderComponent";
import { Paper, Stack, Box } from "@mui/material";
import { Table, Tag, Spin } from "antd";
import SendIcon from "@mui/icons-material/Send";
import { Link, useParams, useNavigate } from "react-router-dom";
import { searchValue } from "../../../../utils/setColor";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import DangerButton from "../../../../components/buttons/DangerButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {
  getOneSupplierQuotationRfqSent,
  getSelectedQuotationAction,
  sendEmailAction,
  updateQuotationAction,
  updateQuotationActionRfq,
} from "../../../../store/quotation/actions";
import { myQuotationActions } from "../../../../store/quotation";
import { quotationColumns } from "../../../../components/reUsableTable/colmns";
import { LoadingOutlined } from "@ant-design/icons";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LabelValue = (props: any) => (
  <Stack direction="row" spacing={2}>
    <span className="font-bold">{props?.label}</span>
    <span> {props?.value}</span>
  </Stack>
);

const UpdateSaveButtons = (props: any) => {
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { rfqId } = useParams();
  const navigate = useNavigate();
  const handleSendEmail = async () => {
    auth?.token &&
      quotation?.allRfqSupplier?.data?.length > 0 &&
      sendEmailAction(auth.token, {
        quotationToSuppliers: quotation?.allRfqSupplier?.data?.map(
          (d: any) => d._id
        ),
      })(dispatch);
  };

  const handleCancelQuotation = async () => {
    if (auth?.token) {
      await updateQuotationAction(
        quotation?.selected?._id,
        { status: "Cancelled" },
        auth?.token
      )(dispatch);
      await updateQuotationActionRfq(rfqId as string, auth?.token, {
        status: "Cancelled",
      })(dispatch);
      navigate(-1);
    }
  };

  return (
    <div>
      {quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() !== "replied" && (
          <PrimaryButton
            onClick={async () => await handleSendEmail()}
            name="Re-send Email"
          />
        )}
      {quotation.isFetching ? (
        <Spin indicator={antIcon} style={{ width: 100 }} />
      ) : (
        quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() === "sent" && (
          <DangerButton
            onClick={async () => await handleCancelQuotation()}
            name="Cancel "
          />
        )
      )}
      {quotation?.selectedRfq?.status &&
        quotation?.selectedRfq?.status?.toLowerCase() === "sent" && (
          <Link
            to={`/reply/proforma/${quotation?.selectedRfq?._id}`}
            target="_blank"
          >
            <PrimaryButton
              // onClick={async () => ()}
              name="Manual Reply "
            />
          </Link>
        )}
      {!props.isCancel && props?.sendButtonDefault && (
        <LoadingButton
          loading={props.isLoading}
          loadingPosition="start"
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          onClick={() => props?.onClick()}
        >
          Send Quotation
        </LoadingButton>
      )}
    </div>
  );
};

const PageView = (_props: any) => {
  const { auth, quotation } = useSelector((state: any) => state);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id, rfqId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));
    // console.log("this  is my RFQID:", rfqId);
    auth?.token &&
      getSelectedQuotationAction(auth?.token, `?quotationId=${id}`)(dispatch);
    auth?.token &&
      rfqId &&
      getOneSupplierQuotationRfqSent(rfqId, auth?.token)(dispatch);
  }, [id, auth?.token, rfqId, dispatch]);

  const data: {
    type: any;
    brand: any;
    model: any;
    specification: any;
    quantity: any;
    status: string;
  }[] = [];
  const values = quotation?.selected?.listOfProducts;

  values &&
    values?.forEach((el: any) => {
      data.push({
        type: el.product.model,
        brand: el.product.brand,
        model: el.product.model,
        specification: el.specification
          .map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        quantity: el.quantity,
        status: quotation.selected.status,
      });
    });

  const handleSendEmail = async () => {
    console.log(quotation.allRfqSupplier.data.map((d: any) => d._id));
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2">
        <DetailsHeaderActionBar
          pageName="Request For Quotation"
          title={id}
          goBack={goBack}
        />
      </div>
      {!rfqId && (
        <HeaderComponent
          title={`RFQ / ${id} `}
          filterCard={
            quotation?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
              />
            ) : (
              <></>
            )
          }
          isNotAddButton={true}
          isNotCollapse={true}
          goBack="/purchase/quotations"
          isOtherButton={true}
        />
      )}
      <Paper elevation={0} style={{ marginBottom: "20px" }}>
        <Stack spacing={0}>
          {rfqId && (
            <Box className="w-[80%] max-h-full bg-white p-8 flex flex-col gap-4 relative">
              <Stack direction={"column"}>
                <span className="text-2xl font-bold">
                  Request For Quotation
                </span>
                <span className="text-xl font-medium">
                  {quotation?.selected?.quotationId}
                </span>
              </Stack>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack spacing={1}>
                  <LabelValue
                    label="Supplier Name:"
                    value={rfqId ? quotation?.selectedRfq?.supplier?.name : " "}
                  />
                  <LabelValue
                    label="Confirmation deadline:"
                    value={
                      rfqId ? quotation?.selectedRfq?.confirmationDate : " "
                    }
                  />
                </Stack>
                <div className="absolute -right-52 top-10">
                  <Stack>
                    <Paper elevation={1}>
                      <Stack direction="row" spacing={1}>
                        <h1 style={{ paddingLeft: "0.5rem" }}>Status</h1>
                        <Tag
                          color={
                            quotation?.selectedRfq?.status &&
                            searchValue(quotation?.selectedRfq?.status)
                          }
                          style={{ width: "5rem", textAlign: "center" }}
                        >
                          {quotation?.selectedRfq?.status}
                        </Tag>
                      </Stack>
                    </Paper>
                  </Stack>
                </div>
              </Stack>
            </Box>
          )}
          <Table columns={quotationColumns} dataSource={data} />
        </Stack>
      </Paper>

      {(values?.length >= 1 &&
        quotation?.selectedRfq?.status?.toLowerCase() === "Replied") ||
        ("Sent" && rfqId && (
          <UpdateSaveButtons
            //  isCancel={isCancel}
            isLoading={isLoading}
            sendButtonDefault={false} //this will depend on quotation status
            onClick={() => {
              setIsCancel(false);
            }}
            handleSendEmail={handleSendEmail}
          />
        ))}
    </Stack>
  );
};

export default PageView;
