import "./style.css";

const LoadingEllipsis = () => {
  return (
    <div id="loading-text">
      Loading<span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  );
};

export default LoadingEllipsis;
