import { useState } from "react";
import { Stack, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { Carousel, Tag } from "antd";
import { getColorByValue } from "../../utils/setColor";
import ProductDetailsModal from "../Modals/ProductDetailsModal";
import ProductDetailsCard from "../cards/ProductDetailsCard";
import UpdateProduct from "../../components/forms/NewProductForm";
import DetailsModel from "../Modals/DetailsModel";
import defaultPic from "../../assets/nophoto.png";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    setisEdit(false);
    setIsModalOpen(true);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const component = isEdit ? (
    <UpdateProduct
      dataToUpdate={props?.data}
      action={"update"}
      onCancel={handleOnCancel}
    />
  ) : (
    <ProductDetailsCard data={props?.data} />
  );

  return (
    <>
      <div className="relative w-80 h-48 p-2 pt-10 bg-white text-black rounded-md border border-white hover:border-gray-300 duration-300 xhover:shadow-md">
        <div className="absolute top-1 left-1">
          <Checkbox
            id={props?.data._id}
            onChange={props?.handleSelected}
            {...label}
            color="success"
          />
        </div>
        <Link to={`/inventory/pr/${props?.data?._id}`}>
          <div className="flex w-fit">
            <div style={{ width: "7rem" }}>
              {props?.data?.images?.length === 0 && (
                <img src={defaultPic} width={"100%"} alt="" />
              )}
              {props?.data?.images?.length > 0 && (
                <Carousel autoplay>
                  {props?.data?.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="flex flex-col pl-2">
              <h1>{props?.data.model}</h1>
              <div className=" gap-1">
                <Stack spacing={1}>
                  {props?.data &&
                    props?.data.specs
                      ?.slice(0, 2)
                      ?.map((data: any, _index: number) => (
                        <Stack>
                          <p className="text-xs">
                            <b>{data?.label}</b>
                          </p>
                          <p className="text-xs">{data?.value?.join(", ")}</p>
                        </Stack>
                      ))}
                </Stack>
              </div>
            </div>
          </div>
        </Link>
        <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
          <Tag color={getColorByValue(props?.types, props?.data.type)}>
            {props?.data?.type}
          </Tag>

          <Tag color={getColorByValue(props?.brands, props?.data?.brand)}>
            {props?.data?.brand}
          </Tag>

          <button
            aria-label="delete"
            className="text-gray-400"
            onClick={handleOnClickViewIcon}
          >
            <RemoveRedEye fontSize="small" className="h-2" />
          </button>
          {/* <button
            aria-label="edit"
            className="text-gray-400"
            onClick={handleOnEdit}
          >
            <BorderColorIcon fontSize="small" />
          </button> */}
        </div>
      </div>
      {/* product modal here */}
      {isEdit && (
        <ProductDetailsModal
          component={component}
          isModalOpen={isModalOpen}
          handleOnClickViewIcon={handleOnClickViewIcon}
          handleOnCancel={handleOnCancel}
        />
      )}
      {!isEdit && (
        <DetailsModel
          component={component}
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
          handleOnClickViewIcon={handleOnClickViewIcon}
        />
      )}
    </>
  );
};

export default Component;
