import { Modal } from "antd";
import React from "react";

const SettingModel = (props: any) => {
  return (
    <>
      <Modal
        title={props.modalTittle}
        keyboard={false}
        onCancel={props.RoleModelChanges}
        footer={null}
        open={props.handleOpenRoleModal}
        width={1000}
      >
        {props.content}
      </Modal>
    </>
  );
};
export default SettingModel;
