import React from "react";

const SupplierDetailsCard = (props: any) => {
  return (
    <div className="py-2">
      <div className="flex flex-col">
        <span>
          <strong style={{ marginRight: "10px" }}>Name:</strong>
          {props?.showData?.name}
        </span>
        <span>
          <strong style={{ marginRight: "10px" }}>Email:</strong>
          {props?.showData?.email}
        </span>
        <span>
          <strong style={{ marginRight: "10px" }}>Address:</strong>
          {props?.showData?.address}
        </span>
        <span>
          <strong style={{ marginRight: "10px" }}>type:</strong>
          {props?.showData?.type}
        </span>
      </div>
      <div>
        <h1 className="mt-4 font-bold text-lg">Employees</h1>
        {props?.showData?.employees?.map((item: any) => (
          <div className="flex mt-2">
            <span style={{ marginRight: "10px" }}>
              <strong style={{ marginRight: "10px" }}>Name:</strong>
              {item?.name}
            </span>
            <span style={{ marginRight: "10px" }}>
              <strong style={{ marginRight: "10px" }}>Title:</strong>
              {item?.position}
            </span>
            <span style={{ marginRight: "10px" }}>
              <strong style={{ marginRight: "10px" }}>Contact:</strong>
              {item?.contact}
            </span>
          </div>
        ))}
      </div>
      <span className="mt-6">
        <strong style={{ marginRight: "10px" }}>Created By:</strong>
        {props?.showData?.createdBy?.names}
      </span>
    </div>
  );
};

export default SupplierDetailsCard;
