import { notification } from "antd";
import { myCompanyActions } from ".";
import {
  campanyResizedLogoService,
  createCompanyService, getAllCompanyService, getOneCompanyService, updateCompanyService,
} from "./services";

export const createCompanyAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await createCompanyService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myCompanyActions.setNew(res));
        // dispatch(myCompanyActions.setAll(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCompanyAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getAllCompanyService(token, query);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setAll(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCompanyAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getOneCompanyService(id, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateCompanyActions = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await updateCompanyService(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllCompanyService(token, "");
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setAll(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const companySizedImageAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await campanyResizedLogoService(data, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSizedLog(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
