import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import PieChrt from "./Pie";

function VaultStatus() {
  return (
    <div className="w-[40%] bg-[#fff] p-2 h-[28rem] rounded-lg">
      <div className="flex justify-between p-4">
        <h2 className="text-[#0F0F47] font-[600] text-xl opacity-80">
          Vault status
        </h2>
        <BiDotsHorizontalRounded className="mt-2 opacity-50" />
      </div>
      <div className="mt-20 mb-20 ">
        <PieChrt />
      </div>

      <span className="text-[#0F0F47] float-right text-lg font-[400] opacity-50 ">
        500 total devices
      </span>
    </div>
  );
}

export default VaultStatus;
