import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Select,
  Form,
  Input,
  Button,
  Tooltip,
  notification,
  SelectProps,
} from "antd";
// import PriceListFilter from "../../../../components/filters/PriceListFiltering";
import {
  createSubscriptionPriceListAction,
  updateSubscriptionPriceListAction,
} from "../../../../store/subscription/priceList/actions";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import {
  getSinglePricelistItemsAction,
  getSubscriptionPricelistAction,
} from "../../../../store/channel/actions";
import { useLocation, useParams } from "react-router-dom";
import { getActivePrice, searchFuction } from "../../../../utils/converter";
import { myChannelActions } from "../../../../store/channel";
import { mySubScritionPriceList } from "../../../../store/subscription/priceList";
import "./subscription.css";
import { myLayoutActions } from "../../../../store/layout";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { auth, channel, subPriceList, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { subChannelId } = useParams();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [selectePricelisteId, setSelectedPricelisteId] = useState("");
  const [selectedMonthPlan, setSelectedMonthPlan] = useState<Array<any>>([]);
  const [selectedSubType, setSelectedSubType] = useState<Array<any>>([]);
  const [selectBeforeValue, setSelectBeforeValue] = useState<any>(0.0);
  const [amountToshow, setAmountToshow] = useState<any>(0);
  const location = useLocation();
  const [productFilter, setProductFilter] = useState([]);

  const bundleOptions: SelectProps["options"] = [];
  const smsOptions: SelectProps["options"] = [];
  const callOptions: SelectProps["options"] = [];
  const isSubsPage = () => {
    return ["/sub/"].some((path) => location.pathname.includes(path));
  };
  const isSubs = isSubsPage();

  const hasNotChannel = () => {
    return ["/subscription/devices"].some((path) =>
      location.pathname.includes(path)
    );
  };
  const isChannelNotExist = hasNotChannel();

  const phonesPrice = getActivePrice(
    channel?.selectedPriceListItems?.data?.prices ?? props?.dataToUpdate?.prices
  );

  const onFinish = async (values: any) => {
    console.log("data from form", values);
    const others: Array<any> = values?.payment?.map((item: any) => {
      return {
        ...item,
        bundle: values?.bundle,
        sms: values?.sms,
        call: values?.call,
        initialPayment: amountToshow,
        pricelistItem: values?.pricelistItem,
      };
    });
    const updateDate: Array<any> = values?.payment?.map((item: any) => {
      return {
        ...item,
        bundle: values?.bundle,
        sms: values?.sms,
        call: values?.call,
        initialPayment: amountToshow,
      };
    });

    delete values.payment;

    const payload = {
      ...values,
      initialPayment: amountToshow,
    };


    others.push(payload);
    if (props?.dataToUpdate) {
      const updatePayload = { plansToUpdate: [...updateDate] };
      // console.log("update here ....", updatePayload);
      await updateSubscriptionPriceListAction(
        updatePayload,
        auth?.token
      )(dispatch);
      notification.success({ message: "Updated Succesfully" });
    } else {
      await createSubscriptionPriceListAction(auth?.token, others)(dispatch);
    }
    props?.onCancel();
    dispatch(myChannelActions.setSelectedPriceListItems(null));
    dispatch(mySubScritionPriceList.setUpdated(null));
    await getSubscriptionPricelistAction(
      auth?.token,
      `?channel=${isChannelNotExist ? subChannelId : channel?.selected?._id
      }&page=${page - 1}&limit=${limit}&type=${layout?.subscriptionType}`
    )(dispatch);
    form.resetFields();
    setSelectedMonthPlan([]);
  };

  useEffect(() => {
    if (selectePricelisteId) {
      auth?.token &&
        getSinglePricelistItemsAction(
          auth?.token,
          selectePricelisteId
        )(dispatch);
    }
  }, [auth?.token, selectePricelisteId, dispatch]);

  const initialData = props?.dataToUpdate?.subscriptionPlan?.map(
    (data: any) => {
      return {
        _id: data?._id,
        dailyInstalment: data?.dailyInstalment,
        duration: data?.duration,
        initialPayment: data?.initialPayment,
        monthlyInstalment: data?.monthlyInstalment,
        weeklyInstalment: data?.weeklyInstalment,
        bundle: data?.bundle,
        call: data?.call,
        sms: data?.sms,
      };
    }
  );

  useEffect(() => {
    form.setFieldsValue({
      initialPayment: amountToshow,
    });
  }, [amountToshow]);

  const handleSelectBeforeChange = (value: any) => {
    setSelectBeforeValue(value);
    if (phonesPrice !== undefined) {
      const result = (phonesPrice * value) / 100;
      setAmountToshow(result);
    } else {
      setAmountToshow(0);
    }
  };

  useEffect(() => {
    handleSelectBeforeChange(selectBeforeValue);
  }, [phonesPrice]);

  const handleAmountInputChange = (amount: any) => {
    const result = (amount / phonesPrice) * 100;
    setSelectBeforeValue(result?.toFixed(1));
    setAmountToshow(amount);
  };

  useEffect(() => {
    if (props?.dataToUpdate) {
      setAmountToshow(props?.dataToUpdate?.subscriptionPlan[0]?.initialPayment);
      handleAmountInputChange(
        props?.dataToUpdate?.subscriptionPlan[0]?.initialPayment
      );
    }
  }, [props?.dataToUpdate]);


  return (
    <Stack spacing={0}>
      {/* {!props?.dataToUpdate && <PriceListFilter />} */}
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              layout="vertical"
              onFinish={onFinish}
              initialValues={props?.dataToUpdate || {}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <Stack spacing={0} direction="column">
                {/* {!initialData && ( */}
                <div className="grid grid-cols-2">
                  {!initialData && (
                    <>
                      <div className="flex flex-col -space-y-4 ">
                        <Form.Item
                          name="pricelistItem"
                          initialValue={props?.dataToUpdate?.product?.model}
                          label="Product Model"
                          rules={[
                            {
                              required: true,
                              message: "Model is required!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            onSearch={(value: any) =>
                              searchFuction(
                                value,
                                channel?.priceListItems?.data,
                                (option: any, val) =>
                                  option?.product?.model
                                    ?.toLowerCase()
                                    .includes(val.toLowerCase()),
                                setProductFilter
                              )
                            }
                            allowClear
                            loading={channel.isFetching}
                            disabled={channel.isFetching}
                            style={{ width: 320, height: 50 }}
                            placeholder="Select By Model"
                            optionFilterProp="children"
                            filterOption={false}
                            onChange={(value: any) => {
                              console.log("::", value);
                              !value && setSelectedPricelisteId("");
                              value && setSelectedPricelisteId(value);
                            }}
                          >
                            {productFilter.length > 0
                              ? productFilter
                                ?.filter(
                                  (pro: any) =>
                                    pro?.product?.type === "Smartphone"
                                )
                                ?.map((pro: any) => (
                                  <Option key={pro._id} value={pro._id}>
                                    <Tooltip
                                      placement="rightTop"
                                      title={pro?.specification

                                        ?.map(
                                          (spec: any) =>
                                            spec[1] && spec.join(": ")
                                        )
                                        .join(", ")}
                                    >
                                      <span className="w-full">
                                        <p className="-mb-3">
                                          {pro?.product?.model}
                                        </p>
                                        <div className="text-xs text-gray-400 pt-2">
                                          {pro?.specification
                                            ?.slice(2, 6)
                                            ?.map((spec: any) => {
                                              if (
                                                spec[1] &&
                                                !["Brand"].includes(spec[0])
                                              )
                                                return spec.join(": ");
                                            })
                                            .join(", ")}
                                        </div>
                                      </span>
                                    </Tooltip>
                                  </Option>
                                ))
                              : channel?.priceListItems?.data
                                ?.filter(
                                  (pro: any) =>
                                    pro?.product?.type === "Smartphone"
                                )
                                ?.map((pro: any) => (
                                  <Option key={pro._id} value={pro._id}>
                                    <Tooltip
                                      placement="rightTop"
                                      title={pro?.specification

                                        ?.map(
                                          (spec: any) =>
                                            spec[1] && spec.join(": ")
                                        )
                                        .join(", ")}
                                    >
                                      <span className="w-full">
                                        <p className="-mb-3">
                                          {pro?.product?.model}
                                        </p>
                                        <div className="text-xs text-gray-400 pt-2">
                                          {pro?.specification
                                            ?.slice(2, 6)
                                            ?.map((spec: any) => {
                                              if (
                                                spec[1] &&
                                                !["Brand"].includes(spec[0])
                                              )
                                                return spec.join(": ");
                                            })
                                            .join(", ")}
                                        </div>
                                      </span>
                                    </Tooltip>
                                  </Option>
                                ))}
                          </Select>
                        </Form.Item>
                        {phonesPrice && selectePricelisteId && (
                          <h1 className="pb-2 font-bold">{`Prices:${phonesPrice} RWF`}</h1>
                        )}
                      </div>
                      <Form.Item
                        name="subscriptionType"
                        label="Subscription Type"
                        rules={[
                          {
                            required: true,
                            message: "Subscription Type is required!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={channel.isFetching}
                          disabled={channel.isFetching}
                          style={{ width: 300 }}
                          placeholder="Select Subscription Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").toString().includes(input)
                          }
                          onChange={(value: any) => { setSelectedSubType([value]); dispatch(myLayoutActions.setSubscriptionType(value)) }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toString().toLowerCase()
                              )
                          }
                          options={["B2C", "B2B"].map((pro: any) => ({
                            label: `${pro}`,
                            value: pro,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        name="duration"
                        label="Subscription Plan"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan[0]?.duration
                        }
                        rules={[
                          {
                            required: true,
                            message: "Subscription Plan is required!",
                          },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </>
                  )}
                  {selectedSubType[0] !== "B2B" && (
                    <>
                      <Form.Item
                        name="initialPayment"
                        label={`Initial Payment (${selectBeforeValue}%)`}
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan[0]
                            ?.initialPayment
                        }
                      >
                        <Input
                          type="number"
                          disabled={
                            !phonesPrice &&
                            !selectePricelisteId &&
                            !props?.dataToUpdate
                          }
                          addonBefore={
                            <>
                              <Input
                                disabled={
                                  !phonesPrice &&
                                  !selectePricelisteId &&
                                  !props?.dataToUpdate
                                }
                                style={{
                                  width: "60px",
                                  border: "none",
                                  outline: "none",
                                  backgroundColor: "#fafafa",
                                }}
                                className="bg-red-200"
                                value={`${selectBeforeValue}`}
                                onChange={(e) =>
                                  handleSelectBeforeChange(e.target.value)
                                }
                              />
                            </>
                          }
                          value={amountToshow}
                          onChange={(e) =>
                            handleAmountInputChange(e.target.value)
                          }
                          placeholder={`${amountToshow}`}
                        />
                      </Form.Item>

                      {!initialData && (
                        <>
                          <Form.Item
                            name="dailyInstalment"
                            label="Daily Instalment"
                            initialValue={
                              props?.dataToUpdate?.subscriptionPlan[0]
                                ?.dailyInstalment
                            }
                            rules={[
                              {
                                required: true,
                                message: "Daily Instalment is required!",
                              },
                            ]}
                          >
                            <Input type="number" />
                          </Form.Item>
                          <Form.Item
                            name="weeklyInstalment"
                            label="Weekly Instalment"
                            initialValue={
                              props?.dataToUpdate?.subscriptionPlan[0]
                                ?.weeklyInstalment
                            }
                            rules={[
                              {
                                required: true,
                                message: "Weekly Instalment is required!",
                              },
                            ]}
                          >
                            <Input type="number" />
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                  {!initialData && (
                    <Form.Item
                      name="monthlyInstalment"
                      initialValue={
                        props?.dataToUpdate?.subscriptionPlan[0]
                          ?.monthlyInstalment
                      }
                      label="Monthly Instalment"
                      rules={[
                        {
                          required: true,
                          message: "Monthly Instalment is required!",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  )}
                  {
                    <>
                      <Form.Item
                        name="bundle"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan[0]?.bundle
                        }
                        label="Bundle"
                      >
                        <Select
                          mode="tags"
                          style={{ width: 320 }}
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan[0]?.bundle
                          }
                          options={bundleOptions}
                        />
                      </Form.Item>
                      <Form.Item
                        name="sms"
                        label="SMS"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan[0]?.sms
                        }
                      >
                        <Select
                          style={{ width: 290 }}
                          mode="tags"
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan[0]?.sms
                          }
                          options={smsOptions}
                        />
                      </Form.Item>
                      <Form.Item
                        name="call"
                        label="Call"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan[0]?.call
                        }
                      >
                        <Select
                          mode="tags"
                          style={{ width: 315, marginLeft: '2px' }}
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan[0]?.call
                          }
                          options={callOptions}
                        />
                      </Form.Item>
                    </>
                  }
                </div>
                {/* )} */}
                <Form.List
                  name="payment"
                  {...formItemLayout}
                  initialValue={initialData ?? []}
                >
                  {(fields, { add, remove }) => {
                    const addButtonDisabled = fields.length <= 2;
                    return (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div className="flex flex-col pb-1 mb-3 relative">
                            <hr className="mt-10 border-b" />
                            <p className="mb-2 text-gray-500">
                              Plan information: [x]{" "}
                              {initialData ? key + 1 : key + 2}
                            </p>

                            <div className="grid grid-cols-2">
                              <Form.Item
                                {...restField}
                                name={[name, "duration"]}
                                label="Subscription Plan"
                                // initialValue={
                                //   props?.dataToUpdate?.subscriptionPlan[0]
                                //     ?.duration
                                // }
                                rules={[
                                  {
                                    required: true,
                                    message: "Subscription Plan is required!",
                                  },
                                ]}
                              >
                                <Input type="number" />
                              </Form.Item>
                              {selectedSubType[0] !== "B2B" && (
                                <>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "dailyInstalment"]}
                                    label="Daily Instalment"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Daily Instalment is required!",
                                      },
                                    ]}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "weeklyInstalment"]}
                                    label="Weekly Instalment"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Weekly Instalment is required!",
                                      },
                                    ]}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                </>
                              )}

                              <Form.Item
                                {...restField}
                                name={[name, "monthlyInstalment"]}
                                label="Monthly Instalment"
                              >
                                <Input type="number" />
                              </Form.Item>
                            </div>
                            <Button
                              onClick={() => { initialData && remove(name) }}
                              className="flex rounded-md shadow gap-x-2 py-2 w-fit px-3 mt-6 border text-red-500 absolute top-6 right-0"
                            // disabled={initialData}
                            >
                              <MinusCircleOutlined className=" my-auto" />
                            </Button>
                          </div>
                        ))}

                        <Form.Item className="absolute top-10 right-8">
                          <Button
                            onClick={() => {
                              addButtonDisabled && add();
                            }
                            }
                            className="shadow !m-0 justify-between !items-center"
                          >
                            <PlusOutlined />
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  className="w-32"
                  loading={subPriceList?.isFetching}
                >
                  {props?.dataToUpdate ? "Update" : "Submit"}
                </LoadingButton>
              </Stack>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default PageView;
