import { Link } from "react-router-dom";
import { Carousel } from "antd";
import { searchValue } from "../../../utils/setColor";

const ProductModelCard = (props: any) => {
  console.log(props);
  return (
    <>
      <div className="relative w-[530px] h-min p-2 pt-10 bg-white text-black rounded-md border hover:border-black">
        {/* <Link to={`/inventory/ws/stock/${props?.product?._id}/list`}> */}
          <div className="flex w-fit h-max">
            <div className="absolute top-1 right-0 bg-green-500 px-5 py-1 rounded-l-lg font-semibold text-white">
              Available
            </div>
            <div className="w-[10rem]">
              <Carousel autoplay>
                {props?.product?.images?.map((im: any) => (
                  <div>
                    <img
                      src={im}
                      width={"100%"}
                      className=" min-h-[170px]"
                      alt=""
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="flex flex-col pl-2">
              <h1 className="font-bold">{props?.product?.model}</h1>
              <div className=" gap-1">
                <div className="grid grid-cols-2 gap-2">
                  {props?.data &&
                    props?.data?._id
                      // ?.slice(0, 2)
                      ?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <>
                              <p className="text-xs">
                                <b>{data[0]}</b>
                              </p>
                              <p className="text-xs">{data[1]}</p>
                            </>
                          )
                      )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-4 gap-x-4">
            {props?.data?.statuses?.map((item: any, _index: number) => (
              <p className={`text-[${searchValue(item?.status)}]`}>
                <b className="capitalize">{`${
                  item?.status?.toLowerCase() === "in"
                    ? "In Warehouse "
                    : item?.status?.toLowerCase() === "out"
                    ? "In Shops"
                    : "Sold"
                } : `}</b>

                {item?.count}
              </p>
            ))}
          </div>
        {/* </Link> */}
      </div>
    </>
  );
};

export default ProductModelCard;
