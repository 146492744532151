/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Table, InputNumber, Modal, DatePicker, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Paper, Stack } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { createProformaAction } from "../../store/proforma/actions";
import LoadingButton from "@mui/lab/LoadingButton";
import Result from "../../components/Results/SuccessfullySent";

const columns: ColumnsType<any> = [
  {
    title: "Models",
    dataIndex: "model",
    key: "model",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Description",
    dataIndex: "specification",
    key: "specification",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Available Quantity",
    dataIndex: "qtyAvailable",
    key: "qtyAvailable",
    render: (text, record) => (
      <InputNumber
        type="number"
        min={0}
        disabled={record.status !== "sent"}
        defaultValue={parseInt(text)}
        onChange={(value: any) => {
          record.qtyAvailable = value; // Update the name in the data source
        }}
      />
    ),
  },
  {
    title: "Price/Unit",
    key: "priceUnit",
    dataIndex: "priceUnit",
    render: (text, record) => (
      <Input
        type="number"
        style={{ minWidth: "10rem" }}
        defaultValue={text}
        disabled={record.status !== "sent"}
        onChange={(e: any) => {
          record.priceUnit = e.target.value; // Update the name in the data source
        }}
      />
    ),
  },
  {
    title: "Delivery Date",
    key: "validityDate",
    dataIndex: "validityDate",
    render: (text, record) => {
      record.validityDate = dayjs().format("DD/MM/YYYY").toString();
      return (
        <DatePicker
          defaultValue={dayjs(record.validityDate, "DD/MM/YYYY")}
          format="DD/MM/YYYY"
          disabled={record.status !== "sent"}
          onChange={(date, dateString) => {
            record.validityDate = dateString;
          }}
        />
      );
    },
  },
];

const App = (props: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { quotation, proforma } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const value = quotation?.selectedRfq?.quotation.listOfProducts;

  const [data, setData] = React.useState(dataMapper);
  const [termsAndConditions, setTermsAndConditions] = React.useState("");

  useEffect(() => {
    value &&
      value.forEach((element: any) => {
        element &&
          dataMapper.push({
            model: element.product.model,
            specification: element.specification
              .map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
              .join(", "),
            qtyAvailable: "",
            qty: element.quantity,
            validityDate: "",
            priceUnit: "",
            quotationItem: element._id,
            status: quotation.selectedRfq.status.toLowerCase(),
          });
      });

    setData(dataMapper);
  }, [quotation.selectedRfq]);

  const handleModal = () => {
    setIsModalOpen(true);
  };

  function closeTab() {
    window.close();
  }

  return (
    <Stack alignItems="end" spacing={2} sx={{ width: "100%" }}>
      <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
      <LoadingButton
        onClick={async () => {
          console.log({
            quotation: quotation.selectedRfq._id,
            listOfProducts: data,
            termsAndConditions,
          });

          await createProformaAction("", {
            quotation: quotation.selectedRfq._id,
            listOfProducts: data,
            termsAndConditions,
            isNotify: true,
            notification: {
              action: "RFQ Replied",
              role: ["admin", "finance", "finance-manager"],
              message: `RFQ With ID: ${quotation?.selectedRfq?.quotation?.quotationId} has been Replied`,
              title: "Request for Quotation Replied",
            },
          })(dispatch);
          handleModal();
        }}
        disabled={quotation.selectedRfq?.status.toLowerCase() !== "sent"}
        sx={{ width: "10rem" }}
        variant="contained"
        endIcon={<SendIcon />}
        loading={proforma.isFetching}
      >
        Send
      </LoadingButton>
      <Modal
        title={""}
        open={isModalOpen}
        footer={null}
        maskClosable={false}
        className="min-w-min"
        style={{ marginTop: "200px" }}
        bodyStyle={{ height: 350 }}
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <Paper style={{ width: 1200, marginTop: "20px", boxShadow: "none" }}>
            <Result
              title={"Quotation was successfully Sent"}
              proforma={proforma}
              closeTab={closeTab}
            />
          </Paper>
        </div>
      </Modal>
    </Stack>
  );
};

export default App;
