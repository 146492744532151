import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Dropdown, Tag, Modal } from "antd";
import { BsThreeDots } from "react-icons/bs";
import ViewAction from "./view";
import ConfirmSubInvoiceForm from "../../../../../components/forms/ConfirmSubInvoiceForm";

const ThreeInvoiceDotDropdown = (props: any) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);

  const handleOpenConfirmModel = () => {
    if (props?.record?.status === "paid") {
      setUpdateAmount(true);
    } else {
      setUpdateAmount(false);
    }
    setOpenConfirm(true);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
    setUpdateAmount(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <ViewAction onOpenViewModel={props?.onOpenViewModel} />,
    },
    {
      key: "2",
      label: (
        <Tag
          color="#87d068"
          style={{
            fontSize: "15px",
            height: "30px",
            lineHeight: "30px",
            borderRadius: "10px",
          }}
          onClick={handleOpenConfirmModel}
        >
          {props?.record?.status === "paid" ? "Update" : "Confirm Payment"}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <>
        {/* {props?.record?.status !== "paid" && ( */}
        <Dropdown menu={{ items }} placement="bottom">
          <BsThreeDots size={20} />
        </Dropdown>
        {/* )} */}
        {/* {props?.record?.status === "paid" && (
          <ViewAction onOpenViewModel={props?.onOpenViewModel} />
        )} */}
      </>
      <Modal
        title="Confirm Payment"
        centered
        open={openConfirm}
        onCancel={handleCancel}
        width={400}
        footer={null}
      >
        <ConfirmSubInvoiceForm
          onCancel={handleCancel}
          itemId={props?.record?.data?._id}
          amount={updateAmount ? props?.record?.data : null}
          updateAmount={updateAmount}
        />
      </Modal>
    </>
  );
};
export default ThreeInvoiceDotDropdown;
