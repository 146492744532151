import React from "react";
import * as convert from "../../assets/data/productExport";
import PurchaseOrderCard from "./PurchaseOrderCard";

const PurchaseOrderGrid = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportPurchaseOrderData(
      d._id,
      d.supplier,
      d.poId,
      d.poStatus,
      d.deliveryDeadline,
      d.totalAmount,
      d
    )
  );

  return data.map((d: any) => <PurchaseOrderCard data={d} />);
};

export default PurchaseOrderGrid;
