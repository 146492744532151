import React from "react";
import ProductCardGrid from "./NosaleProductCardGrid";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { pos } = useSelector((state: any) => state);

  return pos?.nosale?.data?.noSales?.map((d: any) => (
    <ProductCardGrid data={d} />
  ));

  // return <ProductCardGrid />;
};

export default App;
