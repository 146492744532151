import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

const ThreeDotDropdown: React.FC<{
  showDropdownMenu: boolean;
  cardId: string;
  setShowDropdownMenu: (show: boolean) => void;
}> = ({ showDropdownMenu, setShowDropdownMenu, cardId }) => {
  return (
    <div className="absolute right-0 top-0 z-10">
      <button
        data-ripple-light="true"
        data-popover-target="menu"
        className="xshadow p-2"
        onClick={() => setShowDropdownMenu(!showDropdownMenu)}
      >
        <MoreVertIcon />
      </button>
      <ul
        role="menu"
        data-popover="menu"
        data-popover-placement="bottom"
        onBlur={() => console.log("blurred....")}
        onMouseOver={() => console.log("mouse over me....")}
        className={`${
          showDropdownMenu ? "" : "hidden"
        } absolute z-50 min-w-[180px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none`}
      >
        <Link to={`/inventory/ws/stock/${cardId}/details`}>
          <li
            role="menuitem"
            className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
          >
            View Shop Stock
          </li>
          <hr />
        </Link>
        <Link to={`/inventory/ws/stock/${cardId}/items`}>
          <li
            role="menuitem"
            className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
          >
            Warehouse Items
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default ThreeDotDropdown;
