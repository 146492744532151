import { Modal } from "antd";

const DetailsModel = (props: any) => {
    return (
        <Modal
        title={props?.modalTitle}
        open={props?.isModalOpen}
        onCancel={props?.handleOnCancel}
        footer={null}
        className="min-w-max"
      >
        <div className="">
            {props?.component}
        </div>
        
      </Modal>
    );
}
export default DetailsModel;