import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DangerButton = (props: any) => {
  return (
    <button
      {...props}
      type="button"
      className="focus:outline-none border border-red-700 text-red-700 hover:text-white hover:bg-red-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
    >
      {props?.name + " "}
      {props?.isLoading && <Spin indicator={<LoadingOutlined spin />} />}
    </button>
  );
};

export default DangerButton;
