import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, DatePickerProps, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TbGridDots } from "react-icons/tb";
import { AiOutlineCloudUpload, AiOutlineUnorderedList } from "react-icons/ai";
import { mySubscritionActions } from "../../../../store/subscription/order";
import SubscriptionOrdersFilter from "../../../../components/filters/SubscriptionOrdersFilter";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CreateOrder from "./Createorder";
import { Table } from "antd";
import {
  createOrderAction,
  deleteSubsorderAction,
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import type { ColumnsType } from "antd/es/table";
import ThreeDotDropdown from "../Accounts/accountactions/threeDotDropdown";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import { handlerUpdateAccountAction } from "../../../../store/layout/actions";

const OrdersCard = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState("");
  const { auth, orders } = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const value = orders?.all?.data;
  const showDrawer = async () => {
    await dispatch(mySubscritionActions.setSelected(null));
    await createOrderAction(auth?.token, {})(dispatch);
    setOpen(true);
    // auth?.token &&
    await getAllSubscriptionOrdersAction(
      auth?.token,
      `?status=created&status=pending&status=draft&limit=${limit}&page=${
        page - 1
      }`
    )(dispatch);
  };

  const reOpenModel = (data: any) => {
    setOrderId(data);
    setOpen(true);
  };

  const onClose = async () => {
    setOpen(false);
    setOrderId("");
    dispatch(mySubscritionActions.setSelected(null));
    handlerUpdateAccountAction(false)(dispatch);
  };

  useEffect(() => {
    if (selectedStatus) {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=${selectedStatus}&limit=${limit}&page=${page - 1}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=created&status=pending&status=draft&limit=${limit}&page=${
            page - 1
          }`
        )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, selectedStatus]);

  useEffect(() => {
    orderId &&
      auth?.token &&
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
  }, [auth.token, dispatch, orderId]);

  const goBack = () => {
    navigate(-1);
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150,
    },
    {
      title: "Order Number",
      dataIndex: "customerNumber",
      width: 200,
    },
    {
      title: "Number of Devices",
      dataIndex: "deviceNumber",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
    {
      title: "Created Time",
      dataIndex: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 h-[26px] py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    orders?.all?.data &&
      orders?.all?.data.forEach((el: any) => {
        dataMapper.push({
          dataIndex: el._id,
          customerName: el?.account?.customerDetails?.name,
          customerNumber: el?.account?.customerDetails?.name,
          deviceNumber: el?.list?.length,
          createdBy: el?.createdBy?.names,
          date: el?.createdAt?.substring(0, 10),
          time: el?.createdAt?.substring(11, 16),
          status: el?.status,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [orders?.all?.data]);
  const [activeOrder, setActiveOrder] = useState("orders as grid");

  const handleIconClick = (iconType: string) => {
    setActiveOrder(iconType);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  const deleteOrder = async () => {
    if (auth?.token) {
      await deleteSubsorderAction(auth?.token, deleteDataId)(dispatch);
      onCancelDeleteModel();
      await getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=created&status=pending&status=draft&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  return (
    <>
      <div className=" text-[#0F0F47] my-4">
        <DetailsHeaderActionBar pageName="Subscription" title="Order" />
      </div>
      <div className="text-black scrollbar-hide h-full w-full bg-white pb-2 ">
        <div className="flex flex-wrap pl-3 justify-between">
          <div className="flex flex-row items-center">
            <SubscriptionOrdersFilter setSelectedStatus={setSelectedStatus} />
          </div>
          <div className="flex flex-wrap items-center gap-4 mr-4">
            <DatePicker onChange={onChange} className={"w-48"} />
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color: activeOrder === "orders as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("orders as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("orders as table")}
                className={`cursor-pointer ${
                  activeOrder === "orders as table"
                    ? "fill-blue-500"
                    : "fill-gray-500"
                }`}
              />
            </div>
            <div className="w-[2px] h-10 mr-2 bg-gray-300"></div>

            <div className="flex flex-row items-center gap-4 text-black pb-2 ">
              <Button className="bg-[#2943D6]  text-white" onClick={showDrawer}>
                <PlusOutlined /> New Order
              </Button>
            </div>
            <div className="text-[#2943D6] flex flex-row items-center cursor-pointer">
              <AiOutlineCloudUpload size={25} />
              <p>upload</p>
            </div>
          </div>
        </div>

        {activeOrder === "orders as grid" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <div className={`flex flex-wrap gap-3  text-white`}>
              <>
                {orders?.isFetching ? (
                  <div className="flex flex-wrap">
                    {[1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4]?.map((el: any) => (
                      <CurstomeCardSkeleton />
                    ))}
                  </div>
                ) : (
                  <>
                    {orders?.all?.data?.map((item: any) => (
                      <div
                        key={item?._id}
                        className={`${
                          item?._id === orders?.selected?._id
                            ? "bg-slate-200"
                            : "bg-white"
                        } rounded-lg  w-[360px] min-h-[156px] p-[16px] shadow relative`}
                        onClick={(event: any) => {
                          const isDisabledDiv = event.target.closest(
                            ".ant-dropdown-trigger"
                          );
                          const isDisabledlabel =
                            event.target.closest(".ant-dropdown-menu");
                          if (!isDisabledDiv && !isDisabledlabel) {
                            if (item?.status === "draft") {
                              reOpenModel(item?._id);
                            } else {
                              navigate(`/subscription/order/${item?._id}`);
                            }
                          }
                        }}
                      >
                        <div className="flex flex-row  justify-between">
                          <div className="w-60 space-y-1">
                            {!item?.account?.customerDetails?.name ? (
                              <h2 className="text-lg leading-6  text-[#f21414] font-semibold font-poppins capitalize">
                                Undefined
                              </h2>
                            ) : (
                              <h2 className="text-lg leading-6  text-[#0F0F47] font-semibold font-poppins capitalize">
                                {item?.account?.customerDetails?.name ||
                                  "Undefined"}
                              </h2>
                            )}
                            <p className="text-[#605BFF] text-sm">
                              {item?.SOID}
                            </p>
                          </div>

                          <div className="absolute right-2 top-3 flex items-center">
                            <Tag
                              color={searchValue(item?.status)}
                              className="text-center px-2 py-0.5 capitalize"
                            >
                              {item?.status}
                            </Tag>
                            <ThreeDotDropdown
                              onDelete={() => openDeleteModel(item?._id)}
                            />
                          </div>
                        </div>
                        <p className="text-black pt-8">
                          Created By: {item?.createdBy?.names}
                        </p>
                        <div className=" mt-2 flex justify-between">
                          <div className="flex flex-col">
                            <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                              On: {item?.createdAt?.substring(0, 10)}
                              {" T "}
                              {item?.createdAt?.substring(11, 16)}
                            </p>
                          </div>
                          <div>
                            <p className="text-[#0F0F47] pl-3 pb-2 text-sm">
                              {item?.list?.length > 1
                                ? `${item?.list?.length} Devices`
                                : `${item?.list?.length} Device`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            </div>
          </ScrollableFrame>
        )}
        {activeOrder === "orders as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <Table
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (event) => {
                    record?.status === "draft"
                      ? reOpenModel(record?._id)
                      : navigate(`/subscription/order/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={orders?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>
      <CreateOrder onClose={onClose} open={open} setOpen={setOpen} />
      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteOrder}
        onCancel={onCancelDeleteModel}
        itemName={"Order"}
        isLoading={orders?.isFetching}
      />
    </>
  );
};

export default OrdersCard;
