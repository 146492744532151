import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "WareHouse States",
  initialState: {
    isFetching: false,
    requestSentIsFetching: false,
    all: { data: [] },
    selected: null,
    selectedDeliveryNote: null,
    attributes: null,
    new: null,
    allStockRequested: null,
    updated: null,
    query: null,
    filters: null,
    oneSelected: null,
    selectedRequest: null,
    warehouseItem: null,
    warehouseOut: null,
    warehouseOutItem: null,
    selectedWarehouseOut: null,
    selectedOnestock: null,
    warehouseOutTabs: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSelectedDeliveryNote(state, action) {
      state.selectedDeliveryNote = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },

    setRequestSentIsFetching(state, action) {
      state.requestSentIsFetching = action.payload;
    },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllStockOut(state, action) {
      state.allStockRequested = action.payload;
    },
    setSelectedRequest(state, action) {
      state.selectedRequest = action.payload;
    },
    setOne(state, action) {
      state.oneSelected = action.payload;
    },
    setWarehouseItem(state, action) {
      state.warehouseItem = action.payload;
    },
    setWarehouseOut(state, action) {
      state.warehouseOut = action.payload;
    },
    setWarehouseOutItem(state, action) {
      state.warehouseOutItem = action.payload;
    },
    setWarehouseOutSelected(state, action) {
      state.selectedWarehouseOut = action.payload;
    },
    setWarehouseSelected(state, action) {
      state.selectedOnestock = action.payload;
    },
    setWarehouseOutTabs(state, action) {
      state.warehouseOutTabs = action.payload;
    },
  },
});

export const myWareHouseOutActions = mySlice.actions;

export default mySlice.reducer;
