import React, { useState } from "react";
import { Modal, QRCode } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import DangerButton from "../../../../components/buttons/DangerButton";
import EmployeeForm from "../../../../components/forms/NewEmployeeForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { deleteUserActions } from "../../../../store/setting/actions";
import { myLayoutActions } from "../../../../store/layout";
import DeactivateUserForm from "./diactivateUserForm";
import { MdDownload } from "react-icons/md";
const TopUserDetails = () => {
  const { layout, setting, auth } = useSelector((state: any) => state);
  const sideNav = layout?.isSideNavOpen;
  const [userToEdit, setUserToEdit] = useState<any>("");
  const [openUserEditModal, setOpenUserEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const dispatch = useDispatch();
  const deleteId = layout?.userInfo?._id;
  const goBack = () => {
    dispatch(myLayoutActions.setViewUser(false));
  };
  const handleEditUser = () => {
    setUserToEdit(!userToEdit);
  };
  const handleOpenRoleModal = () => {
    setOpenUserEditModal(true);
  };
  const handleCloseRoleModal = () => {
    setOpenUserEditModal(!openUserEditModal);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const showDeactivateModal = () => {
    setOpenModel(true);
  };

  const cancelDeactivateModal = () => {
    setOpenModel(false);
  };
  const deleteUser = async () => {
    if (auth?.token) {
      const res = await deleteUserActions(
        deleteId as string,
        { isDeleted: "true" },
        auth?.token
      )(dispatch);
      res && goBack();
    }
  };

  const userData = {
    email: layout?.userInfo?.email,
    names: layout?.userInfo?.names,
  };

  const userDataJson = JSON.stringify(userData);

  return (
    <>
      <div
        className={`xl:flex gap-5  p-8 border-solid border-2 border-[#0302291A] rounded-xl bg-white h-full ${
          sideNav ? " w-full" : " w-full"
        }`}
      >
        <div className="w-32 h-32 rounded-full">
          <img
            src={layout?.userInfo?.picture}
            alt=""
            className="rounded-full w-full"
          />
        </div>
        <div>
          <h1 className="text-[#030229] font-medium text-lg ">
            {layout?.userInfo?.names}{" "}
            <span className="text-[#030229b5] font-normal text-base">
              | {layout?.userInfo?.role}
            </span>{" "}
          </h1>
          <div className="2xl:flex gap-5">
            <div className="pl-6 mt-5">
              <p className="text-[#030229d7] font-normal text-sm">
                Email:{" "}
                <span className="text-[#0302296d] font-normal text-sm">
                  {layout?.userInfo?.email}
                </span>
              </p>
              <p className="text-[#030229c7] pt-2 font-normal text-sm">
                Phone:{" "}
                <span className="text-[#0302296d] font-normal text-sm">
                  {layout?.userInfo?.phone}
                </span>
              </p>
              <p className="text-[#030229c7] pt-2 font-normal text-sm">
                National ID:{" "}
                <span className="text-[#0302296d] font-normal text-sm">
                  {layout?.userInfo?.nid}
                </span>
              </p>
              {layout?.userInfo?.tag && (
                <p className="text-[#030229c7] pt-2 font-normal text-sm">
                  Company:
                  <span className="text-[#0302296d] font-normal text-sm capitalize pl-3">
                    {layout?.userInfo?.tag}
                  </span>
                </p>
              )}
            </div>
            <div className="flex gap-5 mt-14 ml-10">
              <PrimaryButton name="Edit" onClick={handleOpenRoleModal} />
              <PrimaryButton name="downLoad Card" />
              {/* <DangerButton name="Delete" onClick={handleOpenDeleteModal} /> */}
              <DangerButton name="Deactivate" onClick={showDeactivateModal} />
              <Modal
                open={openModel}
                onCancel={cancelDeactivateModal}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                <DeactivateUserForm onCancel={cancelDeactivateModal} />
              </Modal>
              <Modal
                title={<h1 className="my-3 text-lg">Update Employee</h1>}
                width={1000}
                open={openUserEditModal}
                onCancel={handleCloseRoleModal}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                <EmployeeForm
                  data={layout?.userInfo}
                  onCancel={handleCloseRoleModal}
                />
              </Modal>
            </div>
          </div>
        </div>
        <DeleteModal
          visible={openDeleteModal}
          onOk={deleteUser}
          isLoading={setting.isFetching}
          onCancel={handleCloseDeleteModal}
          itemName="user"
        />
        <div id="myqrcode " className="ml-auto">
          <QRCode
            value={userDataJson}
            style={{
              marginBottom: 2,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default TopUserDetails;
