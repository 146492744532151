import React from "react";
import { Row, notification } from "antd";
import { useEffect, useState } from "react";
import { Avatar, Card, Skeleton, Switch, Result, Spin } from "antd";

import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import styles from "../styles/Home.module.css";
import { ReactComponent as ForceLogo } from "../assets/icons/dashboard/Samphone_Logo.svg";
import { Button, Checkbox, Form, Input } from "antd";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  createResetPasswordAction,
  getResetPasswordTokenStatusAction
} from "../store/auth/authActions";
import { LoadingOutlined } from "@ant-design/icons";
// import { LoadingOutlined } from "@ant-design/icons";
// import { Form, Input, Spin, notification } from "antd";

const { Meta } = Card;
export default function ResetPasswordForm() {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { resetToken } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  useEffect(() => {
    getResetPasswordTokenStatusAction(resetToken)(dispatch);
  }, [dispatch, resetToken]);
  const onFinish = async (values: any) => {
    await createResetPasswordAction(
      { ...values },
      resetToken as string
    )(dispatch);
    notification.success({ message: "success" });
    navigate("/login");
    form.resetFields();
  };

  const styles = {
    shadow: {
      boxShadow: "1px 4px 12px 0px rgba(0, 0, 0, 0.05)"
    }
  };
  const OnPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };
  const OnConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <div className="w-full bg-white">
      {
        <>
        {auth?.isFetching && <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "white" }}
                                  color="white"
                                  spin
                                />
                              }
                            />}
          {!auth?.isFetching && auth?.resetTokenExpiredStatus?.status === 200 && (
            <div className="w-[35rem] h-fit m-auto  mt-20  p-5 bg-white ">
              <ForceLogo className="w-36 text-sm  absolute top-10 ml-44" />
              <div className="mt-10 w-full">
                <p className="text-[#03022980] text-center">
                  Reset your Password
                </p>
                <div
                  className="w-full p-12 mt-5 rounded-md bg-white"
                  style={styles.shadow}
                >
                  <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    style={{ maxWidth: "100%", marginTop: "20px" }}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="newPassword"
                      label={
                        <span className="text-[#030229] pl-1 text-sm font-normal">
                          New Password
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input new password!"
                        },
                        {
                          min: 6,
                          message:
                            "Password should be at least 6 characters long"
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
                          message:
                            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        }
                      ]}
                      className="w-full"
                    >
                      <Input.Password
                        className="pl-4 h-[42px] w-[100%] text-base"
                        onChange={OnPasswordChange}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label={
                        <span className="text-[#030229] pl-1 text-sm font-normal">
                          confirm Password
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input new password!"
                        }
                      ]}
                      className="w-full"
                    >
                      <Input.Password
                        className="pl-4 h-[42px] w-[100%] text-base"
                        onChange={OnConfirmPasswordChange}
                      />
                    </Form.Item>
                    {confirmPassword?.length > 0 &&
                      newPassword != confirmPassword && (
                        <h1 className="text-[#ff000079] pb-2">
                          New and Confirm Password must be the same
                        </h1>
                      )}
                    <h1 className="text-[#030229CC] text-[12px] pt-5">
                      Reset Password.Make sure that it is contain Upper case,
                      Lower case, numbers and must be at least 6 characters
                    </h1>
                    <Form.Item>
                      <button
                      disabled={newPassword != confirmPassword}
                        type="submit"
                        className="bg-[#605BFF] text-sm rounded-md mt-5 text-center w-full p-3 text-white"
                      >
                        {auth?.isFetching && newPassword === confirmPassword ? (
                          <>
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24, color: "white" }}
                                  color="white"
                                  spin
                                />
                              }
                            />
                          </>
                        ) : (
                          "Submit"
                        )}{" "}
                      </button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          ) } 
      
            {!auth?.isFetching && auth?.resetTokenExpiredStatus === ""  &&  <Result
                status="404"
                title="404"
                subTitle="Sorry, Token has orleady Expired"
                extra={
                  <LoadingButton
                    variant="contained"
                    onClick={() => navigate("/")}
                  >
                    Back Home
                  </LoadingButton>
                }
                style={{ background: "white", minHeight: "100vh" }}
              />}
             
        
          
        </>
      }
    </div>
  );
}
