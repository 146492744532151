import react, { useEffect, useState } from "react";
import SettingComponent from "./SettingComponent";
import { Button, Select, Switch } from "antd";
import {ReactComponent as locationIcon} from "../../../../assets/icons/location.svg";
import {ReactComponent as passwordLengtIcon} from "../../../../assets/icons/dashboard/tabler_lock-access.svg"
import {ReactComponent as passwordLengthIcon} from "../../../../assets/icons/tabler_lock-access.svg";
import * as aiIcons from "react-icons/ai";
import * as ciIcons from "react-icons/ci"
import { GrLocation } from "react-icons/gr";
import { getUserLogedInLogsAction } from "../../../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
const styles = {
  customShadow: {
    boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
  },
  border: {
    border: "0.5px solid rgba(15, 15, 71, 0.25)"
  },
  themeBorder: {
    border: "2px solid #605BFF",
    borderRadius: "5px",
    padding: "7px"
  },
  nonThemeBorder: {
    border: "0.2px solid #03022980",
    borderRadius: "5px",
    padding: "7px"
  }
};

const Security = () => {
  const dispatch=useDispatch();
  const {auth,setting}=useSelector((state:any)=>state);
  const [switchStates, setSwitchStates] = useState(true);
  const [switchVerificationStates, setSwitchVerificationStates] = useState(true);
  const [switchPasswordStates, setSwitchPasswordStates] = useState(true);
  const [locationSwitchState,setLocationSwitchState] = useState(true);
  const handleSwitchChange = () => {
    setSwitchStates(!switchStates);
  };
useEffect(() => {
  auth?.token && getUserLogedInLogsAction(auth?.token)(dispatch);
}, [auth.token, dispatch]);
console.log(">>>>>.",setting?.userLogedInLogs)
const text = (
  <div>
  <ul className=" ml-6">
      {setting?.userLogedInLogs?.data.map((el:any,index:any)=>{
          return(
          <>
          <li className="flex" >
          <div className=" mt-3  h-[auto]">
            <div className="h-3 w-3 bg-[#0f0f4716] border-[0.1px] border-[#0f0f4746] rounded-full"></div>
            {index !== setting?.userLogedInLogs?.data.length - 1 && (
              <hr className=" border-[0.1px] border-[#0f0f4711] mt-[0.6px] ml-[.3rem] mr-3 h-full cursor-pointer  duration-500" />
            )} 
          </div>
          <div
            className={` mb-3 px-6 py-2 w-full h-full rounded-md`}
          >
            <div className="w-[90%]">
              <div className=" mb-2 font- normal">
                <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                  {el.resMessage}
                </p>
                <p className="text-[#0f0f475e]  text-[12px] pt-2">
                 <span>{el.createdAt?.slice(0,10)}</span> <span className="pl-10">{el.createdAt?.slice(11,20)}</span> 
                </p>
              </div>
              <div className=" mt-4">
                <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                 BaseUrl: <span className="text-[#0F0F47B2] pt-1">{el?.actionDetails?.Url}</span>
                </p>
                <p className="text-[#0f0f47] text-[12px] pt-2">
               Status: <span className="text-[#0F0F47B2] pt-1"> {el.resStatus}</span>
                </p>
              </div>
            </div>
          </div>
        </li>

      
          </>
      )})}
            </ul>
  </div>
);

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Activity Logs',
    children: text,
  }
];
  return (
    <>
      <div
        className="bg-white mt-5 h-[89vh] overflow-y-scroll divide-y rounded-md px-6"
        style={styles.customShadow}
      >
        <SettingComponent
          title={"Two-step Verification"}
          details={"Require a security key or code in additional to password"}
          btn={
            <span
              style={styles.border}
              className="rounded-[8px] py-1 px-1 flex gap-2"
            >
              <p>{switchStates ? "On" : "Off"}</p>
              <Switch
                className="mt-1"
                defaultChecked={switchStates}
                onChange={() => handleSwitchChange()}
              />
            </span>
          }
        />
        <SettingComponent
          title={"Logout everyone"}
          details={"This will require everyone to login into the system"}
          btn={<Button>Logout everyone</Button>}
        />
        <SettingComponent
        
        icon={ciIcons.CiLock  }
          title={"Two-step Verification"}
          details={"Require a security key or code in additional to password"}
          btn={
            <span
              style={styles.border}
              className="rounded-[8px] py-1 px-1 flex gap-2"
            >
              <p>{switchVerificationStates ? "On" : "Off"}</p>
              <Switch
                className="mt-1"
                defaultChecked={switchVerificationStates}
                onChange={() => setSwitchVerificationStates(!switchVerificationStates)}
              />
            </span>
          }
        />
        <SettingComponent
        
        icon={aiIcons.AiOutlineStop   }
          title={"Re-passwords ban"}
          details={"Ban on the use of re-passwords"}
          btn={
            <span
              style={styles.border}
              className="rounded-[8px] py-1 px-1 flex gap-2"
            >
              <p>{switchPasswordStates ? "On" : "Off"}</p>
              <Switch
                className="mt-1"
                defaultChecked={switchPasswordStates}
                onChange={() => setSwitchPasswordStates(!switchPasswordStates)}
              />
            </span>
          }
        />
        <SettingComponent
        
        icon={ciIcons.CiLock  }
          title={"Password length"}
          details={"password length not less than"}
          btn={
            <div >
          <Select
    defaultValue={{ value: '8', label: '8 Characters' }}
    style={{ width: 150 }}
    // onChange={handleChange}
    options={ [
        { label: '10 Characters', value: '10' },
        { label: '12 Characters', value: '12' },
      ]}
  />
          </div>
          }
        />
        <SettingComponent
        
        icon={GrLocation   }
          title={"Allow Use location"}
          details={"Allow application to use your location"}
          btn={
            <span
              style={styles.border}
              className="rounded-[8px] py-1 px-1 flex gap-2"
            >
              <p>{locationSwitchState ? "On" : "Off"}</p>
              <Switch
                className="mt-1"
                defaultChecked={locationSwitchState}
                onChange={() => setLocationSwitchState(!locationSwitchState)}
              />
            </span>
          }
        />
        <div className="mt-10">
        <Collapse items={items} bordered={false}  />
          
         
        </div>
      </div>
    </>
  );
};
export default Security;
