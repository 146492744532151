import React, { useState } from "react";
import { Input, notification, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DeliveryNoteTable from "../../../components/tables/DeliveryNoteTable";
import { searchValue } from "../../../utils/setColor";
import {
  getOneDeliveryNoteAction,
  updateOneDeliveryNoteAction,
} from "../../../store/wareHouse/actions";
import { createPurchaseItemsAction } from "../../../store/ebm/actions";
import { Stack } from "@mui/material";
import PurchaseOrderDetailsSkeleton from "../../../components/skeleton/PurchaseOrderDetailsSkeleton";

const DeliveryNoteDetails = (props: any) => {
  const { wareHouse, auth, ebm } = useSelector((state: any) => state);
  const [registrationTypeCode, setRegistrationTypeCode] = useState<string>();
  const { deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const deliveryNote = wareHouse?.selectedDeliveryNote?.data;

  React.useEffect(() => {
    auth?.token &&
      getOneDeliveryNoteAction(auth?.token, deliveryNoteId as string)(dispatch);
  }, [auth?.token, deliveryNoteId, ebm.new, dispatch]);

  // ["verified", "Verified-Complete"]?.includes(
  //   deliveryNote.status
  // )

  // console.log(
  //   "wareHouse?.selectedDeliveryNote?.data:::>>>",
  //   wareHouse?.selectedDeliveryNote?.data?.listOfItems?.at(0)
  // );

  // console.log("deliveryNote?.ebmRegistered:::>>", deliveryNote?.ebmRegistered);

  return (
    <>
      <div className="text-black p-5 mt-3 space-y-2">
        <DetailsHeaderActionBar
          pageName={props?.pageName}
          title={
            ["In-Transit"].includes(
              wareHouse?.selectedDeliveryNote?.data?.status
            )
              ? wareHouse?.selectedDeliveryNote?.data?.deliveryID
              : wareHouse?.selectedDeliveryNote?.data?.warehouseID
          }
        />
        {!deliveryNote && (
          <div className="p-5 bg-white rounded-lg space-y-6">
            <PurchaseOrderDetailsSkeleton />
          </div>
        )}
        {deliveryNote && (
          <div className="p-5 bg-white rounded-lg space-y-6">
            <div className="flex justify-between font-semibold text-lg space-y-2">
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <div>
                  <p>Order</p>
                  <p className="text-blue-500 text-lg">
                    {["In-Transit"].includes(
                      wareHouse?.selectedDeliveryNote?.data?.status
                    )
                      ? wareHouse?.selectedDeliveryNote?.data?.deliveryID
                      : wareHouse?.selectedDeliveryNote?.data?.warehouseID}
                  </p>
                </div>
                {deliveryNote.ebmRegistered && (
                  <Tag color={"blue"}>SAVED IN EBM</Tag>
                )}
                <Tag color={searchValue(deliveryNote?.status)}>
                  {deliveryNote?.status}
                </Tag>
              </Stack>
            </div>
            <div className="flex justify-between items-start text-sm  ">
              <div className="grid grid-cols-1 gap-x-10">
                <p className="font-bold capitalize">
                  Supplier:{" "}
                  <Tag
                    color={searchValue(deliveryNote?.pOrderID?.supplier?.type)}
                  >
                    {deliveryNote?.pOrderID?.supplier?.type}
                  </Tag>
                </p>
                <p className="font-bold">
                  {deliveryNote?.pOrderID?.supplier?.name} ,
                </p>
                <p className="font-medium">
                  {" "}
                  <span>TIN: </span>
                  {deliveryNote?.pOrderID?.supplier?.tin} ,
                </p>
                <p>{deliveryNote?.pOrderID?.supplier?.address},</p>
                <p>{deliveryNote?.pOrderID?.supplier?.phone},</p>
                <p>{deliveryNote?.pOrderID?.supplier?.email},</p>
              </div>
              <div className="grid grid-cols-2 gap-x-6 text-sm  text-right">
                <p className="font-bold">Shipping Date:</p>
                <p>
                  {wareHouse?.selectedDeliveryNote?.data?.shippingDetails
                    ?.shippingDate || "N/A"}{" "}
                </p>
                <p className="font-bold">Shipping Type : </p>
                <p>
                  {wareHouse?.selectedDeliveryNote?.data?.shippingDetails
                    ?.shiptype || "N/A"}{" "}
                </p>
                <p className="font-bold">Tracking ID :</p>
                <p>
                  {wareHouse?.selectedDeliveryNote?.data?.shippingDetails
                    ?.trackingId || "N/A"}{" "}
                </p>
                <p className="font-bold">Company :</p>
                <p>
                  {wareHouse?.selectedDeliveryNote?.data?.shippingDetails
                    ?.name || "N/A"}{" "}
                </p>
                <p className="font-bold">Contacts :</p>
                <p>
                  {wareHouse?.selectedDeliveryNote?.data?.shippingDetails
                    ?.contact || "N/A"}{" "}
                </p>
              </div>
            </div>
            {/* <SinglePurchaseOrderTable /> */}
            <DeliveryNoteTable />
            <div className="space-x-2">
              {deliveryNote?.status === "In-Transit" && (
                <PrimaryButton
                  name="Arrived"
                  onClick={() => {
                    console.log("arrived!...");
                    auth?.token &&
                      updateOneDeliveryNoteAction(
                        auth?.token,
                        deliveryNoteId as string,
                        {
                          status: "Arrived",
                          // TODO: update delivery deadline
                        }
                      )(dispatch);
                  }}
                  isLoading={wareHouse.isFetching}
                />
              )}
              {["Arrived", "Verified-Incomplete"]?.includes(
                deliveryNote?.status
              ) && (
                <PrimaryButton
                  name="Validate"
                  disabled={wareHouse?.isFetching}
                  onClick={() => {
                    const statuses = deliveryNote?.listOfItems?.map(
                      (item: any, _index: number) => item?.status
                    );
                    auth?.token &&
                      updateOneDeliveryNoteAction(
                        auth.token,
                        deliveryNote?._id,
                        {
                          status:
                            statuses &&
                            statuses.every(
                              (status: string) => status === "Complete"
                            )
                              ? "Verified-Complete"
                              : "Verified-Incomplete",
                        }
                      )(dispatch);
                  }}
                  isLoading={wareHouse?.isFetching}
                />
              )}
              {!deliveryNote?.ebmRegistered &&
                ["verified", "Verified-Complete"]?.includes(
                  deliveryNote?.status
                ) && (
                  <Stack spacing={2} direction="column">
                    <Stack className="w-[9rem]">
                      <Input
                        placeholder="Refence Invoice ID"
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          // console.log(e.target.value);
                          setRegistrationTypeCode(e.target.value);
                        }}
                      />
                    </Stack>
                    <PrimaryButton
                      name="Save Purchase"
                      disabled={wareHouse?.isFetching}
                      onClick={async () => {
                        if (
                          !["Stored-Complete"]?.includes(
                            wareHouse?.selectedDeliveryNote?.data?.listOfItems?.at(
                              0
                            )?.status
                          )
                        ) {
                          notification.error({
                            message: "Please Complete Stock In",
                          });
                          return;
                        }
                        if (auth?.token) {
                          const res = await createPurchaseItemsAction(
                            auth?.token,
                            {
                              deliveryNoteId: deliveryNote._id,
                              purchaseStatusCode:"02",
                              referenceId: registrationTypeCode,
                            }
                          )(dispatch);
                          res &&
                            notification.success({
                              message: "EBM Purchase Saved Successfuly!",
                            });
                          !res &&
                            notification.error({
                              message: "EBM Purchase Failed Tobe Saved!",
                            });
                        }
                      }}
                      isLoading={ebm?.isFetching}
                    />
                  </Stack>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeliveryNoteDetails;
