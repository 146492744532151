import HeaderComponent from "../../../components/HeaderComponent";
import { Paper, Stack, Button, Box, Skeleton } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import SupplierTable from "../../../components/tables/SupplierTable";
import SupplierGrid from "../../../components/grids/SupplierGrid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import AddNewSupplier from "../../../components/forms/NewSupplierForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllSuppliersAction } from "../../../store/supplier/actions";
import { getAllAttributesAction } from "../../../store/product/actions";
import { mySupplierActions } from "../../../store/supplier";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { supplierIncludeItem } from "../../../assets/data/includedItem";

const SupplierFilter = (props: any) => {
  const { supplier } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");

  dispatch(mySupplierActions.setQuery(`?${type && `type=${type}`}&`));
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
    }
  };
  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          Filter
        </Button>

        {selectedFilters && (
          <Stack
            spacing={1}
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              allowClear
              placeholder="Filter By Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={supplier.isFetching}
              disabled={supplier.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={[
                { label: "International", value: "international" },
                { label: "Local", value: "local" },
              ]}
              onChange={(value: any) => {
                console.log("::", value);
                !value && setType("");
                value && setType(value);
              }}
            />
          </Stack>
        )}
        <h1 className="text-gray-400">
          Result:{" "}
          {supplier.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            supplier?.all?.result
          )}
        </h1>
      </Stack>
    </>
  );
};

const PageView = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  // const [query, setQuery] = useState("?");

  const { auth, supplier } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token &&
      getAllSuppliersAction(
        auth?.token,
        supplier?.query +
          `field=${supplierIncludeItem}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    auth?.token && getAllAttributesAction(auth?.token, "?")(dispatch);
  }, [auth, dispatch, limit, page, supplier?.query]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={1}>
      <div className="text-black pt-2">
        <DetailsHeaderActionBar
          pageName="Suppliers"
          goBack={goBack}
          title=" "
        />
      </div>
      <HeaderComponent
        // title="Manage Suppliers"
        item="Supplier"
        modelTitle="Add Supplier"
        isNotCollapse={true}
        filterCard={<SupplierFilter />}
        ModelComponent={<AddNewSupplier onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
        setIsGridView={setIsGridView}
      />
      {/* <SearchInput
        onSearch={handleSearch}
        onChange={(e: any) => {
          e.preventDefault();
          setSearchKey(e.target.value);
        }}
      /> */}
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={supplier?.all?.total}
        count={Math.ceil(supplier?.all?.total / limit)}
      >
        {isGridView && (
          <Box sx={{ pt: 2 }}>
            <div className="flex flex-wrap gap-5 justify-start items-start">
              {supplier.isFetching && (
                <>
                  {[1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3].map(() => (
                    <Box sx={{ width: 250, p: 1 }}>
                      <Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />

                          <Box sx={{ width: "100%" }}>
                            <Stack>
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Skeleton animation="wave" height={120} width="100%" />
                      </Stack>
                    </Box>
                  ))}
                </>
              )}
              {!supplier.isFetching && (
                <SupplierGrid data={supplier?.all?.data} />
              )}
            </div>
          </Box>
        )}
      </ScrollableFrame>
      {!isGridView && !supplier.isFetching && (
        <Paper elevation={2}>
          <Stack spacing={1}>
            <SupplierTable data={supplier?.all?.data} />
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

export default PageView;
