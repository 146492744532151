import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Table,
  notification,
} from "antd";
import { EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
// import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import AddItem from "./AddItems";
import {
  createOrderAction,
  updateOneAccountAction,
} from "../../../../store/account/actions";
import { getPricelistItemAction } from "../../../../store/channel/actions";
import {
  deleteSubsorderItemAction,
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
  submitSubOrdersAction,
} from "../../../../store/subscription/order/actions";
import { IconButton, Stack } from "@mui/material";
import { myChannelActions } from "../../../../store/channel";
import DeleteIcon from "@mui/icons-material/Delete";
import { handlerUpdateAccountAction } from "../../../../store/layout/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface FormData {
  name: string;
  url: string;
  owner: string;
  type: string;
  approver: string;
  // dateTime: Moment | null;
  description: string;
}

const CreateOrder = (props: any) => {
  const [form] = Form.useForm<FormData>();
  const dispatch = useDispatch();
  const { orders, auth, account, layout } = useSelector((state: any) => state);
  const [contactsAdded, setContactsAdded] = useState(false);
  const [channelId, setChannelId] = useState("");

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  const onFinishAccount = async (values: any) => {
    // console.log("onfinish", values);
    const payload = {
      contacts: values.contacts && [...values.contacts],
      customerDetails: {
        ...values,
      },
    };

    delete payload?.customerDetails?.contacts;

    if (orderId && auth?.token) {
      if (!layout?.isUpdateAccount) {
        await createOrderAction(auth?.token, {
          order: orderId,
          ...payload,
        })(dispatch);
        form.resetFields();
        await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
      } else {
        await updateOneAccountAction(
          auth?.token,
          orders?.selected?.account?._id,
          { ...payload }
        )(dispatch);
        form.resetFields();
        await handlerUpdateAccountAction(false)(dispatch);
        await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
      }
    }
  };

  useEffect(() => {
    if (channelId) {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${channelId}&hasSubs=true`
        )(dispatch);
    } else {
      dispatch(myChannelActions.setPriceListItems(null));
    }
  }, [auth?.token, dispatch, channelId]);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "",
      width: 30,
      render: (text: any, record: any) => {
        return (
          <Popconfirm
            placement="topRight"
            title={`Do you really want to remove`}
            description={`${record?.model} from the list?`}
            okText="Yes"
            cancelText="No"
            onConfirm={async () =>
              await deleteSubsorderItemAction(
                auth.token,
                record.key,
                orderId
              )(dispatch)
            }
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon style={{ color: "red" }} fontSize="inherit" />
            </IconButton>
          </Popconfirm>
        );
      },
    },
  ];

  const formData = orders?.selected?.list?.map((el: any) => {
    return {
      key: el?._id,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      specification: `${el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      quantity: el?.quantity,
      data: el,
    };
  });

  const handleSubmit = async () => {
    if (!orders?.selected?.account?.duration) {
      return notification.error({ message: "Please Select Duration" });
    }

    if (!orders?.selected?.account?.planType) {
      return notification.error({ message: "Please Select Plan Type" });
    }

    if (orderId && auth?.token) {
      const response = await submitSubOrdersAction(
        orderId,
        { status: "created" },
        auth?.token
      )(dispatch);
      if (response) {
        props?.onClose();
        await getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=created&status=pending&status=draft`
        )(dispatch);
      }
    }
  };

  useEffect(() => {
    if (orders?.selected?.account) {
      form.setFieldsValue(orders?.selected?.account?.customerDetails);
    } else {
      form.resetFields();
    }
  }, [form, orders?.selected?.account]);

  const initialData = {
    ...orders?.selected?.account?.customerDetails,
  };

  return (
    <div>
      <Drawer
        onClose={props?.onClose}
        open={props?.open}
        title="Create New Order"
        width={800}
        className="text-[#2943D6]"
        bodyStyle={{ paddingBottom: "80px" }}
        footerStyle={{ textAlign: "right" }}
        footer={
          orders?.selected?.account?.customerDetails !== undefined &&
          orders?.selected?.list?.length > 0 && (
            <>
              <Button
                className="bg-[#605BFF] text-gray-900 outline-none"
                style={{ color: "black" }}
                disabled={orders?.selected?.list?.length < 1}
                onClick={handleSubmit}
                loading={orders?.createOderIsFetching}
              >
                Save
              </Button>
            </>
          )
        }
      >
        {orders?.selected?.account?.customerDetails !== undefined &&
          !layout?.isUpdateAccount && (
            <div className="flex items-end justify-end">
              <EditOutlined
                className="border rounded-full p-2 -mt-4 border-green-600"
                style={{ color: "green" }}
                onClick={() => handlerUpdateAccountAction(true)(dispatch)}
              />
            </div>
          )}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishAccount}
          initialValues={initialData || {}}
          disabled={
            orders?.selected?.account?.customerDetails !== undefined &&
            !layout?.isUpdateAccount
          }
        >
          <div>
            <div className="grid grid-cols-2 gap-x-4 pl-18">
              <Form.Item
                label={
                  <span className="font-medium text-sm">Customer Name</span>
                }
                name="name"
                rules={[
                  { required: true, message: "Please enter customer name" },
                ]}
              >
                <Input style={{ height: 45 }} />
              </Form.Item>
              <Form.Item
                label={
                  <span className="font-medium text-sm">Phone Number</span>
                }
                name="tel"
                rules={[
                  { required: true, message: "Please enter Phone Number" },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please enter customer Number"
                  style={{ height: 45 }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="font-medium text-sm">Country</span>}
                name="country"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  placeholder="Please enter Country"
                  style={{ height: 45 }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="font-medium text-sm">Address</span>}
                name="address"
                rules={[
                  { required: true, message: "Please enter customer address" },
                ]}
              >
                <Input
                  placeholder="Please enter customer address"
                  style={{ height: 45 }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="font-medium text-sm">Email</span>}
                name="email"
                rules={[{ required: true, message: "Please enter Email" }]}
              >
                <Input
                  placeholder="Please enter Email"
                  style={{ height: 45 }}
                />
              </Form.Item>
            </div>
            {(orders?.selected?.account?.contacts === undefined ||
              layout?.isUpdateAccount) && (
              <Divider orientation="center">Add Contacts</Divider>
            )}
            <Form.List
              name="contacts"
              {...formItemLayout}
              // initialValue={initialContactData ?? [{}]}
            >
              {(
                fields: { [x: string]: any; key: any; name: any }[],
                { add, remove }: any
              ) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Stack>
                      <Stack direction={"row"} className="gap-4">
                        <Form.Item
                          {...restField}
                          label={
                            <span className="font-medium text-sm">Names</span>
                          }
                          name={[name, "name"]}
                          rules={[{ required: true, message: "Missing names" }]}
                          style={{ minWidth: 200 }}
                        >
                          <Input placeholder="Names" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={
                            <span className="font-medium text-sm">
                              Phone Number
                            </span>
                          }
                          name={[name, "phone"]}
                          rules={[
                            {
                              required: true,
                              message: "Phone is required!",
                              whitespace: true,
                            },
                          ]}
                          style={{ minWidth: 200 }}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={
                            <span className="font-medium text-sm">Email</span>
                          }
                          name={[name, "email"]}
                          rules={[
                            {
                              required: true,
                              message: "email is required!",
                              type: "email",
                            },
                          ]}
                          style={{ minWidth: 200 }}
                        >
                          <Input placeholder="enter your email" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Stack>
                    </Stack>
                  ))}
                  {(orders?.selected?.account?.contacts === undefined ||
                    layout?.isUpdateAccount) && (
                    <Form.Item>
                      <Button
                        onClick={() => {
                          add();
                          setContactsAdded(true); // Set contactsAdded to true when "Add Contacts" is clicked
                        }}
                        // startIcon={<PlusOutlined />}
                      >
                        Add Contacts
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </div>
          {(orders?.selected?.account?.customerDetails === undefined ||
            layout?.isUpdateAccount) && (
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: 100, marginBottom: "20px" }}
              loading={
                account?.isFetching ||
                orders?.isFetching ||
                orders?.newOrderIsFetching
              }
            >
              {layout?.isUpdateAccount ? "Update" : "Next"}
            </LoadingButton>
          )}
        </Form>

        {orders?.selected?.account?.customerDetails !== undefined && (
          <>
            {/* <div className="mb-4">
              <ContactItems orderId={orderId} />
            </div> */}
            <div className="mb-4">
              <AddItem
                orderId={orderId}
                channelId={channelId}
                setChannelId={setChannelId}
              />
            </div>
            <Table
              columns={columns}
              dataSource={formData}
              loading={orders?.newOrderIsFetching}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};

export default CreateOrder;
