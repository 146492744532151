import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getFinanceTransactionService = async (token: any, query?: any) => {
  return HttpRequest.get(
    `${SERVER_URL}/cartItem/transactionRange${query}`,
    token
  );
};

export const getPriceListService = async (token: any, query?: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem/pos${query}`, token);
};
