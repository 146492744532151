import React, { useState } from "react";
import { Button, InputNumber, Image } from "antd";
import {
  BsFileEarmarkTextFill,
  BsThreeDotsVertical,
  BsFileEarmarkPdfFill,
} from "react-icons/bs";
import { Document, Page, pdfjs } from "react-pdf";

interface FilesCardsProps {
  title: string;
  description: string;
  imageSrc: any;
}

const FilesComponent: React.FC<FilesCardsProps> = ({
  title,
  description,
  imageSrc,
}) => {
  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);

  interface ImageComponentProps {
    imageLink: string;
  }

  const ImageComponent: React.FC<ImageComponentProps> = ({ imageLink }) => {
    return <img src={imageLink} alt="Image" />;
  };

  // PDFViewer for displaying PDFs
  interface PDFViewerProps {
    pdfLink: string;
  }

  const PDFViewer: React.FC<PDFViewerProps> = ({ pdfLink }) => {
    return (
      <div>
        <Document file={pdfLink}>
          <Page pageNumber={1} />
        </Document>
      </div>
    );
  };

  const DisplayFile: React.FC<{ fileLink: any }> = ({ fileLink }) => {
    if (fileLink.endsWith(".pdf")) {
      return <PDFViewer pdfLink={fileLink} />;
    } else if (fileLink.endsWith(".jpg") || fileLink.endsWith(".png")) {
      return <ImageComponent imageLink={fileLink} />;
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  return (
    <>
      <div className="w-[18rem] h-[20rem] rounded overflow-hidden shadow-md mt-10 bg-white">
        <div className="relative my-4 rounded w-[17rem]">
          <div className="w-[17rem] h-[18rem] bg-white mx-2 rounded-lg pt-0">
            <a href={imageSrc} target="_blank" rel="noreferrer">
              <img
                src={imageSrc}
                alt="fileimage"
                className="w-full h-full blur-2 transition-all duration-300 hover:blur-none cursor-pointer object-cover"
                onClick={() => setVisible(true)}
              />
              {/* <DisplayFile fileLink={imageSrc} /> */}
            </a>
          </div>
          <div
            className={`flex mx-2 w-full bottom-0 left-0 absolute h-min-[3.5rem] ${
              title === "Delivery Notes"
                ? "bg-[#AFBBFB]"
                : title === "Quotation Documents"
                ? "bg-[#B0E1C8] "
                : title === "Proposal Documents"
                ? "bg-[#BBD9FC]"
                : "bg-red-50"
            } pt-2 justify-around cursor-pointer`}
            onClick={() => setVisible(true)}
          >
            <div>
              <BsFileEarmarkTextFill size={30} className="text-[#605BFF]" />
            </div>
            <div className="leading-5">
              <div className="font-bold text-md text-black">{title ?? ""}</div>
              <div className="font-thin text-[#0F0F47]">{description}</div>
            </div>
            <BsThreeDotsVertical size={20} className="mt-1" />
          </div>
        </div>
      </div>
      {/* <Image
        width={200}
        style={{ display: "none" }}
        src={imageSrc}
        preview={{
          visible,
          src: imageSrc,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      /> */}
    </>
  );
};
export default FilesComponent;
