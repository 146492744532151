import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderChannel";
import { Paper, Stack, Box } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import ChannelGrid from "../../../components/grids/ChannelGrid";
import { Spin } from "antd";

import AddNewChannel from "../../../components/forms/newChannelForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction } from "../../../store/channel/actions";
import { myChannelActions } from "../../../store/channel";

const ChannelFilter = (props: any) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  dispatch(myChannelActions.setQuery(`?${name && `name=${name}`}`));

  const { auth, channel } = useSelector((state: any) => state);
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <h1 className="text-gray-400">
          Result:{" "}
          {channel.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            channel?.all?.result
          )}
        </h1>
      </Stack>
    </Paper>
  );
};

const PageView = (props: any) => {
  const { auth, channel ,layout} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  const datass = [
    { id: 1, value: 11 },
    { id: 2, value: 21 },
  ];

  return (
    <Stack spacing={1} sx={{ mt: 6 }}>
      {props?.channelRoute === "channel" &&
        !["sales-supervisor"]?.includes(auth?.user?.role) && (
          <HeaderComponent
            item="Channel"
            modelTitle="Add New Channel"
            filterCard={<ChannelFilter />}
            isNotCollapse={true}
            ModelComponent={<AddNewChannel onCancel={handleCancel} />}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCancel={handleCancel}
          />
        )}
      <Box sx={{ pt: 2 }}>
        <div className={`grid ${layout?.isSideNavOpen? "sm:grid-cols-1 lg:grid-cols-2":"sm:grid-cols-2 lg:grid-cols-3"} xl:grid-cols-3 2xl:grid-cols-4 gap-4`}>
          <ChannelGrid data={channel?.all?.data} route={props?.channelRoute} />
        </div>
      </Box>
    </Stack>
  );
};

export default PageView;
