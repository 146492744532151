import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { LineChart } from "react-chartkick";
import { useSelector } from "react-redux";
import {
  calculatePercentageChange,
  convertArrayToObjectNew,
} from "../../../utils/converter";
import moment from "moment";
import { useMediaQuery } from "@mui/material";

export const RevenueContainer = (props: any) => {
  const { dashboard } = useSelector((state: any) => state);

  const toDayDate = moment().format("YYYY-MM-DD");
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let previousDay = moment().subtract(1, "days").format("YYYY-MM-DD");
  const currentHour = currentDate.getHours();
  const startHour = 6;

  const data = [
    {
      name: toDayDate === props?.enteredDate ? "Today" : props?.enteredDate,
      data: convertArrayToObjectNew(
        dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueByHour
      ),
      color: "#4c39d4",
    },
    {
      name:
        previousDay === props?.compareDate ? "Yesterday" : props?.compareDate,
      data: convertArrayToObjectNew(
        dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate
          ?.revenueByHour
      ),
      color: "#C4C4C4",
    },
  ];
  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  const todayRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate?.revenueTotal;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );

  const currentDateRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueByHour
      .filter((item: any) => item.hour >= startHour && item.hour <= currentHour)
      .reduce((total: any, item: any) => total + item.revenue, 0);

  const comparedDateRevenue =
    dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate?.revenueByHour
      .filter((item: any) => item.hour >= startHour && item.hour <= currentHour)
      .reduce((total: any, item: any) => total + item.revenue, 0);

  const todayPercentage = calculatePercentageChange(
    currentDateRevenue,
    comparedDateRevenue
  );

  return (
    <div className="flex flex-wrap flex-col w-full h-fit mx-auto">
      <div className="flex-1 flex flex-col w-full gap-2 relative">
        <div className=" flex-auto bg-white p-2 shadow max-h-[432px] rounded-md">
          <p className="text-gray-400 capitalize xmb-2">Total Revenue</p>
          <p className="text-2xl font-semibold">
            {dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate
              ? "RWF " +
                dashboard?.revenueAndQtyStats?.data?.revenue?.currentDate?.revenueTotal?.toLocaleString()
              : "RWF 0"}
          </p>
          <p className="text-gray-400 capitalize">
            {dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate
              ? "RWF " +
                dashboard?.revenueAndQtyStats?.data?.revenue?.comparedDate?.revenueTotal?.toLocaleString()
              : "RWF 0"}
          </p>
          {toDayDate === props?.enteredDate ? (
            <div
              className={`absolute top-2 right-2 ${
                todayPercentage.type === "increase"
                  ? "bg-green-200"
                  : todayPercentage.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  todayPercentage.type === "increase"
                    ? "text-green-500"
                    : todayPercentage.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {todayPercentage.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : todayPercentage.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {todayPercentage.percentage + "%"}
              </span>
            </div>
          ) : (
            <div
              className={`absolute top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : todayPercentage.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : todayPercentage.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : todayPercentage.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage + "%"}
              </span>
            </div>
          )}
          <LineChart
            data={data}
            backgroundColor="white"
            prefix="RWF "
            thousands=","
            xtitle="Hours"
            loading="Loading..."
            options={lineOptions}
            legend={true}
          />
        </div>
      </div>
    </div>
  );
};

export const RevenueKpiContainer = (props: any) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { dashboard } = useSelector((state: any) => state);

  const toDayDate = moment().format("YYYY-MM-DD");
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let previousDay = moment().subtract(1, "days").format("YYYY-MM-DD");
  const currentHour = currentDate.getHours();
  const startHour = 6;

  const data = [
    {
      name: toDayDate === props?.enteredDate ? "Today" : props?.enteredDate,
      data: convertArrayToObjectNew(
        dashboard?.kpiStats?.data?.revenue?.currentDate?.revenueByHour
      ),
      color: "#4c39d4",
    },
    {
      name:
        previousDay === props?.compareDate ? "Yesterday" : props?.compareDate,
      data: convertArrayToObjectNew(
        dashboard?.kpiStats?.data?.revenue?.comparedDate?.revenueByHour
      ),
      color: "#C4C4C4",
    },
  ];
  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  const todayRevenue =
    dashboard?.kpiStats?.data?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue =
    dashboard?.kpiStats?.data?.revenue?.comparedDate?.revenueTotal;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );

  const currentDateRevenue =
    dashboard?.kpiStats?.data?.revenue?.currentDate?.revenueByHour
      .filter((item: any) => item.hour >= startHour && item.hour <= currentHour)
      .reduce((total: any, item: any) => total + item.revenue, 0);

  const comparedDateRevenue =
    dashboard?.kpiStats?.data?.revenue?.comparedDate?.revenueByHour
      .filter((item: any) => item.hour >= startHour && item.hour <= currentHour)
      .reduce((total: any, item: any) => total + item.revenue, 0);

  const todayPercentage = calculatePercentageChange(
    currentDateRevenue,
    comparedDateRevenue
  );

  // const getTotalRevenueFromQuantitySold = () => {
  //   console.log(
  //     "Revenue:::::",
  //     dashboard?.quantitySoldStats?.data
  //       ?.map((item: any) => item)
  //       // ?.reduce(
  //       //   (total: number, currentRevenue: number) => (total += currentRevenue)
  //       // )
  //   );
  // };
  // getTotalRevenueFromQuantitySold();

  return (
    <div className="flex flex-wrap flex-col w-full h-fit mx-auto">
      <div className="flex-1 flex flex-col w-full gap-2 relative">
        <div className="flex-auto bg-white p-5 shadow rounded-md">
          <p
            className={`text-gray-400 ${
              isMobile ? "text-[4vw]" : "text-[1.4vw]"
            } capitalize xmb-2`}
          >
            Total Revenue
          </p>
          <p
            className={`${
              isMobile ? "text-[5vw]" : "text-[2vw]"
            }  text-black font-semibold`}
          >
            {dashboard?.kpiStats?.data?.revenue?.currentDate
              ? "RWF " +
                dashboard?.kpiStats?.data?.revenue?.currentDate?.revenueTotal?.toLocaleString()
              : "RWF 0"}
          </p>
          <p
            className={`text-gray-400 ${
              isMobile ? "text-[3vw]" : "text-[1.2vw]"
            }  capitalize`}
          >
            {dashboard?.kpiStats?.data?.revenue?.comparedDate
              ? "RWF " +
                dashboard?.kpiStats?.data?.revenue?.comparedDate?.revenueTotal?.toLocaleString()
              : "RWF 0"}
          </p>
          {toDayDate === props?.enteredDate ? (
            <div
              className={`absolute top-2 right-2 ${
                todayPercentage.type === "increase"
                  ? "bg-green-200"
                  : todayPercentage.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  todayPercentage.type === "increase"
                    ? "text-green-500"
                    : todayPercentage.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {todayPercentage.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : todayPercentage.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {todayPercentage.percentage || 0}%
              </span>
            </div>
          ) : (
            <div
              className={`absolute top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : todayPercentage.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : todayPercentage.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : todayPercentage.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage || 0}%
              </span>
            </div>
          )}
          <LineChart
            fontSize={"1.2vw"}
            data={data}
            height={isMobile ? "30vh" : "40vh"}
            backgroundColor="white"
            prefix="RWF "
            thousands=","
            xtitle="Hours"
            loading="Loading..."
            options={lineOptions}
            legend={true}
          />
        </div>
      </div>
    </div>
  );
};

export const QuantityContainer = (props: any) => {
  const { dashboard } = useSelector((state: any) => state);

  const toDayDate = new Date().toISOString().slice(0, 10);
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let previousDay = currentDate.toISOString().split("T")[0];

  const convertArrayToObjectNew = (data: any) =>
    data?.reduce(
      (result: any, item: any) => ({
        ...result,
        [item?.hour]: parseInt(item?.quantity),
      }),
      {}
    );

  const data = [
    {
      name: toDayDate === props?.enteredDate ? "Today" : props?.enteredDate,
      data: convertArrayToObjectNew(
        dashboard?.dataActivetion?.data?.quantity?.currentDate?.quantityByHour
      ),
      // data: convertArrayToObjectNew(
      //   dashboard?.revenueAndQtyStats?.data?.quantity?.currentDate
      //     ?.quantityByHour
      // ),
      color: "#2943D6",
    },
    {
      name:
        previousDay === props?.compareDate ? "Yesterday" : props?.compareDate,
      data: convertArrayToObjectNew(
        dashboard?.revenueAndQtyStats?.data?.quantity?.comparedDate
          ?.quantityByHour
      ),
      color: "#C4C4C4",
    },
  ];
  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  const todayQuantity =
    dashboard?.revenueAndQtyStats?.data?.quantity?.currentDate?.totalQuantity;
  const yesterdayQuantity =
    dashboard?.revenueAndQtyStats?.data?.quantity?.comparedDate?.totalQuantity;

  //will be changed
  const totalQuantities = dashboard?.pieceSoldStats?.data.reduce(
    (total: any, item: any) => total + item.currentQuantity,
    0
  );

  const percentageChange = calculatePercentageChange(
    totalQuantities,
    yesterdayQuantity
  );

  return (
    <div className="flex flex-wrap flex-col w-full h-fit mx-auto">
      <div className="flex-1 flex flex-col w-full gap-2 relative">
        <div className=" flex-auto bg-white p-2 shadow max-h-[432px] rounded-md">
          <p className="text-gray-400 capitalize mb-2">Quantity Sold</p>
          <p className="text-2xl font-semibold">
            {(totalQuantities ?? 0) + " Pieces"}
          </p>
          <p className="text-gray-400 capitalize">
            {(yesterdayQuantity ?? 0) + " Pieces"}
          </p>
          {percentageChange && (
            <div
              className={`absolute top-2 right-2 ${
                percentageChange.type === "increase"
                  ? "bg-green-200"
                  : percentageChange.type === "decrease"
                  ? "bg-[#FEE6E9]"
                  : "bg-blue-200"
              }  rounded-lg px-3 py-1`}
            >
              <span
                className={`${
                  percentageChange.type === "increase"
                    ? "text-green-500"
                    : percentageChange.type === "decrease"
                    ? "text-red-500"
                    : "text-blue-900"
                } flex items-center py-0.5`}
              >
                {percentageChange.type === "increase" ? (
                  <ArrowUpwardIcon />
                ) : percentageChange.type === "decrease" ? (
                  <ArrowDownwardIcon />
                ) : (
                  ""
                )}
                {percentageChange.percentage + "%"}
              </span>
            </div>
          )}
          <LineChart
            data={data}
            thousands=","
            xtitle="Hours"
            loading="Loading..."
            options={lineOptions}
          />
        </div>
      </div>
    </div>
  );
};
