import React, { useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import { Paper, Stack, IconButton, InputBase, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import SupplierTable from "../../components/tables/SupplierTable";

import DashLayout from "../../components/layout/Layout";
import * as listFilters from "../../assets/data/supplier";
import * as data from "../../assets/data/dummyData";
import { Select, Space, Input, Collapse } from "antd";

import AddNewSupplier from "../../components/forms/NewSupplierForm";

const { Search } = Input;

const listSummary = [
  { title: "All Suppliers", value: 105 },
  { title: " Active Suppliers", value: 100 },
  //   { title: "All Products", value: 5600 },
];

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    console.log("Clossing.....");
    setIsModalOpen(false);
  };
  return (
    <>
      <Stack spacing={1}>
        <HeaderComponent
          title="Suppliers"
          item="Supplier"
          modelTitle="Add New Supplier"
          listSummary={listSummary}
          ModelComponent={<AddNewSupplier />}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />

        <Paper elevation={0}>
          <Stack spacing={1}>
            <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
              <Stack
                spacing={1}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <h1>Filters:</h1>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Filter By Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={listFilters.supplierTypes}
                    onChange={(value: any) => {
                      console.log("::", value);
                    }}
                  />

                  <h1 className="text-gray-400">Results: 8</h1>
                </Stack>
                <Paper
                  elevation={1}
                  sx={{
                    p: 0,
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                    background: "white",
                  }}
                >
                  <IconButton sx={{ p: 1 }} disabled>
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search "
                    inputProps={{ "aria-label": "search " }}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: 1 }}
                    aria-label="directions"
                  >
                    <DirectionsIcon />
                  </IconButton>
                </Paper>
              </Stack>
            </Paper>
            <Paper elevation={2}>
              <SupplierTable data={data.dataSuppliers} />
            </Paper>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default PageView;
