import React, { useState } from "react";
import DangerButton from "../../../buttons/DangerButton";
import PrimaryButton from "../../../buttons/PrimaryButton";
import ProductDetailsModal from "../../../Modals/ProductDetailsModal";
import UpdateProduct from "../../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductsAction } from "../../../../store/product/actions";
import { useNavigate } from "react-router-dom";
import { Avatar, Carousel, Image, List, Tag } from "antd";
import { Stack } from "@mui/material";

const SidePanelDetails = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [visible, setVisible] = useState(false);
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (props?.simpleDetails) {
      setDataToupdate({ ...props?.simpleDetails, specs: props?.specsData });
    }
  }, [props?.simpleDetails, props?.specsData]);

  console.log(props);
  return (
    <div>
      <div className="flex flex-col justify-center items-center rounded-lg px-2 bg-white w-[380px] space-y-6">
        <div className="-mt-10">
          <div className="flex items-center justify-center bg-slate-200 w-[140px] h-[140px] rounded-full">
            <div className="flex items-center justify-center bg-slate-300 w-[125px] h-[125px] rounded-full">
              <Carousel
                autoplay
                className="bg-white  w-[110px] h-[110px] rounded-full overflow-clip flex  justify-center items-center "
              >
                {props?.simpleDetails?.logo && (
                  <div>
                    <Image
                      className="h-auto w-[100%] rounded-lg m-auto"
                      src={props?.simpleDetails?.logo}
                      alt="description"
                    />
                  </div>
                )}
              </Carousel>
            </div>
          </div>
          <Stack
            direction={"column"}
            spacing={1}
            className="text-center font-semibold"
          >
            <p className="text-lg">{props?.simpleDetails?.name}</p>
            <p className="text-sm font-light">{props?.simpleDetails?.email}</p>
            <p className="text-sm font-light">{props?.simpleDetails?.phone}</p>
            <p className="text-sm font-semibold">{props?.simpleDetails?.tin}</p>
          </Stack>
        </div>
        <div className="flex flex-col flex-wrap rounded-md  bg-white p-4 w-full">
          <p className="text-sm font-semibold">Brands:</p>
          <Stack spacing={1} direction="row" className="pt-1">
            {props?.simpleDetails?.brands?.map((brand: any) => (
              <Tag>{brand}</Tag>
            ))}
          </Stack>
        </div>
        <div className="flex flex-col flex-wrap rounded-md  bg-white p-4 w-full">
          <p className="text-sm font-semibold">Contacts:</p>

          <List
            itemLayout="horizontal"
            dataSource={props?.simpleDetails?.employees}
            renderItem={(item: any, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item?.name[0]}
                    </Avatar>
                  }
                  title={
                    <p className="text-[12px] font-semibold text-black">
                      {item?.name}
                    </p>
                  }
                  // description={`${item?.position}, ${item?.phone}, ${item?.email} `}
                  description={
                    <Stack>
                      <p className="text-[12px] font-light text-black">
                        {item?.position}
                      </p>
                      <p className="text-[12px] font-light text-slate-500">
                        {item?.phone}
                      </p>
                      <p className="text-[12px] font-light text-slate-500">
                        {item?.email}
                      </p>
                    </Stack>
                  }
                />
              </List.Item>
            )}
          />
        </div>
        <div className="flex justify-center gap-2">
          <PrimaryButton name="Edit" onClick={handleOnEdit} />
          <DangerButton name="Delete" onClick={props?.handleClickDelete} />
        </div>
        <span className="text-center uppercase font-medium pb-4">
          <p className="text-sm font-light">{props?.simpleDetails?.address}</p>
          {props?.simpleDetails?.type}
        </span>
      </div>
      {/* product modal here */}
      {
        <ProductDetailsModal
          // component={<props.component />}
          component={
            <props.UpdateForm
              data={dataToUpdate}
              // id={dataToUpdate}
              dataToUpdate={dataToUpdate}
              action={"update"}
              onCancel={handleOnCancel}
            />
          }
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
        />
      }

      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props?.simpleDetails?.images?.map((img: any) => (
            <Image src={img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default SidePanelDetails;
