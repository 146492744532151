import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "ebmPurchase",
  initialState: {
    isFetching: false,
    all:null,
    new:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAll(state, action) {
      state.all= action.payload;
    },
    setNew(state, action) {
      state.new= action.payload;
    },
      
  },
});

export const ebmPurchaseActions = mySlice.actions;

export default mySlice.reducer;
