import React, { useState } from 'react';
import { Button, Modal } from 'antd';

interface vaultProps{
    onOpen?:any,
    onClose?:any,
    data?:any,
    component?:any,
    title?:any,
    width?:any,
}
const VaultModal= ({onOpen,data,onClose,title,width,component}:vaultProps) => {
  return (
    <>
      <Modal title={title} open={onOpen} onOk={onClose} width={width} onCancel={onClose} footer={null}>
        {data}{component}
      </Modal>
    </>
  );
};

export default VaultModal;