import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CreatePermissionModal from "../../../../components/Modals/CreatePermissionModal.tsx";
import PermissionForm from "../../../../components/forms/permissionform";
import {
  deletePermissionAction,
  getPermissionAction,
} from "../../../../store/setting/roleAndPermissions/actions";
import DeleteModal from "../../../../components/Modals/DeleteModal";

interface DataType {
  key: React.Key;
  role: string;
  permission: string;
  address: string;
}

const Permissions = () => {
  const { auth, roleAndPermission } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [permissionModal, setPermissionModal] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState("");
  const [permissionToDelete, setPermissionToDelete] = useState<any>("");

  const allPermissions = roleAndPermission?.all?.data;

  useEffect(() => {
    getPermissionAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const Data = allPermissions?.map((el: any) => {
    return {
      key: el?._id,
      permission: el?.name,
      access_keys: el?.access_keys?.map((d: any) => d?.key),
      access: el?.access_keys?.length,
      content: el?.description,
    };
  });

  const PermissionModelChanges = (value: any) => {
    setPermissionModal(!permissionModal);
    setDataToUpdate(value);
  };

  const handleClosePermissionModal = () => {
    setPermissionModal(false);
    setDataToUpdate("");
  };

  const [visible, setVisible] = useState(false);
  const handleClickDelete = async (permissionData: any) => {
    setVisible(true);
    setPermissionToDelete(permissionData);
  };

  const handlerDelectPermission = async () => {
    auth?.token &&
      (await deletePermissionAction(auth?.token, permissionToDelete?.key, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "permission",
      render: (text: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Access",
      dataIndex: "access",
      render: (text: any, _record: any) => (
        <>
          <Tag className="bg-[#615bff] py-1 px-4 w-fit text-white text-xs">
            {text}
          </Tag>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "content",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (text, record: any) => (
        <label className="flex gap-3 ">
          <AiTwotoneEdit
            className=" fill-[#446dc7]"
            size={22}
            onClick={() => PermissionModelChanges(record)}
          />
          <MdDelete
            className=" fill-[#e62d2d]"
            size={22}
            onClick={() => handleClickDelete(record)}
          />
        </label>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={Data}
        loading={roleAndPermission?.isFetching}
        size="small"
      />
      <CreatePermissionModal
        modalTittle={"Update Permission"}
        handleOpenPermissionModel={permissionModal}
        PermissionModelChanges={PermissionModelChanges}
        content={
          <PermissionForm
            onCancel={handleClosePermissionModal}
            dataToUpdate={dataToUpdate}
          />
        }
      />

      <DeleteModal
        visible={visible}
        isLoading={roleAndPermission.isFetching}
        onOk={handlerDelectPermission}
        onCancel={handleCancels}
        itemName={`${permissionToDelete?.permission} Permission`}
      />
    </>
  );
};
export default Permissions;
