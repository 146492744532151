import React from "react";
import DashLayout from "../../components/layout/Layout";
import "chartkick/chart.js";
import { useEffect, useState } from "react";
import {
  getDataActivationStatsActions,
  getRevenueAndQtyStatsActions,
  getSamphonePaymentStatsActions,
  getMTNPaymentStatsActions,
  getPiecesSoldStatsActions,
  getQuantitySoldStatsActions,
  getShopStatsActions,
  getAgentStatsActions,
  getCorporatePaymentStatsActions,
  getMacyePaymentStatsActions,
  getAllNosaleStatsActions,
  getYelloPaymentStatsActions,
  getVubavubaPaymentStatsActions,
  getIntelligraInsurancePaymentStatsActions,
  getIkosoraStatsActions,
  getCurrentStockActions,
  getAllNosaleStatsforSpecificBrandActions,
  getBrandedStockActions,
  getBrandedStockStatsActions,
  getCommisionStatsActions,
  getFranchiseeCommisionActions,
  getIkosoraStockStatsAction,
} from "../../store/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import PaymentsInfo from "../../components/dashboard/Sections/PaymentsInfo";
import { DatePickerProps } from "antd";
import DashboardTopSection from "../../components/dashboard/Sections/DashboardTopSection";
import StockAndWarehouseInfo from "../../components/dashboard/Sections/StockAndWarehouseInfo";
import RegionalInfomation from "../../components/dashboard/Sections/RegionalInfomation";
import moment from "moment";
import QuantitySold from "../../components/dashboard/Sections/QuantitySold";
import { Divider } from "@mui/material";
import { getAllChannelAction } from "../../store/channel/actions";
import {
  handleSelectedChannelAction,
  showFranchiseeDash,
} from "../../store/layout/actions";
import FilterDashboardTabsButton from "./FilterDashboardTabsButton";

import { getActiveShop } from "../../utils/converter";
import DateSelection from "./dashboardSubComponents/DateSelection";
import { myKPIActions } from "../../store/kpi";
import { myEmployeeActions } from "../../store/setting";

const PageView = () => {
  const isIkosoraDash = localStorage.getItem("isIkosoraDashboard");
  const selectedDashboard =
    isIkosoraDash !== null ? JSON.parse(isIkosoraDash) : false;
  const { auth, layout } = useSelector((state: any) => state);

  const franchiseeShops =
    ["franchisee"]?.includes(auth?.user?.role) &&
    getActiveShop(auth?.user?.shop?.assigned)
      ?.map((el: any) => el?.shop?._id)
      ?.join(",");

  const franchiseeShopsQuery =
    ["franchisee"]?.includes(auth?.user?.role) &&
    getActiveShop(auth?.user?.shop?.assigned)?.map((el: any) => el?.shop?._id);

  const [hasIkosora, setHasIkosora] = useState<boolean>(selectedDashboard);
  const dispatch = useDispatch();
  const channels = auth?.user?.channel;
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const initialChannel = channels && channels?.at(0)?._id;
  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const plainOptions = ["type", "brand", "model"];
  const [checkedValue, setCheckedValue] = React.useState("brand");
  const [selectedKeyValue, setSelecteKeyValue] = React.useState("brand");
  const [selectedAgentRegionKey, setSelecteAgentRegionKey] = React.useState("");
  const [selectedShopRegionKey, setSelecteShopRegionKey] = React.useState("");
  const [selectedBrandedRegionKey, setSelecteBrandedRegionKey] =
    React.useState("");
  const [selectQtyByShopRegion, setSelectQtyByShopRegion] = React.useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedOverView, setSelectedOverView] = useState<any>("day");

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };
  const samphonePaymentMethos = [
    "Samphone_Bank_Deposit",
    "Samphone_POS",
    "MoMo_Pay",
  ];
  const mtnPaymentMethos = [
    "MTN_POS",
    "MTN_Staff_Device_Requisition",
    "MTN_Bank_Deposit",
  ];
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  useEffect(() => {
    initialChannel && setSelectedChannel(initialChannel);
    (initialChannel || selectedChannel) &&
      handleSelectedChannelAction(initialChannel ?? selectedChannel)(dispatch);
  }, [dispatch, initialChannel, selectedChannel]);

  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      getRevenueAndQtyStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }&product=${
          hasIkosora ? `${process.env.REACT_APP_Mtn_Branded_Product_ID}` : ""
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getAllNosaleStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : `&shops=${layout?.selectedShop}`
        }`
      )(dispatch);
      getAllNosaleStatsforSpecificBrandActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }&product=${process.env.REACT_APP_Mtn_Branded_Product_ID}`
      )(dispatch);
      getIkosoraStatsActions(
        auth?.token,
        `?selectBy=brand&date1=${enteredDate}&date2=${compareDate}&product=${
          process.env.REACT_APP_Mtn_Branded_Product_ID
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`
      )(dispatch);

      getMacyePaymentStatsActions(
        { modes: ["Macye_Macye"] },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${selectedOverView === "range" ? `&paymentModes=Macye_Macye` : ""}${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getYelloPaymentStatsActions(
        { modes: ["Yello_Payment"] },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${selectedOverView === "range" ? `&paymentModes=Yello_Payment` : ""}${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getVubavubaPaymentStatsActions(
        { modes: ["Vuba_Vuba"] },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${selectedOverView === "range" ? `&paymentModes=Vuba_Vuba` : ""}${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getIntelligraInsurancePaymentStatsActions(
        { modes: ["Intelligra_Insurance"] },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          selectedOverView === "range"
            ? `&paymentModes=Intelligra_Insurance`
            : ""
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getCorporatePaymentStatsActions(
        { modes: ["Corporate_Sales"] },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          selectedOverView === "range" ? `&paymentModes=Corporate_Sales` : ""
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);

      getSamphonePaymentStatsActions(
        { modes: samphonePaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          selectedOverView === "range"
            ? `&paymentModes=${samphonePaymentMethos}`
            : ""
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getMTNPaymentStatsActions(
        { modes: mtnPaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          selectedOverView === "range"
            ? `&paymentModes=${mtnPaymentMethos}`
            : ""
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getDataActivationStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&channel=${
          initialChannel ?? selectedChannel
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`,
        selectedOverView
      )(dispatch);
      getPiecesSoldStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&selectBy=type&channel=${
          initialChannel ?? selectedChannel
        }${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : ""
        }`
      )(dispatch);
      getBrandedStockStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&brand=${process.env.REACT_APP_Mtn_Branded_Product_ID}`
      )(dispatch);
      getCommisionStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    enteredDate,
    compareDate,
    selectedChannel,
    initialChannel,
    hasIkosora,
    selectedOverView,
  ]);

  //Current Stock
  useEffect(() => {
    if (auth?.token) {
      getCurrentStockActions(
        auth.token,
        `${selectedKeyValue}=$product.${selectedKeyValue}${
          ["franchisee"]?.includes(auth?.user?.role)
            ? `&shops=${franchiseeShops}`
            : `&shops=${layout?.selectedShop}`
        }`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, dispatch, selectedKeyValue, layout?.selectedShop]);

  //agent
  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      if (selectedOverView === "range") {
        getAgentStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&regions=${selectedAgentRegionKey}&channel=${
            initialChannel ?? selectedChannel
          }&date1End=${compareDate}${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      } else {
        getAgentStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&regions=${selectedAgentRegionKey}&channel=${
            initialChannel ?? selectedChannel
          }${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    enteredDate,
    compareDate,
    selectedAgentRegionKey,
    selectedChannel,
    initialChannel,
    selectedOverView,
  ]);

  //shop
  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      if (selectedOverView === "range") {
        getShopStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&regions=${selectedShopRegionKey}&channel=${
            initialChannel ?? selectedChannel
          }&product=${
            hasIkosora ? `${process.env.REACT_APP_Mtn_Branded_Product_ID}` : ""
          }&date1End=${compareDate}${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      } else {
        getShopStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&regions=${selectedShopRegionKey}&channel=${
            initialChannel ?? selectedChannel
          }&product=${
            hasIkosora ? `${process.env.REACT_APP_Mtn_Branded_Product_ID}` : ""
          }${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    enteredDate,
    compareDate,
    selectedShopRegionKey,
    selectedChannel,
    initialChannel,
    selectedOverView,
  ]);

  //quantity sold
  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      if (selectedOverView === "range") {
        getQuantitySoldStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&selectBy=${checkedValue}&region=${selectQtyByShopRegion}&channel=${
            initialChannel ?? selectedChannel
          }&date1End=${compareDate}${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      } else {
        getQuantitySoldStatsActions(
          auth?.token,
          `?date1=${enteredDate}&date2=${compareDate}&selectBy=${checkedValue}&region=${selectQtyByShopRegion}&channel=${
            initialChannel ?? selectedChannel
          }${
            ["franchisee"]?.includes(auth?.user?.role)
              ? `&shops=${franchiseeShops}`
              : ""
          }`
        )(dispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    enteredDate,
    compareDate,
    selectedChannel,
    selectQtyByShopRegion,
    initialChannel,
    selectedOverView,
    checkedValue,
  ]);

  useEffect(() => {
    localStorage.setItem("isIkosoraDashboard", JSON.stringify(hasIkosora));
  }, [hasIkosora]);

  //branded stock
  useEffect(() => {
    if (auth?.token) {
      getBrandedStockActions(
        auth?.token,
        `?region=${selectedBrandedRegionKey}&brand=${process.env.REACT_APP_Mtn_Branded_Product_ID}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, selectedBrandedRegionKey, hasIkosora]);

  //ikosora stock statistics
  useEffect(() => {
    if (auth?.token && hasIkosora) {
      getIkosoraStockStatsAction(
        auth?.token,
        `?product=${process.env.REACT_APP_Mtn_Branded_Product_ID}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, hasIkosora]);

  //commisson By Franchisee
  useEffect(() => {
    if (auth?.token && ["franchisee"]?.includes(auth?.user?.role)) {
      showFranchiseeDash(true)(dispatch);
      getFranchiseeCommisionActions(
        auth?.token,
        `?${franchiseeShopsQuery?.map((id: any) => `shop=${id}`)?.join("&")}`
      )(dispatch);
    } else {
      showFranchiseeDash(false)(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, auth?.user?.role, dispatch]);

  useEffect(() => {
    dispatch(myKPIActions.setAllEarning(null));
    dispatch(myKPIActions.setAll([]));
    dispatch(myEmployeeActions.setSelected(null));
  }, []);

  return (
    <DashLayout>
      <div className="py-10  text-black piece_sold w-full h-full">
        {/* Section for datepicker */}
        <DateSelection
          setSelectedChannel={setSelectedChannel}
          hasIkosora={hasIkosora}
          setSelectedOverView={setSelectedOverView}
          setEnteredDate={setEnteredDate}
          setCompareDate={setCompareDate}
          selectedOverView={selectedOverView}
          onChangeDate={onChangeDate}
          enteredDate={enteredDate}
          onChangePreviousDate={onChangePreviousDate}
          compareDate={compareDate}
        />
        <FilterDashboardTabsButton
          setHasIkosora={setHasIkosora}
          selectedOverView={selectedOverView}
        />
        <Divider sx={{ mb: 1 }} />
        {hasIkosora && (
          <h1 className="pl-1 pr-4 pt-4 text-lg font-medium">
            IKOSORA + Sales Statistic
          </h1>
        )}
        <div className=" mx-auto">
          {/* Total revenue, Quantity sold, Data Activation, Nosale,
          branded-TotalInWarehaouse, branded-TotalInShop,
          branded-TotalReceived, branded-TotalSold */}

          <DashboardTopSection
            enteredDate={enteredDate}
            compareDate={compareDate}
            hasIkosora={hasIkosora}
          />

          {!hasIkosora && (
            <>
              {/* Macye, Corporate, Samphone,
              MTN Payments, Ikosora, Commission */}

              <PaymentsInfo
                enteredDate={enteredDate}
                compareDate={compareDate}
              />
              {/* Piece Sold and Products*/}
              <StockAndWarehouseInfo
                setSelecteKeyValue={setSelecteKeyValue}
                selectedKeyValue={selectedKeyValue}
                isFrachisee={franchiseeShops}
              />
            </>
          )}

          {/* Shop, Agent, Ikosora-nosale, IkosoraNosaleReason
          Ikosora-stock, Ikosora-sales */}

          <RegionalInfomation
            setSelecteAgentRegionKey={setSelecteAgentRegionKey}
            setSelecteShopRegionKey={setSelecteShopRegionKey}
            selectedAgentRegionKey={selectedAgentRegionKey}
            selectedShopRegionKey={selectedShopRegionKey}
            setSelecteBrandedRegionKey={setSelecteBrandedRegionKey}
            enteredDate={enteredDate}
            compareDate={compareDate}
            hasIkosora={hasIkosora}
          />

          {/* Quantity Sold */}
          {!hasIkosora && (
            <QuantitySold
              title="Quantity Sold"
              setCheckedValue={setCheckedValue}
              setSelectQtyByShopRegion={setSelectQtyByShopRegion}
              checkedValue={checkedValue}
              plainOptions={plainOptions}
              enteredDate={enteredDate}
              compareDate={compareDate}
            />
          )}
        </div>
      </div>
    </DashLayout>
  );
};

export default PageView;
