import React from "react";
import type { MenuProps } from "antd";
import { Button, Dropdown, Popconfirm } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  getAllTransactionInRangeAction,
  revertSalesAction,
} from "../../../store/pos/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ThreeDotDropdown = (props: any) => {
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  // const confirm = async (data: any) => {
  //   auth?.token &&
  //     (await revertSalesAction(
  //       auth?.token,
  //       `?cartItemId=${data?._id}&serialNumber=${data?.warehouseProduct?.serialNumber}`
  //     )(dispatch));
  //   await getAllTransactionInRangeAction(
  //     auth?.token,
  //     `${`?limit=15&page=0&startDate=${formattedPreviousDate}&endDate=${formattedDate}`}`
  //   )(dispatch);
  // };

  let items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        //  (
        //   <Popconfirm
        //     title="Revert Sale"
        //     description="Are you sure to Revert this Sales?"
        //     onConfirm={() => confirm(props?.onRevertSale)}
        //     okText="Yes"
        //     cancelText="No"
        //     okButtonProps={{ style: { background: "blue", color: "white" } }}
        //   >
        <Button
          type="primary"
          style={{ color: "white", background: "blue" }}
          // onClick={() => props?.onRevertSale}
          onClick={() => props?.onRevertSale()}
        >
          Revert Sales
        </Button>
      ),
      //   </Popconfirm>
      // ),
    },
    {
      key: "2",
      label: <p onClick={() => props?.onPaidOn()}>Paid On</p>,
    },
    {
      key: "3",
      label: <p onClick={() => props?.onAmountPaid()}>Amount Paid</p>,
    },
    {
      key: "4",
      label: <p onClick={() => props?.onPaymentMethod()}>Payment Method</p>,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <BsThreeDotsVertical color="black" />
    </Dropdown>
  );
};

export default ThreeDotDropdown;
