import React, { useEffect } from "react";
import { Table, Tag, Space, Divider, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersAction,
  getCustomersAction,
  getTopCustomersAction,
} from "../../../store/customer/actions";
import { ReactComponent as UserCustomer } from "../../../assets/icons/customer.svg";
import { searchValue } from "../../../utils/setColor";
import type { TableColumnsType } from "antd";
import { useNavigate } from "react-router-dom";
import DateRangeCustomer from "./DateRangeCustomer";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { myLayoutActions } from "../../../store/layout";

interface DataType {
  key: React.Key;
  name: string;
  foto: string;
  id: any;
  email: string;
  phone: string;
  adress: string;
  status: any;
}

interface DataTypes {
  key: React.Key;
  customer: string;
  agent: any;
  paymentmode: string;
  amount: any;
  discount: any;
  transaction: any;
  quantity: any;
  total: any;
  status: any;
}

const PageView = (props: any) => {
  const { auth, customer } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        customer?.query || `?limit=5`
      )(dispatch);
  }, [auth?.token, customer.new, customer?.query, customer.updated, dispatch]);

  useEffect(() => {
    auth?.token &&
      getTopCustomersAction(
        auth?.token,
        customer?.query || `?limit=5`
      )(dispatch);
  }, [auth?.token, customer?.query, dispatch]);

  useEffect(() => {
    auth?.token && getCustomersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  const customers = [
    {
      label: "All Customers",
      value: customer?.allCustomers?.data?.all?.toLocaleString() || 0,
    },
    {
      label: "Active Customers",
      value: customer?.allCustomers?.data?.active?.toLocaleString() || 0,
    },
    {
      label: "Inactive Customers",
      value: customer?.allCustomers?.data?.inactive?.toLocaleString() || 0,
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Space>
            <img
              className="w-6 h-6 rounded-full"
              src={record?.foto}
              alt="customer avatar"
            />
            <p className="capitalize"> {text?.toLowerCase()}</p>
          </Space>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "adress",
      key: "adress",
      render: (text, _record) => {
        return <p className="capitalize"> {text?.toLowerCase()}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, _record) => {
        return (
          <>
            <Tag color={searchValue(text)}>{text}</Tag>
          </>
        );
      },
    },
  ];
  const column: TableColumnsType<DataTypes> = [
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, _record) => {
        return <p className="capitalize"> {text?.toLowerCase()}</p>;
      },
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, _record) => {
        return <p className="capitalize"> {text?.toLowerCase()}</p>;
      },
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentmode",
      key: "paymentmode",
    },
    {
      title: "Amount (Rwf)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, _record) => {
        return <p>{text}%</p>;
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction",
      key: "transaction",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total (Rwf)",
      dataIndex: "total",
      key: "total",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <>
            <Tag color={searchValue(record?.status)}>{record?.status}</Tag>
          </>
        );
      },
    },
  ];

  const customerdata = customer?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      name: el?.name,
      foto: el?.picture,
      id: el?.nid,
      email: el?.email,
      phone: el?.phone,
      adress: el?.address,
      status: el?.status,
    };
  });
  
  const recentactivity = customer?.selectByDate?.data?.transactions?.map(
    (el: any) => {
      return {
        key: el?._id,
        customer: el?.cart?.customer?.name,
        agent: el?.createdBy?.names,
        paymentmode: el?.cart?.payment?.map((p:any)=>p?.mode)?.join(' , '),
        amount: el?.cart?.payment?.map((a:any)=>a?.amount?.toLocaleString()),
        discount: el?.cart?.discount?.discountPerc,
        transaction: el?.cart?.cartId,
        quantity: 1,
        total:el?.cart?.payment?.reduce((acc:any, current:any) => acc + current.amount, 0)?.toLocaleString(),
        status: el?.status,
      };
    }
  );
  return (
    <div className="text-[#030229] py-10 scrollbar-hide overflow-y-auto h-screen">
      <div className="flex gap-8 flex-wrap">
        {customers?.map((data: any) => (
          <div className="bg-white w-[20rem] p-6 flex gap-8 rounded-lg">
            <UserCustomer />
            <span>
              <h1 className="font-semibold text-[1.35rem] opacity-80 leading-loose">
                {data?.value}
              </h1>
              <p className="opacity-70">{data?.label}</p>
            </span>
          </div>
        ))}
      </div>
      <div className=" flex flex-col md:flex-row xl:justify-between mt-6 sm:gap-4">
        <div className="xl:w-[64%] sm:w-full bg-white rounded-lg overflow-x-auto">
          <div className="font-semibold flex justify-between mx-4 p-2 py-4">
            <h1>Customers</h1>
            <p
              onClick={() => {
                dispatch(myLayoutActions.setIsCustomertableview("table view"));
                navigate("/customers");
              }}
              className="text-[.7rem] cursor-pointer text-[#605BFF] font-normal"
            >
              View All
            </p>
          </div>
          <Table
            columns={columns}
            dataSource={customerdata}
            pagination={false}
            loading={customer?.isFetching}
          />
        </div>
        <div className="xl:w-[35%] lg:w-[60%] sm:w-full rounded-lg p-[.2rem] bg-white sm:mt-6 xl:mt-0 pb-4">
          <h1 className="p-4 font-semibold py-4 ">Top Customers</h1>
          {customer?.topCustomer?.data?.map((d: any, index: any) => (
            <>
              {customer?.isFetcching ? (
                <div className="w-[10rem] h-[10rem bg-red-400  border-gray-300]">
                  <Skeleton />
                </div>
              ) : (
                <div key={index}>
                  <div className="flex justify-between px-8 mt-2">
                    <div className="flex gap-3 ">
                      <img
                        className="w-8 h-8 rounded-full"
                        src={d?.customer?.picture}
                        alt="customer avatar"
                      />
                      <span>
                        <h1 className="text-sm font-medium capitalize">
                          {d?.customer?.name?.toLowerCase()}
                        </h1>
                        <p className="text-[.7rem] opacity-70">
                          {d?.customer?.email}
                        </p>
                      </span>
                    </div>
                    <h1 className=" text-sm font-medium">
                      Rwf {d?.totalPayment?.toLocaleString()}
                    </h1>
                  </div>
                  {index !== customer.topCustomer.data.length - 1 && (
                    <Divider />
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="xl:flex sm:flex-wrap xl:justify-between  mt-6">
        <div className="xl:w-[64%] sm:w-full bg-white rounded-lg overflow-x-auto">
        <h1 className="p-2 font-semibold py-4 mx-4">All Activities</h1>
          <ScrollableFrame
            loading={customer?.isFetching}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={customer?.selectByDate?.data?.totalCount}
            count={Math.ceil(customer?.selectByDate?.data?.totalCount / limit)}>
            <Table
              columns={column}
              dataSource={recentactivity}
              pagination={false}
              loading={customer?.isFetching}
              scroll={{ y: 700 }}
            />
          </ScrollableFrame>
        </div>
        <div className="xl:w-[35%] lg:w-[60%] sm:w-full rounded-lg p-[.2rem] bg-white overflow-x-auto sm:mt-6 xl:mt-0 h-[26rem]">
          <DateRangeCustomer page={page} limit={limit} />
        </div>
      </div>
    </div>
  );
};
export default PageView;
