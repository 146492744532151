import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PrimaryButton = (props: any) => {
  return (
    <button
      {...props}
      type="button"
      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none w-fit"
    >
      {props?.name + " "}
      {props?.isLoading && <Spin indicator={<LoadingOutlined spin />} />}
    </button>
  );
};

export default PrimaryButton;
