import { notification } from "antd";
import { myVaultActions } from ".";
import { authActions } from "../auth";
import store from "store";
import {
  AddDeviceService,
  getDeviceStatus,
  lockDeviceService,
  unLockDeviceService,
  getDeviceDetailsService,
  sendMessageService,
  getAllDevicesService,
  getDeviceStatusService,
  getOverviewCountService,
  getNotesServices,
  getDeviceServiceStatus,
  getMessageTamplateService,
  getSearchDevicesService,
  getDevicePieChartstatsService,
  AddcsvfileService,
  reloadDeviceService,
  releaseDeviceService,
} from "./services";

export const addDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await AddDeviceService(data, token);
      if (res?.status === 200) {
        dispatch(myVaultActions.setNew(res));

        const messages: any = [];
        res?.data?.deviceList.forEach((device: any) => {
          if (device?.resultMessage) {
            messages.push(device.resultMessage);
          }
        });
        messages.forEach((message: any) => {
          notification.success({
            message: message,
          });
        });


        dispatch(myVaultActions.setIsFetching(false));
      }

      if (res?.response?.status === 400) {
        notification.error({
          message: res?.response?.data?.error,
        });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addcsvfileAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await AddcsvfileService(data, token);

      if (res?.status === 200) {
        dispatch(myVaultActions.setnewCsv(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({ message: "Success" });
      } 
      if (res?.response?.status === 400) {
        notification.error({
          message: res?.response?.data?.error,
        });
      }
      else {
        dispatch(myVaultActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(myVaultActions.setIsFetching(false));
    }
  };
};

export const lockDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await lockDeviceService(data, token);

      if (res?.status === 200) {
        dispatch(myVaultActions.setLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        if (res?.data?.lockResponseList[0]?.resultCode !== 200) {
          notification.error({
            message: res?.data?.lockResponseList[0]?.resultMessage,
          });
        } else {
          notification.success({
            message: res?.data?.lockResponseList[0]?.resultMessage,
          });
        }
      }

      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const unLockDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await unLockDeviceService(data, token);
      if (res?.status === 200) {
        dispatch(myVaultActions.setUnLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        if (res?.data?.unlockResponseList[0]?.resultCode !== 200) {
          notification.error({
            message: res?.data?.unlockResponseList[0]?.resultMessage,
          });
        } else {
          notification.success({
            message: res?.data?.unlockResponseList[0]?.resultMessage,
          });
        }
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const reloadDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await reloadDeviceService(data, token);
      if (res?.status === 200) {
        dispatch(myVaultActions.setReload(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({
          message: res?.message,
        });
      }

      if (res?.response?.status === 400) {
        notification.error({
          message: res?.response?.data?.error,
        });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const releaseDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await releaseDeviceService(data, token);
      if (res?.status === 200) {
        dispatch(myVaultActions.setRelease(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({
          message: res?.message,
        });
      }

      if (res?.response?.status === 400) {
        notification.error({
          message: res?.response?.data?.error,
        });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendMessageAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await sendMessageService(data, token);

      if (res?.status === 200) {
        dispatch(myVaultActions.setUnLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({ message: "Success" });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllDevicesAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getAllDevicesService(token, query);

      if (res?.status === 200) {
        dispatch(myVaultActions.setAll(res));
        dispatch(myVaultActions.setIsFetching(false));
      } else {
        dispatch(myVaultActions.setAll({ data: [] }));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//

export const getSearchDevicesAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getSearchDevicesService(token, query);

      if (res?.status === 200) {
        dispatch(myVaultActions.setAll(res));
        dispatch(myVaultActions.setIsFetching(false));
      }

      if (res?.response?.status === 404) {
        notification.error({
          message: res?.response?.data?.error || "device not found",
        });
        dispatch(myVaultActions.setAll({ data: [] }));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDeviceDetailsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceDetailsService(data, token);
      if (res?.status === 201) {
        dispatch(myVaultActions.setSelected(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// setPiechart
export const getDevicesStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceStatusService(token, query);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setStatusCount(res?.data));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDevicesPiechartAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDevicePieChartstatsService(token, query);
    
      if (res?.status === 200) {
        dispatch(myVaultActions.setPiechart(res?.data));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOverviewCountAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getOverviewCountService(token);
    
      if (res?.status === 200) {
        dispatch(myVaultActions.setOverViewCount(res?.data));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDeviceStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceStatus(token, query);
 
      if (res?.status === 200) {
        dispatch(myVaultActions.setDeviceStatus(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDeviceServiceStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceServiceStatus(token, query);
  
      if (res?.status === 200) {
        dispatch(myVaultActions.setDeviceServiceStatus(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getMessageTamplateAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getMessageTamplateService(token, query);
     
      if (res?.status === 200) {
        dispatch(myVaultActions.setMessageTamplate(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getnotesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getNotesServices(token, query);
      
      if (res?.status === 200) {
        dispatch(myVaultActions.setNotes(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
