import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createCompanyService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/settings/company`, data, token);
};

export const getAllCompanyService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/company${query}`, token);
};

export const getOneCompanyService = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/company/one/${itemId}`, token);
};
export const campanyResizedLogoService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/upload`, data, token);
};
export const updateCompanyService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/company/one/${itemId}`,
    data,
    token
  );
};