import { Radio, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllShopAction } from "../../store/channel/actions";

const ProductsStats = (props: any) => {
  const { dashboard, layout, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const capitalizedTitle =
    props?.selectedKeyValue?.charAt(0)?.toUpperCase() +
    props?.selectedKeyValue?.slice(1);

  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      width: 50,
      render: (text: string) => (
        <div className="flex truncate">
          <span className="w-4 h-4 bg-blue-400"></span>
        </div>
      ),
    },
    {
      title: capitalizedTitle + "s",
      dataIndex: "brand",
      key: "brand",
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title: "In Shops",
      dataIndex: "inShops",
      key: "inShops",
      // render: (text: string) => <div className=" w-full truncate">{text}</div>,
    },
    {
      title: !props?.isFrachisee && "In Warehouse",
      dataIndex: "inWarehouse",
      key: "inWarehouse",
      render: (text: string, record: any) =>
        !record?.isFrachisee && <div>{text}</div>,
    },
    {
      title: "Sold",
      dataIndex: "sold",
      key: "sold",
      render: (text: string) => <div style={{ color: "red" }}>{text}</div>,
    },
    {
      title: "Idle",
      dataIndex: "idle",
      key: "idle",
      render: (text: string) => <div style={{ color: "blue" }}>{text}</div>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_: any, data: any) => (
        <Typography style={{ fontWeight: 600 }}>
          {data.inShops + data.inWarehouse + data.sold + data.idle}
        </Typography>
      ),
    },
  ];

  const dataToDisplay = dashboard?.currentStock?.data?.map((el: any) => {
    return {
      brand: el?.name,
      inShops: el?.status?.out ?? 0,
      inWarehouse: el?.status?.in ?? 0,
      sold: el?.status?.sold ?? 0,
      idle: el?.status?.idle ?? 0,
      isFrachisee: props?.isFrachisee,
    };
  });

  const handleSelectedKey = (value: any) => {
    props?.setSelecteKeyValue(value);
  };

  useEffect(() => {
    auth?.token &&
      layout?.selectedChannel &&
      getAllShopAction(
        auth?.token,
        `channel=${layout?.selectedChannel}`
      )(dispatch);
  }, [auth, layout?.selectedChannel, dispatch]);

  return (
    <div className="flex-1 w-full lg:w-8/12 rounded-lg p-4 bg-white ">
      <div className="lg:flex justify-between mb-4">
        <h3>Current Stock</h3>
        <div className="flex items-center space-x-2">
          {/* <span>
            {layout?.selectedChannel && (
              <Select
                showSearch
                onSearch={(value: any) =>
                  searchFuction(
                    value,
                    channel?.allShop?.data,
                    (option: any, val) =>
                      option?.name?.toLowerCase().includes(val.toLowerCase()),
                    setShopFilter
                  )
                }
                filterOption={false}
                placeholder="Filter by Shop"
                allowClear
                style={{ width: 180 }}
                onChange={(value) => {
                  handleSelectedShop(value)(dispatch);
                }}
              >
                {shopFilter.length > 0
                  ? shopFilter.map((d: any) => (
                      <Select.Option value={d?._id} key={d?._id}>
                        {d?.name}
                      </Select.Option>
                    ))
                  : channel?.allShop &&
                    channel?.allShop?.data.map((d: any, index: any) => (
                      <Select.Option value={d?._id} key={index}>
                        {d?.name}
                      </Select.Option>
                    ))}
              </Select>
            )}
          </span> */}
          <Radio.Group
            defaultValue="brand"
            buttonStyle="solid"
            onChange={(e: any) => handleSelectedKey(e.target.value)}
          >
            <Radio.Button value="model">Models</Radio.Button>
            <Radio.Button value="type">Types</Radio.Button>
            <Radio.Button value="brand">Brands</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className="lg:flex space-x-2">
        <div className="flex-1">
          <div className="h-fit overflow-y-auto">
            <Table
              columns={columns}
              dataSource={dataToDisplay}
              pagination={false}
              loading={dashboard?.productStatsIsFetching}
              className="table-auto w-[95%]"
              scroll={{ y: 500 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsStats;
