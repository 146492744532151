import React, { useEffect, useState } from "react";
import { DatePicker, Select, DatePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { GoPlus } from "react-icons/go";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import { ReactComponent as VectorIcons } from "../assets/images/icons/Vector (1).svg";
import { ReactComponent as PurchaseIcons } from "../assets/images/icons/Group 8936 (1).svg";
import { ReactComponent as CartIcons } from "../assets/images/icons/Group 1000002857 (1).svg";
import { ReactComponent as PIcons } from "../assets/images/icons/Group 8936 (5).svg";
import { ReactComponent as StockIcons } from "../assets/images/icons/Group 8936.svg";
import { ReactComponent as RRAIcons } from "../assets/images/icons/RRA logo 1.svg";
import OverviewChart from "../components/OverviewChart";
import OverViewPieChart from "../components/OverviewPieChart";
import Timer from "../components/Timer";
import { useDispatch, useSelector } from "react-redux";
import { getEbmOverviewAction, getEbmSelectOptionAction } from "../store/action";
import { getNameByCode } from "../utils/ConvertCodeToName";
import LoadingCard from "../components/LoadingCard";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";

const Overview = () => {
  const { auth, ebmGeneral,ebmLayout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const [selectedDate2, setSelectedDate2] = useState<any>(formattedDate);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate(dateString);
  };
  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate2(dateString);
  };
  useEffect(() => {
    auth?.token &&
      getEbmOverviewAction(
        auth.token,
        ""
      )(dispatch);
  }, [auth?.token, dispatch]);
  useEffect(()=>{
    auth?.token && getEbmSelectOptionAction(auth?.token,"")(dispatch)
  },[dispatch,auth])
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
    }
  };
  const countryCode=[
    {
        label:"Rwanda",
        code:"RW"
    },
    {
        label:"Uganda",
        code:"UG"
    },
    {
        label:"Congo",
        code:"DRC"
    },
    {
        label:"United State of America",
        code:"USA"
    },
]
  const ConvertCodeToName=((items:any)=>{
    countryCode.filter((el:any)=>{
        if(el.code===items){
            console.log("value label",el.label)
            return el.label
        }
    })

})
 const text= ConvertCodeToName("USA")
 const items = [
  { code: 'RW', label: 'Rwanda' },
  { code: 'USA', label: 'United state' },
  { code: 'Ug', label: 'Uganda' }
];


const label = getNameByCode(items, "RW");
  return (
    <div>
      
      <div className="lg:flex justify-between">
        <div className="flex gap-5 item-center justify-center">
          <RRAIcons />
          <Timer />
        </div>
        <div className="flex gap-8 h-10 mt-5">
          <h1 className="pt-1">From</h1>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange} />
          <h1 className="pt-1">To</h1>
          <DatePicker
            defaultValue={dayjs(formattedDate)}
            onChange={onChangeDate}
          />
        </div>
      </div>
      <div className={`grid ${ebmLayout?.isSideNavOpen ?"grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4":"grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"} gap-5 mt-7 `}>
    { ebmGeneral?.isFetching ? <LoadingCard/>:
        <TwoIconsCard
          amount={
            ebmGeneral?.ebmOverview?.totalItems ||0
          }
          title={"Total Items"}
          name={`${
            ebmGeneral?.ebmOverview?.newItem ||0
            
          } new Item Added!`}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<CartIcons />}
        />}
       { ebmGeneral?.isFetching ? <LoadingCard/>: <TwoIconsCard
          amount={ebmGeneral?.ebmOverview?.totalCustomer ||0}
          title={"Total Customer"}
          name={"20 New Customer added!"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<CustomerAmountIcons />}
        />}
       { ebmGeneral?.isFetching ? <LoadingCard/>: <TwoIconsCard
          amount={ebmGeneral?.ebmOverview?.totalSales?.count ||0}
          title={"Total Sales"}
          name={"20 Waiting for approval"}
          iconTwo={<AmountIcons />}
        />}
        { ebmGeneral?.isFetching ? <LoadingCard/>:
        <TwoIconsCard
          amount={ebmGeneral?.ebmOverview?.totalPurchase ||0}
          title={"Total Purchase"}
          name={`2 Waiting for Approval`}
          iconTwo={<PIcons />}
        />}
        <TwoIconsCard
          amount={"50"}
          title={"Total Import"}
          name={"20 Waitiing for approval"}
          iconTwo={<PurchaseIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Total in Stock"}
          name={"Increased than Yesterday"}
          icon={<VectorIcons color="#43900C" />}
          iconTwo={<StockIcons />}
        />
      </div>
      <div className="2xl:flex gap-5 mt-5 flex-wrap">
        <div className="w-full 2xl:w-2/3 bg-white p-10 rounded-md">
          <div className="flex justify-between w-full my-3">
            <h1 className="text-[#030229] text-lg">
              Sales and Purchase Management
            </h1>
            <p>{label}</p>
            <div className="flex gap-5">
              <div className="flex gap-2  ">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#AFADFF] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p>Sales</p>
              </div>
              <div className="flex gap-2">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#DEDEDE] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p>Purchase</p>
              </div>
            </div>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Monthly"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Monthly"
                }
              ]}
            />
          </div>
          <OverviewChart />
        </div>
        <div className="w-full 2xl:w-[30%] bg-white p-4 mt-5 2xl:mt-0">
          <h1 className="text-[#030229] text-lg pl-6">Import Summary</h1>
          <div className="mt-8 relative  right-20">
            <OverViewPieChart/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
