import { Progress, Table } from "antd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import {
  calculatePercentageChange,
  convertObjectToArray,
} from "../../utils/converter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const data01 = [{ value: 1 }];
const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
];

const NoSaleKPI = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { dashboard } = useSelector((state: any) => state);

  const currentTotal = dashboard?.kpiStats?.data?.nosale[0]?.date1totalAmount;
  const comparedTotal = dashboard?.kpiStats?.data?.nosale[1]?.date2totalAmount;

  const percentage =
    calculatePercentageChange(currentTotal, comparedTotal) || 0;

  const pieCartData =
    dashboard?.kpiStats?.data?.nosale[0]?.type &&
    convertObjectToArray(dashboard?.kpiStats?.data?.nosale[0]?.type);
  const datas = pieCartData?.map((d: any) => {
    return {
      name: d[0],
      value: d[1],
    };
  });

  return (
    <div className="relative w-full shadow bg-white p-5  rounded-md">
      <div className="flex space-x-4">
        <p
          className={`text-gray-400 ${
            isMobile ? "text-[4vw]" : "text-[1.4vw]"
          } capitalize xmb-2`}
        >
          No Sales
        </p>
      </div>
      <p
        className={`${
          isMobile ? "text-[5vw]" : "text-[2vw]"
        }  text-black font-semibold`}
      >
        {`RWF ${currentTotal?.toLocaleString() ?? "0"}`}
      </p>
      <p
        className={`text-gray-400 ${
          isMobile ? "text-[3vw]" : "text-[1.2vw]"
        }  capitalize`}
      >{`RWF ${comparedTotal?.toLocaleString() ?? "0"}`}</p>
      <div
        className={` ${
          percentage?.type === "increase"
            ? "bg-green-200"
            : percentage?.type === "decrease"
            ? "bg-red-200"
            : "bg-blue-200"
        } absolute top-2 right-2 rounded-lg px-3 py-1`}
      >
        <span
          className={`${
            percentage?.type === "increase"
              ? "text-green-500"
              : percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          }`}
        >
          {percentage?.type === "increase" ? (
            <ArrowUpwardIcon />
          ) : percentage?.type === "decrease" ? (
            <ArrowDownwardIcon />
          ) : (
            ""
          )}

          {`${percentage?.percentage ? percentage?.percentage : 0}%`}
        </span>
      </div>
      <div className="h-[38vh]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            width={100}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={70}
              fill="#8884d8"
              legendType="none"
            />
            <Pie
              data={datas}
              dataKey="value"
              cx="50%"
              cy="50%"
              fontSize={isMobile ? "5vw" : "1.2vw"}
              innerRadius={70}
              outerRadius={90}
              label
              legendType="rect"
            >
              {datas?.map((_entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Legend
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              fontSize={"1.2vw"}
              align="center"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default NoSaleKPI;
