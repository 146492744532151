export function isNumber(n: any) {
  console.log("NUMBER", n);
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const storeSelectedCaption = (selectedCaption:any) => {
  localStorage.setItem('selectedCaption', selectedCaption);
};
const storeSelectedSubVault = (selectedSubVault:any) => {
  localStorage.setItem('selectedSubVault', selectedSubVault);
};
const storeSelectedSubEbm = (selectedSubEbm:any) => {
  localStorage.setItem('selectedSubEbm', selectedSubEbm);
};


const storeActiveMenu = (activeMenu:any) => {
    localStorage.setItem('activeCollapse', activeMenu);
}
const storeActiveVault = (activeVault:any) => {
    localStorage.setItem('activeCollapseVault', activeVault);
}
const storeActiveEbm = (activeEbm:any) => {
    localStorage.setItem('activeCollapseEbm', activeEbm);
}

const getActiveMenu = () => {
  const activeOne = localStorage.getItem('activeCollapse');
  if (activeOne !== null) {
    return parseInt(activeOne);
  } else {
    return 0; // or any default value you prefer
  }
};
const getActiveVault = () => {
  const activeVaultOne = localStorage.getItem('activeCollapseVault');
  if (activeVaultOne !== null) {
    return parseInt(activeVaultOne);
  } else {
    return 0; // or any default value you prefer
  }
};
const getActiveEbm = () => {
  const activeEbmOne = localStorage.getItem('activeCollapseEbm');
  if (activeEbmOne !== null) {
    return parseInt(activeEbmOne);
  } else {
    return 0; // or any default value you prefer
  }
};

export {
  storeActiveMenu,
  getActiveMenu,
  storeSelectedCaption,
  storeActiveVault,
  storeActiveEbm,
  storeSelectedSubEbm,
  storeSelectedSubVault,
  getActiveVault,
  getActiveEbm
}