import React from "react";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import DeleteAction from "./deleteaccount";
import UpdateAction from "./updateaccount";

const ThreeDotDropdown = (props: any) => {
  let items: MenuProps["items"] = [
    {
      key: "1",
      label: <UpdateAction onUpdate={props?.onUpdate} />,
    },
    {
      key: "2",
      label: <DeleteAction onDelete={props?.onDelete} />,
    },
  ];

  // Check if the route is equal to "/subscription/order" and remove the "UpdateAction" item.
  if (window.location.pathname === "/subscription/order") {
    items = items.filter((item: any) => item.key !== "1");
  }

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <BsThreeDotsVertical color="black" />
    </Dropdown>
  );
};

export default ThreeDotDropdown;
