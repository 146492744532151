import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const addStockItemService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/warehouse`, data, token);
};

export const getAllDeliveryNoteService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/delivNote${query}`, token);
};

export const getOneDeliveryNoteService = async (
  token: string,
  delivNoteId: string
) => {
  return HttpRequest.get(`${SERVER_URL}/delivNote/one/${delivNoteId}`, token);
};

export const updateOneDeliveryNoteService = async (
  token: string,
  delivNoteId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/delivNote/one/${delivNoteId}`,
    data,
    token
  );
};

export const updateOneDeliveryItemService = async (
  token: string,
  delivNoteId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/deliverItem/one/${delivNoteId}`,
    data,
    token
  );
};

export const getStockStats = async (token: any, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse/stockStats${query}`, token);
};

export const getAllShopStock = async (token: any, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem/search${query}`, token);
};

export const createShopStockCart = async (token: any, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/cart`, data, token);
};

export const getShopStockCart = async (token: any, cartId: any) => {
  return HttpRequest.get(`${SERVER_URL}/cart/one/${cartId}`, token);
};

export const updateShopStockCart = async (
  token: any,
  cartId: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cart/additem/${cartId}`,
    data,
    token
  );
};

export const updateShopStockCartDetails = async (
  token: any,
  cartId: any,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/cart/one/${cartId}`, data, token);
};
export const updateShopStockCartItemDetails = async (
  token: any,
  id: any,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/cartItem/one/${id}`, data, token);
};

export const removeCartItem = async (token: any, cartId: string, data: any) => {
  return HttpRequest.delete(
    `${SERVER_URL}/cart/removeitem/${cartId}`,
    token,
    data
  );
};
export const warehouseItemCartData = async (token: any, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem${query}`, token);
};

export const getDeliveryNoteStatsService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/delivNote/stats`, token);
};
