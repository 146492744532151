import { myTransferActions } from ".";
import {
  getAllChannel,
  getAllProductService,
  getAllServiceStock,
  getAllShop,
  makeTransferService,
} from "./services";

export const getAllChannelTotransferAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTransferActions.setIsFetching(true));
      const res = await getAllChannel(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myTransferActions.setAll(res));
        dispatch(myTransferActions.setIsFetching(false));
      }
      dispatch(myTransferActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopTotransferAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTransferActions.setIsFetching(true));
      const res = await getAllShop(token, query);
      // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myTransferActions.setShopTo(res));
        dispatch(myTransferActions.setIsFetching(false));
      }
      dispatch(myTransferActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTransferActions.setIsFetching(true));
      const res = await getAllProductService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myTransferActions.setShopProducts(res));
        dispatch(myTransferActions.setIsFetching(false));
      }
      dispatch(myTransferActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopStockAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTransferActions.setIsFetching(true));
      const res = await getAllServiceStock(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myTransferActions.setShopStock(res));
        dispatch(myTransferActions.setIsFetching(false));
      }
      dispatch(myTransferActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const makeTransferAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTransferActions.setIsFetching(true));
      const res = await makeTransferService(data, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myTransferActions.setNew(res));
        dispatch(myTransferActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
