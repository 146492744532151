import React from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, notification, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AddOneStockItemAction,
  getOneDeliveryNoteAction,
  updateOneDeliveryItemAction,
  updateOneDeliveryNoteAction,
} from "../../store/wareHouse/actions";
import FileUpload from "../buttons/FileUpload";

const App = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const snRef = React.useRef<any>(null);
  const nextButtonRef = React.useRef<any>(null);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);
  const [fileList, setFileList] = React.useState<any>(null);
  const [currentItem, setCurrentItem] = React.useState<number>(
    props?.data?.qtyStored
  );
  const [itemsArr, setItemArray] = React.useState<any>([]);
  const isFinalItem =
    props?.data?.qtyReceived - (props?.data?.qtyStored ?? 0) ===
    itemsArr.length;
  React.useEffect(() => {
    snRef.current.focus();
  }, []);

  const onFinish = async (values: any) => {
    if (
      new Set(values?.serialNumber?.trim()?.split(" ")).size !==
      values?.serialNumber?.trim()?.split(" ").length
    ) {
      setDuplicateItems(
        values?.serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              values?.serialNumber?.trim()?.split(" ").indexOf(value) !== index
          )
      );
      return notification.warning({
        message: "IMEI has been duplicated",
        description: `${values?.serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              values?.serialNumber?.trim()?.split(" ").indexOf(value) !== index
          )
          .join(" , ")}`,
      });
    }
    if (values?.serialNumber?.trim()?.split(" ").length > 1000) {
      return notification.warning({
        message: "IMEI limits is 1000",
        description: `Remove ${
          values?.serialNumber?.trim()?.split(" ").length - 1000
        } IMEI `,
      });
    }
    const ites = values?.serialNumber
      ?.trim()
      ?.split(" ")
      .map((imei: String) => ({
        serialNumber: imei,
        quotationItem: props?.data?.quotationItemId,
        warehouseItem: props?.data?._id,
        product: props?.data?.data?.purchaseItem?.quotationItem?.product?._id,
      }));
    // itemsArr.push(payload);
    setItemArray(ites);
    form.resetFields(["serialNumber", "imei1", "imei2"]);
  };

  const handlerSubmit = async () => {
    // console.log(values, "ssssssssssssssssssssssssssssssssssssss");
    // return;
    if (auth?.token && (isFinalItem || itemsArr.length > 0)) {
      const res = await AddOneStockItemAction(
        auth?.token,
        props?.data?.deliveryNoteId,
        {
          items: itemsArr,
        }
      )(dispatch);
      if (auth?.token && isFinalItem && res?.check) {
        await updateOneDeliveryItemAction(
          auth?.token,
          props?.data?._id,
          props?.data?.deliveryNoteId,
          { status: `Stored-${props?.data?.itemStatus}` }
        )(dispatch);

        if (
          wareHouse?.selectedDeliveryNote?.data?.listOfItems?.filter(
            (obj: any) => obj.status === "Stored"
          ).length ===
          wareHouse?.selectedDeliveryNote?.data?.listOfItems?.length
        ) {
          auth?.token &&
            updateOneDeliveryNoteAction(
              auth.token,
              wareHouse?.selectedDeliveryNote?.data?._id,
              {
                status: `Stored-${wareHouse?.selectedDeliveryNote?.data?.status}`,
              }
            )(dispatch);
        }
      }
      setCurrentItem(0);
      if (!res?.check) {
        notification.warning({ message: res?.data?.error });
        setTimeout(() => {
          return window.location.reload();
        }, 3000);
      } else {
        return window.location.reload();
      }
    } else {
      form.resetFields(["serialNumber", "imei1", "imei2"]);
      setCurrentItem(currentItem + 1);
      snRef.current.focus();
    }
  };

  React.useEffect(() => {
    auth?.token &&
      getOneDeliveryNoteAction(
        auth?.token,
        props?.data?.deliveryNoteId
      )(dispatch);
  }, [auth?.token, dispatch, props?.data?.deliveryNoteId]);

  return (
    <>
      <div className="mb-10">
        <h1 className="font-bold">Add Product Item</h1>
        <p className="font-semibold py-0.5">
          Total No of Products:{" "}
          {`${props?.data?.qtyReceived - (props?.data?.qtyStored ?? 0)}`}
        </p>
        <div className="flex flex-col justify-between mx-2 bg-[#F4F6FA] p-2.5 rounded gap-2">
          <div className="flex justify-between bg-[#F4F6FA]  rounded">
            <p className="text-xs mr-0.5">
              <span className="text-xs mr-0.5 font-semibold">Type:</span>
              {props?.data?.type}
            </p>
            <p className="text-xs mr-0.5">
              <span className="text-xs mr-0.5 font-semibold">Brand:</span>
              {props?.data?.brand}
            </p>

            <p className="text-xs mr-0.5">
              <span className="text-xs mr-0.5 font-semibold">Model:</span>
              {props?.data?.model}
            </p>
          </div>
          <p className="text-xs mr-0.5">
            <span className="text-xs mr-0.5 font-bold">Description:</span>
            {console.log(props?.data?.description)}
            {props?.data?.description.map(
              (item: any) =>
                item[1] && (
                  <div className="flex items-center gap-2">
                    <span className="font-semibold text-xs">{item[0]}: </span>
                    <span className=" text-sm">{item[1]}</span>
                  </div>
                )
            )}
          </p>
        </div>
      </div>
      <Form
        // {...formItemLayout}
        form={form}
        name="Stock"
        onFinish={onFinish}
        initialValues={props.data || {}}
        scrollToFirstError
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={true}
        style={{
          maxWidth: 600,
        }}
      >
        <div className="flex items-center">
          <Form.Item
            name="serialNumber"
            label="Serial Number"
            tooltip="Please enter Serial Number?"
            rules={[
              {
                required: true,
                message: "Serial Number is required!",
                whitespace: true,
              },
            ]}
          >
            <Input ref={snRef} style={{ width: 460, height: 35 }} />
          </Form.Item>
          <Button type="submit">Verify</Button>
          <div className="flex items-center mt-2">
            <span className="pr-2.5">Or</span>
            <FileUpload
              setFileList={setFileList}
              data={props?.data}
              onCancel={props?.onCancel}
              setFiles={props?.setFiles}
              files={props?.files}
            />
          </div>
        </div>
      </Form>

      <div className="flex flex-wrap  mx-2 bg-[#F4F6FA] p-2.5 rounded gap-2">
        {itemsArr?.map((item: any) => (
          <Tag closable onClose={() => {}}>
            {item?.serialNumber}
          </Tag>
        ))}
      </div>
      {itemsArr.length > 0 && (
        <div className="mt-2 flex items-end justify-end w-3/4">
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 200 }}
            ref={nextButtonRef}
            onClick={handlerSubmit}
            loading={wareHouse?.isFetching}
          >
            {isFinalItem ? "Finish" : "Next"}
          </LoadingButton>
        </div>
      )}
    </>
  );
};

export default App;
