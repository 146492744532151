import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getRoleAction } from "../../../../store/setting/actions";
import * as faIcons from "react-icons/fa";
import {
  deleteRoleAction,
  getPermissionAction,
} from "../../../../store/setting/roleAndPermissions/actions";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import SettingModel from "../../../../components/Modals/settingModel";
import SettingForm from "../../../../components/forms/settingForm";
interface DataType {
  key: React.Key;
  role: string;
  permission: string;
  address: string;
  access: string;
}

const Roles = () => {
  const { auth, setting, roleAndPermission } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [roleToDelete, setRoleToDelete] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState("");

  const RoleModelChanges = (value: any) => {
    setRoleModal(!roleModal);
    setDataToUpdate(value);
  };

  const handleCloseRoleModal = () => {
    setRoleModal(false);
    setDataToUpdate("");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Role Name",
      dataIndex: "role",
      render: (text: any) => <p className="capitalize py-2 pl-5">{text}</p>,
    },

    {
      title: "Users Count",
      dataIndex: "address",
      render: (text: any) => (
        <div className="flex gap-2 rounded-md px-2 py-1 bg-[#605BFF] w-fit">
          <faIcons.FaUsers size={15} color="#ffff" />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Permissions Count",
      dataIndex: "permission",
      render: (text: any, record: any) => (
        <div className="flex gap-2 ml-5">
          <Tooltip
            title={
              <h1 className="py-[2px] text-base capitalize">
                {record?.permissionData}
              </h1>
            }
            color={"#605BFF"}
          >
            <p className="px-6 py-[2px] rounded-md  w-fit border border-[#d7d6e0] cursor-pointer">
              {text.length}
            </p>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Access Count",
      dataIndex: "access",
      render: (text: any, record: any) => {
        return (
          <div className="flex gap-2 ml-5">
            <Tooltip
              title={
                <h1 className="py-[2px] text-base capitalize">
                  {record?.accessData}
                </h1>
              }
              color={"#605BFF"}
            >
              <p className="px-6 py-[2px] rounded-md  w-fit border border-[#d7d6e0] cursor-pointer">
                {text.length ?? 0}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (text, record: any) => (
        <label className="flex gap-3 ">
          <AiTwotoneEdit
            className=" fill-[#446dc7]"
            size={22}
            onClick={() => RoleModelChanges(record)}
          />
          <MdDelete
            className=" fill-[#e62d2d]"
            size={22}
            onClick={() => handleClickDelete(record)}
          />
        </label>
      ),
    },
  ];

  const allRoles = setting?.role?.data;
  useEffect(() => {
    getRoleAction(auth?.token, "?")(dispatch);
    getPermissionAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const Data = allRoles?.map((el: any) => {
    return {
      key: el?._id,
      role: el?.name,
      permission: el?.permissions?.map((d: any) => d?.name),
      permissionData: el?.permissions?.map((d: any) => d?.name)?.join(", "),
      access: el?.access?.length > 0 && el.access?.map((d: any) => d),
      accessData:
        el?.access?.length > 0 && el.access?.map((d: any) => d)?.join(", "),
    };
  });

  const handleClickDelete = async (permissionData: any) => {
    setVisible(true);
    setRoleToDelete(permissionData);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const handlerDelectPermission = async () => {
    auth?.token &&
      (await deleteRoleAction(auth?.token, roleToDelete?.key, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={Data}
        loading={setting?.isFetching}
        size="small"
      />
      <SettingModel
        modalTittle={"Update Role"}
        handleOpenRoleModal={roleModal}
        RoleModelChanges={RoleModelChanges}
        content={
          <SettingForm
            onCancel={handleCloseRoleModal}
            dataToUpdate={dataToUpdate}
          />
        }
      />
      <DeleteModal
        visible={visible}
        isLoading={roleAndPermission.isFetching}
        onOk={handlerDelectPermission}
        onCancel={handleCancels}
        itemName={`${roleToDelete?.role} Role`}
      />
    </>
  );
};
export default Roles;
