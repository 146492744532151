import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneProductAction } from "../../../../store/product/actions";
import { getStockInItemsActions } from "../../../../store/inventory/actions";
// import ShopStockTopGraph from "./ShopStockTopGraph";
import { Table } from "antd";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";
import { StockHeaders } from "../../../../components/csvHeaders/Headers";

const columns = [
  {
    title: "Model",
    dataIndex: "data",
    render: (text: any, record: any) => (
      <p>
        {record.data?.quotationItem?.product?.model +
          "~" +
          record.data?.quotationItem?.product?.type}
      </p>
    ),
  },
  {
    title: "Descriptions",
    dataIndex: "specification",
  },
  {
    title: "Serial Number",
    dataIndex: "data",
    render: (text: any, record: any) => <p>{record.data?.serialNumber}</p>,
  },
  {
    title: "Status",
    dataIndex: "data",
    render: (text: any, record: any) => (
      <p>{record.data?.status === "in" && "In-stock"}</p>
    ),
  },
];

const ProductStockItems = () => {
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const { auth, product, inventory } = useSelector((state: any) => state);
  const { productType } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productType) {
        auth?.token &&
          (await getOneProductAction(auth?.token, productType)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productType, auth, dispatch]);

  useEffect(() => {
    auth?.token &&
      productType &&
      getStockInItemsActions(
        auth?.token,
        `product=${productType}&status=in&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page, productType]);

  const value = inventory?.stockItems?.data || false;

  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          data: el,
          key: el?._id,
          specification: `${el?.quotationItem?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          model: el?.quotationItem?.product?.model,
          brand: el?.quotationItem?.product?.brand,
          type: el?.quotationItem?.product?.type,
        });
      });
    dataMapper.sort((a, b) => a.model.localeCompare(b.model));
    setformData(dataMapper);
  }, [inventory?.stockItems]);

  const csvData = formData.map((record: any) => ({
    model: record?.model,
    type: record?.type,
    brand: record?.brand,
    imei: record.data?.serialNumber,
    storage: record?.data?.quotationItem?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
      .map((item: any) => item[1]),
    memory: record?.data?.quotationItem?.specification
      .filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
      .map((item: any) => item[1]),
    status: record?.data?.status,
  }));

  return (
    <div className="text-black space-y-5 mt-4">
      <div className="flex items-center justify-between">
        <DetailsHeaderActionBar
          pageName="Warehouse"
          title={product.selected?.model}
        />
        <CsvDownloadButton
          csvHeaders={StockHeaders}
          csvData={csvData}
          filename={`Stock.csv`}
        />
      </div>
      {/* <ShopStockTopGraph /> */}
      <div className="bg-white p-2 rounded-lg">
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={inventory?.stockItems?.total}
          count={Math.ceil(inventory?.stockItems?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={formData}
            loading={inventory?.isFetching || product?.isFetching}
            pagination={false}
          />
        </ScrollableFrame>
      </div>
    </div>
  );
};

export default ProductStockItems;
