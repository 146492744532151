import React from 'react'
import phoneImg from "../assets/images/Apple-iPhone-15-Pro Max 500px by 500px 1.png"
import { Carousel } from 'antd'
const ItemDetails = () => {
  return (
    <div className='text-black px-4'>
        <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2 ">
        <div className="bg-[#E5E5E5] w-[80px] h-[80px] p-2 rounded-md">
                    <Carousel autoplay className="w-full h-full">
                          <div>
                            <img src={phoneImg} className="w-full h-full" alt="" />
                          </div>
                        
                    </Carousel>
                  </div>
                  <div className='flex gap-20 mt-1'>
                    <div>
                      <p className="text-[#030229B2] text-sm mt-4">Item Code:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      CA2CTXU0000021
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Item Name:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      iPhone 15 Pro
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Orgin:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      CANADA
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">item Type:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Finished Product
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Pack Unit:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Carton
                      </p>
                    
                    </div>
                    <div>
                      <p className="text-[#030229B2] text-sm mt-4">Qty Unit:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Gram
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Purchase Price:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      RWF 0
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Qty Unit:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Gram
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Sale Price:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      RWF 1,700,000
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">beginning Stock:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      0
                      </p>
                    
                    </div>
                  </div>
        </div>
        </div>
  )
}

export default ItemDetails