import react, { useEffect, useState } from "react";
import { Select, Switch, Checkbox, Table } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import * as piIcons from "react-icons/pi";
import SettingComponent from "./security/SettingComponent";
import { ColumnsType } from "antd/es/table";
import { getActivityLogsAction } from "../../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import ActivityLogsDrawer from "../../../components/Modals/activityLogsDrawer";
interface DataType {
  key: React.Key;
  userName: string;
  activity: string;
  resMessage: string;
  createdAt: string;
  resStatus: string;
  status: any;
}
const SystemLogs = () => {
  const { auth, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [data,setData]=useState<any>();
  useEffect(() => {
    auth?.token && getActivityLogsAction(auth?.token)(dispatch);
  }, [auth.token, dispatch]);
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.5px solid rgba(15, 15, 71, 0.25)"
    }
  };
  const [open, setOpen] = useState(false);

  const showDrawer = (data:any) => {
    
    setData(data)
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User name",
      dataIndex: "userName",
      width: 150
    },
    {
      title: "Activity Name",
      dataIndex: "activity",
      width: 100
    },
    {
      title: "Message",
      dataIndex: "resMessage"
    },
    {
      title: "Status",
      dataIndex: "resStatus"
    },
    {
      title: "Date and Time",
      dataIndex: "createdAt"
    },
    {
      title: "Action",
      dataIndex: "address",
      render: (_, record) => (
        <piIcons.PiEyeLight size={20} className="cursor-pointer" onClick={()=>{
          showDrawer(record)
          
        }}/>
      )
    }
  ];

  const ActivityLogsData = setting?.activityLogs?.data?.map((el: any) => {
    return {
      key: el?._id,
      userName: el?.useInfo?.userName,
      activity: el?.actionDetails?.Url,
      resMessage: el?.resMessage,
      resStatus: el?.resStatus,
      createdAt:
        el?.createdAt?.slice(0, 10) + "  " + el?.createdAt?.slice(11, 20),
      data: el
    };
  });
  return (
    <>
      <h1 className="text-[#030229] font-medium text-lg">
        System Activities Perfomed by users
      </h1>
      <p className="text-[#030229d8] text-sm font-normal">
        Details about activities perfomed by users on the system
      </p>
      <div
        className="bg-white overflow-y-auto mt-5 h-[82vh] divide-y rounded-md px-6 py-8"
        style={styles.customShadow}
      > <ActivityLogsDrawer
      open={open}
      onClose={onClose}
      data={data}
    />
        
        <div>
          <Table
            columns={columns}
            loading={setting?.isFetching}
            dataSource={ActivityLogsData}
          />
        </div>
      </div>
    </>
  );
};
export default SystemLogs;
