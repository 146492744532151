import { ebmItemActions } from ".";
import { getPriceListItem, getPriceLists, getSinglePriceListItem } from "./services";

export const getPricelistAction = (token: string, query?: string) => {
    return async (dispatch: any) => {
      try {
        dispatch(ebmItemActions.setIsFetching(true));
        const res = await getPriceLists(token, query);
        // console.log(">>>>>>>:: channels", res);
        if (res?.status === 200) {
          dispatch(ebmItemActions.setPriceList(res));
          dispatch(ebmItemActions.setIsFetching(false));
        }
        dispatch(ebmItemActions.setIsFetching(false));
      } catch (err) {
        console.log(err);
      }
    };
  };
  export const getPricelistItemAction = (token: string, query?: string) => {
    return async (dispatch: any) => {
      try {
        dispatch(ebmItemActions.setIsFetching(true));
        const res = await getPriceListItem(token, query);
        // console.log(">>>>>>>:: channels", res);
        if (res?.status === 200) {
          dispatch(ebmItemActions.setPriceListItems(res));
          dispatch(ebmItemActions.setIsFetching(false));
        }
        dispatch(ebmItemActions.setIsFetching(false));
      } catch (err) {
        console.log(err);
      }
    };
  };
  export const getSinglePricelistItemsAction = (
    token: string,
    itemId: string
  ) => {
    return async (dispatch: any) => {
      try {
        dispatch(ebmItemActions.setIsFetching(true));
        const res = await getSinglePriceListItem(token, itemId);
        // console.log(">>>>>>>:: channels", res);
        if (res?.status === 200) {
          dispatch(ebmItemActions.setSelectedPriceListItems(res));
          dispatch(ebmItemActions.setIsFetching(false));
        }
        dispatch(ebmItemActions.setIsFetching(false));
      } catch (err) {
        console.log(err);
      }
    };
  };