import React, { useRef, useState, useEffect } from "react";
import { Button, Form, Input, Select, Tag } from "antd";
import VaultButton from "../buttons/vaultButton";
import { addDeviceAction } from "../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined} from "@ant-design/icons";
import { Spin } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import type { InputRef } from "antd";

interface FormProps {
  inputName?: any;
  selectName?: any;
  placeholder?: any;
  selectPlaceholder?: any;
}

const { Option } = Select;

const AddDevices: React.FC<FormProps> = ({
  inputName,
  selectName,
  placeholder,
  selectPlaceholder,
}) => {
  const [form] = Form.useForm();
  const { auth, vault } = useSelector((state: any) => state);
  const [selectService, setSelectService] = useState<number>();
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const dispatch = useDispatch();

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue.trim() && tags.indexOf(inputValue.trim()) === -1) {
      setTags([...tags, inputValue.trim()]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleTagClose = (tag: string) => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
  };

  const tagChild = tags.map((tag: string) => (
    <Tag key={tag} closable onClose={() => handleTagClose(tag)}>
      {tag}
    </Tag>
  ));

  const addDevice = async (values: any) => {
    const deviceUidArray = tags.length > 0 ? tags : [];
    const selectedOption = options.find(
      (option) => option.key === values.paymentMethod
    );
    const paymentMethod = selectedOption
      ? selectedOption.paymentMethod
      : undefined;

    if (auth.token && deviceUidArray.length > 0 && paymentMethod) {
      addDeviceAction(auth.token, {
        deviceUid: deviceUidArray,
        paymentMethod: paymentMethod,
      })(dispatch);

      setTags([]);
      form.resetFields();
    } else {
      console.error("Device UID or Payment Method missing or invalid");
    }
  };

  const options = [
    {
      key: 1,
      serviceName: "prepaid",
      paymentMethod: "prepaid",
      value3: "Idle",
    },
    {
      key: 2,
      serviceName: "DeviceFinancing",
      paymentMethod: "prepaid",
      value3: "Ready For Use",
    },
    {
      key: 3,
      serviceName: "DeviceFinancing",
      paymentMethod: "postpaid",
      value3: "Ready for Use",
    },
  ];

  // const tagPlusStyle: React.CSSProperties = {
  //   height: 22,
  //   width: 35,
  //   borderStyle: "dashed",
  // };

  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%", marginTop: "20px" }}
        onFinish={addDevice}
      >
        <Form.Item
          name="deviceUid"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Enter Device IMEI
            </span>
          }
          // rules={[{ required: true, message: "Please enter device Id" }]}
          className="w-full"
        >
          <div style={{ marginBottom: 16 }}>
            <TweenOneGroup
              appear={false}
              enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              onEnd={(e: any) => {
                if (e.type === "appear" || e.type === "enter") {
                  (e.target as any).style = "display: inline-block";
                }
              }}
            >
              {tagChild}
            </TweenOneGroup>
          </div>

          <Input
            onClick={showInput}
            ref={inputRef}
            type="text"
            size="small"
            className="capitalize rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-gray-500"
            value={inputValue}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Button  onClick={handleInputConfirm}>
          Add More
        </Button>
        <Form.Item
          name="paymentMethod"
          label={
            <span className="text-[#030229b2] pl-1 pt-4 text-sm">
              Assign to service
            </span>
          }
          rules={[{ required: true, message: "Please select payment method" }]}
          className="w-full h-[52px] vault-select"
        >
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder="Inventory"
            className="capitalize rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
            onChange={(value: any) => setSelectService(value)}
          >
            {options.map((el) => (
              <Option key={el.key} value={el.key}>
                {el.serviceName} ({el.paymentMethod})
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="mt-10">
          <VaultButton
            btnName={
              vault?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "Save"
              )
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default AddDevices;
