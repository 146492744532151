import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { IoEyeOutline } from "react-icons/io5";
import { getCommissionTransactionAction } from "../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";

const ManageCommissionTable = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getCommissionTransactionAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  const columns = [
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Account Number",
      dataIndex: "account",
      key: "account",
    },
    {
      title: "Shops",
      dataIndex: "count",
      key: "count",
      width:100,
      render: (text: any) => (
          <p className="text-sm text-center w-full h-10 capitalize cursor-pointer border border-gray-400 rounded-full p-2">
            {text}
          </p>
      ),
    },
    {
      title: "Total Commission",
      dataIndex: "TotalAmount",
      key: "TotalAmount",
    },
    {
      title: "Paid Amount",
      dataIndex: "AmountPaid",
      key: "AmountPaid",
    },
    {
      title: "Balance",
      dataIndex: "owed",
      key: "owed",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => {
        return (
          <div>
            <IoEyeOutline
              fill="green"
              size={23}
              onClick={() => props?.onClick(record)}
              className="cursor-pointer"
            />
          </div>
        );
      },
    },
  ];

  const data = channel?.commissionTransaction?.data?.map((el: any) => {
    return {
      key: el?._id,
      count: el?.shops?.length,
      TotalAmount: el?.totalCommission?.toLocaleString(),
      AmountPaid: el?.paidAmount?.toLocaleString(),
      owed: el?.unPaidAmount?.toLocaleString(),
      account: el?.commissionProfiles?.account,
      owner: el?.commissionProfiles?.accountName,
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={channel?.isFetching}
      />
    </>
  );
};

export default ManageCommissionTable;
