// Dropdown.tsx

import React, { useState } from "react";

interface DropdownProps {
  defaultTab: string;
  options: { label: string; value: string }[];
}

function Dropdown({ defaultTab, options }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectTab = (tabValue: string) => {
    setSelectedTab(tabValue);
    setIsOpen(false);
  };

  return (
    <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>
      <div className="relative inline-block text-left">
        <button
          onClick={toggleDropdown}
          type="button"
          className={`text-gray-500 w-32 cursor-pointer bg-[#F9FBFF] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center`}
        >
          {selectedTab}
          <svg
            className={`w-2.5 h-2.5 ml-2.5 ${
              isOpen ? "rotate-180" : ""
            } transition-transform duration-300`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen && (
          <div className="z-10 origin-top-right absolute right-0 mt-2 w-32 rounded-lg shadow-lg">
            <ul className="py-2 text-sm text-gray-500">
              {options.map((option) => (
                <li key={option.value}>
                  <a
                    href="#"
                    onClick={() => selectTab(option.value)}
                    className={`block px-4 py-2 hover:bg-blue-400 dark:hover:text-white ${
                      selectedTab === option.value
                        ? "bg-blue-400 text-white"
                        : ""
                    }`}
                  >
                    {option.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
