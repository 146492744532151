import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Proforma States",
  initialState: {
    isFetching: false,
    all: { data: [] },
    allRfqSupplier: { data: [] },
    selected: null,
    selectedRfq: null,
    attributes: null,
    new: null,
    newRfq: null,
    updated: null,
    query: null,
    filters:null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAllRfqSupplier(state, action) {
      state.allRfqSupplier = action.payload;
    },
    setAttributes(state,action){
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setNewRfq(state, action) {
      state.newRfq = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setSelectedRfq(state, action) {
      state.selectedRfq = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const myProformaActions = mySlice.actions;

export default mySlice.reducer;
