import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table, Empty } from "antd";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

type Payload = {
  OverAllPercentage: any;
  amount: number;
  yesterdayAmount: number;
  pieces?: number;
  yesterdayPieces?: number;
  title?: string;
  data: {
    name: string;
    value: number;
    yesterday: number;
    today: number;
    percentage: number;
  }[];
};

const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
];

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (item: any) => {
      return (
        <div className="flex w-full xtruncate gap-1">
          <span
            className={`h-4 w-6 bg-[${item?.color}] rounded-sm`}
            style={{ backgroundColor: item?.color }}
          ></span>
          <span className=" text-xs truncate w-16">{item?.value}</span>
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "yesterday",
    key: "yesterday",
    render: (text: string, record: any) =>
      record?.layout === "day" && (
        <div className="text-xs w-full truncate text-gray-400 ">{`RWF ${text}`}</div>
      ),
  },

  {
    title: "",
    dataIndex: "today",
    key: "today",
    render: (text: string) => (
      <div className="dashed-border-cell truncate">{`RWF ${text}`}</div>
    ),
  },
  {
    title: "",
    dataIndex: "percentage",
    key: "percentage",
    render: (text: string, record: any) => {
      return (
        <div className="">
          {record?.layout === "day" && (
            <span
              className={`flex ${
                record?.percentage?.type === "increase"
                  ? "text-green-500"
                  : record?.percentage?.type === "decrease"
                  ? "text-red-500"
                  : "text-blue-900"
              }  text-sm`}
            >
              {" "}
              {record?.percentage?.type === "increase" ? (
                <ArrowUpwardIcon />
              ) : record?.percentage?.type === "decrease" ? (
                <ArrowDownwardIcon />
              ) : (
                ""
              )}{" "}
              {`${record?.percentage?.percentage} %`}
            </span>
          )}
        </div>
      );
    },
  },
];

const SamphonePayments: React.FC<Payload> = (props) => {
  const { dashboard, layout } = useSelector((state: any) => state);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel: React.FC<{
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    percent: any;
    index: any;
  }> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const data = props.data[index];

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className="text-xs"
      >
        {`${data.value.toFixed(0)}%`}
      </text>
    );
  };
  const isLargeScreen = window.innerWidth <= 2000;
  return (
    <div className="p-4 relative h-96 bg-white rounded-md">
      <p className="text-gray-400 capitalize mb-2">{props.title}</p>
      <p className="text-2xl font-semibold">
        {"RWF " + (props.amount.toLocaleString() || 0)}/
        <span className="text-xs">{`${props.pieces} Pieces`}</span>
      </p>
      {layout?.dashboardPreview === "day" && (
        <p className="text-base font-semibold text-gray-400">
          {"RWF " + (props.yesterdayAmount.toLocaleString() || 0)}/
          <span className="text-xs">{`${props.yesterdayPieces} Pieces`}</span>
        </p>
      )}
      <div
        className={`${
          layout?.dashboardPreview === "day" ? "absolute" : "hidden"
        } top-2 right-2 ${
          props?.OverAllPercentage?.type === "increase"
            ? "bg-green-200"
            : props?.OverAllPercentage?.type === "decrease"
            ? "bg-red-200"
            : "bg-blue-200"
        } rounded-lg px-3 py-1`}
      >
        {props?.OverAllPercentage?.type === "increase" ? (
          <span className="text-green-500">
            <ArrowUpwardIcon /> {`${props?.OverAllPercentage?.percentage}%`}
          </span>
        ) : props?.OverAllPercentage?.type === "decrease" ? (
          <span className="text-red-500">
            <ArrowDownwardIcon /> {`${props?.OverAllPercentage?.percentage}%`}
          </span>
        ) : (
          <span className="text-blue-900">
            {`${props?.OverAllPercentage?.percentage}%`}
          </span>
        )}
      </div>
      {/* <div className="h-[260px] xw-80"> */}
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-screen-lg">
          <ResponsiveContainer
            width="100%"
            height={300}
            className="w-full overflow-x-auto"
          >
            <PieChart width={100} height={300}>
              {props?.data?.length < 1 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <Pie
                  style={{}}
                  data={props.data}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  // innerRadius={40}
                  innerRadius={isLargeScreen ? 30 : 40}
                  // outerRadius={70}
                  outerRadius={isLargeScreen ? 45 : 70}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  legendType="rect"
                >
                  {props?.data?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              )}
              <Legend
                align="right"
                width={isLargeScreen ? 380 : 500}
                verticalAlign="middle"
                layout="vertical"
                content={(props: any) => {
                  const { payload } = props;

                  const generateData = () => {
                    const data: any[] = [];
                    payload?.forEach((element: any) => {
                      data.push({
                        name: { value: element?.value, color: element?.color },
                        yesterday:
                          element?.payload?.payload?.yesterday?.toLocaleString(),
                        today:
                          element?.payload?.payload?.today?.toLocaleString(),
                        percentage: element?.payload?.payload?.percentage,
                        layout: element?.payload?.layout,
                      });
                    });
                    return data;
                  };
                  const data = generateData();

                  return (
                    <div className="w-full max-w-screen-lg bg-red-50">
                      <Table
                        columns={columns}
                        dataSource={data}
                        loading={dashboard?.isFetching}
                        pagination={false}
                        // scroll={{ y: 300 }}
                        rowClassName={(record: any, index: number) => {
                          if (index === data.length - 1) {
                            return "x!border-b !border-dashed";
                          } else if (index === 0) {
                            return "dashed-top-border";
                          }
                          return "";
                        }}
                        showHeader={false}
                      />
                    </div>
                  );
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default SamphonePayments;
