import React from 'react';
import { Table } from 'antd';
import SettingButton from '../../../../pages/dashboard/setting/SettingButton';



const InsurerTable = (props:any) => {
  const columns= [
    {
      title: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Insurer Name',
      dataIndex: 'name',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
    },
  ];
  
  const data= [
    {
      key: '1',
      code:"3719",
      name: 'Strutika Suzan Garfinkle',
      rate:"N/A",
    },
    {
      key: '2',
      code:"3719",
      name: 'Strutika Suzan Garfinkle',
      rate:"N/A",
    },
    {
    key: '3',
      code:"3719",
      name: 'Strutika Suzan Garfinkle',
      rate:"N/A",
    },
    {
      key: '4',
      code:"3719",
      name: 'Strutika Suzan Garfinkle',
      rate:"N/A",
    },
    
   
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default InsurerTable;