import React, { useState } from "react";
import { Table, Tag } from "antd";
import HoverDropDown from "../DropDown/HoverDropDown";
import type { MenuProps } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import GenearalModal from "../modals/GeneralModal";
import SalesRegistrationForm from "../forms/SalesRegistrationForm";
import { useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import ModifyRegistrationForm from "../forms/ModifyRegistrationForm";
import { SlEye } from "react-icons/sl";

const ManageItemTable = (props: any) => {
  const { ebmSales, ebmGeneral } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saleData, setSaleData] = useState(null);
  const [status, setStatus] = useState("");

  const showModal = (data: any) => {
    setIsModalOpen(true);
    setSaleData(data);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const createMenuItems = (record: any): MenuProps["items"] => [
    {
      label: (
        <div className="flex gap-2">
          <SlEye size={20} className="pt-1" />
          <span className="text-[.8rem] pt-1">View Details</span>
        </div>
      ),
      key: "0",
      onClick: () => props?.open(record)
    },
    {
      label: (
        <div className="flex gap-3" onClick={() => showModal(record)}>
          <FiEdit size={20} className="pt-1 opacity-80" />
          <span className="text-[.8rem] pt-1">Modify</span>
        </div>
      ),
      key: "1"
    },
    {
      label: (
        <div className="flex gap-2">
          <MdDeleteOutline size={22} className="pt-1 opacity-80" />
          <span className="text-[.8rem] pt-1">Delete</span>
        </div>
      ),
      key: "3"
    }
  ];
  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "id"
    },
    {
      title: "Customer",
      dataIndex: "name"
    },
    {
      title: "Sale Date",
      dataIndex: "date"
    },
    {
      title: "Item Name",
      dataIndex: "item"
    },
    {
      title: "Quantity",
      dataIndex: "qauntity"
    },
    {
      title: "Unit Price",
      dataIndex: "price"
    },
    {
      title: "VAT",
      dataIndex: "vat",
      render: (record: any) => {
        return (
          <>
            <span className="text-[12px]">
              {ebmGeneral?.selectOption ? (getNameByCode(
                ebmGeneral?.selectOption["Taxation Type"],
                record[0])
              ) : ""}
            </span>
          </>
        );
      }
    },
    {
      title: "Total Amount",
      dataIndex: "amount"
    },
    {
      title: "Sales Amount",
      dataIndex: "sales"
    },
    {
      title: "Status",
      dataIndex: "data",
      width: 200,
      render: (record: any) => {
        const value = ebmGeneral?.selectOption ? (getNameByCode(ebmGeneral?.selectOption["Sale Status"], record?.ebmSaleStatus)) : ""
        return (
          <>
            <Tag
              className={`w-[8rem] text-center ${value === "Approved"
                ? "text-[#0FA91E] border border-[#0FA91E40] bg-[#0FA91E0D]"
                : value === "Wait for Approval"
                  ? "text-[#FA3659] border border-[#FA3659]"
                  : value === "Cancel Requested"
                    ? "text-[#FA3659] border border-[#EB461240] bg-[#EB46120D]"
                    : value === "Canceled"
                      ? "text-[#FA3659] border border-[#FA3659]"
                      : value === "Refunded"
                        ? "text-[#FB923C] border border-[#FB923C40] bg-[#FB923C0D]"
                        : value === "Transfered" ? "text-[#F19F1D80] border border-[#F19F1D80]" : ""
                } rounded-full py-1 font-normal text-[12px]`}
            >
              {value}
            </Tag>
          </>
        )
      }
    },
    {
      title: " ",
      dataIndex: "data",
      render: (record: any) => (
        <>
          <HoverDropDown items={createMenuItems(record)} />
        </>
      )
    }
  ];
  const data = ebmSales?.allSales?.data?.map((el: any) => {
    return {
      key: el?._id,
      id: el?.cartId,
      name: el?.customer?.name,
      date: el?.paidOn?.slice(0, 10),
      item: el?.list?.map((d: any) => d?.warehouseProduct?.product?.model),
      vat: el?.list?.map((d: any) => d?.priceProduct?.product?.ebm?.taxTyCd),
      qauntity: el?.list?.length,
      price: el?.list?.map((d: any) => d?.payment?.amount),
      amount: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      sales: el?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      // vat:"0",
      data: el
    };
  });
  return (
    <>
      <GenearalModal
        openModal={isModalOpen}
        closeModal={handleOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Modify Sales
          </h1>
        }
        component={
          <div className="px-4 h-[78vh] w-full overflow-y-auto">
            <div className="mts-5 w-full">
              <ModifyRegistrationForm
                setIsModalOpen={setIsModalOpen}
                data={saleData}
              />
            </div>
          </div>
        }
        width={1200}
      />
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        loading={ebmSales?.isFetching}
      />
    </>
  );
};

export default ManageItemTable;
