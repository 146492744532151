import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceProforma = async (data: any, token: string) => {

  return await HttpRequest.post(`${SERVER_URL}/prof`, data, token);
};
export const getAllServiceProforma = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/prof${query}`, token);
};

export const getOneServiceProforma = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/prof${query}`, token);
};

export const addOneItemServiceProforma = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(`${SERVER_URL}/prof/additem/${id}`, data, token);
};

export const updateServiceProforma = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/prof/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceProforma = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/prof/one/${itemId}`, token);
};

export const getProformaItemService = async (token: string, quotationItem: string) => {
  return HttpRequest.get(`${SERVER_URL}/profItem?quotationItem=${quotationItem}`, token);
};

export const getSelectedProformaItemsService = async (token: string, quotationItem: string) => {
  return HttpRequest.get(`${SERVER_URL}/profItem?quotation=${quotationItem}&isSelected=true`, token);
};

export const updateProforomaItemService = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(`${SERVER_URL}/profItem/addtopo/${id}`, data, token);
};