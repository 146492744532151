import { myNotificationActions } from ".";
import {
  getAllNotificationService,
  getAllUnReadNotificationService,
  updateNotificationService,
} from "./services";

export const getAllUnreadNotificationAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myNotificationActions.setIsFetching(true));
      const res = await getAllUnReadNotificationService(token, query);
      if (res?.status === 200) {
        dispatch(myNotificationActions.setUnread(res));
        dispatch(myNotificationActions.setIsFetching(false));
      }
      dispatch(myNotificationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllNotificationAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myNotificationActions.setIsFetching(true));
      const res = await getAllNotificationService(token, query);
      if (res?.status === 200) {
        dispatch(myNotificationActions.setAll(res));
        dispatch(myNotificationActions.setIsFetching(false));
      }
      dispatch(myNotificationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateNotificationAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myNotificationActions.setIsFetching(true));
      const res = await updateNotificationService(data, token);
      if (res?.status === 200) {
        const resAll = await getAllNotificationService(token, "?");
        dispatch(myNotificationActions.setUnread(null));
        dispatch(myNotificationActions.setAll(resAll));
        dispatch(myNotificationActions.setUpdated(res));
        dispatch(myNotificationActions.setIsFetching(false));
      }
      dispatch(myNotificationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
