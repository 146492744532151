import React, { useState } from "react";
import { Button, Table, Tag } from "antd";
import PayCommissionForm from "../forms/PayCommissionForm";
import DetailsModel from "../Modals/DetailsModel";
import { useSelector } from "react-redux";
import { searchValue } from "../../utils/setColor";
import ScrollableFrame from "../layout/ScrollableFrame";

interface DataType {
  key: React.Key;
  brand: string;
  description: string;
  model: string;
  owed: number;
  amount: number;
  TotalAmount: number;
  date: string;
  status: string;
}

const columns = [
  {
    title: "Perfomed At",
    dataIndex: "date"
  },
  {
    title: "brand",
    dataIndex: "brand"
  },
  {
    title: "Model",
    dataIndex: "model"
  },
  {
    title: "Type",
    dataIndex: "type"
  },
  {
    title: "IMEI",
    dataIndex: "imei"
  },
  {
    title: "Balance",
    dataIndex: "owed"
  },
  {
    title: "Total Commission",
    dataIndex: "TotalAmount"
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: any, record: any) => {
      return (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      );
    },
  }
];
const CommissionDetailsTable = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<DataType[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    const selectedData = flattenedData.filter((item: any) =>
      newSelectedRowKeys.includes(item._id)
    );
    setSelectedRowData(selectedData);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleOnClickViewIcon = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setSelectedRowData([]);
    setSelectedRowKeys([]);
  };
  const flattenTransactions = (data: any): any[] => {
    let flattenedData: any[] = [];

    const flattenHelper = (arr: any[]) => {
      arr.forEach((el) => {
        if (el.transactions && el.transactions.length > 0) {
          flattenHelper(el.transactions);
        } else if (
          el._id &&
          el.cartItem &&
          el.cartItem.warehouseProduct &&
          el.cartItem.warehouseProduct.product
        ) {
          flattenedData.push(el);
        }
      });
    };

    flattenHelper(data);

    return flattenedData;
  };
  const flattenedData: any[] = flattenTransactions(
    channel?.allCommissionTransaction?.data || []
  );
  const data = channel?.allCommissionTransaction?.data?.map((el: any) => {
    return {
      key: el?._id,
      brand: el?.cartItem?.warehouseProduct?.product?.brand,
      model:  el?.cartItem?.warehouseProduct?.product?.model,
      type:  el?.cartItem?.warehouseProduct?.product?.type,
      imei:  el?.cartItem?.warehouseProduct?.serialNumber,
      description: el?.cartItem?.warehouseProduct?.product?.specs?.map(
        (item: any) =>
          <div className="flex items-center gap-5">
            <span className="font-bold text-xs">{item?.label}: </span>
            <span className=" text-sm">{item?.value}</span>
          </div>
      ),
      owed: el.amount?.toLocaleString(),
      TotalAmount: el?.amount?.toLocaleString(),
      date: el?.cartItem?.paidOn?.slice(0, 10),
      status: el.status
    };
  });
  return (
    <div>
      <DetailsModel
        component={<PayCommissionForm data={selectedRowData} info={props} onCancel={handleOnCancel} />}
        modalTitle={
          <h1 className="text-[#030229] text-base font-medium">
            Pay Commission
          </h1>
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />
      <div style={{ marginBottom: 16 }} className="flex justify-between">
        <div>
          <Button
            type="primary"
            onClick={handleOnClickViewIcon}
            disabled={!hasSelected}
            loading={loading}
            className="bg-[#605BFF] cursor-pointer"
          >
            Pay Now
          </Button>
        </div>
        <div style={{ marginLeft: 8, fontSize: "14px" }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} transactions` : ""}
        </div>
      </div>
      <ScrollableFrame
          setPage={props.setPage}
          setLimit={props.setLimit}
          limit={props.limit}
          total={channel?.allCommissionTransaction?.total}
          count={Math.ceil(channel?.allCommissionTransaction?.total / props.limit)}
        >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={channel?.isFetching}
      />
      </ScrollableFrame>
    </div>
  );
};

export default CommissionDetailsTable;
