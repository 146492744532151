import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Skeleton, Tag } from "antd";
import AssignShopForm from "../../../../components/forms/AssignShopForm";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import AssignChannelForm from "../../../../components/forms/AssignChannelForm";
import BsThreeDotsDropdown from "./BsThreeDotsDropdown";
import ChangePasswordForm from "../../../../components/forms/ChangePasswordForm";
import ChangePasswordModal from "../../../../components/Modals/ChangePasswordModal";
import ResetPassword from "../../../../components/forms/ResetPassword";

const UserGrid = (props: any) => {
  const { layout, setting } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userData, setUserData] = useState("");
  const [data, setData] = useState<any>([]);
  const [userIdData, setUserIdData] = useState<any>([]);

  const allUsers = setting?.all?.data;
  const AssignShopModelOpen = (value: any) => {
    setIsModalOpen(true);
    setData(value);
  };
  const AssignChannelModelOpen = (value: any) => {
    setIsChannelModalOpen(true);
    setUserIdData(value);
  };

  const handleChannelCancel = () => {
    setIsChannelModalOpen(false);
    setUserIdData(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData(null);
  };

  const OpenChangePassword = (value: any) => {
    setChangePassword(!changePassword);
    setUserData(value);
  };

  const handleCloseChangePassword = () => {
    setChangePassword(false);
  };

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  return (
    <>
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props.limit}
        total={setting?.all?.total}
        count={Math.ceil(setting?.all?.total / props?.limit)}
      >
        <Modal
          title={`Assign Shop To ${data?.names}`}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          className="min-w-min"
        >
          <div>
            <div style={style}>
              <AssignShopForm onCancel={handleCancel} agentId={data?._id} />
            </div>
          </div>
        </Modal>
        <div
          className={`grid ${
            layout.isSideNavOpen ? "grid-cols-3" : "grid-cols-4"
          } gap-3`}
        >
          {allUsers.map((user: any) => (
            <>
              {setting?.isFetching ? (
                <div className="bg-white rounded-lg h-[140px] min-w-[30%] w-auto shadow-md p-4 border border-gray-300">
                  <Skeleton />
                </div>
              ) : (
                <div
                  className="bg-white w-[100%] h-[11rem] p-4 rounded-md cursor-pointer relative"
                  onClick={(event: any) => {
                    const isDisabledDiv = event.target.closest(
                      ".ant-dropdown-trigger"
                    );
                    const isDisabledlabel =
                      event.target.closest(".ant-dropdown-menu");
                    if (!isDisabledDiv && !isDisabledlabel) {
                      navigate(`/setting/employee/${user._id}`);
                    }
                  }}
                >
                  <div className="flex gap-4">
                    <img
                      src={user?.picture}
                      alt="profile"
                      className="rounded-full w-[3rem] h-[3rem]"
                    />
                    <div className="flex justify-between  w-full">
                      <h1 className="text-base font-normal capitalize">
                        {user?.names}{" "}
                        <p className=" text-[blue] pt-1 capitalize">
                          {user?.role?.split("-")?.join(" ")}
                        </p>
                        {user?.channel && (
                          <p className=" text-[blue] pt-1">
                            {user?.channel?.at(0)?.name}
                          </p>
                        )}
                      </h1>

                      <button className="bg-green-500  h-7 text-white px-2 rounded-md text-sm font-normal">
                        {user?.isActive === true ? "Active" : "Inactive"}
                      </button>
                    </div>
                  </div>
                  <div className="ml-14">
                    <p className="pt-3 text-sm">{user?.email}</p>
                    <p className="text-sm pt-2">{user?.phone}</p>
                   {user?.role==="sales-agent" && user?.tag && (<Tag className="text-sm px-3 mt-1">{user?.tag}</Tag>)}
                  </div>

                  <BsThreeDotsDropdown
                    onChangePassword={() => OpenChangePassword(user)}
                    onAssignChannel={() => AssignChannelModelOpen(user)}
                    onAssignShop={() => AssignShopModelOpen(user)}
                    userData={user}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </ScrollableFrame>

      <Modal
        title={`Assign Channel To ${userIdData?.names}`}
        open={isChannelModalOpen}
        onCancel={handleChannelCancel}
        footer={null}
        className="50vh"
      >
        <div style={style}>
          <AssignChannelForm
            onCancel={handleChannelCancel}
            userId={userIdData?._id}
          />
        </div>
      </Modal>

      {changePassword && (
        <ChangePasswordModal
          handleOpenChangePasswordModel={changePassword}
          handleChangePasswordModel={handleCloseChangePassword}
          content={
            <ResetPassword
              userData={userData}
              onCancel={handleCloseChangePassword}
            />
          }
        />
      )}
    </>
  );
};
export default UserGrid;
