import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const PurchaseOrderDetailsSkeleton = () => {
  return (
    <Stack>
      <Box className="flex justify-between">
        <Box className="flex gap-4 mb-4">
          <Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 130 }} />
            </Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 140 }} />
            </Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 140 }} />
            </Box>
          </Box>
          <Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 70 }} />
            </Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 90 }} />
            </Box>
            <Box>
              <Skeleton variant="text" sx={{ width: 60 }} />
            </Box>
          </Box>
        </Box>
        <Box className="pr-10">
          <Box className="flex gap-4 mb-4">
            <Box className="flex gap-2">
              <Box>
                <Skeleton variant="text" sx={{ width: 130 }} />
              </Box>
              <Box></Box>
              <Box>
                <Box>
                  <Skeleton variant="text" sx={{ width: 70 }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(4)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="flex gap-3 mt-10">
        <Skeleton variant="text" sx={{ width: 70, height: 50 }} />
        <Skeleton variant="text" sx={{ width: 70 }} />
      </Box>
    </Stack>
  );
};

export default PurchaseOrderDetailsSkeleton;
