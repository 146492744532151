import React, { useState, useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TopCards from "./topCards";
import OverviewChart from "./overviewChart";
import OrdersHistory from "./ordersHistory";
import PaymentsHistory from "./paymentHistory";
import VaultStatus from "./vaultStatus";
import BillingStatus from "./billingStatus";
import Subsdetails from "./subsdetails";
import Dropdown from "../../../../components/Dropdown";
import {
  getAllOrderHistoryAction,
  getAllOverViewAction,
  getAllPaymentHistoryAction,
} from "../../../../store/subscription/overview/actions";

const PageView = () => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const options = [
    { label: "channel1", value: "channel1" },
    { label: "channel2", value: "channel2" },
    { label: "channel3", value: "channel3" },
  ];

  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (auth.token) {
      getAllOverViewAction(auth.token, "?")(dispatch);
      getAllOrderHistoryAction(auth.token, "?")(dispatch);
      getAllPaymentHistoryAction(auth.token, "?")(dispatch);
    }
  }, [dispatch, auth.token]);

  return (
    <div className="w-full min-h-screen mt-10 mb-10">
      <div className="text-[#0F0F47] mb-8">
        <DetailsHeaderActionBar pageName="Subscription" title={"Overview"} />
      </div>
      <div className={`flex space-x-2 items-center justify-between `}>
        {["partner"]?.includes(auth?.user?.role) ? (
          <h1 className="font-medium"> RWANDA's SALES KPI DASHBOARD</h1>
        ) : (
          <div className="flex flex-row items-center  w-full rounded-md">
            <Dropdown defaultTab="Allchannels" options={options} />
          </div>
        )}
        <div className="space-x-6 flex">
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs()}
            style={{ width: 120 }}
            disabledDate={disabledDate}
          />
        </div>
      </div>
      <TopCards />
      <div className="flex min-w-full gap-4 mt-6">
        <OverviewChart />
        <OrdersHistory />
      </div>
      <div className="flex  min-w-full gap-3 mt-6 ">
        <div className="w-full">
          <div className="flex gap-4">
            <PaymentsHistory />
            <VaultStatus />
          </div>
        </div>
      </div>
      <div className="flex min-w-full gap-4 mt-6">
        <Subsdetails />
        <BillingStatus />
      </div>
    </div>
  );
};

export default PageView;
