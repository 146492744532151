import { Input, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { updatePurchaseOrderItemAction } from "../../store/purchase/actions";
import { useMediaQuery } from "@mui/material";
const { Text } = Typography;

const CustomerInvoicesTable = () => {
  const { purchase, auth } = useSelector((state: any) => state);
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const dispatch = useDispatch();
  const data: {
    _id: any;
    type: any;
    brand: any;
    description: any;
    model: any;
    priceUnit: any;
    quantity: any;
    status: any;
    token: string;
    dispatch: any;
    quantityLeft: any;
    poID: string;
  }[] = [];

  purchase?.selected &&
    purchase?.selected?.data?.listOfProducts?.map((el: any) => {
      data.push({
        _id: el?._id,
        type: el?.quotationItem?.product?.type,
        brand: el?.quotationItem?.product?.brand,
        description: el?.quotationItem?.specification?.map(
          (item: any) =>
            item[1] && (
              <div className="flex items-center gap-2">
                <span className="font-bold text-xs">{item[0]}: </span>
                <span className=" text-sm">{item[1]}</span>
              </div>
            )
        ),
        model: el?.quotationItem?.product?.model,
        priceUnit: el?.priceUnit?.toLocaleString("en-RW"),
        quantity: el?.quantity,
        quantityLeft: el?.quantityLeft,
        status: purchase?.selected?.data?.status,
        token: auth?.token,
        poID: purchase?.selected?.data?._id,
        dispatch: dispatch,
      });
    });
  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      render: (text, record) => (
        <Input
          style={{ width: "5rem" }}
          type="number"
          defaultValue={text}
          disabled={
            !["draft", "finance"].includes(record.status?.toLowerCase())
          }
          onBlur={(e: any) => {
            updatePurchaseOrderItemAction(
              record.token,
              record._id,
              record.poID,
              {
                quantity: e.target.value,
                quantityLeft: e.target.value,
              }
            )(record.dispatch);
          }}
          // onChange={(e: any) => {
          //   record.priceUnit = e.target.value; // Update the name in the data source
          // }}
        />
      ),
    },
    {
      title: "Quantity Left",
      key: "quantityLeft",
      dataIndex: "quantityLeft",
    },
    {
      title: "Price/Unit",
      dataIndex: "priceUnit",
      key: "priceUnit",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record) => (
        <div>
          {(record.quantity * record.priceUnit)?.toLocaleString("en-RW")}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        pagination={false}
        scroll={isTablet ? { x: 1300 } : undefined}
        summary={(pageData) => {
          let totalCash = 0;
          // let totalRepayment = 0;

          pageData.forEach(({ priceUnit, quantity }) => {
            totalCash += quantity * +priceUnit;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={7}>
                  Balance
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text style={{ fontWeight: "bolder" }}>
                    {totalCash.toLocaleString()}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default CustomerInvoicesTable;
