import React from "react";
import { Radio } from "antd";

const WarehouseFilter = (props: any) => {
  return (
    <div className="flex items-center space-x-2 pt-4">
      <Radio.Group
        defaultValue=""
        buttonStyle="solid"
        onChange={(e: any) => props.setSelecteStataus(e.target.value)}
      >
        {props?.total && (
          <Radio.Button
            value=""
            className="capitalize text-xs py-2 h-10"
          >{`ALL (${props?.total ?? 0})`}</Radio.Button>
        )}
        {props?.data?.map((el: any) => (
          <Radio.Button
            value={el?.status}
            className="capitalize text-xs py-2 h-10"
          >{`${el?.status} ${el?.count ? `(${el?.count})` : ""}`}</Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default WarehouseFilter;
