import { useState } from "react";
import POSTTranctionsTable from "../../../components/tables/POSTTranctionsTable";
import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import moment from "moment";
// import { useSelector } from "react-redux";

const POSTransactions = (props: any) => {
  let today = moment();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedShop, setSelectedShop] = useState<any>("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<any>(formattedPreviousDate);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(formattedDate);
  return (
    <div className="text-black py-2 scrollbar-hide">
      <DetailsHeaderActionBar pageName="POS" title="Transactions" />
      <ProductFilter
        setSelectedShop={setSelectedShop}
        setSelectedStatus={setSelectedStatus}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        setSelectedEndDate={setSelectedEndDate}
        setSelectedChannel={setSelectedChannel}
        selectedEndDate={selectedEndDate}
        selectedChannel={selectedChannel}
        route={props?.route}
        limit="20"
        page="1"
      />

      <POSTTranctionsTable
        selectedStatus={selectedStatus}
        selectedEndDate={selectedEndDate}
        selectedDate={selectedDate}
        selectedChannel={selectedChannel}
        selectedShop={selectedShop}
      />
    </div>
  );
};

export default POSTransactions;
