import { useEffect, useState } from "react";
import { Paper, Stack } from "@mui/material";
import type { DatePickerProps } from "antd";
import { searchValue } from "../../../../utils/setColor";
import { Select, Form, DatePicker, Tag } from "antd";
import { selectOptionData } from "../../../../assets/data/interfaces";
import { createQuotationActionRfq } from "../../../../store/quotation/actions";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { getAllSuppliersAction } from "../../../../store/supplier/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [confirmationDate, setConfirmationDate] = useState<string | string[]>(
    dayjs().format("DD/MM/YYYY")
  );
  const [suppliers, setSuppliers] = useState([""]);
  const { auth, supplier, quotation } = useSelector((state: any) => state);
  const [itemFilter, setItemFilter] = useState([]);
  const onChangeDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    console.log(dateString);
    setConfirmationDate(dateString);
  };

  const handleSaving = async () => {
    auth?.token &&
      quotation.selected &&
      createQuotationActionRfq(auth.token, {
        quotation: quotation.selected._id,
        suppliers: suppliers,
        confirmationDate,
      })(dispatch);

    // ((auth.token && quotation.newRfq) ||
    //   (auth.token && quotation.selectedRfq)) &&
    //   updateQuotationActionRfq(quotation.selectedRfq.data._id, auth.token, {
    //     quotation: quotation.selected._id,
    //     supplier: suppliers,
    //     confirmationDate,
    //   })(dispatch);
  };
  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
  };
  const listSuppliers = supplier?.all?.data.map(
    (sup: any): selectOptionData => {
      return { label: sup.name, value: sup._id };
    }
  );

  useEffect(() => {
    console.log("you clickeddd");
    handleSaving();
  }, [confirmationDate, suppliers]);

  useEffect(() => {
    auth?.token && getAllSuppliersAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  type Option = {
    product?: {
      model?: string;
    };
    label?: string;
  };

  type SetFilteredOptions = React.Dispatch<
    React.SetStateAction<Option[] | any>
  >;

  const handleSearch = (
    value: string,
    data: any,
    filterFunction: (option: Option, value: string) => boolean,
    setFilteredOptions: SetFilteredOptions
  ) => {
    const filtered = data?.filter((option: any) =>
      filterFunction(option, value)
    );
    setFilteredOptions(filtered);
  };

  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack spacing={2}>
        <Stack spacing={1} direction="row" justifyContent={"space-between"}>
          <Stack>
            <h4> Request For Quotation</h4>
            <p className="text-sm" style={{ color: "blue" }}>
              {quotation.selected.quotationId}
            </p>
          </Stack>
          <Stack>
            <Paper elevation={1}>
              <Stack direction="row" spacing={1}>
                <h1 style={{ paddingLeft: "0.5rem" }}>Status</h1>
                <Tag
                  color={searchValue(quotation?.selected?.status)}
                  style={{ width: "5rem", textAlign: "center" }}
                >
                  {quotation?.selected?.status}
                </Tag>
              </Stack>
            </Paper>
          </Stack>
        </Stack>

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          layout="vertical"
          scrollToFirstError
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            {quotation?.selected?.status === "draft" && (
              <Form.Item
                name="supplier"
                label="Select Suppliers"
                rules={[
                  {
                    required: true,
                    message: "Please select SUpplier!",
                  },
                ]}
              >
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      supplier?.all?.data,
                      (option: any, val) =>
                        option?.product?.model
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setItemFilter
                    )
                  }
                  showSearch
                  style={{ width: 400 }}
                  allowClear
                  loading={supplier.isFetching || quotation.isFetching}
                  disabled={
                    quotation?.selected?.status !== "draft" ||
                    supplier.isFetching ||
                    quotation.isFetching
                  }
                  placeholder="Select Supplier"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={listSuppliers}
                  mode="multiple"
                  onChange={async (value: any) => {
                    console.log(
                      "Supplier::",
                      value,
                      "  quotation::",
                      quotation.selected._id
                    );
                    quotation?.selected?.status === "draft" &&
                      setSuppliers(value);
                    // await handleSaving();
                  }}
                />
              </Form.Item>
            )}
            <Form.Item label="Confirmation Deadline">
              <DatePicker
                disabled={
                  quotation?.selected?.status !== "draft" ||
                  supplier.isFetching ||
                  quotation.isFetching
                }
                defaultValue={dayjs(confirmationDate.toString(), "DD/MM/YYYY")}
                format="DD/MM/YYYY"
                onChange={onChangeDate}
              />
            </Form.Item>{" "}
          </Stack>
        </Form>
      </Stack>
    </Paper>
  );
};

export default PageView;
