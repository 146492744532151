import React, { useState } from "react";
import { AlertFilled, MessageFilled, CloseOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { ChatTab } from "./chatTab";
import { Announcements } from "./announcements";

const tabs = [
  {
    label: "Chat",
    Icon: <MessageFilled />,
    children: <ChatTab />,
  },
  // {
  //   label: "Samphone Wiki",
  //   Icon: <AlertFilled />,
  //   children: <Announcements />,
  // },
];

export const Chat = () => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="flex flex-col fixed w-[30.2rem] mh-[80vh] z-[100] right-[1%] bottom-[1%]">
      {isOpened && (
        <div
          style={{
            width: "100%",
            height: "85%",
            borderRadius: "1rem",
            marginBottom: "6rem",
            paddingBottom: "1rem",
            backgroundColor: "white",
            border: "1px solid  #4c39d4",
          }}
        >
          <Tabs
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
            defaultActiveKey="1"
            tabBarStyle={{
              display: "flex",
              flexDirection: "row",
            }}
            items={tabs.map((tab, i) => {
              const id = String(i + 1);
              return {
                label: (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "14rem",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {tab.Icon}
                    {tab.label}
                  </span>
                ),
                key: id,
                children: tab.children,
              };
            })}
          />
        </div>
      )}
      <button
        onClick={() => setIsOpened(!isOpened)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "4rem",
          height: "4rem",
          borderRadius: "50%",
          backgroundColor: "#4c39d4",
          position: "absolute",
          bottom: "2%",
          right: "0",
        }}
      >
        {isOpened ? (
          <CloseOutlined
            style={{
              fontSize: "16px",
              color: "#fff",
            }}
          />
        ) : (
          <MessageFilled
            style={{
              fontSize: "16px",
              color: "#fff",
            }}
          />
        )}
      </button>
    </div>
  );
};
