import react, { useEffect, useState } from "react";
import SettingButton from "../SettingButton";
import * as bsIcon from "react-icons/bs";
import * as aiIcon from "react-icons/ai";
import profilePic from "../../../assets/images/Group (1).png";
import { Modal, Switch, Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../customSwuitch.css";
import { useDispatch, useSelector } from "react-redux";
import * as bsIcons from "react-icons/bs";
import * as aiIcons from "react-icons/ai";
import UploadComponent from "../../../../components/forms/Upload";
import { getAllChannelAction, updateChannelAction } from "../../../../store/channel/actions";
import HeaderComponent from "../../../../components/HeaderChannel";

import ChannelForm from "../../../../components/forms/newChannelForm";
import AddNewChannel from "../../../../components/forms/newChannelForm";
import { Avatar, List, Skeleton } from 'antd';
import UpdateChannelForm from "./updateChannelForm";
import { viewChannelDetails } from "../../../../store/layout/actions";
import ChannelDetailsPage from "./channelDetailsPage";

const Channels = () => {
  const { auth, layout, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);
  const data = [
    {
      name: "POS",
      contact: [{ name: "Jackson", position: "manager", phone: "0788181818" }],
      link: "pos/overview"
    },
    {
      name: "SUBSCRIPTIONS",
      contact: [{ name: "Jackson", position: "manager", phone: "0788181818" }],
      link: "sub/overview"
    }
  ];

  const styles = {
    shadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.5px solid rgba(15, 15, 71, 0.25)"
    }
  };
  const [check, setCheck] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
const [updateData,setUpdateData]=useState<any>("");
const [userChannel, setChannel] = useState<any>("");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleViewChannel = (value:any) => {
    viewChannelDetails(true, value)(dispatch)
    setChannel(value)
  };
  const ViewChannel = async(value:any) => {
    viewChannelDetails(false, value)(dispatch)
    setChannel(value)
  };
  const[isUpdateModalOpen,setIsUpdateModelOpen]=useState(false)
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    setCheck(!checked);
  };
  const [switchStates, setSwitchStates] = useState(
    new Array(channel.length).fill(true)
  );
  const [switchSubscriptionStates, setSwitchSubscriptionStates] = useState(
    new Array(channel.length).fill(true)
  );
  const handleSwitchChange = (index: number) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = !updatedSwitchStates[index];
    setSwitchStates(updatedSwitchStates);
    auth?.token && ( updateChannelAction(layout?.channelInfo?._id,{isSubscriptionActive:updatedSwitchStates}, auth?.token)(dispatch));
  };
  const UpdatePOSStatus = async(checked: boolean) => {
      (await updateChannelAction(
        layout?.channelInfo?._id,
        {
          isPOSActive: checked,
        },
        auth?.token
      )(dispatch));
      getAllChannelAction(auth?.token)(dispatch)
  };
  const UpdateSubscriptionStatus = async(checked: boolean) => {
      (await updateChannelAction(
        layout?.channelInfo?._id,
        {
          isSubscriptionActive: checked,
        },
        auth?.token
      )(dispatch));
      getAllChannelAction(auth?.token)(dispatch)
  };
  
  const handleSwitchSubscriptionChange = (index: number) => {
    const updatedSwitchSubscriotionStates = [...switchSubscriptionStates];
    updatedSwitchSubscriotionStates[index] =
      !updatedSwitchSubscriotionStates[index];
    setSwitchSubscriptionStates(updatedSwitchSubscriotionStates);
  };
  const updateModelOpen = (value:any) => {
    setUpdateData(value);
    setIsUpdateModelOpen(true);
  };
  const updateCancel=()=>{
    setUpdateData(" ")
    setIsUpdateModelOpen(false)
  }
  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };
  const allChannels = channel?.all?.data;
  return (
    <>
    {layout?.viewChannel? <ChannelDetailsPage/>:
      <div>
        <header className={`py-2 bg-[#F4F6FA] ${layout.isSideNavOpen? "w-[68%]":"w-[75%]"}  fixed top-[60px] z-10`}>
          <div
            className={`flex gap-20 justify-between pr-20  mt-3 bg-[#F4F6FA] h-[100px] ${
              layout.isSideNavOpen ? "w-[90%]" : "w-[100%] "
            }`}
          >
            <div>
              <h1 className="text-[20px] text-[#0F0F47] font-[600]">
                Channel List 
              </h1>
              <p className="text-[#0F0F47B2] pt-2 font-normal text-sm">
                You have {channel?.all?.total} specific channels.
              </p>
            </div>
            <Modal
        title={"Update Channel"}
        open={isUpdateModalOpen}
        onCancel={updateCancel}
        footer={null}
        className="min-w-min"
      >
        <div style={style}>
          <UpdateChannelForm
         data={updateData}
        //  channelId={channelId}
            onCancel={updateCancel}
          />
        </div>
      </Modal>
            
            
          <HeaderComponent
            item="Channel"
            modelTitle="Add New Channel"
            // filterCard={<ChannelFilter />}
            isNotCollapse={true}
            ModelComponent={<AddNewChannel onCancel={handleCancel} />}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCancel={handleCancel}
          />
          </div>
        </header>
        <div className="h-[88vh] overflow-y-auto ">
          <div className="mt-32 mr-4">
            {channel.isFetching? <div> <List
        itemLayout="vertical"
        size="large"
        dataSource={allChannels}
        renderItem={() => (
          <List.Item
          >
            <Skeleton loading={channel?.isFetching} active avatar>
              <List.Item.Meta
                avatar={<Avatar src="" />}
                // title={<a href={item.href}>{item.title}</a>}
                description=""
              />
              "content"
            </Skeleton>
          </List.Item>
        )}
      /></div>:allChannels.map((el: any, index: any) => (
              <div
                className="bg-white rounded-md mt-3 p-6 h-64"
                style={styles.shadow}
              >
                <div className="flex justify-between">
                  <div className="flex gap-4">
                    <img src={el?.logo} alt="mtn" width={42} className="rounded-full"/>
                    <h1 className="text-[#0F0F47] font-medium text-lg">
                      {el.name}
                    </h1>
                  </div>
                  <button className="bg-[#E5E5E5] h-7 rounded-[5px] px-2 flex gap-2 pt-1" 
                   onClick={() =>
                    updateModelOpen(el)
                  }>
                    <aiIcon.AiOutlineEdit color="#0F0F4780" />
                    <span className="text-[#0F0F4780] text-[12px] pt-1 font-normal">
                      Edit
                    </span>
                  </button>
                
                </div>
                <div className="flex justify-between mt-6">
                  <div className="ml-3">
                      <h1
                        key={data[0].link}
                        className="text-[#0F0F47] font-medium text-base"
                      >
                        {data[0].name}
                      </h1>
                  </div>
                  <div className="mt-6">
                    <span
                      style={styles.border}
                      className="rounded-[8px] py-1 px-1"
                    >
                      {el?.isPOSActive ? "On" : "Off"}{" "}
                      <Switch
                      //  disabled
                        defaultChecked={el?.isPOSActive}
                        onChange={() => (ViewChannel(el),UpdatePOSStatus(!(el?.isPOSActive)))}
                      />
                    </span>
                  </div>
                </div>
                <div className="flex justify-between mt-2">
                <h1
                        key={data[1].link}
                        className="text-[#0F0F47] font-medium text-base"
                      >
                        {data[1].name}
                      </h1>
                  <div className="mt-6">
                    <span
                      style={styles.border}
                      className="rounded-[8px] py-1 px-1"
                    >
                      {el?.isSubscriptionActive? "On" : "Off"}{" "}
                      <Switch 
                      // disabled
                        defaultChecked={el?.isSubscriptionActive}
                        onChange={() => (ViewChannel(el),UpdateSubscriptionStatus(!(el?.isSubscriptionActive)))}
                      />
                    </span>
                  </div>
                 
                </div>
                <div className="float-right mt-5 text-[#605BFF] text-sm cursor-pointer" onClick={()=>(handleViewChannel(el))}>View more</div>
              </div>
            ))}
            
          </div>
        </div>
      </div>
      }
    </>
  );
};
export default Channels;
