import React, { useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Select, Input, Typography } from "antd";
import InsurerTable from "../components/tables/InsurerTable";
import GeneralModal from "../components/modals/GeneralModal";
import AddInsurerForm from "../components/forms/AddInsurerForm";
const InsurerManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  return (
    <div>
    <GeneralModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={<h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Add New Insurer
            </h1>}
            component={
              <div className="px-x4  w-full">
                <div className="mt-10 w-full">
                <Typography >
        <pre className="h-10 rounded-md font-medium text-base font-poppins">Insurer Information</pre>
      </Typography>
                  <AddInsurerForm/>

                </div>
              </div>
            }
            width={600}
          />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Insurer Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex gap-5 mt-5">
        <EbmCard title={"Total Insurer"} total={"6"} />
        <EbmCard title={"Individual"} total={"3"} />
        <EbmCard title={"Corperate"} total={"3"} />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="mt-5">
          <InsurerTable open={showDrawer}/>
        </div>
      </div>
    </div>
  );
};

export default InsurerManagement;
