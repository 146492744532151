import React, { useEffect, useState } from "react";
import Card from "./card";
import phone from "../../../assets/images/vault/icons/heroicons-solid_device-mobile.png";
import { ReactComponent as DownIcon } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg";
import { ReactComponent as TravelIcon } from "../../../assets/icons/dashboard/vaults/streamline_travel-map-navigation-arrow-compass-arrow-map-bearing-navigation-maps-heading-gps.svg";
import { ReactComponent as VectorIcon } from "../../../assets/icons/dashboard/vaults/Vector.svg";
import { ReactComponent as GiftIcon } from "../../../assets/icons/dashboard/vaults/gift.svg";
import { ReactComponent as CaurseUp } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold (1).svg";
import { ReactComponent as UpBoldIcon } from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/dashboard/vaults/tabler_activity-heartbeat.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/dashboard/vaults/lock.svg";
import { ReactComponent as DeviceIcon } from "../../../assets/icons/dashboard/vaults/heroicons-solid_device-mobile.svg";
import VaultFilter from "./vaultFilter";
import {
  getAllDevicesAction,
  getDevicesPiechartAction,
  getDevicesStatusAction,
} from "../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomChart from "./customChart";
import CustomPieChart from "./customPieChart";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Col, Row } from "antd";

const VaultOverView = () => {
  const { auth, vault } = useSelector((state: any) => state);
  const [serviceDetails, setServiceDetails] = useState(null);
  const dispatch = useDispatch();
  const [stateName, setStateName] = useState("ALL");

  useEffect(() => {
    if (auth?.token) {
      getAllDevicesAction(auth?.token, `?stateName=${stateName}`)(dispatch);
      getDevicesStatusAction(auth.token, `?stateName=${stateName}`)(dispatch);
      getDevicesPiechartAction(
        auth.token,
        `?stateName=${stateName}&filter=brand`
      )(dispatch);
    }
  }, [auth.token, stateName, dispatch]);

  const styles = {
    boxShadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
    },
  };

  let total = 0;
  if (vault?.statusCount) {
    for (let key in vault.statusCount) {
      if (vault.statusCount.hasOwnProperty(key)) {
        total += vault.statusCount[key];
      }
    }
  }

  const pichartData = vault?.piechart;

  return (
    <>
      <Row gutter={10} className="gap-3">
        <Card
          name={"Total Devices"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : total ? (
              total
            ) : (
              0
            )
          }
          customeIcon={<DeviceIcon />}
          icon={<CaurseUp />}
          percent={"20%"}
        />
        <Card
          name={"Idle"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : vault?.statusCount?.Idle ? (
              vault?.statusCount?.Idle
            ) : (
              0
            )
          }
          customeIcon={<VectorIcon />}
          percent={"-10%"}
          icon={<UpBoldIcon className="mt-[2px]" />}
        />
        <Card
          name={"Ready for use"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : vault?.statusCount?.["Ready For Use"] ? (
              vault?.statusCount?.["Ready For Use"]
            ) : (
              0
            )
          }
          customeIcon={<GiftIcon />}
          percent={"10%"}
          icon={<CaurseUp className="mt-[2px]" />}
        />
        <Card
          name={"Active"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : vault?.statusCount?.Active ? (
              vault?.statusCount?.Active
            ) : (
              0
            )
          }
          customeIcon={<PhoneIcon />}
          percent={"-10%"}
          icon={<DownIcon className="mt-[2px]" />}
        />
        <Card
          name={"Locked"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : vault?.statusCount?.Locked ? (
              vault?.statusCount?.Locked
            ) : (
              0
            )
          }
          customeIcon={<LockIcon />}
          percent={"-10%"}
          icon={<DownIcon className="mt-[2px]" />}
        />
        <Card
          name={"Released"}
          total={
            vault?.isFetching ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : vault?.statusCount?.Released ? (
              vault?.statusCount?.Released
            ) : (
              0
            )
          }
          customeIcon={<TravelIcon />}
          percent={"-10%"}
          icon={<DownIcon className="mt-[2px]" />}
        />
      </Row>
      <div className="flex pt-3 gap-2">
        <div className="w-[60%] h-auto bg-white p-3 rounded-md">
          <div className="flex flex-row justify-between">
            <h1 className="text-[#030229]">Statistics</h1>
            <div className="flex gap-4 text-sm">
              <div className="flex gap-2">
                {" "}
                <div className="w-2 h-2 mt-[6px] rounded-full bg-[#FA3659]"></div>{" "}
                <p className="text-[#FA3659]"> Locked</p>
              </div>
              <div className="flex gap-2">
                {" "}
                <div className="w-2 h-2 mt-[6px] rounded-full bg-[#605BFF80]"></div>{" "}
                <p className="text-[#030229]"> Unlocked</p>
              </div>
              <div className="flex gap-2">
                {" "}
                <div className="w-2 h-2 mt-[6px] rounded-full bg-[#0FA91E80]"></div>{" "}
                <p className="text-[#030229]"> Released</p>
              </div>
            </div>
          </div>
          <CustomChart />
        </div>
        <div className="bg-white  w-[40%] h-auto rounded-md">
          <CustomPieChart data={pichartData} />
        </div>
      </div>
      {/* <div
        className="absolute top-[27rem]  p-5 rounded-md"
        style={styles.boxShadow}
      >
        <div className=" flex mb-5 justify-between text-black">
          <h1 className="text-[#030229]">Statistics</h1>
          <div className="flex gap-4 text-sm">
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#FA3659]"></div>{" "}
              <p className="text-[#FA3659]"> Locked</p>
            </div>
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#605BFF80]"></div>{" "}
              <p className="text-[#030229]"> Unlocked</p>
            </div>
            <div className="flex gap-2">
              {" "}
              <div className="w-2 h-2 mt-[6px] rounded-full bg-[#0FA91E80]"></div>{" "}
              <p className="text-[#030229]"> Released</p>
            </div>
          </div>
        </div>
        <CustomChart />
      </div> */}
      {/* <div className="absolute top-[32rem] right-0 px-20 bg-dwhite rounded-md">
        <CustomPieChart data={pichartData} />
      </div> */}
    </>
  );
};

export default VaultOverView;
