import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedUserLogsAction } from '../../store/setting/actions';
import * as piIcons from "react-icons/pi";
import ActivityLogsDrawer from '../Modals/activityLogsDrawer';
interface DataType {
  key: React.Key;
  activity: string;
  resMessage: string;
  createdAt: string;
  resStatus: string;
  status: any;
}


const ActivityLogsTable= () => {
  const {setting,auth}=useSelector((state:any)=>state)
const dispatch=useDispatch();
const [data,setData]=useState<any>();
const userId=setting?.selected?._id;
  useEffect(() => {
    if (auth?.token) {
      getSelectedUserLogsAction(auth?.token, userId as string)(dispatch);
    }
  }, [auth, userId, dispatch]);
  const [open, setOpen] = useState(false);

  const showDrawer = (data:any) => {
    
    setData(data)
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'Activity Name',
      dataIndex: 'activity',
    },
    {
      title: 'Message',
      dataIndex: 'resMessage',
    },
    {
      title: 'Status',
      dataIndex: 'resStatus',
    },
    {
      title: 'Date and Time',
      dataIndex: 'createdAt',
    },
    {
      title: 'Action',
      dataIndex: 'address',
      render: (_, record) => (
        <piIcons.PiEyeLight size={20} className='cursor-pointer' onClick={()=>{
          showDrawer(record)
          console.log("record",record)
        }}/>
      ),
    },
  ];
  
  const ActivityLogsData = setting?.selectedUserLogs?.map((el: any) => {
    return {
      key: el?._id,
      activity: el?.actionDetails?.Url,
      resMessage: el?.resMessage,
      resStatus: el?.resStatus,
      createdAt: el?.createdAt?.slice(0,10) + '  '+ el?.createdAt?.slice(11,20),
      data: el,
    };
  }
);
  return(
<>
<ActivityLogsDrawer
      open={open}
      onClose={onClose}
      data={data}
    />

  <Table columns={columns} dataSource={ActivityLogsData} loading={setting?.isFetching}/>
</>
)};

export default ActivityLogsTable;