import { useState } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Tag } from "antd";
import ProductDetailsModal from "../../../Modals/ProductDetailsModal";
import UpdateProduct from "../../../forms/NewProductForm";
import DeleteModal from "../../../Modals/DeleteModal";
import { getActiveShop, getTotalPrice } from "../../../../utils/converter";
import { Link } from "react-router-dom";
import { searchValue } from "../../../../utils/setColor";
import { deleteCartAction } from "../../../../store/pos/actions";

const CartCardGridPOS = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  console.log(props?.data);

  const deleteProduct = async () => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    const shopId = activeShop
      ? activeShop[0].shop._id
      : auth?.user?.shop?.shopId;
    auth?.token &&
      (await deleteCartAction(
        auth?.token,
        props?.data?._id,
        `?shop=${shopId}`
      )(dispatch));
    setVisible(false);
  };

  return (
    <>
      <Badge.Ribbon
        color="green"
        text={`Total amount: ${getTotalPrice(
          props?.data?.list
        )?.toLocaleString()} RWF`}
      >
        <div className="relative w-80 h-fit p-2  bg-white text-black rounded-md">
          <Link to={`${props?.data?._id}`}>
            <div className="flex w-full pb-10 pt-10">
              <div className="flex flex-col flex-wrap pl-2">
                <h1 className="font-bold">{props?.data?.cartId}</h1>
                <div className=" gap-1">
                  <p className="text-xs">
                    <b>Customer: </b>
                    {props?.data?.customer?.name}
                  </p>
                  <p className="text-xs">
                    <b>Created Date: </b>
                    {new Date(props?.data?.createdAt).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </p>
                  <p className="text-xs">
                    <b>Created By: </b>
                    {props?.data?.createdBy?.names}
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
            <div className=" capitalize">
              <Tag
                color={searchValue(props?.data.status)}
                style={{ minWidth: "4rem", textAlign: "center" }}
              >
                {props?.data.status}
              </Tag>
            </div>
            {props?.data?.status?.toLowerCase() !== "paid" && (
              <button
                aria-label="delete"
                className="text-gray-400"
                onClick={handleClickDelete}
              >
                <DeleteIcon fontSize="small" className="h-2" />
              </button>
            )}
          </div>
        </div>
      </Badge.Ribbon>
      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={wareHouse.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </>
  );
};

export default CartCardGridPOS;
