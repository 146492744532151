import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: " Company Library",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    sizedLog:null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state,action){
      state.attributes = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSizedLog(state, action) {
      state.sizedLog = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    
  },
});

export const myCompanyActions = mySlice.actions;

export default mySlice.reducer;
