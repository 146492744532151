import { notification } from "antd";
import { mySubscriptionRequestActions } from ".";
import { getAllSubRequestService, updateCartDetailsService } from "./services";

export const getAllSubRequestedAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await getAllSubRequestService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscriptionRequestActions.setAll(res));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const acceptSubRequestAction = (
  token: string,
  cartId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await updateCartDetailsService(token, cartId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubRequestService(token, "?");
        dispatch(mySubscriptionRequestActions.setAll(resAll));
        dispatch(mySubscriptionRequestActions.setUpdated(res?.data));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
        notification.success({ message: "Accepted successfully" });
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubRequestAction = (
  token: string,
  cartId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await updateCartDetailsService(token, cartId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubRequestService(token, "?");
        dispatch(mySubscriptionRequestActions.setAll(resAll));
        dispatch(mySubscriptionRequestActions.setUpdated(res?.data));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
