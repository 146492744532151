import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";

interface DataPoint {
  values: number;
  label: string;
}

interface PieChartWithCenterLabelProps {
  data?: Record<string, number>;
}

// Sample data if props.data is not provided
const defaultData: DataPoint[] = [
  { values: 5, label: "Apple" },
  { values: 10, label: "Samsung" },
  { values: 15, label: "Tecno" },
  { values: 20, label: "Itel" },
  { values: 25, label: "Infinix" },
];

const PieChartWithCenterLabel: React.FC<PieChartWithCenterLabelProps> = (
  props
) => {
  // Use defaultData if props.data is not provided
  const data: DataPoint[] = props.data
    ? Object.entries(props.data).map(([key, value]) => ({
        label: key,
        values: value,
      }))
    : defaultData;

  const colors = ["#3930A8", "#3762D0", "#78CFFF", "#BBD9FC", "#030229"];

  return (
    <PieChart
      series={[
        {
          data: data.map(({ values, label }) => ({ value: values, label })),
          innerRadius: 60,
        },
      ]}
      colors={colors}
      width={500}
      height={200}
    />
  );
};

export default PieChartWithCenterLabel;
