import react, { useState } from "react";
import { Select, Switch, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import * as piIcons from "react-icons/pi";
import SettingComponent from "./security/SettingComponent";
const Notifications = () => {
  const nofification = [
    {
      tittle: "Mobile push Notifications",
      content:
        "Receive push notification whenever your organization required your attentions"
    },
    {
      tittle: "Desktop Notifications",
      content:
        "Receive desktop notification whenever your organization required your attentions"
    },
    {
      tittle: "Email Notifications",
      content:
        "Receive email notification whenever your organization required your attentions"
    }
  ];
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.5px solid rgba(15, 15, 71, 0.25)"
    }
  };
  const [switchState, setSwitchState] = useState(
    new Array(nofification.length).fill(true)
  );
  const checkChanged = (index: number) => {
    const updatedSwitchStates = [...switchState];
    updatedSwitchStates[index] = !updatedSwitchStates[index];
    setSwitchState(updatedSwitchStates);
  };
  return (
    <>
      <h1 className="text-[#030229] font-medium text-lg">
        Notification settings
      </h1>
      <p className="text-[#030229d8] text-sm font-normal">
        Select the kinds of notifications you get about your activities and
        recommandations.
      </p>
      <div
        className="bg-white mt-5 h-[82vh] divide-y rounded-md px-6 py-8"
        style={styles.customShadow}
      >
        <div>
          <h1 className="text-[#030229] font-medium text-lg">
            Notify me when...
          </h1>

          <div>
            {" "}
            <Checkbox className="text-[#030229B2] text-[12px] mt-3 font-normal">
              Daily productivity update
            </Checkbox>
          </div>
          <div>
            {" "}
            <Checkbox className="text-[#030229B2] text-[12px] font-normal my-3">
              New event created
            </Checkbox>
          </div>
          <div>
            {" "}
            <Checkbox className="text-[#030229B2] text-[12px] font-normal">
              When added on new team member
            </Checkbox>
          </div>
        </div>
        {nofification.map((el: any, index: number) => (
          <>
            <SettingComponent
              title={el.tittle}
              details={el.content}
              btn={
                <span style={styles.border} className="rounded-[8px] py-1 px-4">
                  {switchState[index] ? "On" : "Off"}
                  <Switch
                    className="ml-2"
                    defaultChecked={switchState[index]}
                    onChange={() => checkChanged(index)}
                  />
                </span>
              }
            />
          </>
        ))}
      </div>
    </>
  );
};
export default Notifications;
