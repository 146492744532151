import React, { useEffect, useState } from "react";
import { Steps, theme } from "antd";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const POSSteps: React.FC<{
  steps: any;
  handleFinish: any;
  status?: String;
  handlePrintReceipt?: () => void;
}> = ({ steps, handleFinish, status, handlePrintReceipt }) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const { pos } = useSelector((state: any) => state);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item: any) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 10,
  };
  useEffect(() => {
    if (
      status?.toLocaleLowerCase() === "paid" ||
      status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(2);
    }
  }, []);
  return (
    <div className="p-4">
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>

      {status !== "paid" && status !== "cancelled" && (
        <div style={{ marginTop: 24 }}>
          {current < steps.length - 1 && (
            <Button color="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <>
              <LoadingButton
                color="primary"
                onClick={handleFinish}
                loading={pos?.isFetching}
              >
                Checkout
              </LoadingButton>

              {false && <Button style={{ margin: "0 8px" }} onClick={handlePrintReceipt}>
                Print receipt
              </Button>}
            </>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default POSSteps;
