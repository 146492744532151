import { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, Tag } from "antd";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import ProductDetailsModal from "../../Modals/ProductDetailsModal";
import UpdateProduct from "../../forms/NewProductForm";
import DeleteModal from "../../Modals/DeleteModal";
import { useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import ThreeDotDropdown from "../../buttons/ThreeDotDropdown";

const Component = (props: any) => {
  console.log(props);
  const { channel } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [visible, setVisible] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  // const dispatch = useDispatch();

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const deleteProduct = async () => {
    console.log("deleting....");
  };
  console.log(
    props?.data?.prices?.filter((price: any) => price.isActive === true)?.value
  );

  return (
    <div className="relative">
      <ThreeDotDropdown
        setShowDropdownMenu={setShowDropdownMenu}
        showDropdownMenu={showDropdownMenu}
        cardId={props?.data?._id}
      />
      <div className="relative w-80 h-56 p-2 pt-10 bg-white text-black rounded-md border hover:border-black">
        <Link to={`/inventory/ws/stock/${props?.data?._id}`}>
          <div className="flex w-fit">
            <div style={{ width: "7rem" }}>
              {props?.data?.image?.length === 0 && (
                <img src={defaultPic} width={"100%"} alt="" />
              )}
              {props?.data?.image?.length > 0 && (
                <Carousel autoplay>
                  {props?.data?.image?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="flex flex-col pl-2">
              <h1 className="font-bold">{props?.data?.model}</h1>
              <h1 className="font-thin  text-sm">
                Brand: {props?.data?.brand}
              </h1>
              {props?.data?.statuses?.map((item: any, _index: number) => (
                <p className={`text-[${searchValue(item?.status)}] my-1`}>
                  <b>{`${
                    item?.status?.toLowerCase() === "in"
                      ? "In Warehouse "
                      : item?.status?.toLowerCase() === "out"
                      ? "In Shops"
                      : item?.status?.toLowerCase() === "sold"
                      ? "Sold"
                      : "Idle"
                  }:`}</b>
                  {item?.count}
                </p>
              ))}
            </div>
          </div>
          <div className="flex justify-center py-4 gap-x-4"></div>
        </Link>
        <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
          <Tag color={getColorByValue(props?.types, props?.data?.type)}>
            {props?.data?.type}
          </Tag>
        </div>
      </div>

      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </div>
  );
};

export default Component;
