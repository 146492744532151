import React from "react";
import { Button, Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import { getCustomerPurchaseAction } from "../../store/customer/actions";
import type { TableColumnsType } from "antd";

interface ExpandedDataType {
  key: any;
  specification: string;
  sn: string;
  imei: string;
  status: any;
  data: any;
  price: any;
}
interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const CustomerProductTable = (props: any) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [expandedRowData, setExpandedRowData] = React.useState<any>("");
  const { auth, customer } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Agent",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <>
          <p className="font-bold">{record?.data?.createdBy?.names ?? "N/A"}</p>
          {/* <p>{record?.data?.list[0]?.shopStockItem?.shop?.name} </p>
          <p>{record?.data?.list[0]?.shopStockItem?.shop?.channel?.name} </p> */}
        </>
      ),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: (text, record) => (
        <>
          {record?.data?.payment?.map((d: any) => {
            return <p className="font-medium capitalize mb-1">{d?.mode}</p>;
          })}
        </>
      ),
    },
    {
      title: "Amount (Frw)",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <>
          {record?.data?.payment?.map((d: any) => {
            return (
              <p className="font-medium capitalize">
                {d?.amount?.toLocaleString()}
              </p>
            );
          })}
        </>
      ),
    },
    {
      title: "Discount",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <>
          <p className="capitalize">
            {record?.data?.discount?.discounType?.split("-")[0] ?? "N/A"}{" "}
            <Tag className="ml-1">{record?.data?.discount?.discountPerc}%</Tag>
          </p>
        </>
      ),
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text, record: any) => (
        <p
          className="text-sm cursor-pointer text-blue-700"
          onClick={() => navigate(`/pos/transactions/${record.data._id}`)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Total (Frw)",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <p>{text.toLocaleString()}</p>,
    },
    {
      title: "Products",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <p>{record?.data?.list.length} </p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
  ];

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Shop",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>{record?.data?.shopStockItem?.shop?.name} </p>
            <p>{record?.data?.shopStockItem?.shop?.channel?.name} </p>
          </>
        ),
      },
      {
        title: "Model",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <p>
            {record?.data?.shopStockItem?.requestedItem?.product?.product
              ?.model +
              "~" +
              record?.data?.shopStockItem?.requestedItem?.product?.product
                ?.type}{" "}
          </p>
        ),
      },
      {
        title: "Descriptions",
        dataIndex: "specification",
        key: "specification",
      },
      { title: "Serial Number", dataIndex: "sn", key: "sn" },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        render: (text, record) => (
          <>
            <p>{record?.data?.warehouseProduct?.imei1 || "N/A"}</p>
            <p>{record?.data?.warehouseProduct?.imei2}</p>
          </>
        ),
      },
      {
        title: "Price(Frw)",
        dataIndex: "price",
        key: "price",
        render: (text, record) => (
          <p>{(record?.data?.payment?.amount).toLocaleString()}</p>
        ),
      },
      {
        title: "Extended Warranty",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <p>{record?.data?.payment?.extendedWarranty} %</p>
        ),
      },
      {
        title: "Action",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Button className="capitalize min-w-[50px] text-center">
            Repair
          </Button>
        ),
      },
    ];

    const productData: ExpandedDataType[] = [];
    expandedRowData?.data?.list?.forEach((el: any) => {
      productData.push({
        key: el.id,
        sn: el?.warehouseProduct?.serialNumber,
        imei: el?.shopStockItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.shopStockItem?.requestedItem?.product?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });
    console.log("productData::))", productData);
    return (
      <Table columns={columns} dataSource={productData} pagination={false} />
    );
  };

  React.useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customerId}&isSubscriptionSubmitted=false`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  const value = customer?.purchased;

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          key: el._id,
          date_time: el?.updatedAt?.split("T")[0],
          agent: el?.createdBy?.names,
          amount: el?.payment?.reduce(
            (total: number, current: any) => parseInt(total + current?.amount),
            0
          ),
          status: el?.status,
          barCode: el?.warehouseProduct?.serialNumber,
          transactionId: el?.cartId,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [customer?.purchased]);

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
      setExpandedRowData(record);
    } else {
      setExpandedRowKey(null);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={formData}
      onChange={onChange}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        onChange: (page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        },
      }}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: ["0"],
        onExpand: handleRowExpand,
        expandedRowKeys: [expandedRowKey],
      }}
      loading={customer?.isFetching}
    />
  );
};

export default CustomerProductTable;
