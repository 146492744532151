import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Select } from 'antd';

const { Option } = Select;

const data = [
    { date: '01 May', attendance: 65 },
    { date: '02 May', attendance: 70 },
    { date: '03 May', attendance: 75 },
    { date: '04 May', attendance: 80 },
    { date: '05 May', attendance: 85 },
    { date: '06 May', attendance: 60 },
    { date: '07 May', attendance: 85 }
];

const CustomAttendanceChart = () => {
    const [selectedMonth, setSelectedMonth] = useState('May');

    const handleMonthChange = (value: React.SetStateAction<string>) => {
        setSelectedMonth(value);
    };

    const yAxisTickFormatter = (tick:any) => `${tick}%`;

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-poppins text-black">Attendance Comparison Chart</h2>
                <Select
                    className='text-sm'
                    defaultValue="This Month"
                    onChange={handleMonthChange}
                    style={{ width: 130 }}
                >
                    <Option value="January">January</Option>
                    <Option value="February">February</Option>
                    <Option value="March">March</Option>
                    <Option value="April">April</Option>
                    <Option value="May">May</Option>
                    <Option value="June">June</Option>
                    <Option value="July">July</Option>
                    <Option value="August">August</Option>
                    <Option value="September">September</Option>
                    <Option value="October">October</Option>
                    <Option value="November">November</Option>
                    <Option value="December">December</Option>
                </Select>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <defs>
                        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                            <feDropShadow dx="5" dy="5" stdDeviation="5" floodColor="#605BFF" />
                        </filter>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 15 }} tickMargin={15} />
                    <YAxis axisLine={false} tickLine={false} tickFormatter={yAxisTickFormatter} tickMargin={10} />
                    <Tooltip />
                    <Line type="monotone" dataKey="attendance" stroke="#605BFF" strokeWidth={3} activeDot={{ r: 8 }} filter="url(#shadow)" dot={{ fill: '#FFFFFF', stroke: '#605BFF', strokeWidth: 2 }}/>
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};

export default CustomAttendanceChart;

