import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "VaultLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    lock: null,
    unLock: null,
    notes: null,
    message: null,
    deviceStatus: null,
    deviceServiceStatus: null,
    overviewCount: null,
    statusCount: null,
    messageTamplate: null,
    isFetchingShop: false,
    search: null,
    piechart: null,
    newCsv: null,
    reload:null,
    release:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setnewCsv(state, action) {
      state.newCsv = action.payload;
    },
    setReload(state, action) {
      state.reload = action.payload;
    },
    setRelease(state, action){
      state.release=action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setPiechart(state, action) {
      state.piechart = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setMessage(state, action) {
      state.selected = action.payload;
    },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFetchingShop(state, action) {
      state.isFetchingShop = action.payload;
    },
    setDeviceStatus(state, action) {
      state.deviceStatus = action.payload;
    },
    setLock(state, action) {
      state.lock = action.payload;
    },
    setUnLock(state, action) {
      state.lock = action.payload;
    },
    setNotes(state, action) {
      state.notes = action.payload;
    },
    setStatusCount(state, action) {
      state.statusCount = action.payload;
    },
    setDeviceServiceStatus(state, action) {
      state.deviceServiceStatus = action.payload;
    },
    setMessageTamplate(state, action) {
      state.messageTamplate = action.payload;
    },
    setOverViewCount(state, action) {
      state.overviewCount = action.payload;
    },
  },
});

export const myVaultActions = mySlice.actions;

export default mySlice.reducer;
