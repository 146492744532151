import { LoadingButton } from "@mui/lab";
import { Form, notification, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllTransactionInRangeAction,
  updatePaymentAndAmountAction,
} from "../../store/pos/actions";
import { Options } from "../../assets/data/paymentMethod";

const AmountPaidForm: React.FC<{
  cartId: any;
  action: string;
  dataToUpdate: any;
  selectedDate: any;
  selectedEndDate: any;
  onCancel?: any;
}> = ({
  cartId,
  dataToUpdate,
  onCancel,
  action,
  selectedDate,
  selectedEndDate,
}) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const amountPaid = dataToUpdate?.payment?.at(0)?.amount;
  const onFinish = async (values: any) => {
    const paymentData = dataToUpdate?.payment;
    const newAmount = +values?.amount;
    const newPayment = values?.mode;
    const payment = [...dataToUpdate?.payment];

    if (action === "amount") {
      payment[0] = { ...paymentData[0], amount: newAmount };
    } else {
      payment[0] = { ...paymentData[0], mode: newPayment };
    }

    const payload = {
      payment: [...payment],
      comments: [
        {
          description: `${
            action === "amount" ? "Amount paid" : "Payment method"
          } >> ${values?.comment}`,
          createdBy: auth?.user?._id,
        },
      ],
    };

    if (cartId && auth?.token) {
      await updatePaymentAndAmountAction(auth?.token, cartId, { ...payload })(
        dispatch
      );
      onCancel();
      await getAllTransactionInRangeAction(
        auth?.token,
        pos?.query +
          `${`?limit=15&page=0&startDate=${selectedDate}&endDate=${selectedEndDate}`}`
      )(dispatch);
    } else {
      notification.error({ message: "Something went wrong" });
    }
    form.resetFields();
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (action === "amount" && !pos?.isFetching) {
      form.setFieldsValue({
        amount: amountPaid,
      });
    }
  });

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "80%" }}
        scrollToFirstError
      >
        <h1 className="pb-4 font-semibold text-xl">
          {action === "amount" ? "Update Amount Paid" : "Update Payment Method"}
        </h1>
        {action === "amount" ? (
          <>
            <Form.Item name="amount" label="Amount">
              <Input type="number" width={50} />
            </Form.Item>
            <Form.Item
              name="comment"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required!",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name="mode" label="Payment Method">
              <Select
                placeholder="Select a payment method"
                optionFilterProp="children"
                className="py-2"
                style={{ width: 200, height: 50 }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={Options}
              />
            </Form.Item>
            <Form.Item
              name="comment"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required!",
                },
              ]}
            >
              <Input.TextArea rows={4} className="ml-12" />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={pos?.isFetching}
          >
            {"update"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AmountPaidForm;
