import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAccountIncoiceService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/invoice${query}`, token);
};



export const getOneInvoiceService = async (
  id: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/invoice/one/${id}`, token);
};


export const approvePaymentInvoice = async (
  itemId: string,
  token: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/invoice/pay/${itemId}`,
    data,
    token
  );
};
