import HttpRequest from "../../../utils/HttpRequest";
import { SERVER_URL } from "../../../utils/constants";


export const getEbmOverviewService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getItemStats${query || ""}`, token);
};
export const getAllItemsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getAllItems${query || ""}`, token);
};
export const getEbmSelectOptionServices = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getSelectOptions${query || ""}`, token);
};
export const getAllProducts = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/product${query}`, token);
};
export const getAllProductAttributesService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/product/brands${query}`, token);
};
export const getAllEBMItemsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/ebmitem${query}`, token);
};
export const addItemService = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/ebm/addItem`,data, token);
};