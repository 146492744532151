import React from 'react'
import phoneImg from "../assets/images/Apple-iPhone-15-Pro Max 500px by 500px 1.png"
import { Carousel } from 'antd'
const CustomerDetails = () => {
  return (
    <div className='text-black px-4'>
        <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2 ">
                    <div>
                      <p className="text-[#030229B2] text-sm mt-4">Customer ID:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      787300122
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Type:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Individual
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Name:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Solange Mukakarara
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Email:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      Solange@gmail.com
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Phone 1:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      0787300125
                      </p>
                      <p className="text-[#030229B2] text-sm mt-4">Phone 2:</p>
                      <p className="text-[#030229] text-sm pt-1">
                      N/A
                      </p>
                    
                    </div>
        </div>
        </div>
  )
}

export default CustomerDetails