import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WarehouseOutTable from "../../../../components/tables/WarehouseOutTable";
import WarehouseOutTableView from "../../../../components/tables/WarehouseOutTableView";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Stack } from "@mui/material";
import { Spin, Tag } from "antd";
import {
  getAllWarehouseOutAction,
  getAllWarehouseActionBySN,
  updateStockOutAction,
  getAllWarehouseOutItemsAction,
  updateOneStockOutDetailsAction,
} from "../../../../store/wareHouseOut/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import LoadingButton from "@mui/lab/LoadingButton";
import ShopFromDrower from "./ShopFromDrower";
const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const WsCreate = (props: any) => {
  const { auth, wareHouseOut } = useSelector((state: any) => state);
  const [open, setOpen] = React.useState<any>(false);
  const [selectedProductId, setSelectedProductId] = React.useState<any>("");
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [showDeliverer, setShowDeliverer] = React.useState<any>("");
  const [serialNoString, setSerialNoString] = React.useState<any>([]);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const deliverer = wareHouseOut?.warehouseOut?.data[0]?.deliverer;
  const warehouseOutData = wareHouseOut?.warehouseOut?.data[0];

  const onFinish = async (values: any) => {
    const handleDeliverer = async () => {
      if (auth?.token) {
        const res = await updateOneStockOutDetailsAction(
          auth?.token,
          wareHouseOut?.warehouseOut?.data[0]?._id,
          selectedProduct?._id,
          {
            deliverer: {
              ...values,
            },
            status: "In-Transit",
          }
        )(dispatch);
        res && navigate(-2);
      }
      form.resetFields();
    };
    handleDeliverer();
  };

  useEffect(() => {
    setShowDeliverer(
      wareHouseOut?.selectedWarehouseOut &&
        !wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
          ?.map(
            (item: any, _index: number) =>
              item?.qtyApproved === item?.qtyRequested
          )
          ?.includes(false)
    );
  }, [wareHouseOut?.selectedWarehouseOut]);

  useEffect(() => {
    auth?.token &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
  }, [auth?.token, dispatch, id]);

  const handleSetSelected = (product_id: string) => {
    setSelectedProductId(product_id);
    setSelectedProduct(
      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
        (item: any) => item?._id === product_id
      )[0]
    );
    auth?.token &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${product_id}&limit=2000`
      )(dispatch);
  };

  const handleSubmit = async () => {
    // await handleGetStockBySN(serialNoArr);
    form.resetFields();
  };
  //get product by Serial number
  const handleGetStockBySN = async (searchSerialNumber: String) => {
    if (auth?.token && searchSerialNumber) {
      const res = await getAllWarehouseActionBySN(
        auth?.token,
        `?serialNumber=${searchSerialNumber}&status=in`
      )(dispatch);
      if (res) {
        form.resetFields();
        res?.length === 0 &&
          notification.error({
            message: "Product Not Found!",
            description: `Product with S/N ${searchSerialNumber} is not available in the warehouse. `,
          });
        if (res?.length === 1) {
          const resAdd = await updateStockOutAction(
            wareHouseOut?.selectedWarehouseOut?._id,
            {
              product: res[0]?.quotationItem?.product?._id,
              productPriceList: selectedProduct?.product?._id,
              requestedItem: selectedProduct?._id,
              stockRequest:
                wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
              wareHouseItem: res[0]?._id,
              shop: wareHouseOut?.selectedWarehouseOut?.stockRequest?.shopId
                ?._id,
            },
            auth?.token
          )(dispatch);

          resAdd &&
            notification.success({
              message: "Product Is Added!",
              description: `Product with S/N ${searchSerialNumber} is  available in the warehouse. `,
            });
        }
        // se}lectedOnestock
      }
    }
  };

  const handleAddProducts = async () => {
    if (
      serialNoString?.trim().split(" ")?.length <=
        selectedProduct?.qtyApproved -
          wareHouseOut?.warehouseOutItem?.data?.length ??
      0
    ) {
      if (
        new Set(serialNoString?.trim().split(" ")).size !==
        serialNoString?.trim().split(" ")?.length
      ) {
        setDuplicateItems(
          serialNoString
            .trim()
            ?.split(" ")
            .filter(
              (value: any, index: any) =>
                serialNoString.trim()?.split(" ").indexOf(value) !== index
            )
        );
        return notification.warning({
          message: "IMEI has been duplicated",
          description: `${serialNoString
            ?.split(" ")
            .filter(
              (value: any, index: any) =>
                serialNoString?.toString()?.trim().split(" ").indexOf(value) !==
                index
            )
            .join(" , ")}`,
        });
      }

      await updateStockOutAction(
        wareHouseOut?.selectedWarehouseOut?._id,
        {
          action: wareHouseOut?.selectedWarehouseOut?.isTransferred
            ? "transfer"
            : "warehouseout",
          product: selectedProduct?.product?.product?._id,
          serialNumbers: serialNoString?.toString()?.trim().split(" "),
          productPriceList: selectedProduct?.product?._id,
          requestedItem: selectedProduct?._id,
          stockRequest: wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
          shop: wareHouseOut?.selectedWarehouseOut?.stockRequest?.shopId?._id,
        },
        auth?.token
      )(dispatch);
    } else {
      notification.error({
        message: `Please Verify only ${
          selectedProduct?.qtyApproved -
          (wareHouseOut?.warehouseOutItem?.data?.length ?? 0)
        } Devices`,
      });
    }
  };

  const [form] = Form.useForm();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <DetailsHeaderActionBar pageName={props?.pageName} title={"Out"} />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="flex relative gap-20 w-full justify-between items-end">
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              className="w-full"
            >
              <div className="mb-5">
                <p className="text-xsx text-gray-400">
                  {`${warehouseOutData?.stockRequest?.shopId?.name}`}
                </p>
                <p className="text-xsx text-gray-400">
                  {warehouseOutData?.stockRequest?.shopId?.channel?.name}
                </p>
              </div>
              <Tag
                color={
                  wareHouseOut?.selectedWarehouseOut?.isTransferred
                    ? "blue"
                    : "yellow"
                }
              >
                {wareHouseOut?.selectedWarehouseOut?.isTransferred
                  ? "As Transfer"
                  : "From Warehouse"}
              </Tag>
            </Stack>

            {wareHouseOut?.selectedWarehouseOut?.status !== "In-Transit" && (
              <div className="w-max ">
                <p className="w-fit font-bold text-lg">Model name</p>
                <div className="flex gap-5 text-gray-300 items-center">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choose Product Model"
                    onChange={(data: any) => {
                      handleSetSelected(data);
                    }}
                    options={wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.map(
                      (item: any, _index: number) => ({
                        label:
                          `${
                            item?.qtyApproved === item?.qtyRequested
                              ? "✔️ "
                              : ""
                          }  ` + item?.product?.product?.model,
                        value: item?._id,
                      })
                    )}
                  />

                  {wareHouseOut.isFetching && (
                    <div className="flex h-fit ">
                      <Spin indicator={antIcon} />
                    </div>
                  )}

                  {!wareHouseOut.isFetching && (
                    <span>{`${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
                        (item: any) => item?.qtyApproved === item?.qtyRequested
                      )?.length
                    }/${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
                        ?.length
                    }`}</span>
                  )}
                </div>
                {
                  <div className="grid grid-cols-2 min-w-[288px] bg-slate-100 mt-2 rounded-md p-3">
                    <>
                      {selectedProduct?.product?.specification?.map(
                        (item: any, _index: number) =>
                          item[1] && (
                            <>
                              <span className="font-semibold">{item[0]}</span>
                              <span>{item[1]}</span>
                            </>
                          )
                      )}
                    </>
                  </div>
                }
              </div>
            )}
          </Stack>

          {selectedProduct?.qtyRequested && (
            <div className=" flex gap-0">
              <Stack
                direction="row"
                // justifyContent="flex-end"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack marginTop={"10px"}>
                  <span>{`${
                    wareHouseOut?.warehouseOutItem?.data?.length || 0
                  }/${selectedProduct?.qtyApproved}`}</span>
                  <Input
                    style={{ width: 400 }}
                    placeholder="Search Serial Number"
                    onChange={(e: any) => setSerialNoString(e.target.value)}
                  />
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  sx={{ width: 80 }}
                >
                  {!wareHouseOut?.isFetching && (
                    <Button
                      // type="submit"
                      disabled={wareHouseOut?.isFetching}
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                        marginLeft: "4px",
                      }}
                      onClick={() => handleAddProducts()}
                    >
                      Verify
                    </Button>
                  )}
                </Stack>
              </Stack>
            </div>
          )}
          {warehouseOutData?.isTransferred && (
            <div className="absolute right-1 top-32">
              <ShopFromDrower
                showDrawer={showDrawer}
                open={open}
                onClose={onClose}
              />
            </div>
          )}
        </div>
        {wareHouseOut?.selectedWarehouseOut?.status === "In-Transit" ? (
          <WarehouseOutTableView />
        ) : (
          <WarehouseOutTable selectedProductId={selectedProductId} />
        )}
        {wareHouseOut?.selectedWarehouseOut?.status !== "In-Transit" &&
          wareHouseOut?.warehouseOutItem?.data?.length ===
            selectedProduct?.qtyApproved && (
            <div className="space-y-4">
              <p>Deliverer Details</p>
              <Form onFinish={onFinish} form={form} className="w-96">
                <Form.Item
                  name="name"
                  label="Name"
                  initialValue={deliverer?.name}
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone"
                  initialValue={deliverer?.phone}
                  rules={[
                    {
                      required: true,
                      message: "Phone is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="company"
                  label="Company"
                  initialValue={deliverer?.company}
                  rules={[
                    {
                      required: true,
                      message: "Company name is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  initialValue={deliverer?.email}
                  rules={[
                    {
                      required: true,
                      message: "Email is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: "60%" }}
                  loading={wareHouseOut?.isFetching}
                >
                  Save
                </LoadingButton>
              </Form>
            </div>
          )}
      </div>
    </div>
  );
};

export default WsCreate;
