import React, { useState, useEffect } from "react";
import { Form, Input, Select, notification } from "antd";
import DualListBox from "../../pages/dashboard/setting/manage users/dualListBox";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoleAction,
  updateRoleAction,
} from "../../store/setting/roleAndPermissions/actions";
import { findAccessKeysForPermissions } from "../../utils/converter";
import { LoadingButton } from "@mui/lab";

const SettingForm = (props: any) => {
  const [form] = Form.useForm();
  const [selectedPermission, setSelectedPermission] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const { auth, roleAndPermission } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const initializeFormValues = () => {
    form.setFieldsValue({
      name: props?.dataToUpdate?.role || "",
      permissions: props?.dataToUpdate?.permission || "",
    });
  };
  useEffect(() => {
    initializeFormValues();
  }, [props.dataToUpdate]);

  const onFinish = async (values: any) => {
    const payload = { ...values, access: targetKeys };
    if (selectedPermission?.length < 1) {
      return notification.error({ message: "Permission is Required" });
    }
    if (targetKeys?.length < 1) {
      return notification.error({ message: "Access is Required" });
    }

    if (auth?.token) {
      if (props?.dataToUpdate) {
        await updateRoleAction(auth?.token, props?.dataToUpdate?.key, {
          payload,
        })(dispatch);
      } else {
        await createRoleAction(auth?.token, payload)(dispatch);
      }
    }
    form.resetFields();
    props?.onCancel();
  };

  const allPermissions = roleAndPermission?.all?.data;
  const options = allPermissions
    ? allPermissions.map((permission: any) => ({
        value: permission?._id,
        label: permission?.name,
      }))
    : [];

  const handleChangePermission = (value: string[]) => {
    setSelectedPermission(value);
  };
  const allAccessKeys = findAccessKeysForPermissions(
    selectedPermission,
    roleAndPermission
  );

  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        style={{ maxWidth: "100%", margin: "30px" }}
      >
        <Form.Item
          name="name"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Role
            </span>
          }
          className="w-full"
          rules={[
            {
              required: true,
              message: "Role is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="pl-4 h-[42px] w-[100%] " />
        </Form.Item>
        <Form.Item
          name="permissions"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Permissions
            </span>
          }
          className="w-full"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            onChange={handleChangePermission}
            options={options}
          />
        </Form.Item>
        <h1 className="text-[#030229] p-1 text-sm font-normal">Access</h1>
        <DualListBox
          allAccessKeys={
            props?.dataToUpdate ? props?.dataToUpdate?.access : allAccessKeys
          }
          setTargetKeys={setTargetKeys}
          targetKeys={targetKeys}
        />
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              width: "20%",
              mt: "10px",
              textTransform: "none",
              ml: "22rem",
            }}
            loading={roleAndPermission?.isFetching}
          >
            {props?.dataToUpdate ? "Update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </>
  );
};
export default SettingForm;
