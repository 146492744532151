import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllPackageTrackService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/trPackage${query}`, token);
};


export const getAllTrackActivitiesService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/trActivity`, token);
};



