import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { notification, Form } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import {
  getOneSubsOrderAction,
  submitSubOrdersAction,
  updateSubsorderStatusAction,
} from "../../../../store/subscription/order/actions";
import ComponentToPrint from "./ComponentToPrint ";
import { LoadingButton } from "@mui/lab";
import ReactToPrint from "react-to-print";
import { updateSubsDeliveryNoteAction } from "../../../../store/subscription/deliverynote/actions";

const SubscriptionPicklistDetails = (props: any) => {
  const [form] = Form.useForm();
  const componentRef = useRef(null);
  const { orderId } = useParams();
  const { auth, orders, deliverynote } = useSelector((state: any) => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [delivererDetailed, setDelivererDetails] = useState<any>(null);
  // orders?.selected?.account?.deliveryNote?.deliverer;
  const delivererNoteId = orders?.selected?.account?.deliveryNote?._id;
  const delivererDetails = orders?.selected?.account?.deliveryNote?.deliverer;

  useEffect(() => {
    if (orderId && auth?.token) {
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  }, [orderId, auth.token, dispatch]);

  useEffect(() => {
    setDelivererDetails(deliverynote?.singleDeliveryNote?.deliverer);
  }, [deliverynote?.singleDeliveryNote]);

  const goBack = () => {
    navigate(-1);
  };

  const handleAccept = async () => {
    if (!delivererDetails) {
      return notification.error({
        message: "Please Add Deliverer Details",
      });
    }
    try {
      if (orderId && delivererNoteId && auth?.token) {
        await updateSubsorderStatusAction(
          orderId,
          { status: "in-transit" },
          auth.token
        )(dispatch);
        await updateSubsDeliveryNoteAction(
          delivererNoteId,
          { status: "in-transit" },
          auth.token
        )(dispatch);
        // goBack();
        navigate("/subscription/DeliveryNote");
      }
    } catch (error) {
      notification.error({ message: `${error}` });
    }
  };

  const handleCancel = async () => {
    if (auth?.token) {
      const response = await submitSubOrdersAction(
        orderId as string,
        { status: "created" },
        auth?.token
      )(dispatch);
      if (response) {
        navigate(`/subscription/order/${orderId}`);
      }
    }
  };

  return (
    <div className="text-black py-6 relative">
      <div className="flex flex-row items-center text-[#0F0F47] pb-4">
        <DetailsHeaderActionBar
          pageName={"pick list"}
          goBack={goBack}
          title={orders?.selected?.account?.deliveryNote?.SDNID}
        />
      </div>
      {/* <ScrollableFrame hidePagination> */}
      <Stack
        spacing={3}
        className="flex-grow flex flex-col justify-between bg-white scrollbar-hide "
      >
        <ComponentToPrint ref={componentRef} hasAccount={false} />

        <div className="text-black flex items-center justify-between px-4 pb-2">
          <LoadingButton
            variant="contained"
            color="error"
            loading={orders?.createOderIsFetching}
            sx={{
              bgcolor: orders?.isFetching ? "#E5E5E5" : "#F1595C",
              width: "112px",
              color: "#fff",
              textTransform: "none",
            }}
            onClick={handleCancel}
          >
            Cancel
          </LoadingButton>
          <div className="flex items-center space-x-2 px-6">
            <ReactToPrint
              trigger={() => (
                <LoadingButton
                  variant="contained"
                  loading={orders?.isFetching}
                  // onClick={handleAccept}
                  sx={{
                    bgcolor: orders?.isFetching ? "#E5E5E5" : "#4C39D4",
                    width: "112px",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  Print
                </LoadingButton>
              )}
              content={() => componentRef.current}
              copyStyles={true}
            />
            <LoadingButton
              variant="contained"
              color="success"
              loading={orders?.isFetching}
              onClick={handleAccept}
              sx={{
                bgcolor: orders?.isFetching ? "#E5E5E5" : "#0FA958",
                width: "112px",
                color: "#fff",
                textTransform: "none",
              }}
              disabled={
                orders?.selected?.list?.at(0)?.deliveryNoteItem?.length === 0
              }
            >
              Accept
            </LoadingButton>
          </div>
        </div>
      </Stack>
      {/* </ScrollableFrame> */}
    </div>
  );
};
export default SubscriptionPicklistDetails;
