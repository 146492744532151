import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { getAgentEarningsAction } from "../../../../store/kpi/actions";
import { useParams } from "react-router-dom";

const AgentDateRange: React.FC = () => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };
  const date = new Date(selectedDateRange?.startDate);
  const dateTwo = new Date(selectedDateRange?.endDate);

  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "T" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2) +
    ".000Z";
  const formattedDateTwo =
    dateTwo.getFullYear() +
    "-" +
    ("0" + (dateTwo.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateTwo.getDate()).slice(-2) +
    "T" +
    ("0" + dateTwo.getHours()).slice(-2) +
    ":" +
    ("0" + dateTwo.getMinutes()).slice(-2) +
    ":" +
    ("0" + dateTwo.getSeconds()).slice(-2) +
    ".000Z";

  useEffect(() => {
    if (auth?.token) {
      getAgentEarningsAction(
        auth?.token,
        `?date1=${formattedDate.split("T")[0]}&date2=${
          formattedDateTwo.split("T")[0]
        }&agentId=${id}`
      )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    formattedDate,
    formattedDateTwo,
    selectedDateRange,
    id,
  ]);

  return (
    <DateRangePicker
      onChange={handleSelect}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={[selectedDateRange]}
      direction="horizontal"
      locale={enUS}
    />
  );
};

export default AgentDateRange;
