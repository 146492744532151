import React from "react";
import WareHouseOutGridCard from "./WareHouseOutGridCard";

const App = (props: any) => {
  return props?.data?.map((d: any) => {
    return <WareHouseOutGridCard data={d} />;
  });
};

export default App;
