import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  getOneSubsOrderAction,
  submitSubOrdersAction,
} from "../../../../store/subscription/order/actions";
import { Table, Tag, Skeleton, Spin, Space } from "antd";
import { searchValue } from "../../../../utils/setColor";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { LoadingButton } from "@mui/lab";
import { LoadingOutlined } from "@ant-design/icons";
import { addMonthsToDate } from "../../../../utils/converter";
import "./table.css";
const { Column, ColumnGroup } = Table;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const SubscriptionDetails = (props: any) => {
  const { auth, orders } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const order = orders.selected;
  useEffect(() => {
    if (orderId && auth?.token) {
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  }, [orderId, auth.token, dispatch]);

  const goBack = async () => {
    navigate(-1);
  };

  const formData = orders?.selected?.list?.flatMap((el: any) => {
    return {
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      qnty: el?.quantity,
      specification: `${el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: `${el?.product?.prices.reduce(
        (sum: any, price: any) => sum + price.value,
        0
      )}`,
      data: el,
      duration: `${orders?.selected?.account?.duration ?? "N/A"} Months`,
      intallmentType: orders?.selected?.account?.type,
      intallmentAmount: el?.product?.subscriptionPlan
        ?.filter(
          (plan: any) => plan.duration === orders?.selected?.account?.duration
        )
        ?.map((el: any) => el?.monthlyInstalment),
    };
  });

  const handleAccept = async () => {
    if (auth?.token) {
      const res = await submitSubOrdersAction(
        orderId as string,
        { status: "pick-list" },
        auth?.token
      )(dispatch);
      if (res) {
        navigate(`/subscription/picklist/${orderId}`);
      }
      // await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
    }
  };

  const handleCancel = async () => {
    if (auth?.token) {
      await submitSubOrdersAction(
        orderId as string,
        { status: "draft" },
        auth?.token
      )(dispatch);
      await goBack();
      // await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
    }
  };

  return (
    <div className="text-black  py-6 scrollbar-hide overflow-y-auto h-screen">
      {orders.newOrderIsFetching ? (
        <Spin indicator={antIcon} />
      ) : (
        <DetailsHeaderActionBar
          pageName="Subscription"
          title={`Order | ${order?.createdBy?.names}`}
          goBack={goBack}
        />
      )}
      <Stack spacing={3} className="bg-white rounded pb-10 ">
        <div className="text-black flex flex-wrap justify-between px-6">
          <div className="pt-4">
            <b>
              <h3>Client Details</h3>
            </b>
            <div className="text-[#0F0F47] flex items-center">
              <p className="pr-4">Names:</p>
              <span>
                <b>{order?.account?.customerDetails?.name}</b>
              </span>
            </div>
            <div className="text-[#0F0F47] flex items-center">
              <p className="pr-5">Phone:</p>
              <span>
                <b>{order?.account?.customerDetails?.tel}</b>
              </span>
            </div>
            <div className="text-[#0F0F47] flex items-center">
              <p className="pr-2">Country:</p>
              <span>
                <b>{order?.account?.customerDetails?.country}</b>
              </span>
            </div>
            <div className="text-[#0F0F47]">
              Address: <b>{order?.account?.customerDetails?.address}</b>
            </div>
          </div>

          <div className="text-black">
            <div className="pt-4">
              <div className="pb-2 flex flex-row justify-end">
                <Tag
                  color={searchValue(order?.status)}
                  className="text-[15px] rounded-md capitalize px-6 py-1"
                >
                  {order?.status}
                </Tag>
              </div>

              <div className="pb-2 flex flex-row items-center gap-2">
                <p>Order Date: </p>
                {order?.createdAt?.slice(0, 10)}
              </div>
              {order?.account?.duration && (
                <div className="pb-2 flex flex-row justify-end gap-2">
                  <p>Due Date: </p>
                  {addMonthsToDate(
                    order?.createdAt?.slice(0, 10),
                    order?.account?.duration
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <center>
            <h3 className="text-[#0F0F47] pb-4">
              Purchase Order{" "}
              {!orders?.isFetching && (
                <span className="text-blue-500">{`#${order?.SOID}`}</span>
              )}
            </h3>
          </center>
          <div className="table w-full px-6">
            <Table
              dataSource={formData}
              pagination={false}
              loading={orders.newOrderIsFetching}
            >
              <ColumnGroup title="Type">
                <Column title="" dataIndex="type" key="type" />
              </ColumnGroup>
              <ColumnGroup title="Brand">
                <Column title="" dataIndex="brand" key="brand" />
              </ColumnGroup>
              <ColumnGroup title="Model">
                <Column title="" dataIndex="model" key="model" />
              </ColumnGroup>
              <ColumnGroup title="Specification">
                <Column
                  title=""
                  dataIndex="specification"
                  key="specification"
                  width={200}
                />
              </ColumnGroup>
              <ColumnGroup title="Quantity">
                <Column title="" dataIndex="qnty" key="qnty" />
              </ColumnGroup>

              <ColumnGroup title="Installment">
                <Column
                  title="Type"
                  dataIndex="intallmentType"
                  key="intallmentType"
                  render={(text: any) => <p className="uppercase">{text}</p>}
                />
                <Column title="Duration" dataIndex="duration" key="duration" />
                <Column
                  title="Amount Per Month"
                  dataIndex="intallmentAmount"
                  key="intallmentAmount"
                  render={(text: any) => (
                    <p className="uppercase">{text?.toLocaleString()}</p>
                  )}
                />
              </ColumnGroup>
              <ColumnGroup title="Expected Total Amount">
                <Column
                  title=""
                  dataIndex="total"
                  key="total"
                  render={(_text: any, record: any) => (
                    <p className="uppercase">
                      {`${(
                        parseInt(record?.qnty) *
                        Math.floor(
                          parseInt(record?.duration) * record?.intallmentAmount
                        )
                      )?.toLocaleString()} RWF`}
                    </p>
                  )}
                />
              </ColumnGroup>
            </Table>
          </div>

          <div className="flex  pt-10 text-black gap-10 px-6">
            <div>ApprovedBy</div>
            {order?.createdBy?.names}
          </div>
        </div>

        <div className="text-black flex flex-wrap justify-between px-6 pt-20">
          <div className="">
            <LoadingButton
              variant="contained"
              color="error"
              loading={orders?.createOderIsFetching}
              sx={{
                bgcolor: orders?.isFetching ? "#E5E5E5" : "#F1595C",
                width: "112px",
                color: "#fff",
                textTransform: "none",
              }}
              onClick={handleCancel}
            >
              Cancel
            </LoadingButton>
          </div>
          <div className="flex flex-wrap gap-6">
            <LoadingButton
              loading={orders?.isFetching}
              variant="contained"
              style={{
                width: "112px",
                textTransform: "none",
              }}
              className="rounded-md py-1 font-poppins "
            >
              Print
            </LoadingButton>
            {order?.status?.toLowerCase() !== "pick" && (
              <LoadingButton
                variant="contained"
                color="success"
                sx={{
                  bgcolor: orders?.isFetching ? "#E5E5E5" : "#0FA958",
                  minWidth: 100,
                  textTransform: "none",
                }}
                onClick={handleAccept}
                loading={orders?.createOderIsFetching}
              >
                Accept
              </LoadingButton>
            )}
          </div>
        </div>
      </Stack>
    </div>
  );
};
export default SubscriptionDetails;
