import React, { useState } from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotesCard from "./components/NotesCard";
import "../../../../components/layout/sideNav.css";
const HistoryNote: React.FC = (props: any, index) => {
  const { account } = useSelector((state: any) => state);
  // const [isHovered, setIsHovered] = useState();
  console.log("account to history", account.selected.notes);

  return (
    <>
      {account?.selected?.notes.length === 0 ? (
        <Typography>
          <p>currently there is no notes, use above field to create note</p>
        </Typography>
      ) : (
        <NotesCard notes={account?.selected?.notes} />
      )}
    </>
  );
};

export default HistoryNote;
