const statusColor = [
  ["Cancelled", "#f50721"],
  ["Incomplete", "#f50721"],
  ["Verified-Incomplete", "#f50721"],
  ["Stored-Verified-Complete", "#795548"],
  ["Stocked", "#795548"],
  ["Review", "#795548"],
  ["In-Transit", "#ffa500"],
  ["Replied", "#ffa500"],
  ["Accepted", "#ffa500"],
  ["Sent", "#fed607"],
  ["Validated", "#ECBC4C"],
  ["Completed", "#0fa958"],
  ["Approved", "#0fa958"],
  ["Verified-Complete", "#0fa958"],
  ["Stored-Complete", "#0fa958"],
  ["Complete", "#0fa958"],
  ["Arrived", "#0FA958"],
  ["delivered", "#0FA958"],
  ["Pending", "#c0c0c0"],
  ["Draft", "#c0c0c0"],
  ["Finance", "#2196F3"],
  ["Received", "#2196F3"],
  ["Done", "#2196F3"],
  ["international", "#00BCD4"],
  ["local", "#795548"],
  ["Active", "#4CAF50"],
  ["In", "#0FA958"],
  ["Out", "#F19F1D"],
  ["Sold", "#F50721"],
  ["sold", "#F50721"],
  ["Paid", "#4CAF50"],
  ["Fully-In-Transit", "#4CAF50"],
  ["Partial-In-Transit", "#F19F1D"],
  ["Request", "#c0c0c0"],
  ["late", "#f50721"],
  ["B2B", "#fed607"],
  ["B2C", "#F19F1D"],
  ["failed", "#f50721"],
  ["successful", "#0fa958"],
  ["Locked", "#FA365980"],
  ["Released", "#2943D680"],
  ["Ready For Use", "#F19F1D"],
  ["Verified - Not Stored", "#c0c0c0"],
  ["swap", "#c0c0c0"],
];

export const searchValue = (value: string) => {
  for (let i = 0; i < statusColor.length; i++) {
    if (statusColor[i][0]?.toLowerCase() === value?.toLowerCase()) {
      return statusColor[i][1];
      //   break;
    }
  }
};

export const getColorByValue = (arr: any, value: any) => {
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i].value === value) {
      return arr[i].color;
    }
  }
  return null; // If the value is not found in the array, return null or any other appropriate value
};

const cardAccentColor = [
  ["Inventory", "#f9e8e8", "#dc7b7b"],
  ["Eligible", "#e6f2e2", "#92d268"],
  ["information", "#e1f0f5", "#6eb6d3"],
  ["back", "#f8f0e7", "#e6aa69"],
  ["CRB", "#eef0f8", "#647cee"],
];
export const searchCardBgColorValue = (value: string) => {
  for (let i = 0; i < cardAccentColor.length; i++) {
    if (value.toLowerCase().includes(cardAccentColor[i][0]?.toLowerCase())) {
      return cardAccentColor[i][1];
    }
  }
};

export const searchCardDeepBgColorValue = (value: string) => {
  for (let i = 0; i < cardAccentColor.length; i++) {
    if (value.toLowerCase().includes(cardAccentColor[i][0]?.toLowerCase())) {
      return cardAccentColor[i][2];
    }
  }
};
