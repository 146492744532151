import React from "react";
import ProductCardGrid from "./ProductCardGrid";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { product, channel } = useSelector((state: any) => state);
  return channel?.priceListItems?.data?.map((d: any) => (
    <ProductCardGrid
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
      brands={product?.attributes?.brands}
      onClick={props?.onClick}
    ></ProductCardGrid>
  ));
};

export default App;