import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Card } from "antd";
import "./overview.css";
import CustomPieChart from "./CustomPieChart";
const Subsdetails = () => {
  const card = [
    {
      title: "Customer",
      initial: 30,
      final: 10,
      total: "total",
      status: "active",
    },
    {
      title: "Subscription",
      initial: 30,
      final: 10,
      total: "total",
      status: "active",
    },
    {
      title: "Invoices",
      initial: 30,
      final: 10,
      total: "total",
      status: "active",
    },
  ];
  return (
    <div className="bg-[#fff] w-full p-8 rounded-md xmt-10">
      <div className=" flex justify-between ">
        <span className="text-[#0F0F47] font-bold text-2xl mb-3 opacity-80">
          Subscription Plan
        </span>
        <BiDotsHorizontalRounded />
      </div>
      <div className="flex m-5 gap-10">
        {card.map((data) => (
          <Card style={{ width: 300, height: 130 }}>
            <div>
              <h1 className="text-center text-[#0F0F47] font-bold text-xl mt-[-12px]">
                {data.title}
              </h1>
              <div className="flex gap-3 mt-2 ml-14">
                <div className="mr-2">
                  <p className="text-[#0F0F47] font-bold text-xl pt-[3.8px] opacity-70">
                    {data.initial}
                  </p>
                  <p className="text-[#0F0F47] font-[500] text-lg opacity-70 pt-[6px]">
                    {data.total}
                  </p>
                </div>
                <hr className="h-12 w-[2px] bg-black mt-3 opacity-70 " />
                <div className="ml-2">
                  <p className="text-[#0F0F47] font-bold text-2xl pt-[2px] opacity-70">
                    {data.final}
                  </p>
                  <p className="text-[#0F0F47] font-[500] text-lg opacity-70 pt-[3px]">
                    {data.status}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
      <div className="flex m-4 gap-4 w-[100%]">
        <div style={{ width: "54%" }}>
          <Card style={{ width: "100%" }}>
            <h1 className="text-center text-[#0F0F47] font-bold text-2xl opacity-80">
              active plan of users%
            </h1>
            <div className="ml-20 mt-28">
              <CustomPieChart />
            </div>
          </Card>
        </div>
        <Card style={{ width: "43%" }} className="p-5">
          <div style={{ width: "100%" }}>
            <div className="flex justify-between border border-[#0F0F471A] py-3 px-4 rounded-md mt-[-15px]">
              <div
                style={{
                  backgroundColor: "#0F0F47",
                  color: "white",
                  width: "179px",
                  padding: "4px, 16px, 4px, 16px",
                  borderRadius: "5px",
                }}
              >
                {" "}
                <p className="text-sm text-center px-5 py-2">
                  Monthly Recuring revenue
                </p>
              </div>
              <div>
                <h1 className="text-center text-[#0F0F47] font-[600] text-lg pt-3">
                  Rfw 500,000,000
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-between mt-3">
              {" "}
              <span className="text-sm font-medium pt-5 opacity-60">
                Average subscription cost
              </span>{" "}
              <span className="text-sm font-medium pt-4 opacity-60">
                500,000,00
              </span>
            </div>
            <div className="flex justify-between mb-1">
              <span className="text-sm font-medium pt-5 opacity-60 dark:text-white"></span>
              <span className="text-sm font-medium opacity-60 dark:text-white"></span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3 mb-3">
              <div
                className="bg-[#605BFF] h-3 rounded-full"
                style={{ width: "45%" }}
              ></div>
            </div>
            <div className="flex justify-between mb-1">
              <span className="text-sm font-medium pt-5 opacity-60 dark:text-white">
                Ratention Rate
              </span>
              <span className="text-sm font-medium opacity-80 dark:text-white">
                80%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-3 my-3">
              <div
                className="bg-[#605BFF] h-3 rounded-full"
                style={{ width: "80%" }}
              ></div>
            </div>
            <div className="flex justify-between mb-1">
              <span className="text-sm font-medium opacity-60 pt-5 dark:text-white">
                Chun Rate
              </span>
              <span className="text-sm font-medium opacity-80 dark:text-white">
                60%
              </span>
            </div>
            <div className="w-full bg-gray-200 my-5 rounded-full h-3 dark:bg-gray-700">
              <div
                className="bg-[#605BFF] h-3 rounded-full"
                style={{ width: "60%" }}
              ></div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
export default Subsdetails;
