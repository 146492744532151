import React, { useState, useEffect } from 'react';
import sunIcon from './sun.svg';
import { Link, useLocation } from 'react-router-dom';

const TimeComponent = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = time.toLocaleTimeString();
  const formattedDate = time.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
const {pathname}=useLocation()
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-center">
        <div className="flex flex-col ">
          <div className="flex justify-between text-lg font-light mb-2">
            <div>
              <img src={sunIcon} alt="Sun Icon" className="mr-2 h-12 w-10" />
            </div>
            <div className="text-gray-500 flex flex-col items-start">
              <div>{formattedTime}</div>
              <div className="text-gray-500 text-sm">Realtime Insight</div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-black flex flex-col items-start">
          <div className="text-lg">Today:</div>
          <div className="text-xl font-poppins">{formattedDate}</div>
        </div>
        <div className="mt-8">
          <div className={` ${pathname?.includes("attendance") ?"bg-[#615bff10] text-[#ffffffe0] ":"bg-[#605BFF] text-[#FFFFFF]  cursor-pointer"} py-3 rounded-md font-poppins w-[178px]`} >
            <Link to="/attendance" className={`${pathname?.includes("attendance") && "pointer-events-none"} `}>
            View Attendance
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeComponent;


