import { notification } from "antd";
import { myIntegrationActions } from ".";
import {
  createIntegrationService,
} from "./services";

export const createIntegrationAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myIntegrationActions.setIsFetching(true));
      const res = await createIntegrationService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myIntegrationActions.setNew(res));
        // dispatch(myIntegrationActions.setAll(resAll));
        dispatch(myIntegrationActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myIntegrationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

