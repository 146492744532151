import React, { useState } from "react";
import PayrollStatisticsGraph from "./PayrollStatisticsGraph";
import PaymentSatatus from "./PaymentStatus";
import { Card, Table, Tag, Input, DatePicker,DatePickerProps } from "antd";
import { Radio } from 'antd';
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { HiDotsVertical } from "react-icons/hi";
import CustomButton from "../../components/Buttons/CustomButton";
import { FiPlus } from "react-icons/fi";
import GeneralModal from "../../components/Modal/GeneralModal";
import AddPayrollForm from "../../components/Forms/AddPayrollForm";
dayjs.extend(customParseFormat);

const { Search } = Input;
const Payroll = (props: any) => {
  const sampleData = [
    {
      key: '1',
      name: 'Alice Mutuyimana',
      department: 'Dev',
      perfomance:"Good",
      email:"alice@samphone.africa",
      gross:"RWF 10,000",
      taxes:"-RWF 25,000",
      salary:"RWF 800,000",
      status:"UnPaid"
    },
    {
      key: '1',
      name: 'Jackson Mugisha',
      department: 'Dev',
      perfomance:"Good",
      email:"jack@samphone.africa",
      gross:"RWF 10,000",
      taxes:"-RWF 25,000",
      salary:"RWF 800,000",
      status:"Paid"
    },
    {
      key: '1',
      name: 'Alice Mutuyimana',
      department: 'Dev',
      perfomance:"Good",
      email:"alice@samphone.africa",
      gross:"RWF 10,000",
      taxes:"-RWF 25,000",
      salary:"RWF 800,000",
      status:"UnPaid"
    },
    {
      key: '1',
      name: 'Bernardine Bazubagira',
      department: 'Dev',
      perfomance:"Good",
      email:"bernardine@samphone.africa",
      gross:"RWF 10,000",
      taxes:"-RWF 25,000",
      salary:"RWF 800,000",
      status:"Paid"
    },
   
  ];
    const formattedDate = new Date().toISOString().slice(0, 10);
    const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
    const [selectedDate2, setSelectedDate2] = useState<any>(formattedDate);
    const onChange: DatePickerProps["onChange"] = (date, dateString) => {
      console.log(dateString);
      setSelectedDate(dateString);
    };
    const columns = [
      {
        title: 'Employees Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: 'email',
        dataIndex: 'email',
        key: 'date',
      },
      {
        title: 'Gross',
        dataIndex: 'gross',
        key: 'date',
      },
      {
        title: 'Taxes',
        dataIndex: 'taxes',
        key: 'checkIn',
      },
      {
        title: 'Net Salary',
        dataIndex: 'salary',
        key: 'checkOut',
      },
      {
        title: 'Perfomance',
        dataIndex: 'perfomance',
        key: 'workHours',
      },
      {
        title: 'Status',
        key: 'status',
        render: (text: any) => {
          return(
          <Tag color={text?.status==="Paid"? 'green' : 'volcano'} className="px-3 py-1 rounded-md w-16 text-center">
            {text?.status}
          </Tag>
        )}
      },
      {
        title: ' ',
        key: 'data',
        render: (text: any) => {
          return(
            <HiDotsVertical />
        )}
      },
    ];
  
    const onSearch = (value: string) => {
      console.log(value);
      // Implement search functionality if needed
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (data: any) => {
      // setSelectedData(data)
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  return (
    <div className="text-black p-5">
      <div className="flex justify-between">

      <h1 className="py-4">payroll</h1>
      <CustomButton
            title={"Add Payroll"}
            bgColor="[#605BFF]"
            textColor="white"
            icon={<FiPlus color="white" />}
            onClick={showModal}
          />
      </div>
      <GeneralModal
        openModal={isModalOpen}
        closeModal={handleOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center pt-2">
            Add Payroll
          </h1>
        }
        component={
          <div className="px-4 h-full w-full">
              <AddPayrollForm/>
          </div>
        }
        width={900}
      />
      <div className="xl:flex gap-5">

        <div className="bg-white rounded-md w-full xl:w-2/3">
          <PayrollStatisticsGraph />
        </div>
        <div className="bg-white rounded-md p-6 w-full xl:w-2/6 mt-5 xl:mt-0">
          <PaymentSatatus />
        </div>
      </div>
      
      <div className="bg-white rounded-md mt-5 p-3">
        <div className="flex flex-col lg:flex-row items-center justify-between pb-3 px-3 py-3">
          <h2 className="text-black">Employee Summary</h2>
          <div className="flex space-x-4 items-center mt-5 lg:mt-0">
            <div className="flex space-x-4 items-center">
              <Radio.Group defaultValue="all" className="flex space-x-4">
                <Radio value="all" className="ant-radio-button-wrapper-selected">
                  <span className="text-blue-500">All</span>
                </Radio>
                <Radio value="paid">
                  Paid
                </Radio>
                <Radio value="unpaid">
                  Unpaid
                </Radio>
                <Radio value="pending">
                  Pending
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex flex-row items-center gap-5 mt-5 lg:mt-0">
            <Search placeholder="input search text" onSearch={onSearch} enterButton />
            {/* <DepartmentFiltering /> */}
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange} />
          </div>
        </div>
        <div className="w-full overflow-x-auto">
        <Table columns={columns} dataSource={sampleData} rowKey="key" />
        </div>
      </div>
    </div>
  );
};

export default Payroll;

