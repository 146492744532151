import React from "react";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../../components/skeleton/CustomSkeleton";
type btnProps = {
  cardIcon?: any;
  value?: any;
  status?: any;
  percentage?: any;
};
const TopCards = ({ cardIcon, value, status, percentage }: btnProps) => {
  const { invoice } = useSelector((state: any) => state);
  return (
    <div>
      <div className="relative flex-grow shadow sm:min-w-[280px] bg-white rounded-lg  text-black h-[8rem] xmax-w-[330px]  mt-5 p-2 pt-9 justify-center xmb-9 pl-6">
        <div className="flex gap-8">
          <span className="w-[3.3rem] h-[3.3rem]">
            <img src={cardIcon} alt="subscription" />
          </span>
          <span className=" flex flex-col justify-center content-center ">
            <div className="text-[#000] font-bold text-2xl">
              {invoice.isFetching ? <CustomSkeleton /> : value}
            </div>
            <div className="text-[#030229] text-ellipsis font-thin text-base opacity-70">
              {status}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
export default TopCards;
