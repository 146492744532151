import react, { useEffect, useState } from "react";
import SettingButton from "../SettingButton";
import { Form, Select, Spin, Tabs } from "antd";
import * as bsIcon from "react-icons/bs";
import * as tbIcon from "react-icons/tb";
import UserGrid from "./UsersGrid";
import UsersTable from "./UsersTable";
import HeadersComponent from "../../../../components/HeaderComponent";
import AddNewEmployee from "../../../../components/forms/NewEmployeeForm";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllUsersAction,
  getUserStatisticsAction,
} from "../../../../store/setting/actions";
import SearchInput from "../../../../components/buttons/SearchButton";
import { TabsProps } from "antd/lib";
import Roles from "./Roles";
import { FaPlus } from "react-icons/fa";
import SettingModel from "../../../../components/Modals/settingModel";
import SettingForm from "../../../../components/forms/settingForm";
import UserDetailsPage from "./userDetailsPage";
import Permissions from "./Permisions";
import PermissionForm from "../../../../components/forms/permissionform";
import CreatePermissionModal from "../../../../components/Modals/CreatePermissionModal.tsx";
import mtnLogo from "../../../../assets/MTN.png";
import { ReactComponent as PeapleIcon } from "../../../../assets/icons/users.svg";
import { ReactComponent as AgentCount } from "../../../../assets/icons/Icon.svg";
import { ReactComponent as SamphoneAgent } from "../../../../assets/icons/Layer.svg";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { AllUsersHeader } from "../../../../components/csvHeaders/Headers";
import { getAllUsers_CSVAction } from "../../../../store/csvDownload/actions";
import EmployeeFilter from "../../../../components/filters/UserFilter";
import InactiveUsers from "./InactiveUsers";

const UserManagement = (props: any) => {
  const { auth, setting, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userDisplay, setUserDisplay] = useState("display as table");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [searchKey, setSearchKey] = useState();
  const [role, setRole] = useState("");
  const [tags, setTags] = useState("");
  const [storedKey, setStoredKey] = useState("1");
  const [roleModal, setRoleModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [openRoleModal, setOpenRoleModel] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);

  const handleOpenRoleModal = () => {
    setOpenRoleModel(true);
  };
  const handleOpenPermissionModel = () => {
    setOpenPermission(true);
  };
  const handleCloseRoleModal = () => {
    setRoleModal(false);
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };
  const RoleModelChanges = () => {
    setRoleModal(!roleModal);
  };
  const PermissionModelChanges = () => {
    setPermissionModal(!permissionModal);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChange = (key: any) => {
    setStoredKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <h1 className="text-lg">All Users</h1>,
      children:
        userDisplay === "display as table" ? (
          <UsersTable
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            role={role}
          />
        ) : (
          <UserGrid
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            role={role}
          />
        ),
    },
    {
      key: "2",
      label: <h1 className="text-lg">Inactive Users </h1>,
      children: <InactiveUsers userRole={role} />,
    },
    {
      key: "3",
      label: <h1 className="text-lg">Permissions </h1>,
      children: <Permissions />,
    },
    {
      key: "4",
      label: <h1 className="text-lg">Roles</h1>,
      children: <Roles />,
    },
  ];
  const handleSearch = () => {
    if (role && searchKey) {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&sk=${searchKey}&role=${role}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&sk=${searchKey}`
        )(dispatch);
    }
  };

  useEffect(() => {
    if (searchKey === "" && auth?.token) {
      getAllUsersAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}${role ? `&role=${role}` : ""}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, role, searchKey]);

  useEffect(() => {
    if (role) {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&role=${role}${
            tags && `&tag=${tags}`
          }`
        )(dispatch);
    } else {
      auth?.token &&
        getAllUsersAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, role, tags]);
  useEffect(() => {
    auth?.token && getUserStatisticsAction(auth?.user, "?")(dispatch);
  }, [auth?.token]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllUsers_CSVAction(
          auth?.token,
          `?limit=${setting?.all?.total}${role && `&role=${role}`}${
            tags && `&tag=${tags}`
          }${storedKey === "2" ? `&isActive=false` : ""}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvUserData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      userName: el?.names,
      email: el?.email,
      telephone: el?.phone,
      role: el?.role,
      status: el?.isActive === true ? "Active" : "Inactive",
    };
  });

  return (
    <>
      {layout?.viewUser ? (
        <UserDetailsPage />
      ) : (
        <>
          <header className="py-2 pl-2">
            {roleModal && (
              <SettingModel
                modalTittle={"Add new Role"}
                handleOpenRoleModal={handleOpenRoleModal}
                RoleModelChanges={RoleModelChanges}
                content={<SettingForm onCancel={handleCloseRoleModal} />}
              />
            )}
            {permissionModal && (
              <CreatePermissionModal
                modalTittle={"Add new Permission"}
                handleOpenPermissionModel={handleOpenPermissionModel}
                PermissionModelChanges={PermissionModelChanges}
                content={
                  <PermissionForm onCancel={handleClosePermissionModal} />
                }
              />
            )}
            <div className="flex justify-between relative">
              <div>
                <h1 className="text-[20px] text-[#0F0F47] font-[600]">
                  Team Members
                </h1>
                <p className="text-[#0F0F47B2] pt-2 font-normal text-sm">
                  You have {setting?.all?.total ?? 0} People in your Team.
                </p>
                <div className="flex gap-5 mt-2">
                  <div className="bg-white flex gap-5 w-[250px] p-4 rounded-md">
                    <div className="rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2">
                      <AgentCount />
                    </div>
                    <div>
                      <h1 className="text-[#030229] font-semibold text-lg">
                        {" "}
                        {setting.isFetching ? (
                          <Spin />
                        ) : (
                          setting?.userStatistics?.data?.totalCount ?? 0
                        )}
                      </h1>
                      <p className="text-[#030229] text-ellipsis font-thin text-base opacity-90">
                        Total Agents
                      </p>
                    </div>
                  </div>
                  <div className="bg-white flex gap-5 w-[250px] p-4 rounded-md">
                    <div className="rounded-full w-[3rem] h-[4rem] pl-2 pt-2 mt-2">
                      <SamphoneAgent />
                    </div>
                    <div>
                      <h1 className="text-[#030229] font-semibold text-lg">
                        {" "}
                        {setting.isFetching ? (
                          <Spin />
                        ) : (
                          setting?.userStatistics?.data?.samphone ?? 0
                        )}
                      </h1>
                      <p className="text-[#030229] text-ellipsis font-thin text-base opacity-90">
                        Samphone Agents
                      </p>
                    </div>
                  </div>
                  <div className="bg-white flex gap-5 w-[250px] p-4 rounded-md">
                    <div className="rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2">
                      <img src={mtnLogo} alt="*image" />
                    </div>
                    <div>
                      <h1 className="text-[#030229] font-semibold text-lg">
                        {" "}
                        {setting.isFetching ? (
                          <Spin />
                        ) : (
                          setting?.userStatistics?.data?.MTN ?? 0
                        )}
                      </h1>
                      <p className="text-[#030229] text-ellipsis font-thin text-base opacity-90">
                        MTN Agents
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-3 h-fit fixed right-12">
                {storedKey === "1" && (
                  <HeadersComponent
                    item="User"
                    modelTitle="Add New User"
                    isNotCollapse={true}
                    ModelComponent={<AddNewEmployee onCancel={handleCancel} />}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancel}
                    setIsGridView={setIsGridView}
                  />
                )}
                {storedKey === "3" && (
                  <div>
                    {" "}
                    <SettingButton
                      btnName="Add new Permission"
                      icon={<FaPlus />}
                      btnBgColor="[#605BFF]"
                      textColor="white"
                      onClick={PermissionModelChanges}
                    />
                  </div>
                )}
                {storedKey === "4" && (
                  <div>
                    {" "}
                    <SettingButton
                      btnName="Add new Role"
                      icon={<FaPlus />}
                      btnBgColor="[#605BFF]"
                      textColor="white"
                      onClick={RoleModelChanges}
                    />
                  </div>
                )}
                <div className="xmt-[.6rem] bg-[#605BFF] py-1  px-3 rounded">
                  <CSVExportButton
                    csvHeaders={AllUsersHeader}
                    csvData={csvUserData}
                    filename={`Users.csv`}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                layout?.isSideNavOpen ? "xl:flex " : "lg:flex"
              }  justify-between mt-6`}
            >
              <div className="flex gap-3">
                <EmployeeFilter
                  role={role}
                  setRole={setRole}
                  setTags={setTags}
                />
              </div>
              <div
                className={`flex gap-3 divide-x-2 divide-[#0F0F4740] mt-3 xl:mt-0`}
              >
                {/* <SearchBar
              style={styles.customShadow}
              onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
            /> */}
                <SearchInput
                  onSearch={handleSearch}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setSearchKey(e.target.value);
                  }}
                />
                {storedKey === "1" && (
                  <div className=" flex  gap-1  ml-1 pl-3">
                    <bsIcon.BsListUl
                      size={28}
                      color={`${
                        userDisplay === "display as table" ? "white" : "black"
                      } `}
                      className={`${
                        userDisplay === "display as table"
                          ? "bg-[#605BFF]"
                          : "bg-white"
                      }  p-1 rounded-[5px] cursor-pointer`}
                      onClick={() => setUserDisplay("display as table")}
                    />
                    <tbIcon.TbGridDots
                      size={23}
                      color={`${
                        userDisplay === "display as grid" ? "white" : "black"
                      } `}
                      className={`${
                        userDisplay === "display as grid"
                          ? "bg-[#605BFF]"
                          : "bg-white"
                      } p-1 rounded-sm mt-[2px] cursor-pointer`}
                      onClick={() => setUserDisplay("display as grid")}
                    />
                  </div>
                )}
              </div>
            </div>
          </header>
          <div className="mt-6">
            <Tabs items={items} onChange={onChange} />
          </div>
        </>
      )}
    </>
  );
};
export default UserManagement;
