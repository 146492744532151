export const productTypes = [
  {
    value: "smart-phone",
    label: "Smart Phone",
  },
  {
    value: "feature-phone",
    label: "Feature Phone",
  },
  {
    value: "accessory",
    label: "Accessory",
  },
];
export const productBrands = [
  {
    value: "Apple",
    label: "Apple",
  },
  {
    value: "samsung",
    label: "Samsung",
  },
  {
    value: "tecno",
    label: "Tecno",
  },
];
export const productModel = [
  {
    value: "i-phone-12",
    label: "iPhone 12",
  },
  {
    value: "i-phone-12-pro",
    label: "iPhone 12 Pro",
  },
  {
    value: "i-phone-13-pro-max",
    label: "iPhone 13 Pro Max",
  },
];
export const productStorage = [
  {
    value: "128GB",
    label: "128GB",
  },
  {
    value: "256GB",
    label: "256GB",
  },
  {
    value: "512GB",
    label: "512GB",
  },
];
