export const StockRequestHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specificatios", key: "specification" },
  { label: "Quantity Requested", key: "qtyReq" },
  { label: "Quantity Approved", key: "qtyAppreved" },
];

export const PurchaseOrdersHeaders = [
  { label: "Requisition Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Model", key: "items" },
  { label: "Quantity", key: "quantity" },
  { label: "Cost/Unit (RWF)", key: "cost" },
  { label: "Total Cost (RWF)", key: "totalCost" },
  { label: "Delivery Date", key: "deliveryDate" },
];

export const ShopDetailsHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const TransactionsHeaders = [
  { label: "Date", key: "paidOn" },
  // { label: "Paid On", key: "date_time" },
  { label: "Shop", key: "shop_name" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "barCode" },
  { label: "IMEI2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "New SIM Card", key: "newSimCard" },
  { label: "Device Retail Prices (RWF)", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Purchase Cost (RWF)", key: "puchaseCost" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Method Of Payment", key: "methodOfPayment" },
  { label: "Momo Transaction Id", key: "momoTransactionId" },
  { label: "Macye Macye Payment Type", key: "macyemacyeType" },
  { label: "Documents", key: "documents" },
  { label: "RRA ReceiptCode", key: "rraReceiptCode" },
  { label: "Status", key: "status" },
];

export const SaveTheChildrenReportHeaders = [
  { label: "Date", key: "date" },
  { label: "Done by", key: "doneBy" },
  { label: "Customer's Full Name", key: "customerName" },
  { label: "Customer Phone No", key: "customerTel" },
  { label: "Monthly Payment", key: "mPayment" },
  { label: "A/C Type", key: "accountType" },
  { label: "Total Amount Paid", key: "mPaid" },
  { label: "Amount Remain", key: "mPaidremain" },
  { label: "Status", key: "status" },
];

export const SalesReportHeader = [
  { label: "Date", key: "paidOn" },
  // { label: "Paid On", key: "date_time" },
  { label: "Shop", key: "shop" },
  { label: "District", key: "district" },
  { label: "Region", key: "region" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "IMEI 1", key: "barCode" },
  { label: "IMEI 2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "New SIM Card", key: "newSimCard" },
  { label: "Device Retail Prices (RWF)", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Method of Payment", key: "methodOfPayment" },
  { label: "Macye Macye Payment Type", key: "macyemacyeType" },
  { label: "documents", key: "documents" },
  { label: "Transaction Id", key: "transactionId" },
  { label: "RRA Receipt Code", key: "rraCode" },
  { label: "Status", key: "status" },
];

export const WarehouseHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "In Shop", key: "inShop" },
  { label: "In Warehouse", key: "inWarehouse" },
  { label: "Sold", key: "sold" },
];

export const ProductDetailsHeaders = [
  { label: "Product", key: "product" },
  { label: "Shop", key: "shop" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const AllShopsDataHeaders = [
  { label: "Name", key: "name" },
  { label: "type", key: "type" },
  { label: "Location", key: "address" },
  { label: "Region", key: "region" },
];

export const NosalesHeaders = [
  { label: "Date", key: "date" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Price (RWF)", key: "prices" },
  { label: "Quantity", key: "quantity" },
  { label: "Shop", key: "shop" },
  { label: "Agent", key: "agent" },
  { label: "Customer", key: "customer" },
  { label: "Customer's Phone", key: "customerTel" },
  { label: "Reason", key: "comments" },
];

export const ProductsHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
];

export const ProductInfoStockHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Product", key: "product" },
  { label: "SerialNumber", key: "serialNumber" },
  { label: "Specification", key: "specification" },
  { label: "Station", key: "station" },
  { label: "Status", key: "status" },
];

export const ProductInfoPricesHeaders = [
  { label: "Channel", key: "channel" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Prices (RWF)", key: "prices" },
  { label: "Extended Warranty", key: "exWarranty" },
];

export const ProductInfoSuppliersHeaders = [
  { label: "Model", key: "model" },
  { label: "Supplier Names", key: "supplierName" },
  { label: "TIN", key: "tin" },
  { label: "Telephone", key: "telephone" },
  { label: "Email", key: "email" },
  { label: "Type", key: "type" },
  { label: "Address", key: "location" },
];

export const WarehouseInHeaders = [
  { label: "Date", key: "date" },
  { label: "Model", key: "model" },
  { label: "Quantity", key: "quantity" },
  { label: "Supplier", key: "supplier" },
  { label: "Supplier TIN", key: "supplierTIN" },
  { label: "Supplier Phone", key: "supplierPhone" },
  { label: "Supplier Email", key: "supplierEmail" },
  { label: "Shipping Type", key: "shippingTypeName" },
  { label: "Delivere Name", key: "delivererName" },
  { label: "Delivere Phone", key: "delivererPhone" },
];

export const PriceListHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Capacity", key: "storage" },
  { label: "Memory", key: "memory" },
  { label: "Price", key: "amount" },
];

export const PurchasedHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Quantity Received", key: "quantity" },
  { label: "Unit Price", key: "amount" },
  { label: "Total", key: "total" },
];

export const StockHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "Status", key: "status" },
];

export const ShopReportHeader = [
  { label: "Date", key: "date" },
  { label: "Shop", key: "shop" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "Opening Stock", key: "openingStock" },
  { label: "Closing Stock", key: "closingStock" },
];

export const InventoryTransferReportHeader = [
  { label: "Date", key: "date" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "From Shop", key: "fromShop" },
  { label: "To Shop", key: "toShop" },
  { label: "Transfered by (Names)", key: "transferedBy" },
];

export const SoldDeviceHeaders = [
  { label: "Date", key: "date" },
  { label: "Customer's names", key: "customer" },
  { label: "Customer's telephone", key: "custTel" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "IMEI 1", key: "imei1" },
  { label: "IMEI 1", key: "imei2" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
];

export const QuickbookHeader = [
  { label: "InvoiceNo", key: "invoiceNo" },
  { label: "Customer", key: "customer" },
  { label: "InvoiceDate", key: "invoiceDate" },
  { label: "DueDate", key: "dueDate" },
  { label: "Terms", key: "terms" },
  { label: "Location", key: "location" },
  { label: "Memo", key: "memo" },
  { label: "Item(Product/Service)", key: "item" },
  { label: "ItemDescription", key: "memo" },
  { label: "ItemQuantity", key: "itemQuantity" },
  { label: "ItemRate", key: "itemRate" },
  { label: "ItemAmount", key: "itemAmount" },
  { label: "ItemTaxCode", key: "itemTaxCode" },
  { label: "ItemTaxAmount", key: "itemTaxAmount" },
  { label: "Currency", key: "currency" },
  { label: "Service Date", key: "serviceDate" },
];

export const B2C_Account_Headers = [
  { label: "No", key: "no" },
  { label: "Date", key: "date" },
  { label: "Customer's Full Name", key: "name" },
  { label: "Customer Phone No", key: "tel" },
  { label: "Status", key: "status" },
];

export const AllUsersHeader = [
  { label: "Full Name", key: "userName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "telephone" },
  { label: "Role", key: "role" },
  { label: "Status", key: "status" },
];
export const EbmItemHeaders = [
  { label: "Item Code", key: "code" },
  { label: "Item Name", key: "name" },
  { label: "Origin", key: "country" },
  { label: "Item Type", key: "type" },
  { label: "Pack Unit", key: "park" },
  { label: "Qty Unit", key: "unit" },
  { label: "Purchase Price", key: "purchase" },
  { label: "Sale Price", key: "sale" },
  { label: "Beginning Stock", key: "stock" },
];

export const EbmSalesHeaders = [
  { label: "Invoice No.", key: "id" },
  { label: "Customer Name", key: "name" },
  { label: "Sale Date", key: "date" },
  { label: "Item Name", key: "item" },
  { label: "Quantity", key: "qauntity" },
  { label: "Unit Price	", key: "price" },
  { label: "VAT", key: "vat" },
  { label: "Total Amount", key: "amount" },
  { label: "Sales Amount", key: "sales" },
];
