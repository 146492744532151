import React, { useEffect, useState } from "react";
import { Modal, Table, Tag, Popconfirm, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../utils/setColor";
import RejectSubscriptionReqForm from "../forms/RejectSubscriptionReqForm";
import SubscriptionDrower from "../Modals/SubscriptionDrower";
import {
  acceptSubRequestAction,
  getAllSubRequestedAction,
} from "../../store/subscription/subscriptionRequest/actions";

const SubscriptionRequestTable = (props: any) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const { auth, subscriptionRequest } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = React.useState(false);
  const [requestId, setRequestId] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
  };

  const onClose = () => {
    setOpen(false);
  };
  const dataToDisplay = subscriptionRequest?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      date: el?.createdAt?.slice(0, 10),
      nid: el?.customer?.subscriptionInfo?.nationalIDDoc,
      shop: el?.shop?.name,
      channel: el?.shop?.channel?.name,
      agent: el?.createdBy?.names,
      imei: el?.list[0]?.warehouseProduct?.serialNumber,
      model: el?.list[0]?.priceProduct?.product?.model,
      plan: el?.subscriptionPlan,
      subscriptionType: el?.initialPaymentType?.split("_")?.join(" ") ?? "N/A",
      specification:
        el?.list[0]?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 10)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
      unpaidAmount: el?.list[0]?.payment?.amount?.toLocaleString(),
      status: el?.status,
      data: el,
    };
  });

  const showModal = (value: any) => {
    setOpenModel(true);
    setRequestId(value);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Amount to be paid",
      dataIndex: "unpaidAmount",
      key: "unpaidAmount",
    },
    {
      title: "Subscription Period",
      dataIndex: "plan",
      key: "plan",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text} Months`}</p>
      ),
    },
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text}`}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <div className="flex items-center space-x-1 cursor-pointer">
          {!["accepted", "rejected", "paid"]?.includes(record?.status) && (
            <div className="flex items-center space-x-1 cursor-pointer">
              <Popconfirm
                title="Accept Request"
                description="Are you sure to Accept this Subscription Request?"
                onConfirm={() =>
                  acceptSubRequestAction(auth?.token, record?.key, {
                    status: "accepted",
                  })(dispatch)
                }
                okText="Confirm"
                cancelText="Cancel"
                okButtonProps={{
                  loading: subscriptionRequest?.isFetching,
                  className: "bg-blue-500",
                }}
              >
                <Button
                  className="capitalize min-w-[50px] bg-green-500 text-white text-center border-none outline-none"
                  style={{ color: "white" }}
                >
                  Accept
                </Button>
              </Popconfirm>
              <Button
                className="capitalize min-w-[50px] text-center outline-none"
                danger
                onClick={() => showModal(record?.key)}
              >
                Reject
              </Button>
            </div>
          )}
          <Button
            className="capitalize min-w-[50px] text-center outline-none"
            onClick={() => {
              showDrawer(record);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const cancelModal = () => {
    setOpenModel(false);
    setRequestId("");
  };

  useEffect(() => {
    auth?.token && getAllSubRequestedAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  const scroll = { x: true as const };

  return (
    <>
      <div className="scrollbar-thin">
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          scroll={scroll}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
          loading={subscriptionRequest?.isFetching}
          className="scrollbar-hide"
        />
      </div>
      <Modal
        open={openModel}
        onCancel={cancelModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RejectSubscriptionReqForm
          onCancel={cancelModal}
          requestId={requestId}
        />
      </Modal>
      <SubscriptionDrower open={open} onClose={onClose} data={data} />
    </>
  );
};

export default SubscriptionRequestTable;
