import { Button, Card, Select, Checkbox, Form, Input, Tag } from "antd";
import { InputNumber } from "antd/lib";
import React, { useEffect, useRef, useState } from "react";
import {
  getMessageTamplateAction,
  releaseDeviceAction,
  reloadDeviceAction,
  sendMessageAction,
  unLockDeviceAction,
} from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import type { InputRef } from "antd";

const ReleaseDevices = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  const [tags, setTags] = useState<string[]>([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);
  const [inputValue, setInputValue] = useState<string>("");

  const onReleaseDevice = async (values?: any) => {
    const deviceUidArray = tags.length > 0 ? tags : [];
    auth?.token &&
      (await releaseDeviceAction(auth?.token, {
        ...values,
        deviceUid: deviceUidArray,
      })(dispatch));
    props?.onClose();
    setTags([]);
    form.resetFields();
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleTagClose = (tag: string) => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
  };

  const handleInputConfirm = () => {
    if (inputValue.trim() && tags.indexOf(inputValue.trim()) === -1) {
      setTags([...tags, inputValue.trim()]);
    }
    setInputVisible(false);
    setInputValue("");
  };




  const tagChild = tags.map((tag: any) => (
    <Tag key={tag} closable onClose={() => handleTagClose(tag)}>
      {tag}
    </Tag>
  ));

  return (
    <>
      <div>
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onReleaseDevice}
          autoComplete="off"
        >
          <div>
            <span className="text-[#030229] pl-1 text-sm font-normal pt-4">
              IMEI
            </span>
          </div>
          <Form.Item
            name="deviceUid"
            className="w-full"
            // label={
            //   <span className="text-[#030229] pl-1 text-sm font-normal pt-4">
            //     IMEI
            //   </span>
            // }
            // rules={[{ required: true, message: "Please enter device Id" }]}
          >
            <div style={{ marginBottom: 16 }}>
              <TweenOneGroup
                appear={false}
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: "from",
                  duration: 100,
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                onEnd={(e: any) => {
                  if (e.type === "appear" || e.type === "enter") {
                    (e.target as any).style = "display: inline-block";
                  }
                }}
              >
                {tagChild}
              </TweenOneGroup>
            </div>
            <Input
              onClick={showInput}
              ref={inputRef}
              type="text"
              size="small"
              className="rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-gray-500"
              value={inputValue}
              onChange={handleInputChange}
              // onBlur={handleInputConfirm}
              // onPressEnter={handleInputConfirm}
            />
            <Button className="mt-3" onClick={handleInputConfirm}>
              Add More
            </Button>
          </Form.Item>
          <div>
            <span className="text-[#030229] pl-1 text-sm font-normal pt-4">
              Reason
            </span>
          </div>
          <Form.Item
            name="reason"
            className="w-2/3- "
            rules={[{ required: true, message: "Please enter days" }]}
          >
            <Input className="rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-gray-500" />
          </Form.Item>

          <Form.Item className="w-full">
            <div className="flex gap-3 ml-[11rem] mt-10">
              <Button
                className="w-32 border border-red-400"
                onClick={props?.onClose}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="w-32  border border-[#605BFF]"
              >
                {vault?.isFetching ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default ReleaseDevices;
