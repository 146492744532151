import React, { useState } from "react";
import { EbmPage } from "../assets/data/EbmPages";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as Expandicons } from "../assets/images/icons/expand.svg";
import Typography from "@mui/material/Typography";
import "./sideBar.css";
import {
  getActiveEbm,
  storeActiveEbm,
  storeSelectedSubEbm,
} from "../../../utils/helper";
import "../../../components/layout/sideNav.css";
import { ebmLayoutActions } from "../store/layout";
import { useDispatch, useSelector } from "react-redux";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {},
}));

interface EbmProps {
  active?: any;
  setActive?: any;
  isHover?: any;
  setIsHover?: any;
}
const SideBar = ({ active, setActive, isHover, setIsHover }: EbmProps) => {
  const { ebmLayout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveEbm());
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleSideNav = () => {
    dispatch(ebmLayoutActions.setIsSideNavOpen(!isHover));
  };
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    shadow: {
      boxShadow: "0px 2px 4px 0px rgba(3, 2, 41, 0.10)",
      transition: "all 0.6s ease-in-out",
    },
  };
  return (
    <>
      <div
        className="sBar overflow-hidden"
        style={styles.shadow}
        onMouseEnter={() => (setIsHover(true), handleSideNav())}
        onMouseLeave={() => (setIsHover(false), handleSideNav())}
      >
        <ul className="font-medium text-base text-[#030229B2] ">
          {EbmPage.map((ebm: any, index: number) => {
            return (
              <li
                className="flex pys-1 gap-2"
                key={ebm.id}
                onClick={() => {
                  storeActiveEbm(ebm.id);
                  setSelectedTabIndex(ebm.id);
                  localStorage.setItem("activeSideEbm", ebm.id);
                }}
              >
                {ebm.dropDown ? (
                  <Accordion
                    expanded={expanded === ebm.id}
                    onChange={handleChange(ebm.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="w-full"
                    >
                      <Typography
                        className={`flex gap-1 text-[#030229CC] text-base`}
                      >
                        <b
                          className={` pt-1 pr-4 ${selectedTabIndex === ebm.id
                            ? "fill-[#2943D6] ebmIcon"
                            : "fill-[#0F0F47]"
                            }`}
                        >
                          {ebm.icon}
                        </b>{" "}
                        <span
                          className={`pt-1 text-base pr-4 ${selectedTabIndex === ebm?.id
                            ? "text-[#2943D6] "
                            : "text-[#030229CC]"
                            }`}
                        >
                          {ebm.title}
                        </span>{" "}
                      </Typography>
                    </AccordionSummary>
                    {isHover && (
                      <MuiAccordionDetails>
                        <Typography className="">
                          <ul className="">
                            {ebm.dropDown.map((item: any) => {
                              return (
                                <div className="flex">
                                  <div className="ml-3">
                                    <Expandicons />
                                  </div>
                                  <li
                                    key={item?.menu}
                                    className={`text-[#030229B2] cursor-pointer font-medium text-base  relative  ${
                                      localStorage.getItem("selectedSubEbm") ===
                                      item.menu
                                        ? "text-[#2943D6] "
                                        : ""
                                    }`}
                                    onClick={() => {
                                      const selectedSubEbm = item.menu;
                                      storeSelectedSubEbm(selectedSubEbm);
                                      setActive(item.activeTab);
                                      localStorage.setItem(
                                        "activeNav",
                                        item.activeTab
                                      );
                                    }}
                                  >
                                    <span> {item.menu}</span>{" "}
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </Typography>
                      </MuiAccordionDetails>
                    )}
                  </Accordion>
                ) : (
                  <div
                    className="flex gap-1 ml-4 cursor-pointer"
                    onClick={() => setActive(ebm.activeTab)}
                  >
                    <b
                      className={`text-bsase pt-1 pr-4  ${selectedTabIndex === ebm.id
                        ? "fill-[#2943D6] ebmIcon "
                        : "fill-[#0F0F47] ebmIconsx"
                        }`}
                    >
                      {ebm.icon}
                    </b>{" "}
                    <span
                      className={`pt-1  ${
                        selectedTabIndex === ebm.id
                          ? "text-[#2943D6]"
                          : "text-[#030229B2]"
                      }`}
                    >
                      {ebm.title}
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
