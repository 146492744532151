import { Table } from "antd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import {
  calculatePercentageChange,
  convertObjectToArray,
} from "../../utils/converter";

const data01 = [{ value: 1 }];
const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
];

const columns = [
  {
    title: "Shop",
    dataIndex: "shop",
    key: "shop",
  },

  {
    title: "Price (RWF)",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Number of Requests",
    dataIndex: "noRequest",
    key: "noRequest",
  },
];

const IkosoraNoSale = () => {
  const { dashboard } = useSelector((state: any) => state);
  const currentTotal =
    dashboard?.nosaleSpecificBrandStats?.data?.current?.totalAmount ?? 0;
  const comparedTotal =
    dashboard?.nosaleSpecificBrandStats?.data?.compared?.totalAmount ?? 0;

  const percentage = calculatePercentageChange(currentTotal, comparedTotal);

  const formData =
    dashboard?.nosaleSpecificBrandStats?.data?.current?.shops?.map((d: any) => {
      return {
        shop: d?.shopName,
        price: d?.totalAmount?.toLocaleString(),
        noRequest: d?.totalQuantity,
      };
    });

  return (
    <div className="relative w-full mt-4 2xl:mt-0 xmin-h-[100px] xl:h-[470px] bg-white p-3  rounded-md border border-gray-300">
      <div className="flex space-x-4">
        <p className="text-gray-400 capitalize">No Sales</p>
      </div>
      <p className="text-2xl font-semibold">
        {`RWF ${currentTotal?.toLocaleString() ?? "0"}`}
      </p>
      <p className="text-gray-400 capitalize xmb-2">{`RWF ${
        comparedTotal?.toLocaleString() ?? "0"
      }`}</p>
      <div
        className={` ${
          percentage?.type === "increase"
            ? "bg-green-200"
            : percentage?.type === "decrease"
            ? "bg-red-200"
            : "bg-blue-200"
        } absolute top-2 right-2 rounded-lg px-3 py-1`}
      >
        <span
          className={`${
            percentage?.type === "increase"
              ? "text-green-500"
              : percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          }`}
        >
          {percentage?.type === "increase" ? (
            <ArrowUpwardIcon />
          ) : percentage?.type === "decrease" ? (
            <ArrowDownwardIcon />
          ) : (
            ""
          )}

          {`${percentage?.percentage}%`}
        </span>
      </div>
      <div className="mt-4">
        <div className="pb-2">
          <span className="mr-4 ">SC: Service Center</span>
          <span className="block mt-2 sm:inline-block sm:mt-0">
            CS: Connect Shop
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={formData}
          pagination={false}
          scroll={{ y: 300 }}
          rowClassName={(record, index) => {
            if (index === formData.length - 1) {
              return "x!border-b !border-dashed";
            } else if (index === 0) {
              return "dashed-top-border";
            }
            return "";
          }}
        />
      </div>
    </div>
  );
};

export default IkosoraNoSale;
