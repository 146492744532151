import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Customer-Library",
  initialState: {
    isFetching: false,
    allIsFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    topCustomer: null,
    allCustomers: null,
    selectByDate: null,
    updated: null,
    query: null,
    searchResult: null,
    purchased: null,
    customerRepairs: null,
    customerSubscription: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setTopcustomer(state, action) {
      state.topCustomer = action.payload;
    },
    setAllcustomer(state, action) {
      state.allCustomers = action.payload;
    },
    setSelectByDate(state, action) {
      state.selectByDate = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllIsFetching(state, action) {
      state.allIsFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setPurchased(state, action) {
      state.purchased = action.payload;
    },
    setCustomerRepair(state, action) {
      state.customerRepairs = action.payload;
    },
    setCustomerSubscription(state, action) {
      state.customerSubscription = action.payload;
    },
  },
});

export const myCustomerActions = mySlice.actions;

export default mySlice.reducer;
