import React from 'react';
import { Button, Card, Tag, Avatar, List } from 'antd';

const ApplicantsComponent = () => {
  const applicants = [
    { name: 'John Doe', position: 'Product Designer', timeAgo: '5 minutes ago' },
    { name: 'Jane Smith', position: 'Mobile Application Developer', timeAgo: '10 minutes ago' },
    { name: 'Alex Johnson', position: 'Front-end Engineer', timeAgo: '15 minutes ago' },
    { name: 'Chris Brown', position: 'Back-end Engineer', timeAgo: '20 minutes ago' },
  ];

  return (
    <div className='w-[100%] lg:w-[50%]  bg-white rounded-md'>
      <div className='flex flex-row justify-between px-3 py-4'>
        <h2 className="text-lg font-poppins mb-4 text-[#030229]">Applicants</h2>
        <div className="mb-4 bg-white rounded-md text-[#605BFF] text-md shadow-sm">
          <span className='px-2 py-2 text-xs'>See more</span>
        </div>
      </div>

      <List
        className='px-3'
        itemLayout="horizontal"
        dataSource={applicants}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
              title={`${item.name}${``}${item.position}`}
              description={item.timeAgo}
            />
            <div className="mb-4 rounded-md bg-[#605BFF1A] px-3 py-2 text-[#605BFF] text-md shadow-sm cursor-pointer">
              <span className=' text-xs'>Applyied</span>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ApplicantsComponent;


