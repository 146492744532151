import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AgentCard from "../../../components/grids/AgentCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentAction } from "../../../store/channel/actions";
import { Box, Skeleton } from "@mui/material";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { myEmployeeActions } from "../../../store/setting/actions";
import { myKPIActions } from "../../../store/kpi";

const Agents = () => {
  /* This state section is For Paginations */
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [searchKey, setSearchKey] = React.useState();

  const { auth, channel, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token &&
      channel?.selected?._id &&
      getAllAgentAction(
        auth?.token,
        `channel=${channel?.selected?._id}&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth?.token, channel?.selected?._id, page, limit, dispatch]);

  React.useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        channel?.selected?._id &&
        getAllAgentAction(
          auth?.token,
          `channel=${channel?.selected?._id}&page=${page - 1}&limit=${limit}`
        )(dispatch);
    }
  }, [searchKey]);

  const data = channel?.allAgent?.data?.map((el: any) => {
    return {
      id: el?.user?._id,
      channel: el.channel,
      fname: el?.user?.names,
      address: el?.user?.address,
      status: el?.user?.status,
      email: el?.user?.email,
      phone: el?.user?.phone,
      company: el?.user?.tag,
      shop: el?.assigned
        ?.filter((d: any) => d?.isActive === true)
        ?.map((data: any) => data?.shop?.name),
      dob: el?.user?.dob,
      nid: el?.user?.nid,
      picture: el?.user?.picture,
      startDate: el?.assigned[0]?.startDate,
      data: el,
    };
  });

  useEffect(() => {
    dispatch(myKPIActions.setAllEarning(null));
    dispatch(myKPIActions.setAll([]));
    dispatch(myEmployeeActions.setSelected(null));
  }, []);

  return (
    <>
      <div className="mt-5 text-black">
        <DetailsHeaderActionBar pageName="Agent" title=" " />
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.allAgent?.total}
          count={Math.ceil(channel?.allAgent?.total / limit)}
        >
          {channel?.isFetching && (
            <div
              className={`grid ${
                layout?.isSideNavOpen
                  ? "sm:grid-cols-1 lg:grid-cols-2"
                  : "sm:grid-cols-2 lg:grid-cols-3"
              } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
            >
              {Array.from({ length: 12 }).map(() => (
                <Box sx={{}}>
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={110} />
                </Box>
              ))}
            </div>
          )}
          {!channel?.isFetching && (
            <div
              className={`grid ${
                layout?.isSideNavOpen
                  ? "sm:grid-cols-1 lg:grid-cols-2"
                  : "sm:grid-cols-2 lg:grid-cols-3"
              } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
            >
              {data?.map((agent: any, _index: number) => (
                <AgentCard data={agent} />
              ))}
            </div>
          )}
        </ScrollableFrame>
      </div>
    </>
  );
};

export default Agents;
