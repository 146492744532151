// import { PieChart } from "react-chartkick";
import { Table } from "antd";
import { Legend, Pie, Cell, PieChart, ResponsiveContainer } from "recharts";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./piecesSold.style.css";
import { useSelector } from "react-redux";

// const data01 = [{ value: 100 }, { value: 300 }, { value: 100 }];
const data01 = [{ value: 1 }];
// const data02 = [
//   { name: "A1", value: 100, yesterday: 400, today: 210, percentage: 7 },
//   { name: "A2", value: 300, yesterday: 400, today: 210, percentage: 7 },
//   { name: "B1", value: 100, yesterday: 400, today: 210, percentage: 7 },
// ];

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (item: any) => {
      return (
        <div className="flex w-full xtruncate gap-1">
          <span
            className={`h-4 w-6 bg-[${item?.color}] rounded-sm`}
            style={{ backgroundColor: item?.color }}
          ></span>
          <span className=" text-xs truncate w-16">{item?.value}</span>
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "yesterday",
    key: "yesterday",
    render: (text: string) => (
      <div className="text-xs w-full truncate text-gray-400 ">{`${text} Devices`}</div>
    ),
  },
  {
    title: "",
    dataIndex: "today",
    key: "today",
    render: (text: string) => (
      <div className="dashed-border-celltruncate">{`${text} Devices`}</div>
    ),
  },
  {
    title: "",
    dataIndex: "percentage",
    key: "percentage",
    render: (text: string, record: any) => {
      return (
        <span
          className={`flex ${
            record?.percentage?.type === "increase"
              ? "text-green-500"
              : record?.percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          } text-sm`}
        >
          {record?.percentage?.type === "increase" ? (
            <ArrowUpwardIcon />
          ) : record?.percentage?.type === "decrease" ? (
            <ArrowDownwardIcon />
          ) : (
            ""
          )}{" "}
          {` ${record?.percentage?.percentage}%`}
        </span>
      );
    },
  },
];

const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
  "#2943D6",
  "#5A72F8",
  "#516AFA",
  "#ABB1D7",
];

const PiecesSoldPieChart: React.FC<{
  data: any;
  title: string;
  percentageChange: any;
}> = ({ data, title, percentageChange }) => {
  const { dashboard } = useSelector((state: any) => state);
  return (
    <div className="w-full lg:w-1/4 bg-white p-4 xmt-[43rem] md:mt-0 rounded-lg mb-5 lg:mb-0">
      <div className="flex justify-between items-center w-full">
        <h2 className="text-xl capitalize">{title}</h2>

        <div
          className={`${
            percentageChange?.type === "increase"
              ? "bg-green-200"
              : percentageChange?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-200"
          } rounded-lg px-3 py-1`}
        >
          <span
            className={`${
              percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } flex items-center py-0.5`}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}
            {`${percentageChange?.percentage}%`}
          </span>
        </div>
      </div>
      {/* <PieChart data={data} colors={colors} legend="bottom"/> */}
      <div className="h-[420px] xbg-red-300">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={200} height={200}>
            <Pie
              data={[]}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={60}
              fill="#BCBABA"
              // label
              // legend={false}
            />
            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              fill="#2943D6"
              label
            >
              {data?.map((entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Legend
              align="left"
              iconType="diamond"
              content={(props: any) => {
                const { payload } = props;
                const generateData = () => {
                  const data: any[] = [];
                  payload?.forEach((element: any) => {
                    data.push({
                      name: { value: element?.value, color: element?.color },
                      yesterday:
                        element?.payload?.payload?.yesterday?.toLocaleString(),
                      today: element?.payload?.payload?.today?.toLocaleString(),
                      percentage: element?.payload?.payload?.percentage,
                    });
                  });
                  return data;
                };
                const data = generateData();
                return (
                  <div className="">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      loading={dashboard?.isFetching}
                      scroll={{ y: 200 }}
                      className="piece_sold"
                      rowClassName={(record: any, index: number) => {
                        if (index === data.length - 1) {
                          return "x!border-b !border-dashed";
                        } else if (index === 0) {
                          return "dashed-top-border";
                        }
                        return "";
                      }}
                      showHeader={false}
                    />
                  </div>
                );
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div></div>
    </div>
  );
};

export default PiecesSoldPieChart;
