import { notification } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import {
  Divider,
  Image,
  Form,
  Input,
  Space,
  SelectProps,
  Select,
  InputRef,
  Row,
  Col,
} from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";

import {
  createProductAction,
  getAllAttributesAction,
  updateProductsAction,
} from "../../store/product/actions";
import CloseIcon from "@mui/icons-material/Close";
import { getAllOptionsAction } from "../../store/ebm/actions";

const { Option } = Select;

let indexBrand = 0;
let indexBrandType = 0;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App: React.FC<{ dataToUpdate: any; action: String; onCancel?: any }> = ({
  dataToUpdate,
  action,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [imageValue, setImageValue] = useState("");
  const [myImages, setMyImages] = useState<any[]>(
    dataToUpdate ? dataToUpdate?.images : []
  );
  const { product, auth, ebm } = useSelector((state: any) => state);
  const isUpdate = action === "update";
  const onFinish = async (values: any) => {
    // console.log(values,"ddddddddddddd")
    // const images = values?.pict?.map(
    //   (pic: any) => pic?.response?.data?.secure_url
    // );
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (!values.specs) {
      return notification.warning({
        message: "Please Add at least one Variant",
      });
    }

    if (true) {
      if (isUpdate) {
        // console.log("updating ....", values);
        console.log(dataToUpdate);
        auth?.token &&
          updateProductsAction(auth?.token, dataToUpdate._id, {
            brand: values.brand,
            model: values.model,
            type: values.type,
            specs: values.specs,
            ebm: {
              isrcApIcbYn: values.isrcApIcbYn,
              itemClsCd: values.itemClsCd,
              itemTyCd: values.itemTyCd,
              pkgUnitCd: values.pkgUnitCd,
              qtyUnitCd: values.qtyUnitCd,
              taxTyCd: values.taxTyCd,
              useYn: values.useYn,
            },
            images: [...images, ...myImages],
          })(dispatch);
      } else {
        // console.log("Received values of form: ", values);
        auth?.token &&
          createProductAction(auth?.token, {
            brand: values.brand,
            model: values.model,
            type: values.type,
            specs: values.specs,
            ebm: {
              isrcApIcbYn: values.isrcApIcbYn,
              itemClsCd: values.itemClsCd,
              itemTyCd: values.itemTyCd,
              pkgUnitCd: values.pkgUnitCd,
              qtyUnitCd: values.qtyUnitCd,
              taxTyCd: values.taxTyCd,
              useYn: values.useYn,
            },
            images: [...images, ...myImages],
          })(dispatch);
      }
    }
    form.resetFields();
    onCancel();
    console.log("data from form", values);
  };
  console.log("dataToUpdate", dataToUpdate);

  const colorOptions: SelectProps["options"] = [];
  const [items, setItems] = useState([""]);
  const [itemsType, setItemsType] = useState([""]);
  const [name, setName] = useState("");
  const [nameType, setNameType] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [qtyUnitFilter, setQtyUnitFilter] = useState([]);
  const [classCodeFilter, setClassCodeFilter] = useState([]);
  const [taxFilter, setTaxFilter] = useState([]);
  const [pkgFilter, setPkgFilter] = useState([]);
  const [itemTypeFilter, setItemTypeFilter] = useState([]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onNameChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameType(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addTypeItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItemsType([...itemsType, nameType || `New item ${indexBrandType++}`]);
    setNameType("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  // dispatch(myProductActions.setQuery(`?`));
  useEffect(() => {
    auth?.token && getAllAttributesAction(auth?.token, "?")(dispatch);
    product?.attributes &&
      setItems(
        product.attributes.brands.map(function (ite: any) {
          return ite["value"];
        })
      );
    product?.attributes &&
      setItemsType(
        product.attributes.types.map(function (ite: any) {
          return ite["value"];
        })
      );
  }, [auth]);

  const onSearch = () => {
    setMyImages([...myImages, imageValue]);
    setImageValue("");
  };

  const HandleDelete = (im: string) => {
    console.log("Image: ", im);
    console.log("Images", myImages);
    setMyImages(myImages.filter((img: string) => img !== im));
  };

  useEffect(() => {
    if (auth?.token) {
      getAllOptionsAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  type Option = {
    product?: {
      model?: string;
    };
    label?: string;
  };

  type SetFilteredOptions = React.Dispatch<
    React.SetStateAction<Option[] | any>
  >;

  const handleSearch = (
    value: string,
    data: any,
    filterFunction: (option: Option, value: string) => boolean,
    setFilteredOptions: SetFilteredOptions
  ) => {
    const filtered = data?.filter((option: any) =>
      filterFunction(option, value)
    );
    setFilteredOptions(filtered);
  };
  console.log("form prd", dataToUpdate);
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={dataToUpdate}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
      layout="vertical"
    >
      <div className="flex justify-between xmr-6">
        <div className="w-3/5">
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ width: 1000 }}>
              <Form.Item
                name="model"
                label="Product Model"
                // initialValue={dataToUpdate?.model}
                rules={[
                  {
                    required: true,
                    message: "Model is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 1000 }}>
              <Form.Item
                name="type"
                label="Product Type"
                rules={[
                  {
                    required: true,
                    message: "Please select type of the Product!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select/Add Type"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Add Type"
                          ref={inputRef}
                          value={nameType}
                          onChange={onNameChangeType}
                        />
                        <Button variant="contained" onClick={addTypeItem}>
                          {" "}
                          <PlusOutlined />
                        </Button>
                      </Space>
                    </>
                  )}
                  options={itemsType.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item
                name="brand"
                label="Product Brand"
                rules={[
                  {
                    required: true,
                    message: "Please select brand of the Product!",
                  },
                ]}
              >
                <Select
                  showSearch
                  //   style={{ width: 300 }}
                  placeholder="Select/Add Brand"
                  // defaultValue={dataToUpdate?.brand}
                  // value={dataToUpdate?.brand}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Add Brand"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                        />
                        <Button variant="contained" onClick={addItem}>
                          {" "}
                          <PlusOutlined />
                        </Button>
                      </Space>
                    </>
                  )}
                  options={items.map((item) => ({ label: item, value: item }))}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.List name="specs">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Stack
                        direction="column"
                        // justifyContent="space-between"
                        // alignItems="flex-start"
                        // spacing={0.5}
                      >
                        <div className="flex items-center space-x-4">
                          <Form.Item
                            {...restField}
                            name={[name, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing labe of varient",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Label"
                              style={{ width: "8rem" }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            className="mb-6"
                          />
                        </div>
                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          rules={[
                            { required: true, message: "Missing Values" },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "14rem" }}
                            placeholder="Values in form of Tags"
                            defaultValue={dataToUpdate?.specification}
                            options={colorOptions}
                          />
                        </Form.Item>
                      </Stack>
                    ))}
                    <Form.Item>
                      <Button
                        variant="contained"
                        onClick={() => add()}
                        startIcon={<PlusOutlined />}
                      >
                        Add Variant
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item label="Insert Picture Link">
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    value={imageValue}
                    onChange={(e: any) => setImageValue(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      onSearch();
                    }}
                  >
                    Submit
                  </Button>
                </Space.Compact>
              </Form.Item>
              <div className="flex flex-wrap gap-4">
                {myImages?.map((im: string) => (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid whiteSmoke",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100px",
                      height: "100px",
                      margin: "5px",
                    }}
                  >
                    <button
                      type="button"
                      className="absolute top-0 right-0 z-10"
                      onClick={() => HandleDelete(im)}
                    >
                      <CloseIcon />
                    </button>
                    <Image
                      src={im}
                      className="w-full h-full object-cover object-center"
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
        <div className="w-2/5">
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="itemTyCd" label="Item Type" hasFeedback>
                {ebm?.isFetching ? (
                  <Select loading={ebm?.isFetching} />
                ) : (
                  <Select
                    onSearch={(value: any) =>
                      handleSearch(
                        value,
                        ebm?.options?.data?.["Item Type"],
                        (option: any, val) =>
                          option?.label
                            ?.toLowerCase()
                            .includes(val.toLowerCase()),
                        setItemTypeFilter
                      )
                    }
                    showSearch
                    filterOption={false}
                    loading={ebm?.isFetching}
                    defaultValue={dataToUpdate?.ebm?.itemTyCd ?? ""}
                  >
                    {itemTypeFilter.length > 0
                      ? itemTypeFilter?.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option?.label}
                          </Select.Option>
                        ))
                      : ebm?.options?.data?.["Item Type"]?.map((d: any) => (
                          <Option key={d?.value} value={d?.value}>
                            {d?.label}
                          </Option>
                        ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="pkgUnitCd" label="Pkg Unit" hasFeedback>
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      ebm?.options?.data?.["Packing Unit"],
                      (option: any, val) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setPkgFilter
                    )
                  }
                  showSearch
                  filterOption={false}
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.pkgUnitCd ?? ""}
                >
                  {pkgFilter.length > 0
                    ? pkgFilter?.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option?.label}
                        </Select.Option>
                      ))
                    : ebm?.options?.data?.["Packing Unit"]?.map((d: any) => (
                        <Option key={d?.value} value={d?.value}>
                          {d?.label}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="qtyUnitCd" label="Qty Unit" hasFeedback>
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      ebm?.options?.data?.["Quantity Unit"],
                      (option: any, val) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setQtyUnitFilter
                    )
                  }
                  showSearch
                  filterOption={false}
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.qtyUnitCd ?? ""}
                >
                  {qtyUnitFilter.length > 0
                    ? qtyUnitFilter?.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option?.label}
                        </Select.Option>
                      ))
                    : ebm?.options?.data?.["Quantity Unit"]?.map((d: any) => (
                        <Option key={d?.value} value={d?.value}>
                          {d?.label}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="taxTyCd" label="Tax Type" hasFeedback>
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      ebm?.options?.data?.["Taxation Type"],
                      (option: any, val) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setTaxFilter
                    )
                  }
                  filterOption={false}
                  showSearch
                  className="capitalize"
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.taxTyCd ?? ""}
                >
                  {taxFilter.length > 0
                    ? taxFilter.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option?.label}
                        </Select.Option>
                      ))
                    : ebm?.options?.data?.["Taxation Type"]?.map((d: any) => (
                        <Option key={d?.value} value={d?.value}>
                          {d?.label}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="itemClsCd" label="Class Code" hasFeedback>
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      ebm?.options?.data?.itemClassList,
                      (option: any, val) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setClassCodeFilter
                    )
                  }
                  showSearch
                  filterOption={false}
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.itemClsCd ?? ""}
                >
                  {classCodeFilter.length > 0
                    ? classCodeFilter.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option?.label}
                        </Select.Option>
                      ))
                    : ebm?.options?.data?.itemClassList?.map((d: any) => (
                        <Option key={d?.value} value={d?.value}>
                          {d?.label}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: 400 }}>
              <Form.Item name="useYn" label="Use" hasFeedback>
                <Select
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.useYn ?? ""}
                >
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={24} style={{ width: 400 }}>
              <Form.Item
                name="isrcAplcbYn"
                label="Insurance applicable"
                hasFeedback
              >
                <Select
                  loading={ebm?.isFetching}
                  defaultValue={dataToUpdate?.ebm?.isrcAplcbYn ?? ""}
                >
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        </div>
      </div>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "75%" }}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              loading={product?.isFetching}
            >
              {isUpdate ? "Update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Stack>
      </Stack>
    </Form>
  );
};

export default App;
