import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Table, Progress, Typography, Select } from "antd";
import { useSelector } from "react-redux";
import { calculatePercentageChange } from "../../../utils/converter";
import { Checkbox } from "antd";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./checkbox.css";
import CsvDownloadButton from "../../buttons/CsvDownloadButton";
import { regions } from "../../../utils/regions";

const { Text } = Typography;
const { Option } = Select;

const QuantitySold: React.FC<{
  title: string;
  setCheckedValue: any;
  checkedValue: any;
  plainOptions: any;
  enteredDate: any;
  compareDate: any;
  setSelectQtyByShopRegion: any;
}> = ({
  title,
  setCheckedValue,
  checkedValue,
  plainOptions,
  enteredDate,
  compareDate,
  setSelectQtyByShopRegion,
}) => {
  const { dashboard, auth, layout } = useSelector((state: any) => state);

  const totalCurrentRevenue = dashboard?.quantitySoldStats?.data?.reduce(
    (total: any, item: any) => total + item.currentRevenue,
    0
  );

  const totalComparedRevenue = dashboard?.quantitySoldStats?.data?.reduce(
    (total: any, item: any) => total + item.comparedRevenue,
    0
  );

  const percentageChange = calculatePercentageChange(
    totalCurrentRevenue,
    totalComparedRevenue
  );

  const data = dashboard.quantitySoldStats?.data?.map((d: any) => {
    return {
      brand: d?.name,
      quantityYesterday: d?.comparedQuantity,
      quantityToday: d?.currentQuantity,
      revenue: d?.currentRevenue,
      revenueYesterday: d?.comparedRevenue,
      percentage: calculatePercentageChange(
        parseInt(d?.currentRevenue),
        parseInt(d?.comparedRevenue)
      ),
      perc:
        (d?.currentRevenue * 100) / (d?.currentRevenue + d?.comparedRevenue),
      dashPreview: layout?.dashboardPreview,
    };
  });

  const onChange = (e: any) => {
    const { value, checked } = e.target;
    setCheckedValue(checked ? value : "");
  };

  const capitalizedTitle =
    checkedValue?.charAt(0)?.toUpperCase() + checkedValue?.slice(1);

  const columns = [
    {
      title: "",
      dataIndex: "perc",
      key: "perc",
      width: 50,
      render: (text: any) => (
        <div className="">
          <Progress type="dashboard" percent={text.toFixed(1)} size={20} />
        </div>
      ),
    },
    {
      title: capitalizedTitle + "s",
      dataIndex: "brand",
      key: "brand",
      render: (text: string) => <div className="">{text}</div>,
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? ""
          : `${compareDate} Quantity Sold`,
      dataIndex: "quantityYesterday",
      key: "quantityYesterday",
      render: (text: string, record: any) =>
        record?.dashPreview === "day" && (
          <div className=" w-full truncate">{text?.toLocaleString()}</div>
        ),
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? `Quantities sold from ${compareDate} - ${enteredDate}`
          : `${enteredDate} Quantities`,
      dataIndex: "quantityToday",
      key: "quantityToday",
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? ""
          : `${compareDate} Revenue (RWF)`,
      dataIndex: "revenueYesterday",
      key: "revenueYesterday",
      render: (text: string, record: any) =>
        record?.dashPreview === "day" && (
          <div className=" w-full truncate">{text?.toLocaleString()}</div>
        ),
    },
    {
      title:
        layout?.dashboardPreview === "range"
          ? `Revenue(RWF) from ${compareDate} - ${enteredDate}`
          : `${enteredDate} Revenue(RWF)`,
      dataIndex: "revenue",
      key: "revenue",
      render: (text: string) => (
        <div className=" w-full truncate">{text?.toLocaleString()}</div>
      ),
    },
    {
      title: "",
      dataIndex: "percentage",
      key: "percentage",
      render: (text: string, record: any) => {
        return (
          <span
            className={`flex ${
              record?.percentage?.type === "increase"
                ? "text-green-500"
                : record?.percentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-sm`}
          >
            {record?.percentage?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : record?.percentage?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}{" "}
            {`${record?.percentage?.percentage}%`}
          </span>
        );
      },
    },
  ];

  const handleSelectShopRegion = (value: any) => {
    setSelectQtyByShopRegion(value);
  };

  const csvHeaders = [
    { label: capitalizedTitle, key: "name" },
    { label: `Revenue on ${enteredDate}`, key: "todayRevenue" },
    { label: `Quantity Sold on ${enteredDate}`, key: "todaysQty" },
    { label: `Revenue on ${compareDate}`, key: "comparedRevenue" },
    { label: `Quantity Sold on ${compareDate}`, key: "comparedQty" },
  ];

  const csvData = dashboard.quantitySoldStats?.data?.map((record: any) => ({
    name: record?.name,
    todayRevenue: record?.currentRevenue?.toLocaleString(),
    todaysQty: record?.currentQuantity?.toLocaleString(),
    comparedRevenue: record?.comparedRevenue?.toLocaleString(),
    comparedQty: record?.comparedQuantity?.toLocaleString(),
  }));

  return (
    <div className="bg-white mt-5 p-4 rounded-lg">
      <div className="lg:flex justify-between items-center w-full">
        <div className="flex justify-between mb-4 md:mb-0 space-x-2 items-center">
          <h2 className="text-sm  md:text-xl capitalize ">{title}</h2>
          <Select
            placeholder="Filter by Region"
            allowClear
            // style={{ width: 150 }}
            className="w-[60%] md:w-[150px]"
            onChange={(value) => {
              if (value === undefined) {
                handleSelectShopRegion("");
              } else {
                handleSelectShopRegion(value);
              }
            }}
          >
            {regions.map((d: any, index: any) => (
                <Option value={d?.toLowerCase()?.replace(/\s/g, '')} key={index}>
                  {d}
                </Option>
              ))}
          </Select>
          {!["partner"]?.includes(auth?.user?.role) && (
            <Link to="/report/sales">
              <OpenInNewIcon />
            </Link>
          )}
        </div>
        <div className="mt-7 lg:mt-2 flex items-center">
          {plainOptions.map((option: any) => (
            <Checkbox
              key={option}
              value={option}
              checked={checkedValue === option}
              onChange={onChange}
              className="custom-checkbox"
            >
              {option}
            </Checkbox>
          ))}
        </div>
        <div
          className={`${
            percentageChange?.type === "increase"
              ? "bg-green-200"
              : percentageChange?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-200"
          } rounded-lg px-3 py-1 lg:mt-0 float-right lg:float-left -mt-8`}
        >
          <span
            className={`${
              percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            }
             flex items-center py-0.5`}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}

            {`${percentageChange?.percentage}%`}
          </span>
        </div>
      </div>
      <div className="mt-4 lg:mt-2 flex items-center justify-end">
        <div>
          <span className="mr-4">Today</span>
        </div>
        <div className="h-3 w-3 bg-gray-400 mr-1.5"></div>{" "}
        <span>Yesterday</span>
        {/* {!["partner"]?.includes(auth?.user?.role) && ( for future use */}
        <div className="ml-2">
          <CsvDownloadButton
            csvHeaders={csvHeaders}
            csvData={csvData}
            filename={`Quantity Sold Statistics.csv`}
          />
        </div>
        {/* )} */}
      </div>

      <div className="flex-1 lg:mt-2 mt-4">
        <div className="h-fit overflow-y-auto">
          <Table
            columns={columns}
            dataSource={data}
            loading={dashboard?.qtySoldStatsIsFetching}
            sticky
            scroll={{ y: 400 }}
            // dataSource={formData}
            pagination={false}
            className="table-auto"
            summary={(pageData) => {
              let totalRevenues = 0;
              let totalQty = 0;
              let compTRevenue = 0;
              let compTQty = 0;

              pageData.forEach(
                ({
                  revenue,
                  quantityYesterday,
                  revenueYesterday,
                  quantityToday,
                }) => {
                  totalRevenues += revenue;
                  totalQty += quantityToday;
                  compTRevenue += revenueYesterday;
                  compTQty += quantityYesterday;
                }
              );

              return (
                <>
                  <Table.Summary.Row className="!text-[#2E6C8E] bg-white sticky bottom-0">
                    <Table.Summary.Cell index={0} colSpan={2}>
                      <Text style={{ fontWeight: "bolder" }}>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      {layout?.dashboardPreview === "day" && (
                        <Text>{compTQty.toLocaleString()}</Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalQty.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      {layout?.dashboardPreview === "day" && (
                        <Text>{compTRevenue.toLocaleString()}</Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Text style={{ fontWeight: "bolder" }}>
                        {totalRevenues.toLocaleString()}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default QuantitySold;
