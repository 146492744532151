import React, { useEffect, useState } from "react";
import { Table, Tag, Drawer } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import {
  getAllShopAction,
  getCommissionAction,
} from "../../../store/channel/actions";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

const PriceListDevicesWithCommission = (props: any) => {
  const { auth, channel, shop } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { priceId } = useParams();
  const [open, setOpen] = useState(false);
  const [shopData, setShopData] = useState<any>();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const showShopsDrawer = (data: any) => {
    setShopData(data);
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    auth?.token &&
      priceId &&
      getCommissionAction(auth?.token, `product=${priceId}`)(dispatch);
    auth?.token &&
      channel?.selected?._id &&
      getAllShopAction(
        auth?.token,
        `channel=${channel?.selected?._id}`
      )(dispatch);
  }, [auth?.token, dispatch, channel?.selected?._id]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "Shop Price (Rwf)",
      dataIndex: "price",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Commission Price (RWF)",
      dataIndex: "commissionPrice",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Commission applied to All Shops",
      dataIndex: "isAppliedToAll",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Shops",
      dataIndex: "shops",
      width: 300,
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return <Tag color={searchValue(text)}>{text}</Tag>;
      },
    },
  ];

  const data = channel?.allCommission?.data?.map((el: any) => {
    return {
      id: el._id,
      data: el,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      specification: el?.product?.specification?.map((item: any) => (
        <div className="flex items-center gap-2">
          <span className="font-bold text-xs">{item[0]}: </span>
          <span className=" text-sm">{item[1]}</span>
        </div>
      )),
      price: el?.product?.prices
        ?.find((price: any) => price.isActive)
        ?.value?.toLocaleString(),
      commissionPrice: el?.price,
      isAppliedToAll: el?.isAppliedToAll ? "Yes" : "No",
      shops: el?.isAppliedToAll ? (
        <p
          className="text-sm text-center w-10 h-10 capitalize cursor-pointer border border-gray-400 rounded-full p-2"
          onClick={showDrawer}
        >
          {channel?.allShop?.data?.length}
        </p>
      ) : (
        (
          <p
            className="text-sm text-center w-10 h-10 capitalize cursor-pointer border border-gray-400 rounded-full p-2"
            onClick={() => showShopsDrawer(el?.shops)}
          >
            {el?.shops?.length}
          </p>
        ) || "-"
      ),
      status: el?.isActive ? "Active" : "Inactive",
    };
  });

  const [pagination, setPagination] = React.useState({
    pageSize: 15,
    current: 1,
    total: data?.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div className="mt-4 h-[50vh] overflow-y-auto">
      <Table
        columns={columns}
        dataSource={data}
        loading={channel?.isFetching}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <Drawer
        width={600}
        title={
          <h1 className="text-center py-2 text-[#030229] text-lg font-medium">
            Shops With Commission
          </h1>
        }
        onClose={onClose}
        open={open}
      >
        <div className="text-black">
          <ul>
            {channel?.allShop?.data?.map((shop: any) => (
              <li className="capitalize py-2 pl-5 flex gap-3 text-[#030229e5] pt-1 text-sm font-medium">
                {" "}
                <div className="w-1 rounded-sm h-5 bg-blue-500"></div>
                {shop?.name}{" "}
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
      <Drawer
        width={600}
        title={
          <h1 className="text-center py-2 text-[#030229] text-lg font-medium">
            Shops With Commission
          </h1>
        }
        onClose={onCloseDrawer}
        open={openDrawer}
      >
        <div className="text-black">
          <ul>
            {shopData &&
              shopData?.map((el: any) => (
                <li className="capitalize py-2 pl-5 flex gap-3 text-[#030229e5] pt-1 text-sm font-medium">
                  {" "}
                  <div className="w-1 rounded-sm h-5 bg-blue-500"></div>
                  {el?.name}{" "}
                </li>
              ))}
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default PriceListDevicesWithCommission;
