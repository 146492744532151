import React from "react";
import { Modal, Popconfirm, Button } from "antd";
import AccouuntThreeDotDropdown from "./AccountThreeDotDropdown";
import { useSelector, useDispatch } from "react-redux";
import { acceptSubRequestAction } from "../../../../../store/subscription/subscriptionRequest/actions";
import RejectSubscriptionReqForm from "../../../../../components/forms/RejectSubscriptionReqForm";

const AccountTopButton = () => {
  const [openModel, setOpenModel] = React.useState(false);
  const { auth, subscriptionRequest, account } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  const requestId =
    account?.selected?.requests && account?.selected?.requests[0]?._id;
  const requestStatus =
    account?.selected?.requests && account?.selected?.requests[0]?.status;

  const showRejectModal = () => {
    setOpenModel(true);
  };
  const cancelRejectModal = () => {
    setOpenModel(false);
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        {requestStatus === "requested" && (
          <>
            <Popconfirm
              title="Accept Request"
              description="Are you sure to Accept this Subscription Request?"
              onConfirm={() =>
                acceptSubRequestAction(auth?.token, requestId, {
                  status: "accepted",
                })(dispatch)
              }
              okText="Confirm"
              cancelText="Cancel"
              okButtonProps={{
                loading: subscriptionRequest?.isFetching,
                className: "bg-blue-500",
              }}
            >
              <Button
                className="capitalize min-w-[50px] bg-green-500 text-white text-center border-none outline-none"
                style={{ color: "white" }}
              >
                Accept
              </Button>
            </Popconfirm>
            <Button
              className="capitalize min-w-[50px] text-center outline-none"
              danger
              onClick={showRejectModal}
            >
              Reject
            </Button>
          </>
        )}
        {account?.selected?.type?.toLowerCase() !== "b2b" && (
          <AccouuntThreeDotDropdown requestId={requestId} />
        )}
      </div>
      <Modal
        open={openModel}
        onCancel={cancelRejectModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RejectSubscriptionReqForm
          onCancel={cancelRejectModal}
          requestId={requestId}
        />
      </Modal>
    </>
  );
};

export default AccountTopButton;
