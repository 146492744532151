import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { myLayoutActions } from "../../../../store/layout";
import { getOneUserAction } from "../../../../store/setting/actions";
import { getOneChanneltAction, updateChannelAction } from "../../../../store/channel/actions";
import image from "../../../../assets/images/Img_2023_11_06_14_33_19.jpeg"
import { Switch } from "antd";

const ChannelDetailsPage = (props: any) => {
  const { auth, setting, layout } = useSelector((state: any) => state);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(myLayoutActions.setViewChannel(false));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const UpdateSubscriptionStatus = async(checked: boolean) => {
    auth?.token &&
      (await updateChannelAction(
        layout?.channelInfo?._id,
        {
          isSubscriptionActive: checked,
        },
        auth?.token
      )(dispatch));
  };
  const UpdatePOSStatus = async(checked: boolean) => {
    auth?.token &&
      (await updateChannelAction(
        layout?.channelInfo?._id,
        {
          isPOSActive: checked,
        },
        auth?.token
      )(dispatch));
  };
  
  console.log("cjhannel info",layout?.channelInfo)
  useEffect(() => {
    layout?.viewChannel &&
      auth?.token &&
      getOneChanneltAction(
        layout?.userChannel?._id as string,
        auth?.token
      )(dispatch);
  }, [layout?.viewChannel, auth, dispatch, layout?.userChannel?._id]);
  const styles = {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    
  };
  const style = {
    shadow: {
      boxShadow: "0px  0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.1px solid rgba(15, 15, 71, 0.25)"
    }
  };
  return (
    <div className="text-black p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        handleClickDelete={props?.handleClickDelete}
        handleClickEdit={props?.handleClickEdit}
        goBack={goBack}
        pageName={"Channel"}
        title={layout?.channelInfo?.name}
        data={""}
      />
      <div className="w-full bg-white p-6 divide-y h-[83vh] rounded-md" style={styles}>
   <div className="p-6">
        <h1 className="text-[#030229] text-lg font-medium">Channel Profile</h1>
        <div className=" w-full flex mt-5">
            <img
              src={layout?.channelInfo?.logo}
              alt="mtn"
              className="rounded-full w-[10rem] border border-[#0F0F4740] p-1"
            />
          <div className=" ml-20 w-[85%]">
            <h1 className="text-[#0F0F47] font-medium text-lg">
              {layout?.channelInfo?.name}
            </h1>
            <div className="flex justify-between w-full mt-4">
              <div>
              <h2 className="text-[#030229] font-base font-medium">POS</h2>
            
              <p className="text[#03022980] text-sm ">Enable/Disable pos</p>
              </div>
              <span
                      style={style.border}
                      className="rounded-[8px] pt-2 px-2"
                    >
                      {layout?.channelInfo?.isPOSActive? "On" : "Off"}
              <Switch disabled defaultChecked={layout?.channelInfo?.isPOSActive} onChange={UpdatePOSStatus} />
                      
                    </span>
            </div>
            <div className="flex justify-between w-full mt-4">
              <div>
              <h2 className="text-[#030229] font-base font-medium">Subscription</h2>
            
              <p className="text[#03022980] text-sm ">Enable/Disable subsccription</p>
              </div>
              <span
                      style={style.border}
                      className="rounded-[8px] pt-2 px-2"
                    >
                      {layout?.channelInfo?.isSubscriptionActive? "On" : "Off"}{" "}
              <Switch disabled defaultChecked={layout?.channelInfo?.isSubscriptionActive} onChange={UpdateSubscriptionStatus} />
                      
                    </span>
            </div>
          </div>
        </div>
        <div>
        </div>
          

        </div>
        <div className="p-6">
        <h1 className="text-[#030229] text-lg font-medium py-2">Contacts Details</h1>
        {layout?.channelInfo?.contact.map((el:any)=>(
          <div >
          <p className="text-base text-[#030229] font-medium py-2">name</p>
          <p className="text-sm text-[#03022980]">{el?.name}</p>
          <p className="text-base text-[#030229] font-medium py-2">Position</p>
          <p className="text-sm text-[#03022980]">{el?.position}</p>
          <p className="text-base text-[#030229] font-medium py-2">Telephone</p>
          <p className="text-sm text-[#03022980]">{el?.phone}</p>
          </div>

        ))}
        
        </div>
      </div>
    </div>
  );
};

export default ChannelDetailsPage;
