import React, { useState } from "react";
import { Paper, Stack } from "@mui/material";
import { Table, Typography } from "antd";
import { useSelector } from "react-redux";
import BackButton from "../buttons/BackButton";

const { Paragraph } = Typography;

const TransferProductTable = (props: any) => {
  const { inventory } = useSelector((state: any) => state);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: props?.data?.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Shop",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.shopStock?.name ?? "N/A"}</p>
          <p>{record?.data?.shopStock?.channel?.name ?? "N/A"}</p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <p>{record?.data?.requestedItem?.product?.product?.model}</p>
      ),
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
      width: 300,
      render: (text: any, record: any) => (
        <p>
          {record?.data?.requestedItem?.product?.specification
            ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
            .join(", ")}
        </p>
      ),
    },
    {
      title: "Serial Number",
      dataIndex: "sn",
      key: "sn",
      render: (text: any, record: any) => (
        <Paragraph copyable>{record?.data?.serialNumber}</Paragraph>
      ),
    },
  ];

  return (
    <Stack spacing={1}>
      <div className=" text-black">
        <BackButton onClick={props?.reset} />
      </div>
      <Paper elevation={0} style={{ marginBottom: "20px" }}>
        <Stack spacing={0}>
          <Table
            columns={columns}
            dataSource={props?.data}
            pagination={pagination}
            onChange={handleTableChange}
            loading={inventory?.isFetching}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default TransferProductTable;
