import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
interface drawerProps{
    title?:any,
    component?:any,
    openDrawer?:any,
    closeDrawer?:any,
    width?:any,
}
const GeneralDrawer= ({title,component,openDrawer,closeDrawer,width}:drawerProps) => {
  return (
    <>
      <Drawer title={title} onClose={closeDrawer} open={openDrawer}  width={width}>
        {component}
      </Drawer>
    </>
  );
};

export default GeneralDrawer;