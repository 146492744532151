import React,{ ReactNode } from "react"
interface cardProps{
    total?:any,
    name?:any,
    percent?:any,
    icon?: ReactNode;
    customeIcon?:ReactNode;

}
const Card=({total,name,percent,icon,customeIcon}:cardProps)=>{
   
   const styles={
        boxShadow:{
            boxShadow:"1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
        }
    }
    return(
        <>
        <div className="w-[260px] h-[116px] flex  justify-between bg-white rounded-md p-7 cursor-pointer" style={styles.boxShadow}>
                <div className="border mt-2 border-[#2943d622] pl-[8px] pt-2 rounded-full w-[45px] h-[40px]">
                    {customeIcon}
                </div>
                <div className="">
                <div className="flex w-full pl-4 gap-10">
                    <h1 className="text-[#030229d0] font-semibold text-[22px]">{total}</h1>
                        <div className="mt-[7px]">
                           <button className=" border h-7 text-black border-[#0302291b] rounded-lg pt-[5px] px-3 flex gap-2 text-[12px]"> {icon}
                            {percent}</button></div>
                </div>
                
                <p className="text-[#0302297a] font-semibold text-base pt-3 pl-3">{name}</p>
                </div>
            </div>
        </>
    )
}
export default Card