import React from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import CustomButton from '../buttons/CustomButton';
import {ReactComponent as SaveIcon} from "../../assets/images/icons/save.svg"

const layout = {
  labelCol: { span: 4 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values: any) => {
  console.log(values);
};

const AddInsurerForm: React.FC = () => (
  <Form
    {...layout}
    name="nest-messages"
    onFinish={onFinish}
    style={{ maxWidth: "100%" }}
    validateMessages={validateMessages}
  >
    <Form.Item name={['user', 'name']} label=<h1>Code</h1> rules={[{ required: true }]}>
      <Input className="w-64 h-10"/>
    </Form.Item>
    <Form.Item name={['user', 'email']} label=<h1>Insurer name</h1> rules={[{ type: 'email' }]}>
      <Input className="w-64 h-10"/>
    </Form.Item>
    <Form.Item name={['user', 'website']} label=<h1>Rate</h1>>
      <Input type='number' className="w-64 h-10"/>
    </Form.Item>
   
   <Form.Item  className='w-10/12 m-auto'>
   <CustomButton title={"Save"} bgColor='[#605BFF]' width='full' textColor='white' icon={<SaveIcon/>}/>
    </Form.Item>
  </Form>
);

export default AddInsurerForm;