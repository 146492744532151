import React from "react";
import ProductGridPOS from "../../../../components/grids/POS/ProductGridPOS";
import POSItemSearch from "../POSItemSearch";
import { useSelector } from "react-redux";

const AddToCart: React.FC<{
  addToCart: any;
  setIsNewItemAdded: any;
  isNewItemAdded: any;
}> = ({ addToCart, setIsNewItemAdded, isNewItemAdded }) => {
  const { wareHouse } = useSelector((state: any) => state);
  return (
    <div className="h-fit leading-none">
      <POSItemSearch
        addToCart={addToCart}
        setIsNewItemAdded={setIsNewItemAdded}
        isNewItemAdded={isNewItemAdded}
      />
      {!wareHouse.isFetching && (
        <div className="flex flex-wrap flex-col gap-4 justify-start items-start py-2 rounded-lg">
          <h4>Cart items:</h4>
          <div className="flex flex-wrap gap-2">
            {<ProductGridPOS data={wareHouse?.createdCart?.data?.list} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToCart;
