import React from "react";
import { useNavigate } from "react-router-dom";
import { Tag, Badge } from "antd";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  const storedStatus =
    props?.data?.data?.listOfItems &&
    props?.data?.data?.listOfItems?.map(
      (item: any, _index: number) => item?.status
    );

  const checkReceiveAndStoredStatus =
    props?.data?.status &&
    storedStatus?.every((status: any) => status === "Stored-Complete");

  return (
    <>
      <Badge.Ribbon
        style={{ padding: "4px" }}
        color={searchValue(
          ["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
            props?.data?.status
          )
            ? props?.data?.status
            : checkReceiveAndStoredStatus
            ? props?.data?.status
            : "Verified - Not Stored"
        )}
        text={
          ["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
            props?.data?.status
          )
            ? props?.data?.status
            : checkReceiveAndStoredStatus
            ? props?.data?.status
            : "Verified - Not Stored"
        }
      >
        <div
          className="min-h-[150px] bg-white p-2 rounded-md shadow hover:border-black relative "
          onClick={() => {
            navigate(`/inventory/ws/in/${props.data.id}`);
          }}
        >
          <div className="py-2 px-1">
            <div className="text-base space-y-2">
              <h1 className="font-semibold leading-3 text-gray-900">
                {props?.data?.status === "In-Transit"
                  ? props?.data?.deliveryID
                  : props?.data?.warehouseID}
              </h1>
              <p className="text-[#030229] text-xs leading-normal">
                {props?.data?.pOrderID}
              </p>
              <p
                className="w-56 text-gray-600 text-sm "
                style={{ color: "#605BFF" }}
              >
                {props?.data?.data?.pOrderID?.supplier?.name}
              </p>
              <div className="absolute right-6 top-10">
                {props?.data?.data?.ebmRegistered && (
                  <Tag
                    color={"blue"}
                    className="text-center px-4 py-1 rounded-2xl text-xs"
                  >
                    SAVED IN EBM
                  </Tag>
                )}
              </div>
            </div>
            <hr className="mt-4 pb-2" />
            <div className="bottom-0 right-2 flex justify-between items-end w-full">
              <div>
                <p className="text-sm text-[#030229] xfont-normal leading-normal">
                  {props?.data?.data?.updatedAt.split("T")[0]}
                </p>
              </div>
              <p className="text-xs text-gray-900 leading-normal font-medium pr-3">
                <b>{props?.data?.qnt} items</b>
              </p>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
