import React, { useState, useRef } from "react";
import "../../../components/cards/NoSale/noSale.css";
import { useClickAnimation } from "../../../pages/dashboard/POS/clickAnimation";

const FilesCard: React.FC<{
  Icon: any;
  color?: string;
  tailwindTextColor?: string;
  tailwindBorderColor?: string;
  tailwindBackgroundColor?: string;
  onClick?: any;
  bgColor?: string;
  disabled?: boolean;
}> = ({
  Icon,
  color,
  tailwindTextColor,
  tailwindBackgroundColor,
  tailwindBorderColor,
  bgColor,
  onClick,
  disabled,
}) => {
  const buttonRef2 = useRef<HTMLButtonElement>(null!);
  useClickAnimation(buttonRef2, {
    color: "white",
    size: 100,
    duration: 1600,
    effectName: "square-rotate",
  });
  return (
    <div
      className={` button-container flex items-center justify-center border rounded-md   ${
        tailwindBorderColor ? tailwindBorderColor : ""
      } ${tailwindBackgroundColor ? tailwindBackgroundColor : ""}`}
      onClick={onClick}
    >
      <span
        ref={buttonRef2}
        // type="button"
        className={` bg-[#2s943D6] pl-[30%] w-[80px] text-white py-[2px] rounded-md cursor-pointer text-lg block ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <span>
          <Icon
            size={28}
            className={`${tailwindTextColor ? tailwindTextColor : ""}`}
          />
        </span>
      </span>
    </div>
  );
};

export default FilesCard;
