import { Skeleton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../buttons/BackButton";

const DetailsHeaderActionBar = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between mb-2 pt-2">
      <div className="space-x-1">
        <Stack direction={"row"} spacing={1}>
          <BackButton onClick={props?.goBack || (() => navigate(-1))} />
          <span className=" text-[#030229a1]">{props?.pageName}</span>

          <Stack direction={"row"} spacing={1}>
            <span className="mt-[2px] text-[#030229a1]">/</span>
            {!props.title && <Skeleton variant="text" sx={{ width: 130 }} />}

            {props?.title && <span className=" text-[#030229a1]">{props?.title.split(" ")}</span>}
          </Stack>
        </Stack>
      </div>
      {props?.buttonsComponent && <div>{props?.buttonsComponent}</div>}
    </div>
  );
};

export default DetailsHeaderActionBar;
