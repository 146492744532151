import React from 'react';
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import userImage from "../assets/images/Ellipse 2354.png"
import { HiOutlineDotsVertical } from 'react-icons/hi';

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const columns= [
  {
    title: 'Employee Names',
    dataIndex: 'name',
    key: 'name',
    render: (text:any) => <div className='flex gap-2'>
      <img src={userImage} alt="user" className="h-12 w-12 rounded-full"/>
      <div>
      <span>{text}</span>
      <p>Alice@samphone.co</p>
      </div>
    </div>,
  },
  {
    title: 'Department',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: 'Perfomance',
    key: 'parfomance',
    render: (text:any) => {
      return(
     <span className='text-[#12B76A] text-base'>. {text?.parfomance}</span>
    )},
  },
  {
    title: ' ',
    key: 'action',
    render: (record:any) => (
      <HiOutlineDotsVertical size={24}/>
    ),
  },
];
const data = [
  {
    key: '1',
    name: 'Alice Mutuyimana',
    department: "Dev",
    parfomance: 'Good',
  },
  {
    key: '2',
    name: 'Alice Mutuyimana',
    department: "Dev",
    parfomance: 'Good',
  },
  {
    key: '3',
    name: 'Alice Mutuyimana',
    department: "Dev",
    parfomance: 'Good',
  },
 
];

const EmployeePerfomanceTable = () => <Table columns={columns} dataSource={data} />;

export default EmployeePerfomanceTable;