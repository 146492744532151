import React from "react";
import { useSelector } from "react-redux";
import ProductCardGridCommission from "./ProductCardGridCommission";

const App = (props: any) => {
  const { product, channel } = useSelector((state: any) => state);

  return channel?.commissionBychannel?.data?.map((d: any) => (
    <ProductCardGridCommission
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
      brands={product?.attributes?.brands}
      onClick={props?.onClick}
    ></ProductCardGridCommission>
  ));
};

export default App;