import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, DatePicker, DatePickerProps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { updateUserActions } from "../../store/setting/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DeactivateForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth, setting } = useSelector((state: any) => state);
  const [date, setDate] = useState("");

  const onFinish = async (values: any) => {
    if (date) {
      await updateUserActions(
        id as string,
        {
          dateOfDeparture: date,
          isActive: "false",
          isNotify: true,
          notification: {
            action: "Deactivate Employee",
            role: ["admin", "dev"],
            message: `${setting?.selected?.names} has been Deactivated`,
            title: "Deactivate Employee",
          },
        },
        auth?.token
      )(dispatch);
    }
    form.resetFields();
    props?.onCancel();
    navigate(-1);
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="">
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="font-bold text-base sm:text-2xl text-gray-900 pb-4 pl-16">
                Deactivate Employee
              </h1>

              <Col span={9} className="mx-2 sm:mx-0">
                <Form.Item name="date" label="Eand Date">
                  <DatePicker
                    onChange={onChangeDate}
                    style={{ width: 240 }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="w-2/5 flex items-center justify-between sm:mx-32 mx-2"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 200 }}
                  loading={setting.isFetching}
                >
                  Submit
                </LoadingButton>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DeactivateForm;
