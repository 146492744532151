import react, { useEffect, useState } from "react";
import profilepic from "../../../assets/images/profileinfo.png";
import SettingButton from "./SettingButton";
import * as ciIcons from "react-icons/ci"
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  Space,
  Modal,
  Upload,
  Image,
  Select,
  Spin
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangePasswordAction,
  getOneUserAction,
  updateUserActions
} from "../../../store/setting/actions";
import { PlusOutlined } from "@ant-design/icons";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { roles } from "../../../utils/roles";
import UploadComponent from "../../../components/forms/Upload";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import "./setting.css";
import ChangePasswordForm from "../../../components/forms/ChangePasswordForm";
import ChangePin from "../../../components/forms/ChangePin";
import { authActions } from "../../../store/auth";
const SubmitButton = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = React.useState(false);
  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      Submit
    </Button>
  );
};

type FieldType = {
  email?: string;
  password?: string;
  current?: string;
  new?: string;
  confirm?: string;
};
const CreatePersonalProfile = (props: any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = React.useState("250");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [pictures, setPictures] = useState([]);
  const [selectedRole, setSelectedRole] = React.useState<string>("");
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
 const [profileInfo,setProfileInfo]=useState<any>("");
 const [editAddress,setEditAddress]=useState(false);
 const HandleEditAddress=()=>{
  setEditAddress(true)
 }
 React.useEffect(() => {
  setProfileInfo(auth?.user)
}, [auth?.user]);
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const handleCancol = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    }
  };
  const onChange = (key: string) => {
    console.log(key);
  };
  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };
  const handleSelectedRole = (value: string) => {
    setSelectedRole(value);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <h1 className="text-[#0F0F47] py-1 pl-1 text-base font-[600] text-center">
          Change Password
        </h1>
      ),
      children: <ChangePasswordForm userData={auth?.user} onCancel={handleCancel} />
    },
    {
      key: "2",
      label: (
        <h1 className="text-[#0F0F47] py-1 pl-1 text-base font-[600] text-center">
          Change PIN
        </h1>
      ),
      children: <ChangePin userData={auth?.user} onCancel={handleCancel} />
    }
  ];
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if(auth?.token) {
      auth?.user?._id &&
      (await updateUserActions(
        auth?.user?._id,
        {
          ...values,
          names: values.firstName + " " + values.lastName,
          picture: images[0]
        },
        auth?.token
      )(dispatch));
    await getOneUserAction(auth?.user?._id, auth?.token)(dispatch);
  }};
  useEffect(() => {
    form.setFieldsValue({
      firstName: auth?.user?.names?.split(" ")[0],
      lastName: auth?.user?.names?.split(" ")[1],
      nid: auth?.user?.nid,
      email: auth?.user?.email,
      role: auth?.user?.role,
      phone: auth?.user?.phone,
      address: auth?.user?.address,
    });
  });
  return (
    <>
      <div className="rounded-[10px] p-8 bg-white" style={styles.customShadow}>
        <header>
          <h1 className="text-[#0F0F47] font-medium text-lg">
            Personal Information
          </h1>
          <div className="flex mt-8">
            <div className="w-[120px] h-[120px] rounded-full">
              <img src={auth?.user?.picture} className="w-full rounded-full"/>
            </div>
            <div className="mt-10 ml-8 flex gap-5">
           
              <div>
                <SettingButton
                  btnBgColor="white"
                  textColor="black"
                  btnName="Update Password"
                  onClick={showModal}
                />
                
              </div>
            </div>
          </div>
          <Modal
            keyboard={false}
            onCancel={handleCancel}
            footer={null}
            open={isModalOpen}
          >
          
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
         
          </Modal>
        </header>
        <div className="mt-[52px]">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            style={{ maxWidth: "90%" }}
            onFinish={onFinish}
          >
            <div className="flex gap-8 w-full">
              <Form.Item
                name="firstName"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600]">
                    First Name
                  </span>
                }
                className="w-full"
              >
                <Input
                  disabled={true}
                  placeholder="Please enter your first name"
                  className="pl-4 h-[52px] w-[100%] lg:max-d2xl:w-[190px] lg:max-2xls:h-[42px] bg-[#EFF0F6] border-none"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600]">
                    Last Name
                  </span>
                }
                className="w-full"
              >
                <Input
                  disabled={true}
                  placeholder="Please enter your last name"
                  className="pl-4 h-[52px] w-[100%] lg:max-d2xl:w-[190px] lg:maxs-2xl:h-[42px] bg-[#EFF0F6] border-none"
                />
              </Form.Item>
            </div>
            <div className="flex gap-8 w-full">
              <Form.Item
                name="nid"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600]">
                    NID
                  </span>
                }
                className="w-full"
              >
                <Input
                  disabled={true}
                  placeholder="000000"
                  className="pl-4 h-[52px] w-[100%] lg:max-2xld:w-[190px] lg:max-2xsl:h-[42px] bg-[#EFF0F6] border-none"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600]">
                    Email
                  </span>
                }
                className="w-full"
              >
                <Input
                  disabled={true}
                  placeholder="default@gmail.com"
                  className="pl-4 h-[52px] w-[100%] lg:max-2xld:w-[190px] lg:max-2sxl:h-[42px] bg-[#EFF0F6] border-none"
                />
              </Form.Item>
            </div>
            <div className="flex gap-8 w-full">
              <Form.Item
                name="role"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600]">
                    Role
                  </span>
                }
                className="w-full custom-select "
              >
                <Select
                  disabled
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Select  Role of the Employee!"
                  style={{ border: "none" }}
                  className=" capitalize rounded-md h-[52px] w-[100%] custom-select  lg:max-2xls:w-[190px] lg:max-2xls:h-[42px] bcg-[#EFF0F6] border-none"
                  onChange={handleSelectedRole}
                >
                  {roles.map((type: any) => (
                    <Option value={type.toLowerCase()} className="capitalize">
                      {type.split("-").join(" ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  position: "relative",
                  opacity: 0.5,
                  pointerEvents: "none"
                }}
                name="phone"
                className="w-full lg:maxs-2xl:w-72 lg:max-2xl:-mt-2"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[605]">
                    Telephone
                  </span>
                }
              >
                <PhoneInput
                  onChange={handleCountryChange}
                  country={"rw"}
                  buttonStyle={{
                    height: "52px",
                    border: "none",
                    borderRight: "3px solid #fff",
                    backgroundColor: "#EFF0F6"
                  }}
                  inputStyle={{
                    height: "52px",
                    width: "100%",
                    backgroundColor: "#EFF0F6",
                    border: "none"
                  }}
                  containerClass="phone-container"
                  placeholder="+250 780 000 000"
                />
              </Form.Item>
            </div>
            <div className="w-full">
             
              <Form.Item
                name="address"
                label={
                  <span className="text-[#0F0F47] pl-1 text-base font-[600] flex gap-2">
                    Address {!editAddress &&  <ciIcons.CiEdit onClick={HandleEditAddress} className="mt-1 cursor-pointer"/>}
                  </span>
                }
                className="w-full"
              >
               {editAddress?  <Input
                  placeholder="City/Province/Country"
                  className="pl-4 h-[52px] w-full lg:max-s2xl:w-[190px] lg:max-2xl:h-s[42px] bg-[#EFF0F6] border-none"
                />:
                <Input
                disabled
                  placeholder="City/Province/Country"
                  className="pl-4 h-[52px] w-full lg:max-s2xl:w-[190px] lg:max-2xsl:h-[42px] bg-[#EFF0F6] border-none"
                />}
                
              </Form.Item>
            </div>
            <Form.Item>{editAddress && 
              <div className="float-right flex gap-5 mt-20">
                 
               <SettingButton
                  btnName={
                    setting.isFetching ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "white" }}
                            color="white"
                            spin
                          />
                        }
                      />
                    ) : (
                      "Save Changes"
                    )
                  }
                  btnBgColor="[#605BFF]"
                  textColor="white"
                />
                <SettingButton
                  btnName={
                    
                      "Cancel"
                  }
                  btnBgColor="[#605BFF]"
                  textColor="white"
                  onClick={()=>setEditAddress(false)}
                />
              </div>}
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreatePersonalProfile;
