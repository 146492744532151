import { myInvoices } from ".";
import { getAccountIncoiceService, approvePaymentInvoice, getOneInvoiceService } from "./services";

export const getAllAccountInvoiceAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await getAccountIncoiceService(token, query);
      if (res?.status === 200) {
        dispatch(myInvoices.setAll(res));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myInvoices.setIsFetching(false));
    }
  };
};

export const approveInvoicePaymentAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await approvePaymentInvoice(itemId, token, data);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myInvoices.setSelected(res?.data));

        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


export const getOneSubsBillingsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await getOneInvoiceService (id, token);
      if (res?.status === 200) {
        dispatch(myInvoices.setSelected(res?.data));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};