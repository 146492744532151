import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import {
  getOneUserAction,
  updateUserActions
} from "../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  profileAction,
  updateUserPasswordActions
} from "../../store/auth/authActions";
import { authActions } from "../../store/auth";
import { useNavigate } from "react-router-dom";
const ChangePasswordForm = ({
  userData,
  onCancel
}: {
  userData: any;
  onCancel: any;
}) => {
  const { auth, setting } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const ChangeNewPassword = (e: any) => {
    setNewPassword(e.target.value);
  };

  const ConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };
  
  const updatePassword = async (values: any) => {
    auth?.token && await updateUserPasswordActions(
      { ...values },
      auth?.token
      )(dispatch)
    form.resetFields();
    dispatch(authActions.setIsFetching(false));
    // handleOk()
  };
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%" }}
        onFinish={updatePassword}
      >
        <Form.Item
          name="email"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Email
            </span>
          }
          initialValue={userData?.email}
          className="w-full"
        >
          <Input disabled className="pl-4 h-[42px] w-[100%] text-base" />
        </Form.Item>
        <Form.Item
          name="currentPassword"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Current Password
            </span>
          }
          className="w-full"
        >
          <Input.Password
          autoComplete="new-password"
            placeholder="Please enter your old password"
            className="pl-4 h-[42px] w-[100%] "
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              New Password
            </span>
          }
          className="w-full"
          rules={[
            { required: true, message: "Please input new password!" },
            {
              min: 6,
              message: "Password should be at least 6 characters long",
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
              message:
                "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
            },
          ]}
        >
          <Input.Password
            className="pl-4 h-[42px]  w-[100%]"
            onChange={ChangeNewPassword}
          />
        </Form.Item>
        {newPassword.length > 16 && (
          <p className="text-[#ff000079] pb-2">
            Password should not greater than 16 digits
          </p>
        )}
        <Form.Item
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Confirm Password
            </span>
          }
          className="w-full"
          name="confirm"
          rules={[{ required: true, message: "Please confirm new password!" }]}
          // initialValue={""}
        >
          {}
          <Input.Password
            className="pl-4 h-[42px] w-[100%]"
            onChange={ConfirmPassword}
          />
        </Form.Item>
        {confirmPassword.length > 0 && newPassword != confirmPassword && (
          <p className="text-[#ff000079] pb-4">
            New and Confirm Password are not match
          </p>
        )}

        <div className="ml-8">
          <button
            type="submit"
            disabled={
              !(
                newPassword.length > 0 &&
                newPassword.length <= 16 &&
                newPassword === confirmPassword
              )
            }
            className={` border w-[402px] bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {setting.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
export default ChangePasswordForm;
