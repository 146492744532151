import React from "react";
import { Modal } from "antd";

const ProductDetailsModal = (props: any) => {
  return (
    <Modal
      title={"Update Details"}
      open={props?.isModalOpen}
      width={1000}
      onCancel={props?.handleOnCancel}
      footer={null}
    >
      <div className="">
        <div className="w-[100%]">{props?.component}</div>
      </div>
    </Modal>
  );
};

export default ProductDetailsModal;


