import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import store from "store";
import { useLocation } from "react-router-dom";
import { authActions } from "../../store/auth";
import SideNav from "./SideNav";
import { myLayoutActions } from "../../store/layout";
import { useMediaQuery } from "@mui/material";

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { auth, layout } = useSelector((state: any) => state);
  const isEbm = location.pathname === "/ebm";
  const isKnowledgePage = location.pathname === "/knowledge";
  const isSetting = location.pathname === "/setting";
  const isVoaults=location.pathname==="/vaults"
  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
  };
  const smallScreen = useMediaQuery("(max-width:767px)");
  return (
    <div className="landScreenBack">
      <div
        className={` gap-8  w-full h-screen overflow-y-auto ${
          !props?.dashback && "landScreen"
        } `}
      >
        <SideNav
          toggleSideNav={handleSideNav}
          selected={props?.selectedNav || 0}
          isOpen={layout.isSideNavOpen}
        />
        <div className="flex min-h-screen">
          {!props?.dashback && <div className="xw-fit"></div>}
          <Header
            nav={props?.nav}
            title={props?.title || " Samphone"}
            handleFullScreenClick={handleFullScreenClick}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <div
            className={
              isKnowledgePage
                ? `xpr-10 pt-[3rem] pb-4 h-[calc(100%-64px)] w-full ${
                    smallScreen && layout.isSideNavOpen && "pl-1 pt-[3rem]"
                  }  ${
                    !smallScreen && layout.isSideNavOpen
                      ? "pl-[210px]"
                      : !smallScreen && ` pl-[82px]`
                  }`
                : `${isVoaults? "":"pb-4"}  h-[calc(100%-64px)] w-full ${
                    smallScreen
                      ? "px-4 pt-[3rem]"
                      : `${isVoaults? "":"pr-10"} pt-[3rem] pbs-4  ${
                          layout.isSideNavOpen
                            ? `${isSetting ||isVoaults || isEbm ? "pl-[210px]" : "pl-[230px]"} `
                            : `${isSetting || isVoaults || isEbm ? "pl-[5rem]" : "pl-[6rem]"} `
                        }`
                  }`
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
