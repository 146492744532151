import { useEffect } from "react";
import { Box } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/icons/logo.svg";

import PoToSupplierTable from "../components/tables/PoToSupplierTable";
import { getOneProductOrderAction } from "../store/product/actions";
import { myProductActions } from "../store/product";
import { TableHeaderSkeleton } from "../components/skeleton/TableHeaderSkeleton";

const PageView = () => {
  const { id } = useParams();
  const { product } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(myProductActions.setSelected(null));
      id && (await getOneProductOrderAction(id)(dispatch));
    };
    fetchData();
      id &&
      navigate(`/delivery/note/${id}`);
  }, [dispatch, id, navigate]);

  console.log(product);

  return (
    <>
      <Box className="landScreen min-h-screen text-gray-900 flex justify-center font-serif">
        <Box className="w-[80%] max-h-full bg-white p-8 flex flex-col gap-4">
          {product.isFetching ? (
            <TableHeaderSkeleton />
          ) : (
            <>
              <div className="grid grid-cols-2 gap-2">
                <div className="pl-2">
                  {product?.selected?.supplier?.logo ? (
                    <img
                      src={product?.selected?.supplier?.logo}
                      className="w-12 h-12 rounded-full"
                      alt="logo"
                    />
                  ) : (
                    <div className="border-2 border-gray-500 w-fit p-0.5">
                      <p className="font-bold">
                        {" "}
                        <span className="bg-black text-white p-0.5 uppercase">
                          {product?.selected?.supplier?.name?.split(" ")[0]}
                        </span>{" "}
                        {product?.selected?.supplier?.name?.split(" ")[1]}
                      </p>
                    </div>
                  )}
                  <div className="mt-5">
                    <h1 className="font-bold mb-1 uppercase">
                      {product?.selected?.supplier?.name}
                    </h1>
                    <p>
                      TIN:
                      <span className="text-gray-900 pl-2">
                        {product?.selected?.supplier?.tin}
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">
                        {product?.selected?.supplier?.address}
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">
                        {product?.selected?.supplier?.type}
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">
                        {product?.selected?.supplier?.email}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="ml-20">
                  <img
                    className="h-10 object-cover"
                    src={Logo}
                    alt="Company logo"
                  />
                  <div className="mt-5 ml-4">
                    <h1 className="font-bold mb-1">SAMPHONE RWANDA LTD</h1>
                    <p>
                      <span className="text-gray-900">
                        TCB Building, 3rd Floor B, Kiyovu Cell, Nyarugenge
                        Sector
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">
                        Kigali, City of Kigali 0000
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">Rw</span>
                    </p>
                    <p>
                      <span className="text-gray-900">www.samphone.co</span>
                    </p>
                    <p>
                      <span className="text-gray-900">
                        VAT Agency Registration no: 112620773
                      </span>
                    </p>
                    <p>
                      <span className="text-gray-900">Govt. UID 112620773</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="pl-2 mb-5">
                <h1 className="font-bold">Purchase Order</h1>
                <span className="text-blue-500 font-semibold text-lg">
                  {product?.selected?.pOrderID}
                </span>
              </div>
            </>
          )}

          <PoToSupplierTable id={id} />
        </Box>
      </Box>
    </>
  );
};

export default PageView;
