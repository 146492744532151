import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin, Radio, Select, Space, Switch, Button } from "antd";
import type { RadioChangeEvent, SelectProps } from 'antd';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCommissionAction, getAllShopAction, getCommissionAction, getDeviceWithCommissionAction } from "../../store/channel/actions";
import { useParams } from "react-router";

const AssignPriceListCommissionForm = (props: any) => {
  const { auth, channel} = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const { priceId } = useParams();
  const dispatch = useDispatch();
  const [isApplyCommission, setIsApplyCommission] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const shopItems = props?.data?.allShop?.data?.map((shop: any) => ({
    value: shop?._id,
    label: shop?.name,
  }))
  const onChange = (checked: boolean) => {
    setIsApplyCommission(checked)
  };
  const onFinish = async (values: any) => {
    auth?.token && (await createCommissionAction(auth?.token, { ...values, isAppliedToAll: isApplyCommission,product:props?.data?.selectedPriceListItems?.data?._id, channel: channel?.selected?._id})(dispatch));
    auth?.token && priceId && getCommissionAction(auth?.token, `product=${priceId}`)(dispatch);
    auth?.token && getAllShopAction(auth?.token, `channel=${channel?.selected?._id}`)(dispatch);
    form.resetFields();
    props?.onCancel();
  };
  return (
    <>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="isAppliedToAll"
        >
          <div className="flex gap-5">

            <h1 className="text-[#030229] pl-1 text-sm font-normal">Apply To All Shops</h1>
            <Switch defaultChecked={isApplyCommission} checked={isApplyCommission} onChange={onChange} />
          </div>
        </Form.Item>
      {!isApplyCommission && <Form.Item
          name="Shops"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Shops
            </span>
          }
          className="w-full"
          rules={[
            { required: true, message: "Please Product is Required" },
          ]}
        >

          <Select
            mode="multiple"
            placeholder="Select Shop(s)"
            value={selectedItems}
            onChange={setSelectedItems}
            style={{ width: '100%' }}
            options={shopItems}
          />
        </Form.Item>}

        <Form.Item
          label="Commission Price"
          name="price"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item >
          <button
            type="submit"
            className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {channel.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
export default AssignPriceListCommissionForm;
