import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ProductForm from "../../../../components/forms/NewProductForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import {
  deleteProductsAction,
  getOneProductAction,
} from "../../../../store/product/actions";
import SidePanelDetails from "../../../../components/cards/SidePanelDetails";
import ProductModelCard from "../../../../components/grids/warehouse/ProductModelCard";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getStockStatsByIdAction } from "../../../../store/wareHouse/actions";

const ProductDetailsStock = (props: any) => {
  const { productType } = useParams();
  const { auth, product,wareHouse } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deleteProductsAction(auth?.token, {
        selectedIds: [product?.selected?._id],
      })(dispatch));
    navigate("/inventory/pr");
  };

  const handleClickEdit = () => {
    console.log("editing a product....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (productType) {
        console.log("fetching....");
        auth?.token &&
        (await getStockStatsByIdAction(   auth?.token,`/${productType}`)(dispatch));
        auth?.token &&
          (await getOneProductAction(auth?.token, productType)(dispatch));
      }
    };
    fetchOnProduct();
  }, [productType, auth?.token, dispatch]);
  return (
    <div className="text-black">
      <DetailsHeaderActionBar pageName="Warehouse" title={product.selected?.model} />
      <div className="flex flex-row-reverse gap-2 pt-12">
        <SidePanelDetails
          UpdateForm={ProductForm}
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          simpleDetails={product && product.selected}
          specsData={product.selected && product.selected?.specs}
        />
        <div className="flex flex-1 flex-wrap gap-2">
          {wareHouse?.allStockProductStats?.data?.map((item: any, _index: number) => (
            <ProductModelCard product={product?.selected} data={item} />
          ))}
        </div>
      </div>

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={product.isFetching || wareHouse.isFetching}
        onCancel={handleCancels}
        itemName="Product"
      />
    </div>
  );
};

export default ProductDetailsStock;
