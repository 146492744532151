import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Form, Input } from "antd";

import { useSelector, useDispatch } from "react-redux";
import {
  createChannelAction,
  updateChannelAction,
} from "../../store/channel/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewChannelPosSubForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    auth?.token &&
      !props?.channelId &&
      (await createChannelAction(auth?.token, values)(dispatch));
    auth?.token &&
      props?.channelId &&
      (await updateChannelAction(
        props?.channelId,
        values,
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Names"
        tooltip="Please enter Name?"
        rules={[
          { required: true, message: "Name is required!", whitespace: true },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider orientation="left">Add Contacts</Divider>

      <Form.List name="contact" {...formItemLayout}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Stack>
                <Stack direction={"row"}>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Missing names" }]}
                    style={{ minWidth: 250 }}
                  >
                    <Input placeholder="Names" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "position"]}
                    rules={[{ required: true, message: "Missing position" }]}
                  >
                    <Input placeholder="Position" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "phone"]}
                    rules={[{ required: true, message: "Missing Contacts" }]}
                  >
                    <Input placeholder="Contacts" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Stack>
              </Stack>
            ))}
            <Form.Item>
              <Button onClick={() => add()} startIcon={<PlusOutlined />}>
                Add Contacts
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "100%" }}
          loading={channel?.isFetching}
        >
          {props.data ? "update" : "Save"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default NewChannelPosSubForm;
