import { Carousel, Form } from "antd";
import SearchBar from "../../../components/SearchBar";
import { LoadingButton } from "@mui/lab";
import { getAllShopStockAction } from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivePrice,
  getActiveShop,
  getTotalPrice,
} from "../../../utils/converter";
import BarcodeScanner from "../../../components/BarcodeScanner";
import { useState } from "react";

const POSItemSearch = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const productData = wareHouse?.searchResult;

  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    auth?.token &&
      (await getAllShopStockAction(
        auth?.token,
        `?serialNumber=${values?.query}&shopId=${
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
          auth?.user?.shop?.shopId
        }`
      )(dispatch));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  return (
    <div className="bg-white rounded-lg text-black pl-4 pr-4 pt-2 pb-2 leading-none">
      <div className="flex justify-between">
        <div>
          <div className="flex">
            <Form onFinish={onFinish}>
              <Form.Item
                name="query"
                label="Enter serial number"
                initialValue={scannedCode}
              >
                <SearchBar
                  isLoading={wareHouse.isFetching}
                  scannedCode={newSearch ? "" : scannedCode}
                  onChange={handleChange}
                />
              </Form.Item>
              <div className="py-2">
                {!wareHouse?.isFetching &&
                  wareHouse?.searchResult?.status === 200 &&
                  wareHouse?.searchResult?.data?.isInShop &&
                  wareHouse?.searchResult?.data?.product?.status !== "sold" && (
                    <p className="font-semibold text-green-500">
                      Product found
                    </p>
                  )}
                {!wareHouse?.isFetching &&
                  wareHouse?.searchError?.status === 404 && (
                    <p className="font-semibold text-red-500">
                      Product not found
                    </p>
                  )}
                {!wareHouse?.isFetching &&
                  !wareHouse?.searchResult?.data?.isInShop && (
                    <div className="font-semibold text-yellow-500">
                      {wareHouse?.searchResult?.message}
                    </div>
                  )}
                {!wareHouse?.isFetching &&
                  wareHouse?.searchResult?.data?.isInShop &&
                  wareHouse?.searchResult?.data?.product?.status === "sold" && (
                    <div className="font-semibold text-yellow-500">
                      The product is sold
                    </div>
                  )}
              </div>
            </Form>
            <BarcodeScanner getCode={getCode} />
          </div>

          {wareHouse?.searchResult?.status === 200 &&
            wareHouse?.searchResult?.data?.isInShop &&
            wareHouse?.searchResult?.data?.product?.status !== "sold" && (
              <div className="flex gap-2">
                <div style={{ width: "7rem" }}>
                  <Carousel autoplay>
                    {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                      (im: any) => (
                        <div>
                          <img src={im} width={"100%"} alt="" />
                        </div>
                      )
                    )}
                  </Carousel>
                </div>
                <div className="flex gap-x-7">
                  <div className="grid grid-cols-2">
                    <p>Model:</p>
                    <p>
                      {
                        productData?.data?.product?.requestedItem?.product
                          ?.product?.model
                      }
                    </p>
                    <p>Type</p>
                    <p>
                      {
                        productData?.data?.product?.requestedItem?.product
                          ?.product?.type
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    {productData?.data?.product?.requestedItem?.product?.specification?.map(
                      (item: string, _index: number) => (
                        <>
                          <span>{item[0]}</span>
                          <span>{item[1]}</span>
                        </>
                      )
                    )}
                  </div>
                </div>
                <div className="ml-4">
                  <span>Price: </span>
                  <span>
                    {getActivePrice(
                      productData?.data?.product?.requestedItem?.product?.prices
                    )}
                  </span>
                </div>
                <div className="ml-4">
                  <span>Extended Warranty : </span>
                  <span>
                    {
                      productData?.data?.product?.requestedItem?.product
                        ?.extendedWarranty
                    }
                  </span>
                </div>
                <div className="ml-4">
                  <span>In Shop : </span>
                  <span>{productData?.data?.isInShop ? "Yes" : "No"}</span>
                </div>
              </div>
            )}
        </div>
        <div className="gap-4">
          <div className="p-2 text-right">
            <p className="font-bold">Total Price:</p>
            <p className="font-semibold">
              {getTotalPrice(
                wareHouse?.createdCart?.data?.list
              )?.toLocaleString()}
            </p>
          </div>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={props?.addToCart}
            disabled={!productData?.data?.isInShop}
            // startIcon={<AddIcon />}
            sx={{ minWidth: "100%" }}
            // loading={channel?.isFetching}
          >
            Add To Cart
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default POSItemSearch;
