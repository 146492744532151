import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "./DetailPage";
import DeleteModal from "../../../components/Modals/DeleteModal";
import {
  deleteCustomerAction,
  getOneCustomerAction,
} from "../../../store/customer/actions";
import CustomerProductTable from "../../../components/tables/CustomerProductsTable";
import CustomerRepairTable from "../../../components/tables/CustomerRepairTable";
import CustomerSubscriptionTable from "../../../components/tables/CustomerSubscriptionTable";
import CustomerInvoicesTable from "../../../components/tables/CustomerInvoiceTable";

type TAB = {
  title: string;
  component: any;
};

const tabs: TAB[] = [
  {
    title: "Purchased",
    component: <CustomerProductTable />,
  },
  {
    title: "Repairs",
    component: <CustomerRepairTable />,
  },
  {
    title: "Subscription",
    component: <CustomerSubscriptionTable />,
  },
  // TODO: WE HAVE TO RESUME THIS BY FETCHING INVOICES THROUGH CUSTOMER PROFILE
  // {
  //   title: "Invoices",
  //   component: <CustomerInvoicesTable />,
  // },
];

const CustomerDetails = () => {
  const { customerId } = useParams();
  const { auth, customer } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const deleteCustomer = async () => {
    auth?.token &&
      (await deleteCustomerAction(customer?.selected?._id, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
    navigate("/customers");
  };
  const handleClickEdit = () => {
    console.log("editing a customer....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (customerId) {
        console.log("fetching....");
        auth?.token &&
          (await getOneCustomerAction(auth?.token, customerId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [customerId, auth, dispatch]);

  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide">
      <DetailsPage
        pageItemId={customerId}
        tabs={tabs}
        pageName={"Customer"}
        title={customer?.selected?.name}
        simpleDetails={customer && customer.selected}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />

      <DeleteModal
        visible={visible}
        isLoading={customer.isFetching}
        onOk={deleteCustomer}
        onCancel={handleCancels}
        itemName="Customer"
      />
    </div>
  );
};

export default CustomerDetails;
