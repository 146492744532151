import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";
export const getAllOverViewService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/${query}`, token);
};
export const getAllOrderHistoryService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/orderHistory/${query}`, token);
};
export const getAllPaymentHistoryService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/history/${query}`, token);
};

