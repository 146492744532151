import { myKPIActions } from ".";
import { getAgentEarningsService, getAllAgentRevenueService } from "./services";

export const getAllAgentRevenueAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myKPIActions.setIsAllFetching(true));
      const res = await getAllAgentRevenueService(token, query);
      if (res?.status === 200) {
        dispatch(myKPIActions.setAll(res));
        dispatch(myKPIActions.setIsAllFetching(false));
      }
      dispatch(myKPIActions.setIsAllFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAgentEarningsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myKPIActions.setIsFetching(true));
      const res = await getAgentEarningsService(token, query);
      if (res?.status === 200) {
        dispatch(myKPIActions.setAllEarning(res));
        dispatch(myKPIActions.setIsFetching(false));
      }
      dispatch(myKPIActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
