import react, { useState } from "react";
import { ReactComponent as SlackIcon } from "../../../assets/icons/Slack.svg";
import { ReactComponent as ConnectIcon } from "../../../assets/icons/bi_plug.svg";
import { useSelector } from "react-redux";
import { Card, Skeleton } from "antd";
const IntegrationCards = () => {
  const { auth, setting } = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true);
  const [connected,setConnected]=useState(true);

  const styles = {
    custom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.04)",
      border: "1px solid rgba(15, 15, 71, 0.10)"
    }
  };
  const btnStyles = {
    btnCustom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.05)",
      border: "0.5px solid rgba(15, 15, 71, 0.10)"
    }
  };
  const allIntegrations = setting?.integration?.data;
  return (
    <>
      <div className="flex gap-5 flex-wrap w-full">
        {setting?.isFetching ? (
          <>
            <Card style={{ width: 300, marginTop: 16 }}>
              <Skeleton loading={loading} avatar active></Skeleton>
            </Card>
            <Card style={{ width: 300, marginTop: 16 }}>
              <Skeleton loading={loading} avatar active></Skeleton>
            </Card>
          </>
        ) : (
          allIntegrations?.map((el: any) => (
            <>
              <div
                style={styles.custom}
                className="w-[338px] relative bg-white h-[200px] rounded-[10px] "
              >
                <div className="flex gap-2 p-2">
                  <div className="w-10 h-10 rounded-full"> <img src={el?.logo} alt="logo" className="w-full rounded-full"/></div>
                 
                  <h1 className="text-[#0F0F47] font-medium text-base pt-3">
                    {el?.name}
                  </h1>
                </div>
                <div className="h-[190px]">
                <p className="pl-6 pr-8 text-[#0F0F4780] font-normal text-sm">
                  {el?.description}{" "}
                </p>
                </div>
                <div className="flex justify-between right-5 rounded-md absolute bottom-2">
                  
                  {/* <button
                    className="flex gap-2 bg-[#605BFF] rounded-full pt-2 pb-1 px-3 text-[#0F0F47] font-medium text-sm "
                    style={btnStyles.btnCustom}
                  >
                    <ConnectIcon color="white"/> <span className="-pt-2 text-white">Connected</span>{" "}
                  </button> */}
                  <button
                    className="flex gap-2 rounded-full bg-white pt-2 pb-1 px-3 text-[#0F0F47] font-medium text-sm "
                    style={btnStyles.btnCustom}
                    onClick={()=>setConnected(true)}
                  >
                    <ConnectIcon /> <span className="-pt-2">Connect</span>{" "}
                  </button>
                </div>
              </div>
            </>
          ))
        )}
      </div>
    </>
  );
};
export default IntegrationCards;
