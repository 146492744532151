import { Stack, Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { DatePicker, DatePickerProps, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import { getAllAttributesAction } from "../../store/product/actions";
import { getAllTransactionInRangeAction } from "../../store/pos/actions";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import { myChannelActions } from "../../store/channel";
import dayjs from "dayjs";
import moment from "moment";
import { handleSelectedShop } from "../../store/layout/actions";
import { getActiveShop, searchFuction } from "../../utils/converter";
import { myPosActions } from "../../store/pos";

const POSTransactionsFiltering = (props: any) => {
  const { product, auth, pos, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [shopFilter, setShopFilter] = useState([]);
  const channels = auth?.user?.channel;
  const initialChannel = channels && channels?.at(0)?._id;
  const [shop, setShop] = useState("");
  const { Option } = Select;

  dispatch(
    myPosActions.setQuery(
      `?${
        props?.selectedChannel
          ? `channel=${initialChannel ?? props?.selectedChannel}`
          : ""
      }&${shop ? `shop=${shop}` : ""}&${
        props?.selectType &&
        `type=${props?.selectType}&${
          props?.selectBrand && `brand=${props?.selectBrand}&`
        }`
      }`
    )
  );

  props?.selectedChannel &&
    dispatch(
      myChannelActions.setQuery(
        `${props?.selectedChannel && `channel=${props?.selectedChannel}}`}`
      )
    );

  const resetFilter = () => {
    if (selectedFilters) {
      props?.setSelectType("");
      props?.setSelectBrand("");
      props?.setSelectedChannel("");
      props.setSelectedStatus("");
      setShop("");
      handleSelectedShop("")(dispatch);
    }
  };

  useEffect(() => {
    if (props?.selectType || props?.selectBrand) {
      auth?.token &&
        props?.selectedChannel &&
        getAllTransactionInRangeAction(
          auth?.token,
          `?startDate=${props?.selectedDate}&endDate=${
            props?.selectedEndDate
          }&channel=${
            initialChannel ?? props?.selectedChannel
          }&shop=${shop}&limit=${props?.limit}&page=${props?.page - 1}&type=${
            props?.selectType
          }&brand=${props?.selectBrand}`
        )(dispatch);
    } else {
      auth?.token &&
        props?.selectedChannel &&
        getAllTransactionInRangeAction(
          auth?.token,
          `?startDate=${props?.selectedDate}&endDate=${
            props?.selectedEndDate
          }&channel=${
            initialChannel ?? props?.selectedChannel
          }&shop=${shop}&limit=${props?.limit}&page=${props?.page - 1}`
        )(dispatch);
    }

    auth?.token &&
      getAllAttributesAction(auth?.token, product?.query || "?")(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    dispatch,
    product?.query,
    shop,
    props?.selectedDate,
    props?.selectedEndDate,
    props?.limit,
    props?.page,
    props?.selectBrand,
    props?.selectType,
    initialChannel,
  ]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch, channel?.query]);

  useEffect(() => {
    if (shop) {
      handleSelectedShop(shop)(dispatch);
    } else {
      handleSelectedShop("")(dispatch);
    }
  }, [shop, dispatch]);

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    props?.setSelectedDate(dateString);
  };

  const onChangeEndDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    props?.setSelectedEndDate(dateString);
  };

  useEffect(() => {
    if (["partner"]?.includes(auth?.user?.role) && auth?.token) {
      getAllShopAction(auth?.token, `channel=${initialChannel}`)(dispatch);
    }
  }, [auth?.token, initialChannel, dispatch]);

  const franchiseeShops =
    ["franchisee"]?.includes(auth?.user?.role) &&
    getActiveShop(auth?.user?.shop?.assigned);

  return (
    <div className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={`space-between`}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {
            <Button
              startIcon={
                selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
              }
              onClick={() => {
                setSelectedFilters(!selectedFilters);
                resetFilter();
              }}
            >
              Filter
            </Button>
          }

          {selectedFilters && (
            <Stack>
              {props?.route === "finance" && (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {!["franchisee", "partner"]?.includes(auth?.user?.role) && (
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      allowClear
                      placeholder="Filter By Channel"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      onChange={(value: any) => {
                        auth?.token &&
                          getAllShopAction(
                            auth?.token,
                            `channel=${value}`
                          )(dispatch);
                        !value && props?.setSelectedChannel("");
                        value && props?.setSelectedChannel(value);
                      }}
                    >
                      {channel?.all?.data?.map((el: any) => (
                        <Option value={el?._id}>{el?.name}</Option>
                      ))}
                    </Select>
                  )}

                  {props?.selectedChannel && (
                    <Select
                      loading={channel.isFetching}
                      disabled={channel.isFetching}
                      showSearch
                      onSearch={(value: any) =>
                        searchFuction(
                          value,
                          channel?.allShop?.data,
                          (option: any, val) =>
                            option?.name
                              ?.toLowerCase()
                              .includes(val.toLowerCase()),
                          setShopFilter
                        )
                      }
                      allowClear
                      style={{ width: 200 }}
                      placeholder="Filter By Shop"
                      optionFilterProp="children"
                      filterOption={false}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      onChange={(value: any) => {
                        !value && setShop("");
                        value && setShop(value);
                      }}
                    >
                      {shopFilter.length > 0
                        ? shopFilter.map((el: any) => (
                            <Option value={el?._id}>{el?.name}</Option>
                          ))
                        : channel?.allShop?.data?.map((el: any) => (
                            <Option value={el?._id}>{el?.name}</Option>
                          ))}
                    </Select>
                  )}

                  {["franchisee", "partner"]?.includes(auth?.user?.role) && (
                    <Select
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      showSearch={["partner"]?.includes(auth?.user?.role)}
                      onSearch={(value: any) =>
                        searchFuction(
                          value,
                          channel?.allShop?.data,
                          (option: any, val) =>
                            option?.name
                              ?.toLowerCase()
                              .includes(val.toLowerCase()),
                          setShopFilter
                        )
                      }
                      allowClear
                      style={{ width: 200 }}
                      placeholder="Filter By Shop"
                      optionFilterProp="children"
                      filterOption={false}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      onChange={(value: any) => {
                        !value && setShop("");
                        value && setShop(value);
                      }}
                    >
                      <>
                        {["franchisee"]?.includes(auth?.user?.role)
                          ? franchiseeShops?.map((el: any) => (
                              <Option value={el?.shop?._id}>
                                {el?.shop?.name}
                              </Option>
                            ))
                          : shopFilter.length > 0
                          ? shopFilter.map((el: any) => (
                              <Option value={el?._id}>{el?.name}</Option>
                            ))
                          : channel?.allShop?.data?.map((el: any) => (
                              <Option value={el?._id}>{el?.name}</Option>
                            ))}
                      </>
                    </Select>
                  )}

                  <Select
                    showSearch
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Filter By Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toString().includes(input)
                    }
                    loading={product.isFetching}
                    disabled={product.isFetching}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")

                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    options={product?.attributes?.types}
                    onChange={(value: any) => {
                      !value && props?.setSelectType("");
                      value && setShop("");
                      value && props?.setSelectType(value);
                    }}
                  />

                  {props?.selectType && (
                    <Select
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      showSearch
                      allowClear
                      style={{ width: 200 }}
                      placeholder="Filter By Brand"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      options={product?.attributes?.brands}
                      onChange={(value: any) => {
                        !value && props?.setSelectBrand("");
                        value && props?.setSelectBrand(value);
                      }}
                    />
                  )}
                </Stack>
              )}
            </Stack>
          )}
          <h1 className="text-gray-400">
            Available{pos?.transaction?.result > 1 && "s"}:{" "}
            {pos.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              pos?.transaction?.result
            )}
          </h1>
        </Stack>
        <div className="space-x-6 flex justify-end">
          <div className="flex items-center space-x-2">
            <span>From</span>
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dayjs().subtract(1, "day")}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
          <div className="flex items-center space-x-2">
            <span>To</span>
            <DatePicker
              onChange={onChangeEndDate}
              defaultValue={dayjs()}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default POSTransactionsFiltering;
