import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import MakerIcon from "../makericon.png";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = { lat: -1.94407, lng: 30.0619 };

interface Coordinates {
  lat: number;
  lng: number;
}

interface MapSectionProps {
  trackingId: string;
  hasValidLocations: boolean;
}

export const MapSection: React.FC<MapSectionProps> = ({ trackingId, hasValidLocations }) => {
  const { tracking } = useSelector((state: any) => state);
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [busPosition, setBusPosition] = useState<Coordinates | null>(null);
  const [routePath, setRoutePath] = useState<Coordinates[]>([]);
  const [busRotation, setBusRotation] = useState<number>(0);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  if (!apiKey) {
    throw new Error("Google Maps API key is missing");
  }

  useEffect(() => {
    if (!map || !hasValidLocations) return;

    const locationFrom = tracking.selected?.data?.[0]?.locationFrom?.coordinate;
    const locationTo = tracking.selected?.data?.[0]?.locationTo?.coordinate;

    if (!locationFrom?.lat || !locationTo?.lat) {
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new google.maps.LatLng(locationFrom.lat, locationFrom.lng),
        destination: new google.maps.LatLng(locationTo.lat, locationTo.lng),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (
        result: google.maps.DirectionsResult | null,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirectionsResponse(result);
          const path = result.routes[0].overview_path.map(
            (point: google.maps.LatLng) => ({
              lat: point.lat(),
              lng: point.lng(),
            })
          );
          setRoutePath(path);
          setBusPosition(path[path.length - 1]); // Start from the end
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  }, [map, tracking.selected?.data, hasValidLocations]);

  useEffect(() => {
    let busAnimationTimeout: NodeJS.Timeout;

    if (routePath.length > 0) {
      let index = routePath.length - 1;

      const animateBus = () => {
        if (index >= 0) {
          const prevIndex = index - 1;
          if (prevIndex >= 0) {
            const angle = calculateAngle(
              routePath[index],
              routePath[prevIndex]
            );
            setBusRotation(angle + 360);
          }
          setBusPosition(routePath[index]);
          index--;
          busAnimationTimeout = setTimeout(animateBus, 1000);
        }
      };

      animateBus();

      return () => clearTimeout(busAnimationTimeout);
    }
  }, [routePath]);

  const calculateAngle = (start: Coordinates, end: Coordinates) => {
    const latDiff = end.lat - start.lat;
    const lngDiff = end.lng - start.lng;
    return (Math.atan2(latDiff, lngDiff) * 180) / Math.PI;
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  return (
    <div className="relative w-full h-full">
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
          onLoad={handleMapLoad}
        >
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
          {busPosition && (
            <Marker
              position={busPosition}
              icon={{
                url: MakerIcon,
                scaledSize: new google.maps.Size(50, 50),
                anchor: new google.maps.Point(25, 25), // Center the icon
                rotation: busRotation, // Rotate icon
              }}
            />
          )}
          {routePath.length > 0 && <Polyline path={routePath} />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};
