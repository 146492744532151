export const supplierTypes = [
  {
    value: "international",
    label: "International Supplier",
  },
  {
    value: "local",
    label: "Local Supplier",
  },
];
