import { notification } from "antd";
import { mySubscritionActions } from ".";
import { getAllOrderHistoryService, getAllOverViewService, getAllPaymentHistoryService,
} from "./services";
export const getAllOverViewAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllOverViewService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAll(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllOrderHistoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllOrderHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAllOrderHistory(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllPaymentHistoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllPaymentHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAllPaymentHistory(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

