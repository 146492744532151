import { Button } from "antd";
import React from "react";

interface btnProps {
  btnName?: any;
  btnBgColor?: any;
}
const VaultButton = ({ btnName, btnBgColor }: btnProps) => {
  return (
    <>
      <button
        type="submit"
        className="w-full py-2 rounded-md bg-[#605BFF] text-white mt-5"
      >
        {btnName}
      </button>
    </>
  );
};
export default VaultButton;
