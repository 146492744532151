import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Form, Modal, Radio, Select } from "antd";
import { Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddNewCustomer from "../../../../components/forms/NewCustomer";
import LoadingEllipsis from "../../../../components/Loaders/LoadingEllipsis";
import { getOneCustomerByQueryAction } from "../../../../store/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Stack } from "@mui/material";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import SimpleCartItems from "../SimpleCartItems";
import { getTotalPaidAmount } from "../../../../utils/converter";
import UploadComponent from "../../../../components/forms/Upload";
import { myCustomerActions } from "../../../../store/customer";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const UserInfoAndPaymentMethod: React.FC<{ cartId: any }> = ({ cartId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const dispatch = useDispatch();
  const { auth, channel, customer, wareHouse } = useSelector(
    (state: any) => state
  );
  const { Search } = Input;
  const [form] = Form.useForm();
  const paidAmount = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);

  const handleCancel = () => {
    console.log("Clossing.....");
    setIsModalOpen(false);
  };

  const onSearch = async (query: string) => {
    console.log("HEre: ", query);

    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${query}`
      )(dispatch));
    console.log("after....");
  };

  const onFinish = async (values: any) => {
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        ...values,
      })(dispatch));
    console.log("Values: ", values);
  };
  const handleDiscountChange = async (e: any) => {
    const discount = {
      discounType: e.target.value,
      discountPerc: e.target.value?.split("-")[1],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  const fileUpload = async (newFiles: any) => {
    // console.log("Image",newFiles.map((pic: any) => pic?.response?.data?.secure_url))
    const discount = {
      proof: [...newFiles.map((pic: any) => pic?.response?.data?.secure_url)],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  useEffect(() => {
    const addCustomer = async () => {
      customer?.new?.data && auth?.token &&
        (await getOneCustomerByQueryAction(
          auth?.token,
          `?phone=${customer?.new?.data?.phone}`
        )(dispatch));
      dispatch(myCustomerActions.setNew(null));
      dispatch(myCustomerActions.setSearchResult(null));
      console.log("After unsetting states....");
    };
    addCustomer();
  }, [auth?.token, customer?.new, customer?.searchResult, dispatch]);

  const handlePaymentType = (value: any) => {
    console.log("::::py", value);
    setPaymentType(value);
  };

  return (
    <>
      <div className="bg-white p-2 rounded-lg">
        <div className="grid lg:grid-cols-2 w-full overflow-x-hidden">
          <div className="space-y-4">
            {/* customer info */}
            <div>
              <div className="flex items-center py-2 space-x-5">
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  style={{ width: 200 }}
                  loading={customer?.isFetching}
                />

                <span>Or</span>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  startIcon={<PlusOutlined />}
                  //   sx={{ minWidth: "100%" }}
                  loading={channel?.isFetching}
                  onClick={() => setIsModalOpen(true)}
                >
                  Add New
                </LoadingButton>
              </div>

              <div className="py-2">
                {!customer?.isFetching &&
                  wareHouse?.createdCart?.data?.customer && (
                    <p className="font-semibold text-green-500">
                      Customer Added
                    </p>
                  )}
                {!customer?.isFetching &&
                  customer?.searchResult?.length < 1 && (
                    <p className="font-semibold text-red-500">
                      Customer not found
                    </p>
                  )}
                {customer?.isFetching && <LoadingEllipsis />}
              </div>
              <div>
                <div className="flex">
                  <p className="font-semibold mr-2">Name: </p>
                  <p>{wareHouse?.createdCart?.data?.customer?.name}</p>
                </div>
                <div className="flex">
                  <p className="font-semibold mr-2">Phone Number:</p>
                  <p>{wareHouse?.createdCart?.data?.customer?.phone}</p>
                </div>
                <div className="flex">
                  <p className="font-semibold mr-2">Address:</p>
                  <p>{wareHouse?.createdCart?.data?.customer?.email}</p>
                </div>
              </div>
            </div>

            {/* Discount section */}
            <div className="space-y-2 mb-4">
              <p>Discount Type</p>
              <div>
                <Radio.Group
                  buttonStyle="solid"
                  onChange={handleDiscountChange}
                  defaultValue={
                    wareHouse?.createdCart?.data?.discount?.discounType ===
                    "private"
                      ? "default-0"
                      : wareHouse?.createdCart?.data?.discount?.discounType
                  }
                >
                  <Radio.Button value="default-0" defaultChecked>
                    None
                  </Radio.Button>
                  <Radio.Button value="prestige-5">Prestige</Radio.Button>
                  <Radio.Button value="mtn_staff-10">MTN Staff</Radio.Button>
                  <Radio.Button value="momo_staff-5">MoMo Staff</Radio.Button>
                </Radio.Group>
              </div>
              {wareHouse?.createdCart?.data?.discount?.discounType !==
                "private" &&
                wareHouse?.createdCart?.data?.discount?.discounType !==
                  "default-0" && (
                  <div>
                    <UploadComponent
                      setPictures={fileUpload}
                      isCard="picture-card"
                      limit={5}
                      // default={
                      //   wareHouse?.createdCart?.data?.discount && [
                      //     {
                      //       status: "done",
                      //       url: wareHouse?.createdCart?.data?.discount
                      //         ?.proof[0],
                      //     },
                      //   ]
                      // }
                    />
                  </div>
                )}
            </div>

            {/* Payment section */}
            <div className="pb-4">
              <h3 className="my-3">Payment Method</h3>
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                // initialValues={props.data || {}}
                // style={{ minWidth: 600 }}
                scrollToFirstError
                className="lg:max-w-[600px]"
              >
                <Form.List
                  name="payment"
                  {...formItemLayout}
                  initialValue={[...wareHouse?.createdCart?.data?.payment]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Stack direction={"row"}>
                          <Form.Item
                            {...restField}
                            name={[name, "mode"]}
                            rules={[
                              { required: true, message: "Missing names" },
                            ]}
                            style={{ minWidth: 250 }}
                          >
                            <Select
                              onChange={handlePaymentType}
                              showSearch
                              placeholder="Select a payment method"
                              optionFilterProp="children"
                              // onChange={onChange}
                              // onSearch={onSearch}
                              style={{ width: 200 }}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={[
                                {
                                  value: "MoMo_Pay",
                                  label: "MoMo Pay",
                                },
                                {
                                  value: "Macye_Macye",
                                  label: "Macye Macye",
                                },
                                {
                                  value: "Samphone_POS",
                                  label: "Samphone POS",
                                },
                                {
                                  value: "MTN_POS",
                                  label: "MTN POS",
                                },
                                {
                                  value: "Samphone_Bank_Deposit",
                                  label: "Samphone Bank Deposit",
                                },
                                {
                                  value: "MTN_Bank_Deposit",
                                  label: "MTN Bank Deposit",
                                },
                                {
                                  value: "Corporate_Sales",
                                  label: "Corporate Sales",
                                },
                                {
                                  value: "MTN_Staff_Device_Requisition",
                                  label: "MTN Staff Device Requisition",
                                },
                              ]}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing amount",
                              },
                            ]}
                          >
                            <Input placeholder="Amount" type="number" />
                          </Form.Item>

                          {paymentType !== "Macye_Macye" && (
                            <Form.Item
                              {...restField}
                              name={[name, "momoTransactioId"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Missing MoMo Transaction Id",
                              //   },
                              // ]}
                            >
                              <Input placeholder="Transaction Id" type="text" />
                            </Form.Item>
                          )}

                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            className=" my-auto"
                          />
                        </Stack>
                      ))}

                      {/* {
                        Array.from({length: 4}).map((item: any, _index: number) => (
                          
                        ))
                      } */}
                      <Form.Item>
                        <Button
                          onClick={() => add()}
                          startIcon={<PlusOutlined />}
                        >
                          Add Method
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div className="flex items-center justify-end gap-2 max-w-[500px]">
                  <p>
                    <span>Total</span>
                  </p>
                  <span className="text-right font-bold text-neutral-800 ">{`${
                    paidAmount?.toLocaleString() || 0
                  } RWF`}</span>
                </div>
                <Form.Item>
                  {/* <div className="flex"> */}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    // startIcon={<AddIcon />}
                    //   sx={{ minWidth: "100%" }}
                    loading={wareHouse?.isFetching}
                  >
                    Confirm payments
                  </LoadingButton>
                  {/* </div> */}
                </Form.Item>
              </Form>
            </div>
          </div>

          {/* cart items and summary */}
          <SimpleCartItems
            itemsList={wareHouse?.createdCart?.data?.list}
            discount={wareHouse?.createdCart?.data?.discount}
          />
        </div>
      </div>

      <Modal
        title={"Add New Customer"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">
            {<AddNewCustomer onCancel={handleCancel} />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserInfoAndPaymentMethod;
