import React from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../../store/purchase/actions";
import InventoryItemSearch from "../POS/InventoryItemSearch";
import InventorySearchCard from "../../../components/cards/SearchCard";
// import SearchSoldProductTable from "../../../components/tables/SearchSoldProductTable";

const PageView = (props: any) => {
  const { auth, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <div className="text-black py-6">
      <Stack spacing={3}>
        <div>
          <InventoryItemSearch />
        </div>
        <div className="flex space-x-4">
          <div className="w-[25%] rounded-lg space-y-4">
            {inventory?.searchBySn?.data?.map((d: any) => {
              return <InventorySearchCard data={d} />;
            })}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
