import React, { useEffect } from "react";
import { Tag, Table, Divider } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubdeliverynotesItemAction,
  getOnesubDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import UploadComponent from "../../../../components/forms/UploadFile";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

const PrintComponent = (props: any) => {
  const { deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const { auth, deliverynote, deliverynoteItem } = useSelector(
    (state: any) => state
  );
  const delivererDetails = deliverynote?.singleDeliveryNote?.deliverer;

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  useEffect(() => {
    if (deliveryNoteId && auth?.token) {
      getOnesubDeliveryNoteAction(auth?.token, deliveryNoteId)(dispatch);
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${deliveryNoteId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, deliveryNoteId, dispatch, limit, page]);

  const subdeliverynote = deliverynote.all.data;
  const filterSubdeliveryNote: any = subdeliverynote.filter(
    (item: any) => item._id === deliveryNoteId
  );

  const dataToDisplay = deliverynote?.subDeliveryNoteItem?.data?.map(
    (el: any) => {
      return {
        type: el?.wareHouseItem?.quotationItem?.product?.type,
        model: el?.wareHouseItem?.quotationItem?.product?.model,
        brand: el?.wareHouseItem?.quotationItem?.product?.brand,
        imei: el?.wareHouseItem?.serialNumber,
        specs: el?.wareHouseItem?.quotationItem?.specification,
      };
    }
  );

  const columns = [
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "brand",
      title: "Brand",
      dataIndex: "brand",
    },
    {
      key: "model",
      title: "Model",
      dataIndex: "model",
    },
    {
      key: "imei",
      title: "IMEI",
      dataIndex: "imei",
    },
    {
      key: "specs",
      title: "Specs",
      dataIndex: "specs",
      width: 200,
      render: (_text: any, record: any) => (
        <p>
          {record?.specs &&
            record?.specs
              ?.slice(2, 6)
              ?.map((item: any) => item[1] && `${item[0]}:${item[1]}`)
              ?.join(" ")}
        </p>
      ),
    },
  ];

  const addressData =
    deliverynote?.singleDeliveryNote?.account?.customerDetails?.address &&
    deliverynote?.singleDeliveryNote?.account?.customerDetails?.address?.split(
      ", "
    );

  //

  return (
    <div>
      <div className="text-black flex flex-wrap justify-between px-6">
        <div className="pt-4">
          <h1 className="text-md font-semibold">Customer Details</h1>
          {deliverynote?.singleDeliveryNote?.account && (
            <div className="text-[#0F0F47] flex flex-col">
              <p>
                <PersonIcon style={{ color: "gray" }} />{" "}
                <span className="capitalize">
                  {
                    deliverynote?.singleDeliveryNote?.account?.customerDetails
                      ?.name
                  }
                </span>
              </p>
              <p>
                <PhoneIcon style={{ color: "gray" }} />{" "}
                <span className="capitalize">
                  {
                    deliverynote?.singleDeliveryNote?.account?.customerDetails
                      ?.tel
                  }
                </span>
              </p>
              <p>
                <EmailIcon style={{ color: "gray" }} />{" "}
                <span className="capitalize">
                  {
                    deliverynote?.singleDeliveryNote?.account?.customerDetails
                      ?.email
                  }
                </span>
              </p>
              <p className="flex">
                <LocationOnIcon style={{ color: "gray" }} />{" "}
                <span className="capitalize pl-1">
                  {addressData?.map((line: any, index: number) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />} {line?.trim()}{" "}
                    </React.Fragment>
                  ))}
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="text-black">
          <div className="pt-4">
            <div className="pb-2 flex flex-row justify-end">
              <Tag className="capitalize bg-[#ECBC4C] text-white w-18">
                {filterSubdeliveryNote[0]?.status?.split("-")?.join(" ")}
              </Tag>
            </div>
          </div>
        </div>
      </div>
      <div className="table w-full px-6 pt-6 pb-5">
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={deliverynote?.subDeliveryNoteItem?.total}
          count={Math.ceil(deliverynote?.subDeliveryNoteItem?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            loading={
              deliverynote?.deliveryIsFetching ||
              deliverynoteItem?.deliveryIsFetching
            }
            pagination={false}
          />
        </ScrollableFrame>
      </div>
      <h1 className="ml-4 pb-2 font-[400]">Delivery Note Documents</h1>
      {!deliverynote?.isFetching && (
        <>
          {deliverynote?.singleDeliveryNote?.status !== "delivered" ? (
            <div className="pb-10 ml-4">
              <UploadComponent
                setPictures={props?.setPictures}
                limit={10}
                isCard="picture-card"
              />
            </div>
          ) : (
            <div className="pb-10 ml-4 space-y-2">
              {deliverynote?.singleDeliveryNote?.documents?.map(
                (d: any, key: any) => {
                  return (
                    <div>
                      <a href={d} target="_blank" rel="noreferrer">
                        <p className="text-blue-400 hover:underline text-sm">
                          {`Documents ${key + 1}`}
                        </p>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </>
      )}
      <Divider />
      <div className="flex text-black justify-between w-full px-4 xpt-2">
        <div>
          ApprovedBy: {deliverynote?.singleDeliveryNote?.createdBy?.names}
        </div>
        <div>
          Date:{" "}
          {moment(deliverynote?.singleDeliveryNote?.createdAt).format(
            "MMMM Do YYYY"
          )}
        </div>
      </div>
      <div className="pt-4 ml-4">
        <h1 className="text-md font-semibold">Deliverer Details</h1>
        {delivererDetails && (
          <div className="text-[#0F0F47] flex flex-col">
            <p>
              <PersonIcon style={{ color: "gray" }} />{" "}
              <span className="capitalize">{delivererDetails?.name}</span>
            </p>
            <p>
              <PhoneIcon style={{ color: "gray" }} />{" "}
              <span className="capitalize">{delivererDetails?.phone}</span>
            </p>
            <p>
              <EmailIcon style={{ color: "gray" }} />{" "}
              <span className="capitalize">{delivererDetails?.email}</span>
            </p>
            <p>
              <BusinessIcon style={{ color: "gray" }} />{" "}
              <span className="capitalize">{delivererDetails?.company}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintComponent;
