import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel, Modal, Tag, notification } from "antd";
import DeleteModal from "../../Modals/DeleteModal";
import {
  deletePlanAction,
  getSubscriptionPricelistItemAction,
} from "../../../store/channel/actions";
import defaultPic from "../../../assets/nophoto.png";

import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";
import ThreeDotDropdown from "./threeDotDropdown";
import { useParams } from "react-router-dom";

const SubProductCardGrid = (props: any) => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceListData, setPriceListId] = useState(null);
  const [prodDataId, setProdDataId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [editSubPrice, setEditSubPrice] = useState(false);
  const dispatch = useDispatch();
  const { subChannelId } = useParams();

  useEffect(() => {
    if (!isModalOpen) {
      setPriceListId(null);
      setProdDataId(null);
    }
  }, [isModalOpen]);

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleEditSubPrice = async () => {
    setEditSubPrice(true);
  };
  const handleCancelSubPrice = () => {
    setEditSubPrice(false);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    await deletePlanAction(
      auth?.token,
      props?.data._id,
      `?channel=${channel?.selected?._id ?? subChannelId
      }&page=0&limit=15&type=${layout?.subscriptionType}`
    )(dispatch);

  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpenDetailsModel = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div
        className={`group shadow rounded-lg p-4 xmx-auto text-gray-900 bg-white relative ${layout.isSideNavOpen ? "w-[24rem]" : "w-[26.5rem]"
          } h-[23rem]`}
      >
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold mb-2 pl-1">
            {props?.data?.product?.model}
          </h2>
          {[
            "admin",
            "finance",
            "finance-manager",
            "dev",
            "b2b-relation",
          ].includes(auth?.user?.role?.toLowerCase()) && (
              <div>
                <ThreeDotDropdown
                  onUpdate={handleEditSubPrice}
                  onDelete={handleClickDelete}
                />
              </div>
            )}
        </div>
        <p className="text-sm font-normal text-gray-600 flex flex-wrap leading-4">
          {props?.data?.specification?.slice(0, 4)?.map(
            (data: any, _index: number) =>
              data[1] && (
                <div className="flex flex-row">
                  <p className="text-xs px-1">
                    <span className="font-semibold text-xs">{`${data[0]}: `}</span>
                    <span className="text-xs text-gray-600">
                      {` ${data[1]} |`}
                    </span>
                  </p>
                </div>
              )
          )}
        </p>
        <div className="flex justify-center mt-4 mb-6">
          {props?.data?.product?.images?.length === 0 ? (
            <div>
              <img src={defaultPic} className="w-32 h-32" alt="" />
            </div>
          ) : (
            <Carousel autoplay style={{ height: "128px", width: "128px" }}>
              {props?.data?.product?.images?.map((im: any) => (
                <div>
                  <img
                    src={im}
                    width={"100%"}
                    className=" h-36 object-cover"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="mt-6">
          <p className="text-center text-sm text-gray-600">
            {props?.data?.subscriptionPlan?.at(0)?.dailyInstalment && (
              <Tag className="py-0.5 mb-1">
                <span className="font-semibold text-xs">
                  {" "}
                  {`${props?.data?.subscriptionPlan
                    ?.at(0)
                    ?.dailyInstalment?.toLocaleString()} Rwf`}
                </span>
                <span className="text-xs text-gray-600">/Day</span>
              </Tag>
            )}
            {props?.data?.subscriptionPlan?.at(0)?.weeklyInstalment && (
              <Tag className="py-0.5">
                <span className="font-semibold text-xs">
                  {`${props?.data?.subscriptionPlan
                    ?.at(0)
                    ?.weeklyInstalment?.toLocaleString()} Rwf `}
                </span>
                <span className="text-xs text-gray-600">/Week</span>
              </Tag>
            )}
            {props?.data?.subscriptionPlan?.at(0)?.monthlyInstalment && (
              <Tag className="py-0.5">
                <span className="font-semibold text-xs">
                  {`${props?.data?.subscriptionPlan
                    ?.at(0)
                    ?.monthlyInstalment?.toLocaleString()} Rwf`}
                </span>
                <span className="text-xs text-gray-600">/Month</span>
              </Tag>
            )}
          </p>
          <p className="text-center text-lg font-semibold mt-1">{`For ${props?.data?.subscriptionPlan?.at(0)?.duration
            } Months`}</p>
        </div>

        <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button
            className="px-2.5 py-1.5 rounded-md transition-transform duration-300 ease-in-out hover:scale-110 bg-[#030229] hover:bg-[#605BFF] text-white"
            onClick={handleOpenDetailsModel}
          >
            See More Plans
          </button>
        </div>
      </div>

      <Modal
        title={"Edit Pricelist"}
        open={editSubPrice}
        onCancel={handleCancelSubPrice}
        footer={null}
        width={700}
      >
        <AddItem dataToUpdate={props?.data} onCancel={handleCancelSubPrice} />
      </Modal>

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
      <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        width={1000}
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default SubProductCardGrid;
