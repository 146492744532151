import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const data = [
  { value: 35, label: 'CANADA (70 Items)' },
  { value: 10, label: 'CHINA (50 Items)' },
  { value: 15, label: 'ARAB (90 Items)' },
  { value: 20, label: 'USA (250 Items)' },
  { value: 25, label: 'KOREA (40 Items)' },
];

const size = {
  width: 500,
  height: 200,
};

const CenteredLabel = styled('div')({
  position: 'absolute',
  top: '45%',
  left: '40%',
  transform: 'translate(-50%, -50%)',
  fontSize: '18px',
  fontWeight: 'semiBold',
  color: '#030229',
});

export default function PieChartWithCenterLabel() {
  const colors = ['#605BFF', '#807BFF', '#A09DFF', '#BFBDFF', '#DFDEFF'];

  const drawingArea = useDrawingArea();

  return (
    <div style={{ position: 'relative', ...size }}>
      <PieChart series={[{ data, innerRadius:65 }]} colors={colors} {...size} />
      <CenteredLabel>
       <p className='text-center'>500</p> 
      <p className='text-center'> Total Import</p> 
      </CenteredLabel>
    </div>
  );
}
