import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin, Radio, Select, Space, Switch, Button } from "antd";
import type { RadioChangeEvent, SelectProps } from 'antd';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCommissionAction, getDeviceWithCommissionAction } from "../../store/channel/actions";
import { useParams } from "react-router";

const AssignDeviceCommissionForm = (props: any) => {
  const { auth, setting, channel, shop } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const {shopId}=useParams();
  const dispatch = useDispatch();
const [isApplyCommission,setIsApplyCommission]=useState(false)

  const productItems = props?.data?.map((product: any) => ({
    value: product?._id,
    label: product?.product?.model,
  }))
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const onChange = (checked: boolean) => {
    setIsApplyCommission(checked)
  };
  const onFinish = async (values: any) => {
    auth?.token && (await createCommissionAction(auth?.token, { ...values,isAppliedToAll:isApplyCommission, channel: channel?.selected?._id, shops: [shop?.selected?._id] })(dispatch));
    auth?.token && await getDeviceWithCommissionAction( auth?.token,`channelId=${channel?.selected?._id}&shopId=${shopId}`)(dispatch);
    form.resetFields();
    props?.onCancel();
  };
  return (
    <>
      <Form
        name="basic"
        style={{ maxWidth: "100%" }}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="isAppliedToAll"
        >
          <div className="flex gap-5">

            <h1 className="text-[#030229] pl-1 text-sm font-normal">Apply To All Shops</h1>
            <Switch defaultChecked={isApplyCommission} checked={isApplyCommission} onChange={onChange} />
          </div>
        </Form.Item>
        <Form.Item
          name="product"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Products
            </span>
          }
          className="w-full"
          rules={[
            { required: true, message: "Please Product is Required" },
          ]}
        >

          <Select
            style={{ width: "100%" }}
            onChange={handleChange}
            options={productItems}
          />
        </Form.Item>

        <Form.Item
          label="Commission Price"
          name="price"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item >
          <button
            type="submit"
            className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {channel.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
export default AssignDeviceCommissionForm;
