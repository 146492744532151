import React from "react";
import * as cgIcons from "react-icons/cg";

interface IconProps {
  color?: string;
  size?: number;
  fill?:string;
}

type IconComponent = React.FC<IconProps>;

const CustomIcon: React.FC<{
  icon: IconComponent;
  color?: string;
  fill?:string;
  size?: number;
}> = ({ icon: Icon,fill, color , size = 25 }) => {
  return <Icon color={color} size={size} fill={fill}/>;
};

interface ContentProps {
  icon: IconComponent;
  tittle?: any;
  content?: any;
  iconColor?: string;
  iconSize?: number;
  tittleColor?: string;
  contentColor?: string;
  iconFill?:string;
  onClick?: any;
}

const SideBar: React.FC<ContentProps> = ({
  icon: Icon,
  tittle,
  content,
  iconColor,
  iconSize,
  tittleColor,
  contentColor,
  onClick,
  iconFill,
}) => {
  return (
    <>
      <div className="flex  gap-4 py-4 pl-6 cursor-pointer" onClick={onClick}>
        <CustomIcon icon={Icon} fill={iconFill} color={iconColor} size={iconSize} />
        <div>
          <h1 className={`text-${tittleColor} text-base font-medium`}>
            {tittle}
          </h1>
          <p className={`text-${contentColor} text-[13px] pr-4 font-normal`}>
            {content}
          </p>
        </div>
      </div>
    </>
  );
};

export default SideBar;
