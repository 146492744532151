import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  generateFirstInvoiceAction,
  getAllSubdeliverynotesAction,
  getAllSubdeliverynotesItemAction,
  updateSubsDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import PrintComponent from "./PrintComponent";
import { LoadingButton } from "@mui/lab";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { downloadPdf } from "../../../../utils/converter";
import { SERVER_URL } from "../../../../utils/constants";

const SubscriptionDeliveryNoteDetails = (props: any) => {
  const { deliveryNoteId } = useParams();
  const { auth, deliverynote } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState<Array<Object>>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const componentRef = useRef(null);

  const docs = pictures.map((pic: any) => pic?.response?.data?.secure_url);

  const handleAccept = async () => {
    if (deliveryNoteId) {
      await updateSubsDeliveryNoteAction(
        deliveryNoteId,
        {
          status: "delivered",
          documents: [...docs],
        },
        auth.token
      )(dispatch);

      await generateFirstInvoiceAction(
        auth.token,
        deliverynote?.singleDeliveryNote?.account?.order
      )(dispatch);
      goBack();
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getAllSubdeliverynotesAction(auth?.token)(dispatch);
      // getAllSubdeliverynotesItemAction(auth?.token)(dispatch);
    }
  }, [auth.token, dispatch]);
  const goBack = () => {
    navigate(-1);
  };

  const handlePrintPDF = async () => {
    const fileUrl = `${SERVER_URL}/subsDeliveryNote/file/one/${deliveryNoteId}`;
    downloadPdf(fileUrl, deliveryNoteId as string);
  };

  return (
    <div className="text-black  py-6 scrollbar-hide overflow-y-auto h-screen">
      <div className="flex flex-row items-center text-[#0F0F47] pb-4">
        <DetailsHeaderActionBar
          pageName={"Subscription / DeliveryNote"}
          title={deliverynote?.singleDeliveryNote?.SDNID}
          goBack={goBack}
        />
      </div>
      <Stack
        spacing={3}
        className="flex-grow flex flex-col justify-between bg-white scrollbar-hide"
      >
        <PrintComponent ref={componentRef} setPictures={setPictures} />
        <div className="text-black flex items-center justify-between px-4 pb-2">
          <div className="pt-4">
            {deliverynote?.singleDeliveryNote?.status !== "delivered" && (
              <LoadingButton
                loading={deliverynote?.isFetching}
                variant="contained"
                color="error"
                onClick={goBack}
                sx={{
                  bgcolor: deliverynote?.isFetching ? "#E5E5E5" : "#F1595C",
                  width: "112px",
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                Cancel
              </LoadingButton>
            )}
          </div>
          <div className="flex items-center space-x-2 px-6">
            <LoadingButton
              onClick={handlePrintPDF}
              variant="contained"
              loading={deliverynote?.isFetching}
              sx={{
                bgcolor: deliverynote?.isFetching ? "#E5E5E5" : "#4C39D4",
                width: "112px",
                color: "#fff",
                textTransform: "none",
              }}
            >
              Print
            </LoadingButton>

            {deliverynote?.singleDeliveryNote?.status !== "delivered" && (
              <LoadingButton
                variant="contained"
                loading={deliverynote?.isFetching}
                sx={{
                  bgcolor: deliverynote?.isFetching ? "#E5E5E5" : "#0FA958",
                  width: "112px",
                  color: "#fff",
                  textTransform: "none",
                }}
                onClick={handleAccept}
                // disabled={pictures?.length < 1}
              >
                Confirm
              </LoadingButton>
            )}
          </div>
        </div>
      </Stack>
    </div>
  );
};
export default SubscriptionDeliveryNoteDetails;
