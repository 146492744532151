import { notification } from "antd";
import { myTermsActions } from ".";
import {
  createService,
  getAllService,
  getOneService,
  updateService,
  updateIsDeleteService
} from "./services";

export const createConditionsAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsActions.setIsFetching(true));
      const res = await createService(data, token);
      const resAll = await getAllService(token, "");
      if (res?.status === 201) {
        dispatch(myTermsActions.setNew(res));
        dispatch(myTermsActions.setAll(resAll));
        dispatch(myTermsActions.setIsFetching(false));
      }
      dispatch(myTermsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllConditionsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsActions.setIsFetching(true));
      const res = await getAllService(token, query);
      if (res?.status === 200) {
        dispatch(myTermsActions.setAll(res));
        dispatch(myTermsActions.setIsFetching(false));
      }
      dispatch(myTermsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneconditionAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsActions.setIsFetching(true));
      const res = await getOneService(id, token);
      if (res?.status === 200) {
        dispatch(myTermsActions.setSelected(res?.data));
        dispatch(myTermsActions.setIsFetching(false));
      }
      dispatch(myTermsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteOneConditionsAction = (token: string, data: any,itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsActions.setIsFetching(true));
      const res = await updateIsDeleteService(data, token, itemId);

      console.log("dispatching the action.....");
      if (res?.status === 200) {
        dispatch(myTermsActions.setSelected(res?.data));
        const resAll = await getAllService(token, "");
        dispatch(myTermsActions.setAll(resAll));
        dispatch(myTermsActions.setIsFetching(false));
        notification.success({ message: " Terms & Conditions Deleted !" });
      }
      dispatch(myTermsActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const updateConditionsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsActions.setIsFetching(true));
      const res = await updateService(itemId, data, token);
      // console.log("dispatching the action.....")
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        const resAll = await getAllService(token, "");
        dispatch(myTermsActions.setAll(resAll));
        dispatch(myTermsActions.setSelected(res?.data));
        dispatch(myTermsActions.setIsFetching(false));
        notification.success({ message: " Terms & Conditions Updated !"});
      }
      dispatch(myTermsActions.setIsFetching(false));
    } catch (err) {
      // console.log("an error occured......")
      console.log(err);
    }
  };
};
