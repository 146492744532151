import { Button, DatePicker, Divider, Input, Table } from "antd";
import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface DataType {
  key: string;
  type: string;
  brand: string;
  model: string;
  specs: string;
  qtyRequested: number;
  qtyScanned: number;
}

const dataSource: DataType[] = [
  {
    key: "1",
    type: "Name",
    brand: "Apple",
    model: "Iphone 14 Pro",
    specs: "RAM: 8GB, ROM: 512GB",
    qtyRequested: 2,
    qtyScanned: 2,
  },
  {
    key: "2",
    type: "Name",
    brand: "Tecno",
    model: "Pop 7 (BF6)",
    specs: "RAM: 4GB, ROM: 64GB",
    qtyRequested: 2,
    qtyScanned: 2,
  },
  {
    key: "3",
    type: "Name",
    brand: "Apple",
    model: "Iphone 15 Pro",
    specs: "RAM: 8GB, ROM: 128GB",
    qtyRequested: 2,
    qtyScanned: 0,
  },
  {
    key: "4",
    type: "Name",
    brand: "Infinix",
    model: "Smart 7 Plus (X6517)",
    specs: "RAM: 4GB, ROM: 32GB",
    qtyRequested: 4,
    qtyScanned: 0,
  },
];

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Specs",
    dataIndex: "specs",
    key: "specs",
  },
  {
    title: "QTY requested",
    dataIndex: "qtyRequested",
    key: "qtyRequested",
  },
  {
    title: "QTY Scanned",
    dataIndex: "qtyScanned",
    key: "qtyScanned",
  },
  {
    title: "OTP",
    key: "otp",
    render: () => <Button className="text-[#605BFF]">Send OTP</Button>,
  },
  {
    title: "Verify Code",
    key: "verifyCode",
    render: () => <Input maxLength={6} style={{ width: "100px" }} />,
  },
];

const DeliveryNoteTrack: React.FC = () => {
  const handlePrint = () => {
    const input = document.getElementById("table-to-print");
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("delivery-note.pdf");
      });
    }
  };

  return (
    <div className="p-4 text-black bg-white h-screen">
      <div className="flex flex-row items-center justify-between gap-3">
        <div>
          <div className="text-[#030229] font-semibold text-lg">
            Deliverer Details
          </div>
          <div className="text-[#030229] opacity-70 text-md">
            <p>Yves Mugenga</p>
            <p>0789876122</p>
            <p>yves@gmail.com</p>
          </div>
        </div>
        <div className="flex flex-col justify-items-end gap-4">
          <div className="flex self-end">
            {""}
            <Button className="bg-[#4F4F4F] text-white font-poppins cursor-pointer opacity-25">
              Delivered
            </Button>
          </div>
          <div>
            <span className="ml-3">Delivery date:</span>
            <DatePicker />
          </div>
          <Button className="flex border-dashed border-[2px]  border-gray-400 w-[156px] h-[56px] self-end rounded-[10px] cursor-pointer p-3 items-center ">
            <MdOutlineCloudUpload size={32} color="#605BFF" />
            <div className="text-[#605BFF] font-semibold">Upload File</div>
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div></div>
        <div>
          <span className="font-semibold pl-3">Delivery Note</span>
          <span className="text-[#605BFF]">#SDNO0703231</span>
        </div>
        <div></div>
      </div>
      <div id="table-to-print" className="p-3">
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
      <div>
        <div className="text-[#030229] font-semibold text-lg">
          Receiver Details
        </div>
        <div className="text-[#030229] opacity-70 text-md">
          <p>Jackson Mugiraneza</p>
          <p>0781223342</p>
          <p>jackson@gmail.com</p>
        </div>
        <Button className="bg-[#605BFF] mt-3 w-40 text-white font-poppins rounded-md cursor-pointer">
          Send OTP
        </Button>
      </div>
      <div>
        <Divider />
      </div>
      <div className="flex justify-between items-center">
        <div>Approved By</div>
        <div></div>
        <div>Signature</div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <Button className="text-white bg-[#F1595C] cursor-pointer w-40">
          Cancel
        </Button>
        <div></div>
        <div className="flex items-center gap-4">
          <Button
            className="text-white bg-[#605BFF] cursor-pointer w-40"
            onClick={handlePrint}>
            Print
          </Button>
          <Button className="text-white bg-[#0FA958] cursor-pointer w-40">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryNoteTrack;
