import React from "react";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import { getAgentShopsAction } from "../../store/setting/actions";
import DeactivateAgentForm from "../forms/DeactivateAgentForm";
import { useMediaQuery } from "@mui/material";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const AgentTranctionsTable = (props: any) => {
  const { auth, setting ,layout} = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openModel, setOpenModel] = React.useState(false);
  const [deactivateData, setDeactivateData] = React.useState("");
  const columns: ColumnsType<DataType> = [
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text: any, record: any) => (
        <>
          <p>{text}</p>
          <p>{record?.data?.shop?.type}</p>
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Assigned By",
      dataIndex: "data",
      key: "data",
      render: (text, record) => <p>{record?.data?.createdBy?.names}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={searchValue(record?.data?.isActive === true ? "Active" : "")}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.isActive === true ? "Active" : " Not Active"}{" "}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        record?.data?.isActive === true && (
          <button
            className={`${
              record?.data?.isActive === true
                ? "hover:bg-red-500"
                : "hover:bg-green-500"
            } p-1 bg-transparent border-2 rounded-md cursor-pointer  hover:text-white hover:border-white capitalize`}
            onClick={() => showDeactivateAgentModal(record?.data)}
          >
            {"Deactivate"}
          </button>
        ),
    },
  ];

  const showDeactivateAgentModal = (d: any) => {
    setOpenModel(true);
    setDeactivateData(d);
  };
  const cancelDeactivateAgentModal = () => {
    setOpenModel(false);
    setDeactivateData("");
  };
  React.useEffect(()=>{
    layout?.viewUser && auth?.token && getAgentShopsAction(auth?.token,`?user=${layout?.userInfo?._id}`)(dispatch)
  },[layout?.viewUser,auth,dispatch,layout?.userInfo?._id])

  const value = setting?.agentShop?.data[0]?.assigned;

  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          channel: el?.channel.name,
          shop: el?.shop?.name,
          address: el?.shop?.address,
          startDate: el?.startDate,
          endDate: el?.endDate ? el?.endDate : "N/A",
          data: el,
        });
      });

    setformData(
      dataMapper?.sort(
        (a: any, b: any) =>
          new Date(a?.startDate)?.getTime() - new Date(b?.startDate)?.getTime()
      )
    );
  }, [setting.agentShop]);
  const smallDevices = useMediaQuery("(max-width:1320px)");
  // const smallDevices = useMediaQuery("(max-width: 1024px)");
  return (
    <>
      <Table
        columns={columns}
        dataSource={formData}
        onChange={onChange}
        loading={setting?.isFetching}
        scroll={smallDevices ? { x: 1300 } : undefined}
      />
      <Modal
        open={openModel}
        onCancel={cancelDeactivateAgentModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <DeactivateAgentForm
          onCancel={cancelDeactivateAgentModal}
          data={deactivateData}
        />
      </Modal>
    </>
  );
};

export default AgentTranctionsTable;
