import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Select, Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import CustomButton from "../Buttons/CustomButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import UploadPhoto from "./Upload";
const AddEmployeeForm: React.FC = () => {
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const toggleChecked = () => {
    setChecked(!checked);
  };

  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const [pictures, setPictures] = useState([]);
  const onChange: CheckboxProps["onChange"] = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };
  return (
    <Form
      layout="vertical"
      initialValues={{}}
      style={{ width: "100%", padding: "50px" }}
    >
        <div className="flex flex-col justify-center items-center ">
            <Form.Item>
            <UploadPhoto
          setPictures={setPictures}
          limit={1}
        //   default={}
        />
            </Form.Item>
          </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">First Name</p>}
          className="w-1/2"
        >
          <Input className="h-12" />
        </Form.Item>
        <Form.Item
          label={<p className="text-[#030229] text-sm">Joining Date</p>}
          className="w-1/2"
        >
          <DatePicker className="w-full h-12" />
        </Form.Item>
      </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Last Name</p>}
          className="w-1/2"
        >
          <Input className="h-12" />
        </Form.Item>
        <Form.Item
          label={<p className="text-[#030229] text-sm">Employee ID</p>}
          className="w-1/2"
        >
          <Input className="h-12" />
        </Form.Item>
      </div>
      <div className="flex gap-5 w-full">
      <Form.Item
            label={<p className="text-[#030229] text-sm">Telephone</p>}
            name="phone"
            // initialValue={props?.dataToUpdate?.phone}
            rules={[
              {
                required: true,
                message: "Phone Number is required!",
                max: 18,
              },
            ]}
            hasFeedback
          >
            <PhoneInput
              country={"rw"}
              inputStyle={{
                // width: "100%",
                height:"3rem",
              }}
              containerClass="phone-container"
              placeholder="+250 780 000 000"
            />
          </Form.Item>
        <div className="flex gap-2 ml-2">
          <Form.Item
            label={<p className="text-[#030229] text-sm">Basic Pay </p>}
            className="w-1/2"
          >
            <Input className="h-12" placeholder="0.000" />
          </Form.Item>
          <Button size="small" onClick={toggleChecked} className="h-12 mt-7">
            Per Day
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={toggleDisable}
            className="h-12 mt-7"
          >
            Monthly
          </Button>
        </div>
      </div>
      <div className="flex gap-12 w-full">
        <Form.Item
          label={<p className="text-[#030229] text-sm">Department </p>}
          className="w-1/2"
        >
          <Select className="h-12">
            <Select.Option value="Dev">Dev</Select.Option>
            <Select.Option value="finance">Finance</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={<p className="text-[#030229] text-sm">Gender</p>}
          className="w-1/2"
        >
          <Select className="h-12">
            <Select.Option value="male">Male</Select.Option>
            <Select.Option value="female">female</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="flex gap-5 w-full px-10">
        <Form.Item className="w-full">
          <CustomButton
            title={<h1 className="py-2">Discard</h1>}
            textColor="[#605BFF]"
            width="full"
            border="[#605BFF]"
          />
        </Form.Item>
        <Form.Item className="w-full">
          <CustomButton
            title={<h1 className="py-[8.5px] px-1">Save</h1>}
            bgColor="[#605BFF]"
            textColor="white"
            width="full"
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddEmployeeForm;
