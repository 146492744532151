import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";

const AddressDetailsForm = (props: any) => {
  const [form] = Form.useForm();
  const [showRest, setShowRest] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCell, setSelectedCell] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [listDistrict, setListDistrict] = useState<any[]>([]);
  const [listSector, setListSector] = useState<any[]>([]);
  const [listCell, setListCell] = useState<any[]>([]);
  const [listVillage, setListVillage] = useState<any[]>([]);
  const [provinceValue, setProvinceValue] = useState(
    props?.dataToUpdate?.shopLocation?.province
  );
  const [districtValue, setDistrictValue] = useState(
    props?.dataToUpdate?.shopLocation?.district
  );

  const provinceOptions: [{ value: string; label: string }] = Provinces().map(
    (pro: string) => ({
      label: pro,
      value: pro,
    })
  );

  // if Province changes
  useEffect(() => {
    try {
      setListDistrict(
        Districts(selectedProvince ?? "").map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setListSector([]);
      setSelectedSector("");

      return;
    }
  }, [selectedProvince]);

  useEffect(() => {
    try {
      setListSector(
        Sectors(selectedProvince ?? "", selectedDistrict ?? "").map(
          (val: string) => ({
            label: val,
            value: val,
          })
        )
      );
    } catch (e: any) {
      setSelectedCell("");
      setListCell([]);
      return;
    }
  }, [selectedDistrict, listDistrict]);

  useEffect(() => {
    try {
      setListCell(
        Cells(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setSelectedVillage("");
      setListVillage([]);
      return;
    }
  }, [listSector, selectedSector]);

  useEffect(() => {
    try {
      setListVillage([]);
      setSelectedVillage("");
      setListVillage(
        Villages(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? "",
          selectedCell ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      console.log(e);
    }
  }, [listCell, selectedCell]);

  useEffect(() => {
    if (props?.dataToUpdate) {
      form.setFieldsValue(props?.dataToUpdate?.shopLocation);
    } else {
      form.resetFields();
    }
  }, [props?.dataToUpdate]);

  useEffect(() => {
    setProvinceValue(props?.dataToUpdate?.shopLocation?.province);
    setDistrictValue(props?.dataToUpdate?.shopLocation?.district);
  }, [props?.dataToUpdate?.shopLocation]);

  return (
    <div className="mx-4 mt-4 lg:max-2xl:mt-2 lg:max-2xl:ml-0">
      <div className="grid grid-cols-2 space-x-2 lg:max-2xl:gap-4">
        <Form.Item
          name="province"
          label={<span className="text-[#0F0F47] text-sm">Province</span>}
          initialValue={provinceValue}
          rules={[
            {
              required: props?.dataToUpdate ? false : true,
              message: "Provence is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            onChange={(value: any) => {
              setSelectedProvince(value);
              form.resetFields(["district", "sector", "cell", "village"]);
            }}
            options={provinceOptions}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="district"
          label={<span className="text-[#0F0F47] text-sm">District</span>}
          initialValue={districtValue}
          rules={[
            {
              required: props?.dataToUpdate ? false : true,
              message: "District is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            onChange={(value: any) => {
              setSelectedDistrict(value);
              form.resetFields(["sector", "cell", "village"]);
            }}
            options={listDistrict}
            allowClear
          />
        </Form.Item>
        {showRest && (
          <>
            <Form.Item
              name="sector"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Sector</span>}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Sector is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedSector(value);
                  form.resetFields(["cell", "village"]);
                }}
                options={listSector}
                value={selectedSector}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="cell"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Cell</span>}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Cell is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedCell(value);
                  form.resetFields(["village"]);
                }}
                options={listCell}
                value={selectedCell}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="village"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Village</span>}
              initialValue={props?.dataToUpdate?.email}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Village is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => setSelectedVillage(value)}
                options={listVillage}
                value={selectedVillage}
                allowClear
              />
            </Form.Item>
          </>
        )}
      </div>
    </div>
  );
};

export default AddressDetailsForm;
