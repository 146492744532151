import { SiQuickbooks } from "react-icons/si";
import { CSVLink } from "react-csv";
import FilesCard from "./FilesCard";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openCSVButton } from "../../../store/layout/actions";
import { myCSVAction } from "../../../store/csvDownload";
import { Spin } from "antd";

interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
}
const QuickBookExportButton: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
}) => {
  const { CSV } = useSelector((state: any) => state);

  const csvLinkRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const handleButtonClick = (fileName: string) => {
    openCSVButton(true)(dispatch);
    dispatch(myCSVAction.setFileToExport(fileName));
  };

  useEffect(() => {
    if (CSV?.isFileDownload && csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
    openCSVButton(false)(dispatch);
    dispatch(myCSVAction.setFileDownloadedData(null));
    dispatch(myCSVAction.setIsFileDownload(false));
    dispatch(myCSVAction.setAllowCSVDownload(false));
    dispatch(myCSVAction.setFileToExport("csv"));
  }, [CSV?.isFileDownload]);

  return (
    <div onClick={() => handleButtonClick("quickBook")}>
      {CSV?.isFileFetching ? (
        <div className="w-20 pl-4">
          {" "}
          <Spin />
        </div>
      ) : (
        <FilesCard
          Icon={SiQuickbooks}
          tailwindTextColor="text-white-400"
          tailwindBackgroundColor="bg-[#00aa00]"
        />
      )}
      <CSVLink
        data={csvData ?? []}
        headers={csvHeaders}
        ref={csvLinkRef}
        filename={filename}
      />
    </div>
  );
};

export default QuickBookExportButton;
