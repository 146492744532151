import React, { useCallback, useEffect, useRef, useState } from "react";
import { Table, Tag, TableColumnsType, Modal, Popconfirm } from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { BrowserMultiFormatReader } from "@zxing/library";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubsDeliveryItemAction,
  getAllSubdeliverynotesItemAction,
} from "../../../../store/subscription/deliverynote/actions";
import { LoadingButton } from "@mui/lab";
import ScanPickListForm from "../../../../components/forms/ScanPickListForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Divider, IconButton } from "@mui/material";
import AddDelivererForm from "../../../../components/forms/AddDelivererForm";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import { getOneSubsOrderAction } from "../../../../store/subscription/order/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { mySubscritionActions } from "../../../../store/subscription/deliverynote";

interface ExpandedDataType {
  key: React.Key;
  type: string;
  brand: string;
  model: string;
  specs: string;
  qtySent: string;
  qtyReceived: string;
  scannedProduct: String;
}

const ComponentToPrint = (props: any) => {
  const scanner = useRef(new BrowserMultiFormatReader());
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { deliverynote } = useSelector((state: any) => state);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [opendelivereModel, setOpendelivereModel] = useState(false);
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [dataToScan, setDataToScan] = useState<any>("");
  const { auth, orders, deliverynoteItem, account } = useSelector(
    (state: any) => state
  );
  const delivererDetails = orders?.selected?.account?.deliveryNote?.deliverer;
  const deliveryNoteId = orders?.selected?.account?.deliveryNote;

  const singleOrder =
    deliverynoteItem?.selected === null
      ? orders?.selected?.list
      : deliverynoteItem?.selected?.list;

  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: deliverynoteItem?.subDeliveryNoteItem?.total,
    showSizeChanger: true,
  });

  useEffect(() => {
    expandedRowKeys?.length > 0 &&
      auth?.token &&
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?orderItem=${expandedRowKeys}&page=${pagination?.current - 1}&limit=${
          pagination?.pageSize
        }`
      )(dispatch);
  }, [auth.token, dispatch, expandedRowKeys, pagination]);

  const openModal = (value: any) => {
    setDataToScan(value);
    setModalIsOpen(!modalIsOpen);
  };
  const handleOpenDelivererModal = (value: any) => {
    setOpendelivereModel(true);
  };
  const handleCloseDelivererModal = (value: any) => {
    setOpendelivereModel(false);
  };

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setDataToScan("");
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, [scanner]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
      dispatch(mySubscritionActions.setSelected(null));
    } else {
      setExpandedRowKeys([]);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const columns = [
    {
      key: "No",
      title: "No",
      dataIndex: "No",
      width: 200,
      render: (_text: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      key: "itemdescription",
      title: "Item description",
      dataIndex: "itemdescription",
      render: (_text: any, record: any, index: number) => {
        return (
          <div key={index} className="flex flex-wrap items-center">
            <span>
              {record?.data?.product && record?.data?.product?.product?.model}
            </span>
            <span className="pr-2">,</span>
            <span>
              {record?.data?.product &&
                record?.data?.product?.specification
                  ?.slice(2, 6)
                  ?.map((item: any) => item[1] && `${item[0]}:${item[1]}`)
                  ?.join(" + ")}
            </span>
          </div>
        );
      },
    },
    {
      key: "ordered",
      title: "ordered",
      dataIndex: "ordered",
      render: (_text: any, record: any) => (
        <p>{record?.data?.quantity && record?.data?.quantity}</p>
      ),
    },
    {
      key: "scannedProduct",
      title: "delivered",
      dataIndex: "scannedProduct",
    },
    {
      key: "Outstanding",
      title: "Outstanding",
      dataIndex: "Outstanding",
      render: (_text: any, record: any) => (
        <>{record?.data?.quantity - record?.scannedProduct}</>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      render: (_text: any, record: any) => (
        <>
          {!props?.hasAccount && (
            <LoadingButton
              onClick={() => openModal(record?.data)}
              style={{
                color: "white",
                backgroundColor:
                  record?.data?.quantity <= record?.scannedProduct
                    ? "gray"
                    : "blue",
              }}
              disabled={record?.data?.quantity <= record?.scannedProduct}
            >
              {"Scan"}
            </LoadingButton>
          )}
        </>
      ),
    },
  ];

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: "Types", dataIndex: "type", key: "type" },
      { title: "Brand", dataIndex: "brand", key: "brand" },
      { title: "Model", dataIndex: "model", key: "model" },
      { title: "IMEI", dataIndex: "imei", key: "imei" },
      {
        title: "",
        width: 200,
        render: (_text: any, record: any) => {
          return (
            <Popconfirm
              placement="topRight"
              title={`Do you really want to remove`}
              description={`${record?.imei} from the list?`}
              okText="Yes"
              cancelText="No"
              onConfirm={async () =>
                await deleteSubsDeliveryItemAction(
                  auth.token,
                  record.key,
                  `?orderItem=${expandedRowKeys}&page=${
                    pagination?.current - 1
                  }&limit=${pagination?.pageSize}`,
                  orderId
                )(dispatch)
              }
            >
              <IconButton aria-label="delete" size="small">
                <DeleteIcon style={{ color: "red" }} fontSize="inherit" />
              </IconButton>
            </Popconfirm>
          );
        },
      },
    ];

    const orderItemData = deliverynoteItem?.subDeliveryNoteItem?.data?.map(
      (el: any) => {
        return {
          key: el?._id,
          type: el?.orderItem?.product?.product?.type,
          brand: el?.orderItem?.product?.product?.brand,
          model: el?.orderItem?.product?.product?.model,
          imei: el?.wareHouseItem?.serialNumber,
          specs: el?.orderItem?.specification
            ?.slice(2, 6)
            ?.map((data: any) => data[1] && `${data[0]}:${data[1]}`)
            ?.join(", "),
          status: el?.wareHouseItem?.status,
        };
      }
    );

    return (
      <Table
        columns={columns}
        dataSource={orderItemData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={deliverynoteItem?.deliveryIsFetching}
      />
    );
  };

  const mainFormData = singleOrder?.map((el: any) => {
    return {
      key: el._id,
      data: el,
      scannedProduct: el?.deliveryNoteItem?.length,
    };
  });

  useEffect(() => {
    if (props?.hasAccount && auth?.token) {
      getOneSubsOrderAction(auth?.token, account?.selected?.order)(dispatch);
    }
  }, [props?.hasAccount, account?.selected?.order, auth.token, dispatch]);

  const addressData =
    orders?.selected?.account?.customerDetails?.address &&
    orders?.selected?.account?.customerDetails?.address?.split(", ");

  return (
    <>
      <div>
        <div className="text-black flex flex-wrap justify-between px-6 pt-4 capitalize">
          {!props?.hasAccount && (
            <>
              <div className="pb-4">
                <>
                  <h1 className="text-md font-semibold pb-2">
                    Customer Details
                  </h1>
                  <div className="text-[#0F0F47] flex flex-col">
                    <p>
                      <PersonIcon style={{ color: "gray" }} />{" "}
                      <span className="capitalize">
                        {orders?.selected?.account?.customerDetails?.name}
                      </span>
                    </p>
                    <p>
                      <PhoneIcon style={{ color: "gray" }} />{" "}
                      <span>
                        {orders?.selected?.account?.customerDetails?.tel}
                      </span>
                    </p>
                    <p>
                      <EmailIcon style={{ color: "gray" }} />{" "}
                      <span>
                        {orders?.selected?.account?.customerDetails?.email}
                      </span>
                    </p>
                    <p className="flex">
                      <LocationOnIcon style={{ color: "gray" }} />{" "}
                      <span className="capitalize pl-1">
                        {addressData?.map((line: any, index: number) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />} {line?.trim()}{" "}
                          </React.Fragment>
                        ))}
                      </span>
                    </p>
                  </div>
                </>
              </div>
              <div className="text-black">
                <div className="pt-4">
                  <div className="pb-2 flex flex-row justify-end">
                    <Tag className="capitalize bg-[#ECBC4C] text-white w-14 text-center p-1 ">
                      {orders?.selected?.status?.split("-")?.join(" ")}
                    </Tag>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="pb-20 capitalize">
            <Table
              columns={columns}
              dataSource={mainFormData}
              expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpand: handleExpand,
                expandedRowRender: (record) => {
                  if (expandedRowKeys.includes(record.key)) {
                    return expandedRowRender();
                  }
                },
              }}
              loading={orders?.isFetching || deliverynote?.isFetching}
            />
            {!delivererDetails && !props?.hasAccount && (
              <div className="ml-2">
                <Button
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={handleOpenDelivererModal}
                  disabled={!deliveryNoteId}
                >
                  Add Deliverer
                </Button>
              </div>
            )}
          </div>
          <Divider />
          <div className="flex text-black justify-between w-full px-4 pt-2">
            <div className="capitalize">
              {" "}
              ApprovedBy:{" "}
              <span className="font-thin">
                {orders?.selected?.createdBy?.names}
              </span>
            </div>
            <div className="capitalize">
              {" "}
              Date:{" "}
              <span className="font-thin">
                {moment(orders?.selected?.createdAt).format("MMMM Do YYYY")}
              </span>
            </div>
          </div>
          <div className="pt-4 ml-4">
            {delivererDetails && (
              <>
                <h1 className="text-md font-semibold">Deliverer Details</h1>
                <div className="text-[#0F0F47] flex flex-col">
                  <p>
                    <PersonIcon style={{ color: "gray" }} />{" "}
                    <span className="capitalize">{delivererDetails?.name}</span>
                  </p>
                  <p>
                    <PhoneIcon style={{ color: "gray" }} />{" "}
                    <span className="capitalize">
                      {delivererDetails?.phone}
                    </span>
                  </p>
                  <p>
                    <EmailIcon style={{ color: "gray" }} />{" "}
                    <span className="capitalize">
                      {delivererDetails?.email}
                    </span>
                  </p>
                  <p>
                    <BusinessIcon style={{ color: "gray" }} />{" "}
                    <span className="capitalize">
                      {delivererDetails?.company}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={modalIsOpen}
        onCancel={closeModal}
        footer={null}
        okButtonProps={{ style: { display: "none" } }}
        closeIcon={
          <CloseOutlined
            style={{
              position: "absolute",
              top: "0",
              color: "black",
              fontSize: "20px",
            }}
          />
        }
      >
        <ScanPickListForm closeModal={closeModal} dataToScan={dataToScan} />
      </Modal>
      <Modal
        open={opendelivereModel}
        onCancel={handleCloseDelivererModal}
        footer={null}
        okButtonProps={{ style: { display: "none" } }}
      >
        <AddDelivererForm onCancel={handleCloseDelivererModal} />
      </Modal>
    </>
  );
};

export default ComponentToPrint;
