import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Form, Input, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  createShopAction,
  updateShopAction,
} from "../../store/channel/actions";
import AddressDetailsForm from "./AddressDetailsForm";
import { getOneShopAction } from "../../store/shop/actions";
import { useNavigate, useParams } from "react-router-dom";
import { regions } from "../../utils/regions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [selectedType, setSelectedType] = useState(props?.data?.type ?? "");
  const [form] = Form.useForm();
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const { channel, auth } = useSelector((state: any) => state);
  const { Option } = Select;
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    auth?.token &&
      !props?.data?._id &&
      (await createShopAction(auth?.token, {
        ...values,
        channel: props?.id,
        shopLocation: {
          province: values?.province,
          district: values?.district,
          sector: values?.sector,
          cell: values?.cell,
          village: values?.village,
        },
      })(dispatch));

    if (props?.data?._id && auth?.token) {
      await updateShopAction(
        props?.data?._id,
        {
          ...values,
          shopLocation: {
            province: values?.province ?? props?.data?.shopLocation?.province,
            district: values?.district ?? props?.data?.shopLocation?.district,
            sector: values?.sector ?? props?.data?.shopLocation?.sector,
            cell: values?.cell ?? props?.data?.shopLocation?.cell,
            village: values?.village ?? props?.data?.shopLocation?.village,
          },
        },
        auth?.token
      )(dispatch);
      await getOneShopAction(shopId as string, auth?.token)(dispatch);
      navigate(-1);
    }
    form.resetFields();
    props?.onCancel();
  };

  const handleType = (value: any) => {
    setSelectedType(value);
  };

  useEffect(() => {
    form.setFieldsValue(props?.data);
  }, [props?.data]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
      layout="vertical"
    >
      <h1 className="font-semibold text-sm mb-5">{`${
        props.data ? "Update Shop Information" : "Add New Shop"
      }`}</h1>
      <div className="grid grid-cols-2 space-x-2 lg:max-2xl:gap-4">
        <Form.Item
          name="name"
          label="Name"
          tooltip="Please enter name of the Shop?"
          rules={[
            { required: true, message: "Shop is required!", whitespace: true },
          ]}
        >
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          tooltip="Please select type of the Shop?"
          rules={[
            {
              required: true,
              message: "Shop type is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Select  type of the Supplier!"
            onChange={handleType}
            className="capitalize"
          >
            {[
              "service-center",
              "connect-shop",
              "express-shop",
              "expo",
              "other",
            ].map((type: any) => (
              <Option value={type.toLowerCase()} className="capitalize">
                {type?.split("-")?.join(" ")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedType === "connect-shop" && (
          <Form.Item name="merchantName" label="Merchant Name" tooltip="">
            <Input style={{ width: 250 }} />
          </Form.Item>
        )}

        <Form.Item name="msisdn" label="MSISDN" tooltip="">
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item name="momocode" label="MoMo Code" tooltip="">
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          tooltip="Where our Shop is located"
        >
          <Input style={{ width: 250 }} />
        </Form.Item>

        <Form.Item
          name="region"
          label="Region"
          tooltip="Please select region of the Shop?"
          rules={[
            {
              required: false,
              message: "Shop region is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 250 }}
            placeholder="Select  type of the Supplier!"
            className="capitalize"
          >
            {regions.map((d: any, index: any) => (
              <Option
                value={d?.toLowerCase()?.replace(/\s/g, "")}
                key={index}
                className="capitalize"
              >
                {d}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Divider />
      <h2 className="font-semibold text-sm">Location</h2>
      <AddressDetailsForm dataToUpdate={props.data} />

      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "50%" }}
          loading={channel?.isFetching}
        >
          {props?.data ? "Update" : "save"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;
