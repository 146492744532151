import React from "react";
import "chartkick/chart.js";
import { useEffect, useState } from "react";
import { getKPIStatsAction } from "../../store/dashboard/actions";
import {
  QuantityContainer,
  RevenueKpiContainer,
} from "../../components/dashboard/Sections/StatsRevenueQuantity";
import { useDispatch, useSelector } from "react-redux";
import NoSaleKPI from "../../components/dashboard/NoSaleKPI";
import moment from "moment";
import { Carousel } from "@trendyol-js/react-carousel";
import { useMediaQuery } from "@mui/material";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";
import { StatsCard } from "../../components/statistics/statsCard";
import SamphoneLogo from "../../assets/Samphone_Logo_White.png";
import SaveThechildrenLogo from "../../assets/images/Img_2023_11_06_14_33_19.jpeg"
const StatisticsPage = (props: any) => {
  const { dashboard } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");

  const formattedDate = moment().format("YYYY-MM-DD");
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const [refetch, setRefetch] = useState<any>(0);
  const [isFullscreen, setIsFullscreen] = useState(true);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      setRefetch(Math.random());
    }, 20000);
  }, []);

  useEffect(() => {
    getKPIStatsAction(`?date1=${enteredDate}&date2=${compareDate}`)(dispatch);
  }, [enteredDate, compareDate, refetch]);

  console.log("::::<<>>>>",dashboard?.kpiStats?.data)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: isMobile ? "100%" : "100vh",
        backgroundColor: "#041C32",
        background: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(3,27,75,1) 48%)`,
      }}
    >
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={toggleFullscreen}
      >
        <img
          style={{
            fontSize: "1.3vw",
            fontWeight: "bold",
            color: "#fff",
            padding: "0 0 1vw 4vw",
            marginTop: "3vh",
            width: isMobile ? "60vw" : "20vw",
          }}
          src={SamphoneLogo}
          alt=""
        />
        <h1
          style={{
            fontSize: isMobile ? "3vw" : "1.3vw",
            color: "#fff",
            padding: "0 0 0 4vw",
          }}
        >
          RWANDA's SALES KPI DASHBOARD
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isMobile ? "2vw" : "0.5vw",
          padding: isMobile ? `3vh 5vw 1vh 5vw` : `3vh 2.5vw 1vh 2.5vw`,
        }}
      >
        <div
          style={{
            display: "flex",
            minWidth: "65vw",
          }}
        >
          <RevenueKpiContainer
            enteredDate={enteredDate}
            compareDate={compareDate}
          />
        </div>
        <div
          style={{
            display: "flex",
            minWidth: "26vw",
          }}
        >
          <NoSaleKPI />
        </div>
      </div>
      <Carousel
        hideArrows={false}
        autoSwipe={3000}
        swiping={true}
        slide={1}
        dynamic={true}
        show={isMobile ? 1 : isTablet ? 2 : 3}
        transition={2}
        infinite={true}
        useArrowKeys={true}
        className="py-4"
        leftArrow={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: "#4c39d4",
              borderRadius: "0.5rem",
              margin: "0 0 0 1vw",
              height: "100%",
            }}
          >
            <button>
              <LeftCircleFilled
                style={{
                  color: "#fff",
                  fontSize: "1.5rem",
                }}
              />
            </button>
          </div>
        }
        rightArrow={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#4c39d4",
              borderRadius: "0.5rem",
              alignContent: "center",
              margin: "0 1vw 0 0",
              height: "100%",
            }}
          >
            <button>
              <RightCircleFilled
                style={{
                  fontSize: "1.5rem",
                  color: "#fff",
                }}
              />
            </button>
          </div>
        }
      >
        <div
          key={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Quantity sold"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/63ac68a00a308b71074a35c5_Stats%20-%20Qty%20Icon-svg.svg"
            isQuantity={true}
            today={dashboard?.kpiStats?.data?.quantity?.todaysQuantity}
            yesterday={dashboard?.kpiStats?.data?.quantity?.comparedQuantity}
          />
        </div> 
        <div
          key={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Data Activated"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/63ac67c7b966f513dd8d09ee_Stats%20-%20Data%20Icon-svg.svg"
            isQuantity={true}
            today={
              dashboard?.kpiStats?.data?.dataActivation?.todaysdataActivation
            }
            yesterday={
              dashboard?.kpiStats?.data?.dataActivation?.compareddataActivation
            }
          />
        </div>
        <div
          key={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"MoMo Pay"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/6436bb024c43d671ef54f227_MTN%20MoMo%20Logo.png"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[2]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[2]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Macye Macye"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/63b6911924e16e34f7dd71fd_Macye%20Macye.svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[3]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[3]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Samphone POS"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e5f714f04dc6408f05932_fontisto_shopping-pos-machine.svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[1]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[1]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"MTN POS"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e5f9b0c67548ec0c92780_fontisto_shopping-pos-machine%20(1).svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[4]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[4]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={7}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Samphone Bank Deposit"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e60b26dac3fb02d8293e3_la_piggy-bank%20(1).svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[0]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[0]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"MTN Bank Deposit"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e60413629865e69202fcb_la_piggy-bank.svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[7]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[7]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
          key={9}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Save the Children"}
            icon={SaveThechildrenLogo}
            today={
              dashboard?.kpiStats?.data?.saveTheChildren?.currentDate}
            yesterday={
              dashboard?.kpiStats?.data?.saveTheChildren?.comparedDate}
          />
        </div>
        <div
          key={10}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={" Corporate Sales"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e5ced48f56a5237e1c95f_streamline_money-bank-institution-money-saving-bank-payment-finance.svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[8]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[8]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div> 
        <div
        key={11}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"MTN Staff Device Requisition"}
            icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/643e5dff0c6754f1f7c90e15_simple-icons_staffbase-svg.svg"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[6]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[6]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
        key={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Vuba Vuba Payments"}
            icon="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ3gJNwnn8eQ3RciI6b9R7OlcCO6KqBIsSiQ&usqp=CAU"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[5]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[5]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
        key={13}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Yello Payments"}
            icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAUVBMVEX82Ab/3Ab92Ab41wuKfhTpyQ4AAAXx0w8AAAjmyA3v1A//4w1MRg58cA9EQg3rzRMOEwo6NQydjhIEDgqumxEvKwqAdBD/3wZ2bQxyaQy0nhMay9F4AAAAcUlEQVR4AeWPAwIEQQwEu9e2///Ps29t1DBOcEAovJ8SBFESH0+5B2VFFQComo4qB+PhwOUctIeDTJSi3jIIxnWYUkzLNknTdkyUQ9fzg8APXQEVUIziOBIFVGMmtimghtTOUgxzyPJ6BxYFUQuJQ3IGGk8ENCid8GEAAAAASUVORK5CYII="
            today={
              dashboard?.kpiStats?.data?.paymentMethod[9]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[9]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        <div
        key={14}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StatsCard
            title={"Intelligra Insurance Payments"}
            icon="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSciSens0cUh7yRk00IjPlVU-QcPd9WbjCRMA&usqp=CAU"
            today={
              dashboard?.kpiStats?.data?.paymentMethod[10]?.revenueQuantity
                ?.todaysSales?.revenueTotal
            }
            yesterday={
              dashboard?.kpiStats?.data?.paymentMethod[10]?.revenueQuantity
                ?.comparedSales?.revenueTotal
            }
          />
        </div>
        
      </Carousel>
    </div>
  );
};

export default StatisticsPage;
