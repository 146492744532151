import { Form, Input, Select, Spin, message } from "antd";
import React from "react";
import VaultButton from "../buttons/vaultButton";
import VaultUploadComponent from "../../pages/dashboard/vaults/vaultAploadComponent";
import { addcsvfileAction } from "../../store/vault/actions";
import { useSelector, useDispatch } from "react-redux";
import FilesCard from "../cards/NoSale/FilesCard";
import { BiUpload } from "react-icons/bi";
import { LoadingOutlined } from "@ant-design/icons";

type formValuesType = {
  deviceType?: string;
  idType?: string;
  paymentMethod?: string;
  serviceName?: string;
  file?: File | null;
};

const { Option } = Select;

const UploadSvcFile = () => {
  const [form] = Form.useForm();
  const { auth, file, vault, newCsv } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const addDevice = async (values: formValuesType) => {
    try {
      if (auth.token) {
        await addcsvfileAction(auth.token, values)(dispatch);
        form.resetFields();
      }
    } catch (error) {
      message.error("Failed to submit form");
    }
  };

  return (
    <Form
      form={form}
      name="uploadSvcFile"
      layout="vertical"
      autoComplete="off"
      style={{ maxWidth: "100%", marginTop: "20px" }}
      onFinish={addDevice}
    >
      <Form.Item
        name="deviceType"
        label="Device Type"
        rules={[{ required: true, message: "Please enter device Type" }]}
        className="w-full"
      >
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder="Select device type"
          className="capitalize rounded-md h-[52px] w-[100%] vault-select"
        >
          <Option value="mobile">
            <span>mobile</span>
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="idType"
        label="Id Type"
        rules={[{ required: true, message: "Please enter Id Type" }]}
        className="w-full"
      >
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder="Select imei"
          className="capitalize rounded-md h-[52px] w-[100%] vault-select"
        >
          <Option value="imei">
            <span>imei</span>
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="serviceName"
        label="Service Name"
        rules={[{ required: true, message: "Please enter payment Method" }]}
        className="w-full"
      >
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder="Select Service"
          className="capitalize rounded-md h-[52px] w-[100%] vault-select"
        >
          <Option value="DeviceFinancing">
            <span>DeviceFinancing</span>
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="paymentMethod"
        label="Payment method"
        className="w-full"
        rules={[{ required: true, message: "Please select service" }]}
      >
        <Select
          dropdownMatchSelectWidth={false}
          showSearch
          placeholder="payement method"
          className="capitalize rounded-md h-[52px] w-[100%] vault-select"
        >
          <Option value="Inventory">
            <span>
              Inventory
              <p className="text-[#030229b2] text-[12px] font-thin">
                Inventory &gt; Idle
              </p>
            </span>
          </Option>
          <Option value="prepaid">
            <span>
              Device Financing (Prepaid)
              <p className="text-[#030229b2] text-[12px] font-thin">
                Prepaid &gt; Ready for Use
              </p>
            </span>
          </Option>
          <Option value="postpaid">
            <span>
              Device Financing (PostPaid)
              <p className="text-[#030229b2] text-[12px] font-thin">
                PostPaid &gt; Ready for Use
              </p>
            </span>
          </Option>
        </Select>
      </Form.Item>
      <Form.Item name="file" label="File" className="w-full">
        <VaultUploadComponent />
      </Form.Item>
      <Form.Item className="mt-10">
        <VaultButton
          btnName={
            vault?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              "Save"
            )
          }
        />
      </Form.Item>
    </Form>
  );
};

export default UploadSvcFile;

