import React, { useState } from "react";
import { Table, Tag, Modal } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import { getCustomerPurchaseAction } from "../../../store/customer/actions";
import {
  getAllRepairsAction,
  updateRepairStatusAction,
} from "../../../store/repair/actions";
import RepairDrower from "../pages/RepairDrower";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import RepairDelivererOutForm from "../../../components/forms/RepairDelivererOutForm";
import DetailsModel from "../../../components/Modals/DetailsModel";
import SwapDeviceForm from "../../../components/forms/SwapDeviceForm";
import ThreeDotDropdown from "./threeDotDropdown";
import RepairFiltering from "./filter/RepairFiltering";

interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const CustomTable = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const [openModel, setOpenModel] = React.useState(false);
  const [openSwpModel, setOpenSwapModel] = React.useState(false);
  const [completedDataId, setCompletedDataId] = useState("");
  const [rejectReqId, setRejectReqId] = useState<any>("");
  const [repairingId, setRepairingReqId] = useState<any>("");
  const [dataToRevert, setDataToRevert] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>([]);
  const [selectedShop, setSelectedShop] = useState("");
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const showDrawer = (d: any) => {
    setOpen(true);
    setDataToUpdate([...d?.data?.deviceIssues]);
    setData(d);
  };

  const showCompleteModal = (d: any) => {
    setOpenModel(true);
    setCompletedDataId(d);
  };

  const showSwapModal = (value: any) => {
    setOpenSwapModel(true);
    setDataToRevert(value);
  };
  const onloseSwapModal = (value: any) => {
    setOpenSwapModel(false);
    setDataToRevert("");
  };

  const handleCancelCompleteModal = () => {
    setOpenModel(false);
  };

  const onClose = () => {
    setOpen(false);
    setDataToUpdate([]);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.channel} </p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Specifications",
      dataIndex: "specifications",
      key: "specifications",
      width: 200,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Repaired At",
      dataIndex: "repairedAt",
      key: "repairedAt",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, _record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_text, record) => {
        return (
          <>
            {["technician", "admin", "dev"]?.includes(auth?.user?.role) && (
              <span className="flex items-center">
                <ThreeDotDropdown
                  onCompleteModel={() => showCompleteModal(record)}
                  handleRepairingReq={handleRepairingReq}
                  record={record}
                  handleRejectReq={handleRejectReq}
                  onShowDetails={() => showDrawer(record)}
                  onArrival={() => setRepairingReqId(record)}
                  onReject={() => setRejectReqId(record)}
                  onSwap={() => showSwapModal(record)}
                />
              </span>
            )}
          </>
        );
      },
    },
  ];

  const handleRejectReq = async () => {
    if (rejectReqId) {
      await updateRepairStatusAction(auth?.token, rejectReqId?.key, {
        status: "cancelled",
        isNotify: true,
        notification: {
          action: "Repair Cancelled",
          role: ["admin", "sales-supervisor", "sales-agent", "technician"],
          message: `${rejectReqId?.model} Request has been Rejected`,
          title: "Repair Cancelled",
        },
      })(dispatch);
      setRejectReqId("");
    }
  };

  const handleRepairingReq = async () => {
    if (repairingId) {
      await updateRepairStatusAction(auth?.token, repairingId?.key, {
        status: "repairing",
        isNotify: true,
        notification: {
          action: `Repairing ${repairingId?.model}`,
          role: ["admin", "sales-supervisor", "sales-agent", "technician"],
          message: `${repairingId?.model} is In-Repaire `,
          title: `Repairing ${repairingId?.model}`,
        },
      })(dispatch);
      setRepairingReqId("");
    }
  };

  React.useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customerId}`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      getAllRepairsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          selectedShop !== "" ? `&shop=${selectedShop}` : ""
        }`
      )(dispatch);
  }, [auth.token, dispatch, limit, page, selectedShop]);

  const formData = repairs?.all.data?.map((el: any) => {
    return {
      key: el._id,
      date_time: el?.createdAt?.slice(0, 10),
      shop: el?.cartItemId?.shopStockItem?.shop?.name,
      agent: el?.createdBy?.names,
      channel: el?.cartItemId?.shopStockItem?.shop?.channel?.name,
      model: el?.cartItemId?.priceProduct?.product?.model,
      imei: el?.cartItemId?.warehouseProduct?.serialNumber,
      specifications:
        el?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 6)
          ?.map((d: any) => d[1] && `${d[0]} : ${d[1]}`)
          ?.join(", "),
      customer: el?.cartItemId?.cart?.customer?.name,
      status: el?.status,
      handleCancelCompleteModal: handleCancelCompleteModal,
      setRejectReqId: setRejectReqId,
      dispatch: dispatch,
      auth: auth,
      repairedAt: el?.repairedAt,
      cartItemId: el?.cartItemId?._id,
      data: el,
    };
  });

  return (
    <>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={repairs?.all?.total}
        count={Math.ceil(repairs?.all?.total / limit)}
      >
        <RepairFiltering setSelectedShop={setSelectedShop} />
        <Table
          columns={columns}
          dataSource={formData}
          onChange={onChange}
          pagination={false}
          loading={repairs?.isFetching}
        />
      </ScrollableFrame>
      <RepairDrower
        open={open}
        onClose={onClose}
        data={data}
        dataToUpdate={dataToUpdate}
        setDataToUpdate={setDataToUpdate}
      />
      {}
      <Modal
        open={openModel}
        onCancel={handleCancelCompleteModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RepairDelivererOutForm
          onCancel={handleCancelCompleteModal}
          completedDataId={completedDataId}
        />
      </Modal>
      <DetailsModel
        isModalOpen={openSwpModel}
        handleOnCancel={onloseSwapModal}
        component={
          <SwapDeviceForm data={dataToRevert} onClose={onloseSwapModal} />
        }
      />
    </>
  );
};

export default CustomTable;
