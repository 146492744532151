import React, { useState } from "react";
import * as bsIcons from "react-icons/bs";

interface IconProps {
  color?: string;
  size?: number;
  fill?: string;
  position?: string;
  borderRadius?: string;
  bgColor?: string;
}

type IconComponent = React.FC<IconProps>;

const CustomIcon: React.FC<{
  icon: IconComponent;
  color?: string;
  fill?: string;
  size?: number;
}> = ({ icon: Icon, fill, color, size = 25 }) => {
  return <Icon color={color} size={size} fill={fill} />;
};
const CustomClickIcon: React.FC<{
  activeIcon: IconComponent;
  color?: string;
  fill?: string;
  borderRadius?: string;
  size?: number;
  position?: string;
  bgColor?: string;
}> = ({
  activeIcon: Icon,
  fill,
  color,
  position,
  bgColor,
  borderRadius,
  size = 25
}) => {
  return (
    <Icon
      color={color}
      size={size}
      fill={fill}
      borderRadius={borderRadius}
      position={position}
    />
  );
};

interface ContentProps {
  icon: IconComponent;
  activeIcon?: IconComponent;
  name?: any;
  themeImage?: any;
  content?: any;
  iconColor?: string;
  iconSize?: number;
  tittleColor?: string;
  contentColor?: string;
  iconFill?: string;
  iconBorder?: string;
  iconPosition?: string;
  iconBgColor?: string;
  isSelected?:any;
  onClick?: any;
  themeBorderStyle?: React.CSSProperties;
  nonThemeBorderStyle?: React.CSSProperties;
  useThemeBorder?: boolean;
}

const Theme: React.FC<ContentProps> = ({
  icon: Icon,
  activeIcon: ActiveIcon,
  name,
  themeImage,
  content,
  iconColor,
  iconSize,
  tittleColor,
  contentColor,
  onClick,
  isSelected,
  iconBorder,
  iconPosition,
  iconBgColor,
  iconFill,
  
}) => {
  const [themeMode, setThemeMode] = useState("light");
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.5px solid rgba(15, 15, 71, 0.25)"
    },
    themeBorder: {
      border: "2px solid #605BFF",
      borderRadius: "5px"
    },
    nonThemeBorder: {
      border: "0.2px solid #0F0F471A",
      borderRadius: "5px"
    }
  };
  return (
    <>
      <div
        className="cursor-pointer relative"
        onClick={onClick}
      >
        {isSelected && (
          <bsIcons.BsFillCheckCircleFill
            className="absolute -right-2 -top-2 z-10 bg-white rounded-lg"
            color="#605BFF"
            size={25}
          />
        )}
        <div className=" w-[12rem] h-[9rem] xl:h-[10rem] xl:w-[14rem]">
        <img
          src={themeImage}
          alt="dark"
          style={
            isSelected
              ? (styles.themeBorder as React.CSSProperties)
              : (styles.nonThemeBorder as React.CSSProperties)
          }
          className="w-full h-full"
        /></div>
        <h1 className="pt-3 text-[#030229] text-base font-normal flex gap-2">
          {" "}
          <CustomIcon
            icon={Icon}
            fill={iconFill}
            color={iconColor}
            size={iconSize}
          />{" "}
          {name}
        </h1>
      </div>
    </>
  );
};

export default Theme;
