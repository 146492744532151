import React, { useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import Force from "../../assets/icons/dashboard/force.png";
import ForceLogo from "../../assets/icons/dashboard/Samphone_Logo.svg";
import { ReactComponent as NewLogo } from "../../assets/icons/Newlog_S.svg";
import Control from "../../icons/control.png";
import { listManages } from "../../assets/data/pages";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./sideNav.css";
import {
  getActiveMenu,
  storeActiveMenu,
  storeSelectedCaption,
} from "../../utils/helper";
import * as faIcons from "react-icons/fa";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tooltip } from "antd";

const SideNav: React.FC<{
  isOpen: boolean;
  selected?: number;
  toggleSideNav: () => void;
}> = ({ isOpen, toggleSideNav, selected }) => {
  const { auth, layout ,company} = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sideBar, SetSideBar] = useState(false);
  const showSideBar = () => {
    SetSideBar(!sideBar);
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());
  const [openDropDownIndex, setOpenDropDownIndex] = useState(getActiveMenu());
  const smallScreen = useMediaQuery("(max-width:767px)");
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  // const listWithoutLast = listManages.slice(0, -1);
  // const lastItem = listManages.slice(-1)[0];
  const listWithoutSettings = listManages.filter((item) => item.title !== "Settings");
  const settingsItem = listManages.find((item) => item.title === "Settings");
  return (
    <div>
      <div
        className={`nav-container fixed flex w-fit flex-col ${
          sideBar ? "min-h-[32]" : "min-h-screen"
        }   items-center !bg-whites z-50 bg-white`}
      >
        {smallScreen && (
          <faIcons.FaBars
            className="menu-bar mx-5 mt-2 text-black"
            onClick={() => {
              if (!isOpen) {
                toggleSideNav();
              }
              showSideBar();
            }}
            size={20}
          />
        )}

        <div
          className={`  ${
            sideBar && smallScreen ? "nav-menu" : "nav-menu active"
          } ${
            isOpen ? "w-52" : "w-20"
          } bg-[#F4F6FA] h-screen  pt-8 relative duration-300 shadow-lg shadow-gray-500/40`}
        >
          <img
            src={Control}
            className={`absolute cursor-pointer  -right-3 top-[1.06rem] w-5 border-[#0F0F47B2]
           border-2 rounded-full  ${!isOpen && "rotate-180"}`}
            onClick={() => (smallScreen ? showSideBar() : toggleSideNav())}
            alt="nav control"
          />

          <div className={`flex justify-center items-center -mt-[1.09rem] `}>
            {isOpen ? (
              <img
                src={ForceLogo}
                // src={company?.selected?.logo}
                className="cursor-pointer duration-500 w-32  mt-2"
                alt=""
              />
            ) : (
              <h1 className="text-sm font-bold text-black">
                <NewLogo className="h-7" />
              </h1>
              // <img src={F} className="cursor-pointer duration-500 w-5 " />
            )}
          </div>
          <ul className="mxs-6 px-6 mt-8 overflow-ys-auto hide-scrollbar w-full h-[85vh]">
            {listWithoutSettings.map((manage: any) => {
              return (
                (manage?.disabled ||
                  manage?.roles?.includes(auth?.user?.role?.toLowerCase())) && (
                  <li
                    key={manage.id}
                    onClick={() => {
                      setSelectedTabIndex(manage.id);
                      storeActiveMenu(manage.id);
                      if (!manage.dropDown) {
                        navigate(manage.path);
                        showSideBar();
                      }
                    }}
                    className={`flex text-[#0F0F47B2]  rounded-md cursor-pointer hover:bg-[rgba(255,255,255,0.17)] text-sm items-center  
                ${!isOpen ? "ml-[-10px]" : "ml-[-1px]"}`}
                  >
                    <span>
                      {!manage.dropDown ? (
                        <span className="flex flex-row ml-4 my-3">
                          <span
                            className={`text-lg pt-1 pr-4  ${
                              selectedTabIndex === manage.id
                                ? "activeIcon"
                                : "#0F0F47"
                            }`}
                          >
                            <Tooltip
                              title={!isOpen && manage.title}
                              placement="right"
                              color={"#cecece"}
                            >
                              {manage.icon}
                            </Tooltip>
                          </span>
                          <Typography
                            className={` typography ${
                              !isOpen && "hidden"
                            } origin-left duration-200 text-sm  font-[400] ${
                              selectedTabIndex === manage.id
                                ? "text-[#2943D6]"
                                : "null"
                            }`}
                          >
                            {" "}
                            {manage.title}
                          </Typography>
                        </span>
                      ) : (
                        <Accordion
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: "#0F0F47B2",
                            fontSize: "0.875rem",
                          }}
                          expanded={
                            openDropDownIndex === manage?.id && manage?.dropDown
                          }
                          onChange={() => {
                            storeActiveMenu(manage.id);
                            setOpenDropDownIndex(
                              openDropDownIndex === getActiveMenu()
                                ? -1
                                : getActiveMenu()
                            );
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              manage.dropDown && isOpen ? (
                                <ExpandMoreIcon
                                  sx={{
                                    color: `${
                                      selectedTabIndex === manage.id
                                        ? "#2943D6"
                                        : ""
                                    } ${!manage.dropDown ? "" : ""}`,
                                  }}
                                />
                              ) : null
                            }
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            onClick={
                              !isOpen ? () => toggleSideNav() : undefined
                            }
                          >
                            <span
                              className={`text-lg pt-1 pr-4 ${
                                selectedTabIndex === manage.id
                                  ? "activeIcon"
                                  : "#0F0F47"
                              }`}
                            >
                              <Tooltip
                                title={!isOpen && manage.title}
                                placement="right"
                                color={"#cecece"}
                              >
                                {manage.icon}
                              </Tooltip>
                            </span>
                            <Typography
                              className={` typography ${
                                !isOpen && "hidden"
                              } origin-left duration-200 text-sm font-[400] ${
                                selectedTabIndex === manage.id
                                  ? "text-[#2943D6]"
                                  : "null"
                              }`}
                            >
                              {" "}
                              {manage.title}
                            </Typography>
                          </AccordionSummary>
                          {isOpen && manage.dropDown ? (
                            <>
                              <AccordionDetails>
                                <Typography className="flex">
                                  <hr className="border-[0.1px] ml-[.3rem] mr-3  border-[#0f0f473d] h-[auto] cursor-pointer  duration-500" />
                                  <ul>
                                    {manage?.dropDown?.map((drop: any) => (
                                      <>
                                        {!drop?.skip?.includes(
                                          auth?.user?.role?.toLowerCase()
                                        ) && (
                                          <li
                                            key={drop.caption}
                                            className={`py-2 test-xs cursor-pointer ${
                                              localStorage.getItem(
                                                "selectedCaption"
                                              ) === drop.caption
                                                ? "bg-gray-300 rounded-md px-2"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              const selectedCaption =
                                                drop.caption;
                                              storeSelectedCaption(
                                                selectedCaption
                                              );
                                              navigate(drop.path);
                                              showSideBar();
                                            }}
                                          >
                                            {drop.caption}
                                          </li>
                                        )}
                                      </>
                                    ))}
                                  </ul>
                                </Typography>
                              </AccordionDetails>
                            </>
                          ) : (
                            ""
                          )}
                        </Accordion>
                      )}
                    </span>
                  </li>
                )
              );
            })}
          </ul>
          <div
              className={`absolute left-2 bottom-4 text-gray-900 ${
                isOpen && ""
              }`}
            >
             <ul className="mx-6 mt-8 absolute bottom-4">
        {settingsItem &&  settingsItem?.roles?.includes(auth?.user?.role?.toLowerCase()) &&  (
          <li
            key={settingsItem.id}
            onClick={() => {
              setSelectedTabIndex(settingsItem.id);
              storeActiveMenu(settingsItem.id);
              if (!settingsItem.dropDown) {
                navigate(settingsItem.path);
                showSideBar();
              }
            }}
            className={`flex text-[#0F0F47B2] rounded-md cursor-pointer hover:bg-[rgba(255,255,255,0.17)] text-sm items-center ${
              !isOpen ? "ml-[-10px]" : "ml-[-1px]"
            }`}
          >
          <span className="flex flex-row my-3">
                          <span
                            className={`text-lg pt-1 pr-4  ${
                              selectedTabIndex === settingsItem.id
                                ? "activeIcon"
                                : "#0F0F47"
                            }`}
                          >
                            <Tooltip
                              title={!isOpen && settingsItem.title}
                              placement="right"
                              color={"#cecece"}
                            >
                              {settingsItem.icon}
                            </Tooltip>
                          </span>
                          <Typography
                            className={` typography ${
                              !isOpen && "hidden"
                            } origin-left duration-200 text-sm pt-[1px]  font-[400] ${
                              selectedTabIndex === settingsItem.id
                                ? "text-[#2943D6]"
                                : "null"
                            }`}
                          >
                            {" "}
                            {settingsItem.title}
                          </Typography>
                        </span> 
             </li>
          )}
        </ul>
            </div>
          {["partner"]?.includes(auth?.user?.role) && (
            <div
              className={`absolute left-2 bottom-4 text-gray-900 ${
                isOpen && "pl-16"
              }`}
              onClick={() => dispatch(authActions.logout())}
            >
              <ListItemIcon
                className={`SideNav flex flex-col items-center text-xs `}
              >
                <PowerSettingsNewIcon className="SamIcon" />
                {"Logout"}
              </ListItemIcon>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
