import { Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MtnLog from "../../assets/icons/mtn.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

const Component = (props: any) => {
  const { auth } = useSelector((state: any) => state);
  const navigate = useNavigate();
  console.log(":::::channel data", props?.data);
  return (
    <>
      <div className=" min-h-[125px] bg-white border-gray-100 text-black border rounded-md p-2 duration-200 hover:border-gray-300">
        <Stack spacing={1} className="relative">
          <div
            className="pl-2 pb-1.5 min-h-[125px]"
            onClick={() => navigate(props?.data?.data?.link)}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0}>
                <h1 className="font-semibold capitalize">
                  {props?.data?.name}
                </h1>
                <p className="text-xs" style={{ color: "blue" }}>
                  {props.data?.type}
                </p>
              </Stack>
              <p
                className="text-xs"
                style={{
                  color: "white",
                  padding: "3px 10px",
                  borderRadius: "5px",
                }}
              >
                <img
                  className="w-12"
                  src={
                    props?.data?.name?.toLowerCase()?.includes("mtn")
                      ? MtnLog
                      : props?.data?.name?.toLowerCase()?.includes("airtel")
                      ? "https://1000logos.net/wp-content/uploads/2018/01/Airtel-Logo.png"
                      : "https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png"
                  }
                  alt=""
                />
              </p>
            </Stack>
          </div>

          {!["sales-supervisor"]?.includes(auth?.user?.role) && (
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={0}
              className="absolute right-2 bottom-0"
            >
              <IconButton aria-label="edit" size="small">
                <EditIcon fontSize="inherit" />
              </IconButton>

              <IconButton aria-label="delete" size="small">
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </div>
    </>
  );
};

export default Component;
