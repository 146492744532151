import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const PurchaseOrderTable = (props: any) => {
  const { proforma, auth } = useSelector((state: any) => state);
  const [isSelected, setIsSelected] = useState(false);
  const data: {
    _id: any;
    priceUnit: any;
    quantity: any;
    margin_cost: any;
    validityDate: any;
    isSelected: any;
  }[] = [];

  const columns: ColumnsType<any> = [
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Margin cost",
      dataIndex: "margin_cost",
      key: "margin_cost",
      render: (text: string) => <p>N/A</p>,
    },
    {
      title: "Price/Unity",
      dataIndex: "priceUnit",
      key: "priceUnit",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Delivery Date",
      dataIndex: "validityDate",
      key: "validityDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: any) => (
        <LoadingButton
          style={{
            color: `${record?.isSelected ? "white" : "blue"}`,
            textTransform: "capitalize",
          }}
          onClick={() => {
            props?.handleAddPOToList(record);
          }}
          loading={proforma?.isFetching}
          disabled={record?.isSelected}
          variant={record.isSelected ? "contained" : "outlined"}
        >
          {record.isSelected ? "Added To P.O" : "Add To P.O"}
        </LoadingButton>
      ),
    },
  ];

  proforma?.selected &&
    proforma?.selected?.map((el: any) => {
      data.push({
        _id: el?._id,
        priceUnit: el?.priceUnit?.toLocaleString("en-RW"),
        quantity: el?.qtyAvailable,
        margin_cost: "-",
        validityDate: el?.validityDate,
        isSelected: el?.isSelected,
      });
    });

  return (
    <div>
      <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
    </div>
  );
};

export default PurchaseOrderTable;
