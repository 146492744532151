import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { getAllInactiveUsersAction } from "../../../../store/setting/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

const InactiveUsers = (props: any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  useEffect(() => {
    if (auth?.token) {
      getAllInactiveUsersAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}&isActive=false${
          props?.userRole ? `&role=${props?.userRole}` : ""
        }`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, props?.userRole]);

  const Data = setting?.inactiveUser?.data.map((el: any) => {
    return {
      key: el._id,
      userName: el?.names,
      email: el?.email,
      telephone: el?.phone,
      role: el?.role?.split("-")?.join(" "),
      data: el,
      status: el?.isActive === true ? "Active" : "Inactive",
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "userName",
      render: (text: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "telephone",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  return (
    <ScrollableFrame
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={setting?.all?.total}
      count={Math.ceil(setting?.inactiveUser?.total / limit)}
    >
      <Table
        columns={columns}
        dataSource={Data}
        loading={setting?.isFetching}
        pagination={false}
      />
    </ScrollableFrame>
  );
};
export default InactiveUsers;
