import HeaderComponent from "../../../../components/HeaderComponent";
import { Paper, Stack, Button } from "@mui/material";
import { Table, Spin } from "antd";
import AddItem from "./AddItems";
import AddSupplier from "./AddSuppliers";
import SendIcon from "@mui/icons-material/Send";
import { useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedQuotationAction,
  sendEmailAction,
  updateQuotationAction,
} from "../../../../store/quotation/actions";
import { myQuotationActions } from "../../../../store/quotation";
import { quotationColumns } from "../../../../components/reUsableTable/colmns";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const UpdateSaveButtons = (props: any) => {
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSendEmail = async () => {
    console.log(quotation.allRfqSupplier.data.map((d: any) => d._id));
    if (auth?.token && quotation.allRfqSupplier.data.length > 0) {
      const isEmailSent = await sendEmailAction(auth.token, {
        quotationToSuppliers: quotation.allRfqSupplier.data.map(
          (d: any) => d._id
        ),
      })(dispatch);

      isEmailSent && navigate("/purchase/rfq");
    }
  };

  const handleCancelQuotation = async () => {
    auth?.token &&
      quotation.selected._id &&
      (await updateQuotationAction(
        quotation.selected._id,
        { status: "Cancelled" },
        auth?.token
      )(dispatch));
    navigate("/inventory/rfq");
  };

  return (
    <Stack direction={"row"} spacing={2}>
      {/* <LoadingButton
        loading={props.isLoading}
        loadingPosition="start"
        variant="contained"
        onClick={() => props?.onClick()}
      >
        Print RFQ
      </LoadingButton> */}
      {quotation?.allRfqSupplier?.data.length !== 0 && (
        <LoadingButton
          loading={quotation?.isFetching}
          loadingPosition="start"
          variant="contained"
          onClick={async () => {
            await handleSendEmail();
          }}
        >
          Send by Email
        </LoadingButton>
      )}
      <Button
        variant="contained"
        color="error"
        onClick={async () => await handleCancelQuotation()}
      >
        Cancel
      </Button>
      {/* )} */}
      {!props.isCancel && props?.sendButtonDefault && (
        <LoadingButton
          loading={props.isLoading}
          loadingPosition="start"
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          onClick={() => props?.onClick()}
        >
          Send Quotation
        </LoadingButton>
      )}
    </Stack>
  );
};

const PageView = () => {
  const { auth, quotation } = useSelector((state: any) => state);
  const [suppliers, setSuppliers] = useState([""]);
  // console.log("quotation>>>>>", quotation);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const rfqId = searchParams.get("rfqId");

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));
    // console.log("this  is my RFQID:", rfqId);
    // if RFQId exist fetch api of getting one

    auth?.token &&
      getSelectedQuotationAction(auth?.token, `?quotationId=${id}`)(dispatch);
  }, [id, auth?.token, dispatch]);

  const data: {
    key: string;
    type: any;
    brand: any;
    model: any;
    specification: any;
    quantity: any;
    visible: boolean;
    setVisible: (state: boolean) => void;
    token: string;
    isLoading: boolean;
    dispatch: any;
    quotId: string | undefined;
    rowToDelete: string;
    setRowToDelete: (state: string) => void;
    status: string;
  }[] = [];
  const values = quotation?.selected?.listOfProducts;

  values &&
    values?.forEach((el: any) => {
      data.push({
        key: el._id,
        type: el.product.model,
        brand: el.product.brand,
        model: el.product.model,
        specification: el.specification
          .map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        quantity: el.quantity,
        visible: visible,
        setVisible: setVisible,
        token: auth?.token,
        isLoading: false,
        dispatch: dispatch,
        quotId: id,
        rowToDelete: rowToDelete,
        setRowToDelete: setRowToDelete,
        status: quotation.selected.status,
      });
    });

  const handleSendEmail = async () => {
    // sendEmailAction(auth.token, {
    //   quotationToSuppliers: suppliers,
    // })(dispatch);
    console.log(quotation?.allRfqSupplier?.data?.map((d: any) => d._id));
  };

  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide text-black">
      <Stack spacing={1}>
        <HeaderComponent
          title={`RFQ / ${id} `}
          filterCard={
            quotation?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
              />
            ) : (
              <></>
            )
          }
          isNotAddButton={true}
          isNotCollapse={true}
          goBack="/purchase/quotations"
          isOtherButton={true}
        />
        <Paper elevation={0} style={{ marginBottom: "20px" }}>
          <Stack spacing={0}>
            {quotation?.selected?.status?.toLowerCase() === "draft" && (
              <AddItem
                onClickAdd={() => {
                  setIsCancel(true);
                }}
              />
            )}

            <Table columns={quotationColumns} dataSource={data} />
            {/* <Stack spacing={1} direction="row">
            {quotation?.selected?.listOfProducts &&
              quotation?.selected?.listOfProducts.map((prod: any) => (
                <Paper elevation={3} sx={{ p: 2, minWidth: 200 }}>
                  <h1>Model: {prod.product.model}</h1>
                  <h1>ROM: {prod.specification.join(", ")}</h1>
                  <h1>Qty: <b>{prod.quantity}</b></h1>
                </Paper>
              ))}{" "}
          </Stack> */}
          </Stack>
        </Paper>

        {[
          "admin",
          "dev",
          "finance",
          "sales-manager",
          "finance-manager",
        ].includes(auth?.user?.role?.toLowerCase()) && (
          <>
            {quotation?.selected?.listOfProducts?.length > 0 && (
              <Paper elevation={0}>
                {!rfqId && quotation?.selected?.status === "draft" && (
                  <AddSupplier
                    onClickAdd={() => {
                      setIsCancel(true);
                    }}
                    setSuppliers={setSuppliers}
                  />
                )}
              </Paper>
            )}

            {values?.length >= 1 &&
              quotation?.selected?.status?.toLowerCase() === "draft" &&
              !rfqId && (
                <UpdateSaveButtons
                  //  isCancel={isCancel}
                  isLoading={isLoading}
                  sendButtonDefault={false} //this will depend on quotation status
                  onClick={() => {
                    setIsCancel(false);
                  }}
                  handleSendEmail={handleSendEmail}
                />
              )}
          </>
        )}
      </Stack>
    </div>
  );
};

export default PageView;
