import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "ebmItem",
  initialState: {
    isFetching: false,
    priceList:null,
    priceListItems:null,
    selectedPriceListItems:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
    setPriceListItems(state, action) {
        state.priceListItems = action.payload;
      },
      setSelectedPriceListItems(state, action) {
        state.selectedPriceListItems = action.payload;
      },
      
  },
});

export const ebmItemActions = mySlice.actions;

export default mySlice.reducer;
