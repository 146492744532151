import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import {
  calculatePercentageChange,
  separatePaymentsByMode,
} from "../../utils/converter";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const DashboardMacyeMacyeCard: React.FC<{
  title: string;
  height?: string;
}> = ({ title, height }) => {
  const { dashboard, layout } = useSelector((state: any) => state);

  const result = separatePaymentsByMode(
    dashboard?.macyeMacyePaymentStats?.data,
    "Macye_Macye"
  );

  // const currentTotalQty = calculatePaymentTotalQty(
  //   dashboard?.macyeMacyePaymentStats?.data,
  //   "currentDate"
  // );

  const currentTotalQty = result[0]?.quantity?.currentDate?.quantityTotal;
  const comparedTotalQty = result[0]?.quantity?.comparedDate?.quantityTotal;

  const todayRevenue = result[0]?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue = result[0]?.revenue?.comparedDate?.revenueTotal;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );

  return (
    <div
      className={`relative flex flex-col ${
        height ? height : "max-h-[256px]"
      } p-4 bg-white rounded-md`}
    >
      <p className="text-gray-400 capitalize mb-2">{title}</p>
      {layout?.dashboardPreview === "day" ? (
        <p className="text-2xl font-semibold">
          {"RWF " +
            (result[0]?.revenue?.currentDate?.revenueTotal?.toLocaleString() ||
              0)}{" "}
          /<span className="text-xs">{`${currentTotalQty} Pieces`}</span>
        </p>
      ) : (
        <p className="text-2xl font-semibold">
          {"RWF " +
            (dashboard?.macyeMacyePaymentStats?.data
              ?.at(0)
              ?.result?.allTotalRevenue?.toLocaleString() || 0)}{" "}
          /
          <span className="text-xs">{`${
            dashboard?.macyeMacyePaymentStats?.data?.at(0)?.result
              ?.allTotalQuantity
          } Pieces`}</span>
        </p>
      )}

      {layout?.dashboardPreview === "day" && (
        <p className="text-gray-400 capitalize">
          {"RWF " +
            (result[0]?.revenue?.comparedDate?.revenueTotal?.toLocaleString() ||
              0)}{" "}
          /<span className="text-xs">{`${comparedTotalQty} Pieces`}</span>
        </p>
      )}

      {percentageChange && (
        <div
          className={`${
            layout?.dashboardPreview === "day" ? "absolute" : "hidden"
          } top-2 right-2 ${
            percentageChange.type === "increase"
              ? "bg-green-200"
              : percentageChange.type === "decrease"
              ? "bg-[#FEE6E9]"
              : "bg-blue-200"
          }  rounded-lg px-3 py-1`}
        >
          <span
            className={`${
              percentageChange.type === "increase"
                ? "text-green-500"
                : percentageChange.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } flex items-center py-0.5`}
          >
            {percentageChange.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}
            {percentageChange.percentage + "%"}
          </span>
        </div>
      )}
    </div>
  );
};

export default DashboardMacyeMacyeCard;
