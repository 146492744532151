import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Modal, Button, MenuProps, Dropdown } from "antd";
import RequestSubscriptionInfoForm from "../../../../../components/forms/RequestSubscriptionInfoForm";
import SubscriptionDrower from "../../../../../components/Modals/SubscriptionDrower";

const AccountThreeDotDropdown = (props: any) => {
  const { account } = useSelector((state: any) => state);
  const [openInquiryModel, setOpenInquiryModel] = React.useState(false);
  const [openDrower, setOpenDrower] = React.useState(false);
  const requestStatus =
    account?.selected?.requests && account?.selected?.requests[0]?.status;

  const showInquiryModal = (value: any) => {
    setOpenInquiryModel(true);
  };

  const cancelInquiryModal = () => {
    setOpenInquiryModel(false);
  };

  const showDrawer = (d: any) => {
    setOpenDrower(true);
  };

  const onCloseDrower = () => {
    setOpenDrower(false);
  };

  const dataToDisplay = account?.selected?.requests?.map((el: any) => {
    return {
      key: el._id,
      date: el?.createdAt?.slice(0, 10),
      shop: el?.shop?.name,
      agent: el?.createdBy?.names,
      imei: el?.list[0]?.warehouseProduct?.serialNumber,
      model: el?.list[0]?.priceProduct?.product?.model,
      plan: el?.subscriptionPlan,
      specification:
        el?.list[0]?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 10)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
      amountPaid: el?.payment?.reduce(
        (total: number, current: any) =>
          parseInt(total + current?.amount)?.toLocaleString(),
        0
      ),
      unpaidAmount: el?.list[0]?.payment?.amount,
      status: el?.status,
      data: el,
    };
  });

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <label className="flex gap-1">
          <Button
            className="capitalize min-w-[50px] text-center outline-none"
            onClick={showDrawer}
            style={{ width: "100px" }}
          >
            View
          </Button>
        </label>
      ),
    },
    {
      key: "2",
      label: (
        <label className="flex gap-1">
          {requestStatus === "requested" && (
            <Button
              className="capitalize min-w-[50px] text-center outline-none border-blue-500"
              onClick={showInquiryModal}
              style={{ width: "100px" }}
            >
              Inquiry
            </Button>
          )}
        </label>
      ),
    },
  ];
  return (
    <>
      <div className="bg-white px-3 rounded-md">
        <Dropdown menu={{ items }} placement="bottom">
          <BiDotsHorizontalRounded size={32} />
        </Dropdown>
      </div>
      <Modal
        open={openInquiryModel}
        onCancel={cancelInquiryModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RequestSubscriptionInfoForm
          onCancel={cancelInquiryModal}
          inquiryId={props?.requestId}
        />
      </Modal>
      <SubscriptionDrower
        open={openDrower}
        onClose={onCloseDrower}
        data={dataToDisplay}
      />
    </>
  );
};
export default AccountThreeDotDropdown;
