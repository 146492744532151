import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Dot
} from "recharts";

const CustomDot = ({ cx, cy, stroke, strokeWidth, r, payload }: {
    cx?: number;
    cy?: number;
    stroke?: string;
    strokeWidth?: number;
    r?: number;
    payload?: any;
  }) => {
    if (payload && payload.active) {
      return (
        <Dot
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill="#FA365980"
        />
      );
    }
    return null;
  };

const data = [
  {
    name: "Monday",
    uv: 4000,
    pv: 2400,
    statistic: 5000
  },
  {
    name: "Tuesday",
    uv: 3000,
    pv: 1398,
    statistic: 6000
  },
  {
    name: "Wednesday",
    uv: 2000,
    pv: 9800,
    statistic: 4000
  },
  {
    name: "Thursday",
    uv: 2780,
    pv: 3908,
    statistic: 5500
  },
  {
    name: "Friday",
    uv: 1890,
    pv: 4800,
    statistic: 4800
  },
  {
    name: "Saturday",
    uv: 2390,
    pv: 3800,
    statistic: 3500
  },
  {
    name: "Sunday",
    uv: 3490,
    pv: 4300,
    statistic: 3000
  }
];

export default function CustomChart() {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LineChart width={850} height={300} data={data}>
        <CartesianGrid  vertical={false} horizontal={true} />
        <XAxis dataKey="name"  axisLine={false} />
        <YAxis axisLine={false} />
        <Tooltip />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={<CustomDot />}
          dot={false} 
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke="#82ca9d"
          activeDot={<CustomDot />}
          dot={false} 
        />
        <Line
          type="monotone"
          dataKey="statistic"
          stroke="red"
          activeDot={<CustomDot />}
          dot={false} 
        />
      </LineChart>
   
    </div>
  );
}
