import { Stack, Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const SubscriptiondeliveryNoteFilter = (props: any) => {
  const { deliverynote } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = useState(false);
  const { Option } = Select;

  const resetFilter = () => {
    if (selectedFilters) {
      props?.setSelectedStatus("");
    }
  };

  const uniqueStatuses = new Set(
    deliverynote?.all?.data.map((data: any) => data.status)
  );

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between items-center space-x-2">
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          Filter
        </Button>
        {selectedFilters && (
          <div className="flex justify-between items-center space-x-2">
            <Select
              size="small"
              showSearch
              style={{ width: 200, fontSize: "5px" }}
              allowClear
              placeholder="Filter By Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={deliverynote.isFetching}
              disabled={deliverynote.isFetching}
              onClear={() => resetFilter()}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                props?.setSelectedStatus(value); // Update the selected status directly
              }}
            >
              {/* Add the "All deliveryNote" option */}
              {/* Render unique order status options */}
              {Array.from(uniqueStatuses).map((status: any) => (
                <Option value={status} label={status} key={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <h1 className="text-gray-400">
          Result:{" "}
          {deliverynote?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            deliverynote?.all?.total
          )}
        </h1>
      </div>
    </div>
  );
};

export default SubscriptiondeliveryNoteFilter;
