import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import { LineChart } from "react-chartkick";
import {
  calculatePercentageChange,
  convertResultsQntyToObject,
} from "../../utils/converter";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const DashboardMinimalCard: React.FC<{
  title: string;
  height?: string;
  firstDate: string;
  secondDate: string;
}> = ({ title, height, firstDate, secondDate }) => {
  const { dashboard, layout } = useSelector((state: any) => state);
  const toDayDate = new Date().toISOString().slice(0, 10);
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let previousDay = currentDate.toISOString().split("T")[0];
  const convertArrayToObjectNew = (data: any) =>
    data?.reduce(
      (result: any, item: any) => ({
        ...result,
        [item?.hour]: parseInt(item?.quantity),
      }),
      {}
    );

  const data = [
    {
      name: toDayDate === firstDate ? "Today" : firstDate,
      data: convertArrayToObjectNew(
        dashboard?.dataActivetion?.data?.quantity?.currentDate?.quantityByHour
      ),
      color: "#2943D6",
    },
    {
      name: previousDay === secondDate ? "Yesterday" : secondDate,
      data: convertArrayToObjectNew(
        dashboard?.dataActivetion?.data?.quantity?.comparedDate?.quantityByHour
      ),
      color: "#C4C4C4",
    },
  ];

  const dataByRange = [
    {
      name: `${firstDate} - ${secondDate}`,
      data: convertResultsQntyToObject(
        dashboard?.dataActivetion?.data?.currentRange?.results
      ),
      color: "#2943D6",
    },
  ];

  const todayQuantityByRange =
    dashboard?.dataActivetion?.data?.currentRange?.totalQuantity;

  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  const todayQuantity =
    dashboard?.dataActivetion?.data?.quantity?.currentDate?.totalQuantity;
  const yesterdayQuantity =
    dashboard?.dataActivetion?.data?.quantity?.comparedDate?.totalQuantity;

  const percentageChange = calculatePercentageChange(
    todayQuantity ?? 0,
    yesterdayQuantity ?? 0
  );

  return (
    <div
      className={`relative flex flex-col ${
        height ? height : "max-h-[256px]"
      } p-4 bg-white rounded-md`}
    >
      <p className="text-gray-400 capitalize mb-2">{title}</p>
      <p className="text-2xl font-semibold">
        {layout?.dashboardPreview === "day"
          ? `${todayQuantity ?? 0} Pieces`
          : `${todayQuantityByRange ?? 0} Pieces`}
      </p>
      <p className="text-gray-400 capitalize">
        {layout?.dashboardPreview === "day" &&
          `${yesterdayQuantity ?? 0} Pieces`}
      </p>
      {layout?.dashboardPreview === "day" && percentageChange && (
        <div
          className={`absolute top-2 right-2 ${
            percentageChange.type === "increase"
              ? "bg-green-200"
              : percentageChange.type === "decrease"
              ? "bg-[#FEE6E9]"
              : "bg-blue-200"
          }  rounded-lg px-3 py-1`}
        >
          <span
            className={`${
              percentageChange.type === "increase"
                ? "text-green-500"
                : percentageChange.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } flex items-center py-0.5`}
          >
            {percentageChange.type === "increase" ? (
              <ArrowUpwardIcon />
            ) : percentageChange.type === "decrease" ? (
              <ArrowDownwardIcon />
            ) : (
              ""
            )}
            {percentageChange.percentage + "%"}
          </span>
        </div>
      )}

      {layout?.dashboardPreview === "day" ? (
        <LineChart
          data={data}
          thousands=","
          xtitle="Hours"
          height={"100%"}
          options={lineOptions}
          loading="Loading..."
        />
      ) : (
        <LineChart
          data={dataByRange}
          thousands=","
          xtitle="Days"
          height={"100%"}
          options={lineOptions}
          loading="Loading..."
        />
      )}
    </div>
  );
};

export default DashboardMinimalCard;
