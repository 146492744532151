import React, { useState } from "react";
import { Card, Table, Tag, Input, DatePicker,DatePickerProps ,TimePicker } from "antd";
import { ManOutlined, ToolOutlined, UserOutlined, WomanOutlined } from "@ant-design/icons";
import AttendanceChart from "./AttendanceChart";
import DepartmentFiltering from "../department/DepartmentFiltering";
import TimeComponent from "../overview/TimeComponent";
import { GoPlus } from "react-icons/go";
import TwoIconsCard from "../../../EBM/components/cards/TwoIconsCard";
import { ReactComponent as ImportIcons } from "../../../EBM/assets/images/icons/vscode-icons_file-type-excel2.svg";
import { ReactComponent as CartIcons } from "../../../EBM/assets/images/icons/Group 1000002857 (1).svg";
import { Radio } from 'antd';
import EbmButton from "../../../EBM/components/EbmButton";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import CustomButton from "../../components/Buttons/CustomButton";
import GeneralModal from "../../components/Modal/GeneralModal";
import AddAttendanceForm from "../../components/Forms/AddAttendanceForm";
import { FiPlus } from "react-icons/fi";
dayjs.extend(customParseFormat);
const { Search } = Input;

const stats = [
  { label: 'Total Employees', value: 452, change: '+', text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500' },
  { label: 'On Time', value: 360, change: '+5% ', text: "Increase than last month", icon: <UserOutlined />, color: 'text-red-500' },
  { label: 'Absent', value: 30, change: '+3% ', text: "Increase than last month", icon: <ManOutlined />, color: 'text-blue-500' },
  { label: 'Early Departures', value: 6, change: '+3% ', text: "Increase than last month", icon: <WomanOutlined />, color: 'text-purple-500' },
  { label: 'Time-off', value: 42, change: '2% ', text: "Less than last month", icon: <UserOutlined />, color: 'text-blue-500' },
  { label: 'Total Pay-Checks', value: `${10}M`, change: '2% ', text: "Increase than last month", icon: <UserOutlined />, color: 'text-blue-500' }
];

const sampleData = [
  {
    key: '1',
    name: 'John Brown',
    department: 'Finance',
    date: '2024-05-20',
    day: 'Monday',
    checkIn: '09:00',
    checkOut: '17:00',
    workHours: 8,
    active: true,
  },
  {
    key: '2',
    name: 'Jane Smith',
    department: 'HR',
    date: '2024-05-20',
    day: 'saturday',
    checkIn: '09:30',
    checkOut: '17:30',
    workHours: 8,
    active: true,
  },
  {
    key: '3',
    name: 'Samuel Green',
    department: 'Engineering',
    date: '2024-05-20',
    day: 'Thursday',
    checkIn: '10:00',
    checkOut: '18:00',
    workHours: 8,
    active: false,
  }
];

const Attendance: React.FC = () => {
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const [selectedDate2, setSelectedDate2] = useState<any>(formattedDate);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate(dateString);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'date',
    },
    {
      title: 'Check-in',
      dataIndex: 'checkIn',
      key: 'checkIn',
    },
    {
      title: 'Check-out',
      dataIndex: 'checkOut',
      key: 'checkOut',
    },
    {
      title: 'Work hours',
      dataIndex: 'workHours',
      key: 'workHours',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: any, record: any) => (
        <Tag color={record.active ? 'green' : 'volcano'} className="p-2 rounded-md w-24 text-center">
          {record.active ? 'Work from office' : 'Absent'}
        </Tag>
      ),
    },
  ];

  const onSearch = (value: string) => {
    console.log(value);
    // Implement search functionality if needed
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (data: any) => {
    // setSelectedData(data)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="p-4">
          <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Attendance Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <CustomButton
            title={"Add Attendance"}
            bgColor="[#605BFF]"
            textColor="white"
            icon={<FiPlus color="white" />}
            onClick={showModal}
          />
        </div>
      </div>
      <GeneralModal
        openModal={isModalOpen}
        closeModal={handleOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center pt-2">
            Add Attendance
          </h1>
        }
        component={
          <div className="px-4 h-full w-full">
              <AddAttendanceForm/>
          </div>
        }
        width={800}
      />
      <div className="flex flex-col xl:flex-row items-stretch mb-4 px-2 gap-6">
        <div className="p-4  w-[100%] xl:w-[30%] py-8 h-[301px] bg-white rounded-md mt-6">
          <TimeComponent />
        </div>
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5 mt-6 w-full xl:w-[60%]">
          {stats.map((stat, index) => (
            <TwoIconsCard
              amount={`${stat.value}`}
              title={`${stat.label}`}
              name={`${stat.text}`}
              icon={<GoPlus color="#43900C" />}
              iconTwo={<CartIcons />}
            />
          ))}
        </div>
        <div className=" w-[100%] xl:w-[30%] h-full xl:h-[301px] bg-white mt-6">
          <div className="font-poppins text-base font-normal leading-6 text-left">
            <div className="text-lg font-poppins mb-4 text-black px-2 py-2">Weekly Attendance</div>
          </div>
          <AttendanceChart />
        </div>
      </div>
      <div className="bg-white rounded-md">
        <div className="flex flex-col md:flex-row items-center justify-between pb-3 px-3 py-3">
          <h2 className="text-black">Attendance Overview</h2>
          <div className="flex space-x-4 items-center mt-4 md:mt-0">
            <div className="flex space-x-4 items-center">
              <Radio.Group defaultValue="all" className="flex space-x-4">
                <Radio value="all" className="ant-radio-button-wrapper-selected">
                  <span className="text-blue-500">All</span>
                </Radio>
                <Radio value="present">
                  Present
                </Radio>
                <Radio value="halfDay">
                  Half Day
                </Radio>
                <Radio value="absent">
                  Absent
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center mt-4 md:mt-0">
            <Search placeholder="input search text" onSearch={onSearch} enterButton />
            <DepartmentFiltering />
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange}/>
          </div>
        </div>
          <div className="w-full overflow-auto">
        <Table columns={columns} dataSource={sampleData} rowKey="key" />
        </div>
      </div>
    </div>
  );
};

export default Attendance;


