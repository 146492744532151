import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import "./searchButton.css";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { handlerSearchResultAction } from "../../store/layout/actions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
}));
let input;
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "60ch",
      "&:focus": {
        width: "60ch",
      },
    },
  },
}));

export default function ButtonSearch(props: any) {
  const dispatch = useDispatch();
  const onSearchAccount = (val: string) => {
    handlerSearchResultAction(val)(dispatch);
  };
  const { auth, layout } = useSelector((state: any) => state);
  // console.log("search from ", layout?.searchFrom);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search
        className={` w-[30%] md:w-[40%] lg:w-[50%] border-[1px] ml-[2%] xl:ml-[20%]  ${
          layout.isSideNavOpen ? "lg:ml-[7%]" : "lg:ml-[15%]"
        }`}
      >
        <SearchIconWrapper>
          <BiSearch />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => onSearchAccount(e.target.value)}
        />
      </Search>
    </Box>
  );
}
