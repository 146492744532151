import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTrackActivitiesAction,
  getPackageTrackIdAction,
} from "../../../../store/delivery/actions";
import PendingDeliveries from "../../../../components/cards/pendingDeliveries";
import TrackingForm from "../map/TrackingForm";
import { MapSection } from "../map/MapSection";
import { FaTruckLoading } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import { MdOutlineSocialDistance } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { IconType } from "react-icons";

interface CustomIconProps {
  Icon: IconType;
  size?: number;
  circleColor?: string;
  iconColor?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({
  Icon,
  size = 40,
  circleColor = "#fff",
  iconColor = "#000",
}) => {
  const circleStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: circleColor,
    width: size,
    height: size,
    borderWidth: 2,
  };

  const iconStyle: React.CSSProperties = {
    color: iconColor,
    fontSize: size * 0.6,
  };

  return (
    <div style={circleStyle}>
      <Icon style={iconStyle} />
    </div>
  );
};

const Track = () => {
  const { auth, tracking, trActivities } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [trackingId, setTrackingId] = useState<any>();
  const [hasValidLocations, setHasValidLocations] = useState(false);

  const handleTrackChange = (id: string, isValid: boolean) => {
    setTrackingId(id);
    setHasValidLocations(isValid);
  };
  

  useEffect(() => {
    if (auth.token) {
      getPackageTrackIdAction(auth.token, `?packageId=${trackingId}`)(dispatch);
      getAllTrackActivitiesAction(auth.token)(dispatch);
    }
  }, [auth.token, trackingId, dispatch]);

  const trackingHistory = tracking?.trActivities?.data;

  return (
    <div className="px-4 text-black flex flex-col md:flex-row">
      <div className="w-full md:w-1/3 p-4 h-screen">
        <PendingDeliveries />
      </div>

      <div className="w-full md:w-2/3 p-4  bg-white h-screen">
        <div className="relative h-[50%] ">
        <MapSection trackingId={trackingId} hasValidLocations={hasValidLocations} />
          <div className="absolute top-0 right-0 text-red-500 w-[40%]">
            <TrackingForm onTrackChange={handleTrackChange} />
          </div>
        </div>
        <div className=" h-[50%] ">
          <div className="flex flex-row items-center  w-full pt-1">
            <div className="flex flex-row items-center gap-4 w-[30%]">
              <div className="flex flex-col align-center gap-2">
                <div className="text-md font-semibold mt-4">
                  Est. Delivery Time
                </div>
                <span className="flex flex-row items-center gap-2">
                  <IoMdTime className="text-[#605BFF]" />
                  <p>225 km</p>
                </span>
              </div>
              <div className="flex flex-col align-center gap-2">
                <div className="text-md font-semibold mt-4">Total Distance</div>
                <span className="flex flex-row items-center gap-2">
                  <MdOutlineSocialDistance className="text-[#605BFF]" />
                  <p>225 km</p>
                </span>
              </div>
            </div>
            <div className="p-4 w-[70%] flex flex-col align-enter mt-4">
              <Steps
                labelPlacement="vertical"
                current={2}
                items={[
                  {
                    title: "Picked Up",
                    icon: (
                      <CustomIcon Icon={FaTruckLoading} iconColor="#605BFF" />
                    ),
                  },
                  {
                    title: "In Transit",
                    icon: <CustomIcon Icon={FaTruckFast} iconColor="#605BFF" />,
                    status: "process",
                  },
                  {
                    title: "Delivered",
                    icon: (
                      <CustomIcon Icon={FaTruckLoading} iconColor="#605BFF" />
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Tracking History</h2>
            <Steps
              progressDot
              current={1}
              direction="vertical"
              items={
                trackingHistory &&
                trackingHistory?.map((item: any) => ({
                  title: item?.details,
                  description: new Date(
                    item?.createdAt.slice(0, 10)
                  ).toLocaleString(),
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;
