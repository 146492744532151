import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Typography } from "@mui/material";

const DeleteAction = (props: any) => {
  return (
    <div style={{ display: "flex", gap: 6 }} onClick={() => props?.onDelete()}>
      <AiOutlineDelete size={20} color="#F1595C" spacing={5} />
      <Typography sx={{ fontSize: 14 }}>Delete</Typography>
    </div>
  );
};
export default DeleteAction;
