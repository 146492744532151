import React, { useState, useEffect } from "react";
import { Card, Input, Steps, Skeleton } from "antd";
import { BiMessageAltDetail } from "react-icons/bi";
import { IoCallOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { getAllPackageTrackAction } from "../../store/delivery/actions";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLocationOn } from "react-icons/md";
import { TbPointFilled } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
const { Step } = Steps;

const PendingDeliveries = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { auth, tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (auth.token) {
      getAllPackageTrackAction(auth.token, "?")(dispatch);
    }
  }, [auth.token, dispatch]);

  // Filter deliveries based on searchTerm
  const filteredDeliveries = tracking?.all?.data.filter((el: any) =>
    el?.packageId?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  return (
    <div className="p-4 mx-auto text-black">
      <div className="mb-6">
        <Input
          placeholder="Search ..."
          allowClear
          onChange={handleSearch}
          value={searchTerm}
        />
      </div>

      <div className="flex flex-col mb-4">
        <span className="text-lg font-semibold">Pending Deliveries</span>
      </div>
      <div className="p-1 h-[80vh] overflow-y-scroll">
        {tracking.isFetching ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} active paragraph={{ rows: 6 }} />
          ))
        ) : filteredDeliveries.length > 0 ? (
          filteredDeliveries.map((el?: any) => (
            <Card key={el?._id} className="shadow-lg rounded-lg mb-4">
              <div className="mb-4 flex flex-row items-center justify-between">
                <p className="text-sm text-[#B0B0B0]">Tracking ID:</p>
                <span className="text-sm text-black font-semibold">
                  {el?.packageId}
                </span>
              </div>
              <div className="mb-4 flex flex-row items-center justify-between">
                <p className="text-sm text-gray-600">Device</p>
                <p className="text-sm text-gray-600">
                  Status: <span className="text-md">{el?.trackingStatus}</span>
                </p>
              </div>

              <div className="mb-4">
                <Steps current={1} direction="vertical">
                  <Step
                    title={el?.locationFrom?.name}
                    description={el?.from?.description}
                    icon={
                      <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-200">
                        <TbPointFilled className="text-green-500" />
                      </div>
                    }
                  />
                  <Step
                    title={el?.locationTo?.name}
                    description={el?.to?.description}
                    icon={
                      <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300">
                        <FaLocationDot className="h-4 w-3" />
                      </div>
                    }
                  />
                </Steps>
              </div>
              <div className="flex items-center">
                <RxAvatar className="text-xl text-gray-500" />
                <div className="ml-3">
                  <p className="text-sm text-gray-500">Receiver</p>
                  <p className="text-sm font-semibold">{el?.receiver?.name}</p>
                  <p className="text-sm text-gray-500">{"MTN Rwanda"}</p>
                </div>
                <div className="ml-auto flex space-x-2">
                  <div className="bg-[#F7F5FF] p-2 rounded-md flex items-center justify-center">
                    <IoCallOutline className="text-[#605BFF] text-lg" />
                  </div>
                  <div className="bg-[#F7F5FF] p-2 rounded-md flex items-center justify-center relative">
                    <BiMessageAltDetail className="text-[#605BFF] text-lg" />
                    <div className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full border border-white"></div>
                  </div>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <p className="text-center text-gray-500">No results found</p>
        )}
      </div>
    </div>
  );
};

export default PendingDeliveries;
