import React, { useEffect, useState } from "react";
import { Table, InputNumber, Form, Tag, Modal, DatePicker, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Stack, Paper } from "@mui/material";
import type { DatePickerProps } from "antd";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { createDeliveryNoteAction } from "../../store/product/actions";
import Result from "../../components/Results/SuccessfullySent";

const App = (props: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { product } = useSelector((state: any) => state);
  const { TextArea } = Input;
  const [qSent, setQSent] = React.useState<any>("");
  const [tAmount, setTAmount] = React.useState<any>([]);

  const dataMapper: any[] = [];
  const value = product?.selected?.listOfProducts;

  const [data, setData] = React.useState(dataMapper);
  const [termsAndConditions, setTermsAndConditions] = React.useState("");
  const onChangeDeliveryDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    // setFormData(dateString);
    setFormData({
      ...formData,
      shippingDate: dateString,
    });
  };
  const [formData, setFormData] = useState({
    shiptype: "",
    trackingId: "",
    name: "",
    contact: "",
    shippingDate: "" as string | string[],
  });
  const columns: ColumnsType<any> = [
    {
      title: "Purchase Order",
      children: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
        },
        {
          title: "Models",
          dataIndex: "model",
          key: "model",
        },
        {
          title: "Description",
          dataIndex: "specification",
          key: "specification",
        },
        {
          title: "Quantity",
          dataIndex: "qty",
          key: "qty",
        },
        {
          title: "Price(Frw)",
          dataIndex: "data",
          key: "data",
          render: (text, record) => (
            <p> {record?.data?.priceUnit?.toLocaleString()}</p>
          ),
        },
        {
          title: "QTY Left To Be Delivered",
          dataIndex: "quantityLeft",
          key: "quantityLeft",
          render: (text, record) => (
            <span
              style={{
                color: `${record.quantityLeft === 0 ? "green" : "red"}`,
              }}
            >
              {" "}
              {record.quantityLeft}
            </span>
          ),
        },
      ],
    },
    {
      title: "To Be Delivered",
      children: [
        {
          title: "Quantity",
          dataIndex: "qtySent",
          key: "qtySent",
          render: (text, record) => (
            <InputNumber
              type="number"
              min={0}
              disabled={record?.status === "Fully-In-Transit"}
              max={record.quantityLeft}
              defaultValue={parseInt(text)}
              onChange={(value: any) => {
                record.qtySent = value;
                setQSent(value);
              }}
            />
          ),
        },
        {
          title: "Amount(Frw)",
          dataIndex: "total",
          key: "total",
          render: (text, record) => (
            <>
              <p>
                {" "}
                {record?.qtySent &&
                  (record.total =
                    record?.qtySent *
                    record?.data?.priceUnit)?.toLocaleString()}
              </p>
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        el &&
          dataMapper.push({
            type: el.quotationItem.product.type,
            brand: el.quotationItem.product.brand,
            model: el.quotationItem.product.model,
            specification: `${el.quotationItem?.specification
              ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
              ?.join(", ")}`,
            qty: el.quantity,
            quantityLeft: el.quantityLeft,
            qtySent: "",
            total: "",
            status: product?.selected?.status,
            purchaseItem: el._id,
            data: el,
          });
      });

    setData(dataMapper);
  }, [product.selected]);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  const handleModal = () => {
    setIsModalOpen(true);
  };

  function closeTab() {
    window.close();
  }

  const renderFooter = () => {
    return (
      <p className="text-end font-bold text-lg">
        {" "}
        Total:
        <span className="ml-2 font-normal">
          {data
            .reduce((total: number, current: any) => total + current?.total, 0)
            .toLocaleString()}{" "}
          Frw
        </span>
      </p>
    );
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        pagination={false}
        footer={renderFooter}
      />
      <Stack>
        <TextArea
          rows={2}
          style={{ width: "300px" }}
          placeholder="Terms & Conditions"
          onChange={(e) => setTermsAndConditions(e.target.value)}
        />
      </Stack>
      <Stack style={{ width: 800 }}>
        <form className="w-full max-w-full">
          <h1 className="mb-6 mt-4 font-semibold">Shipping Details</h1>
          <div className="grid grid-cols-2 -mx-3 mb-6">
            <div className="w-full md:w-3/4 mb-4 px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                Shipping Type
              </label>
              <select
                id="countries"
                className="appearance-none text-sm block w-full bg-white text-gray-700 border border-gray-200 rounded py-1.5 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                value={formData.shiptype}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    shiptype: e.target.value,
                  })
                }
              >
                <option selected>Choose a Type</option>
                <option value="Road">Ground (road)</option>
                <option value="Rail">Rail</option>
                <option value="Ocean">Ocean</option>
                <option value="Air">Air</option>
              </select>
            </div>
            <div className="w-full md:w-3/4 mb-4 px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                Tracking Id
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1.5 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder=""
                value={formData.trackingId}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    trackingId: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-full md:w-3/4 mb-4 px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                Contact
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1.5 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder=""
                value={formData.contact}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    contact: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-full md:w-3/4 mb-4 px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                Shipping Date
              </label>
              <DatePicker
                onChange={onChangeDeliveryDate}
                style={{ width: 280 }}
              />
            </div>
            <div className="w-full md:w-3/4 mb-4 px-3">
              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                Name/Company
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-1.5 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </form>
      </Stack>

      <Stack alignItems="end" spacing={2} sx={{ width: "100%" }}>
        <LoadingButton
          onClick={async () => {
            await createDeliveryNoteAction("", {
              pOrderID: product.selected._id,
              termsAndConditions: termsAndConditions,
              listOfItems: data,
              shippingDetails: formData,
              isNotify: true,
              notification: {
                action: `Delivery Note from ${product?.selected?.supplier?.name}`,
                role: ["admin", "finance", "finance-manager", "inventory"],
                message: `Shipping Type: ${formData?.shiptype}, Tracking ID: ${formData?.trackingId} Company: ${formData?.name}`,
                title: "Delivery Note",
              },
            })(dispatch);
            handleModal();
          }}
          sx={{ width: "5rem" }}
          variant="contained"
          disabled={product?.selected?.status === "Fully-In-Transit"}
          endIcon={<SendIcon />}
          loading={product.isFetching}
        >
          Send
        </LoadingButton>

        <Modal
          title={""}
          open={isModalOpen}
          footer={null}
          maskClosable={false}
          className="min-w-min"
          style={{ marginTop: "200px" }}
          bodyStyle={{ height: 350 }}
          // icon={props?.icon || <HomeIcon />}
        >
          <div>
            <Paper
              style={{ width: 1200, marginTop: "20px", boxShadow: "none" }}
            >
              <Result
                title={"Delivery Note Sent successfully"}
                closeTab={closeTab}
              />
            </Paper>
          </div>
        </Modal>
      </Stack>
    </>
  );
};

export default App;
function setQSent(value: any) {
  throw new Error("Function not implemented.");
}
