import { notification } from "antd";
import { ebmPurchaseActions } from ".";
import { getEbmImportServices, updateEbmImportItemService } from "./service";

export const getEbmImportAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmPurchaseActions.setIsFetching(true));
      const res = await getEbmImportServices(token, query);
      if (res?.status === 200) {
        dispatch(ebmPurchaseActions.setAll(res?.data));
        dispatch(ebmPurchaseActions.setIsFetching(false));
      }
      dispatch(ebmPurchaseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const updateEbmImportItemAction = (token: string, data: {},query?:string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmPurchaseActions.setIsFetching(true));
      const res = await updateEbmImportItemService(data, token);
      if (res?.status === 201) {
        // dispatch(ebmPurchaseActions.setAll(res));
        dispatch(ebmPurchaseActions.setIsFetching(false));
        notification.success({ message: "Successfully" });
      }
      dispatch(ebmPurchaseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Failed" });
    }
  };
};