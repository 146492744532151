import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSubRequestAction } from "../../store/subscription/subscriptionRequest/actions";
import { submitUpdateNotesAction } from "../../store/account/actions";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const RejectSubscriptionReqForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { auth, subscriptionRequest } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    props?.requestId &&
      (await submitUpdateNotesAction(
        accountId as string,
        {
          notes: {
            title: " Reject Subscription Request",
            details: values?.rejectSubReason,
          },
        },
        auth.token
      )(dispatch));
    await updateSubRequestAction(auth?.token, props?.requestId, {
      ...values,
      status: "rejected",
    })(dispatch);
    notification.success({ message: "Request has been Rejected" });
    form.resetFields();
    props?.onCancel();
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={props?.data || {}}
        style={{ minWidth: 600 }}
        scrollToFirstError
      >
        <h1 className="font-bold text-xl text-gray-900 pb-4 pl-16">
          Reject Subscription Request
        </h1>
        <div className="ml-12">
          <Form.Item
            name="rejectSubReason"
            rules={[
              {
                required: true,
                message: "Reason is required!",
                whitespace: true,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Reason" />
          </Form.Item>
          <Form.Item>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "60%" }}
              loading={subscriptionRequest?.isFetching}
            >
              Submit
            </LoadingButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default RejectSubscriptionReqForm;
