import "./style.css";
import React, { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ReactComponent as DN } from "../../../assets/icons/finance/td.svg";
import { ReactComponent as PO } from "../../../assets/icons/finance/po.svg";
import { ReactComponent as TT } from "../../../assets/icons/finance/tt.svg";
import { ReactComponent as TP } from "../../../assets/icons/finance/tp.svg";
import { ReactComponent as NS } from "../../../assets/icons/finance/ns.svg";
import DateRangeSelector from "../channels/dateRangePicke";
import { Card, Row, Table, Tag } from "antd";
import type { TableColumnsType } from "antd";
import { Avatar, List } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { getAllPurchaseOrdersAction } from "../../../store/finance/actions";
import { DateRangePicker } from "react-date-range";
import { searchValue } from "../../../utils/setColor";
import { enUS } from 'date-fns/locale';




interface DataType {
  key: React.Key;
  date: string;
  customer: string;
  agent: any;
  shop: string;
  model: string;
  amount: string;
  status: any;
}

const financedata = [
  {
    picture: <DN />,
    label: "Total Derivery Notes",
    value: 30?.toLocaleString(),
  },
  {
    picture: <PO />,
    label: "Total Purchase Orders",
    value: 100?.toLocaleString(),
  },
  {
    picture: <TT />,
    label: "Total Transactions",
    value: 2000000?.toLocaleString(),
  },
  {
    picture: <TP />,
    label: "Total Purchased",
    value: 28?.toLocaleString(),
  },
  {
    picture: <NS />,
    label: "No Sales",
    value: 700000?.toLocaleString(),
  },
];

const data = [
  { name: "January", dn: 300, po: 456, tr: 200 },
  { name: "February", dn: 145, po: 230, tr: 230 },
  { name: "March", dn: 100, po: 345, tr: 670 },
  { name: "April", dn: 80, po: 346, tr: 560 },
  { name: "May", dn: 145, po: 340, tr: 30 },
  { name: "June", dn: 900, po: 455, tr: 170 },
  { name: "July", dn: 900, po: 455, tr: 170 },
  { name: "August", dn: 900, po: 455, tr: 170 },
  { name: "September", dn: 900, po: 455, tr: 170 },
  { name: "October", dn: 900, po: 455, tr: 170 },
  { name: "November", dn: 900, po: 455, tr: 170 },
  { name: "December", dn: 900, po: 455, tr: 170 }
];
const listData = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];
export default function PageView() {
  const dispatch = useDispatch();
  const { auth, finance, purchase } = useSelector((state: any) => state);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });


  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };


  const startDates = new Date(selectedDateRange?.startDate).toISOString().split("T")[0]
  const endDates = new Date(selectedDateRange?.endDate).toISOString().split("T")[0]


  const column: TableColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Amount (Rwf)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];


  React.useEffect(() => {
    getAllPurchaseOrdersAction(auth?.token, `?startDate=${startDates}&endDate=${endDates}`)(dispatch);
  }, [auth.token, startDates, endDates, dispatch]);

  const listData = [
    { companyName: 'KIGALI PHONES LTD', status: 'verified' },
    { companyName: 'AY & JAY TRADING LTD', status: 'pending' },
    { companyName: 'Australian walks 100km after outback crash.', status: 'verified' },
    { companyName: 'SAGETEL (HONG KONG) CO. LIMITED', status: 'pending' },
    { companyName: 'AY & JAY TRADING LTD', status: 'verified' },
  ];

  const newData = finance?.all?.data?.transactions?.map((d: any) => {
    const dateObject = new Date(d?.paidOn);
    const formattedDate = dateObject.toISOString().split('T')[0];

    return {
      key: d?._id,
      date: formattedDate,
      customer: d?.cart?.customer?.name,
      agent: d?.shopStockItem?.shop?.createdBy?.names,
      shop: d?.shopStockItem?.shop?.name,
      model: d?.payment?.mode,
      amount: d?.payment?.amount,
      status: <Tag color={d?.status === 'paid' ? 'green' : 'red'}>{d?.status}</Tag>
    };
  })?.slice(0, 5);

  const recentPos = (purchase?.recent?.data)?.slice(0, 5);

  return (
    <div className="text-black py-6">
      <div className="flex gap-8 flex-wrap">
        {financedata?.map((data: any) => (
          <div className="bg-white w-[18rem] p-6 flex gap-8 rounded-lg">
            {data?.picture}
            <span>
              <h1 className="font-semibold text-[1.35rem] opacity-80 leading-loose">
                {data?.value}
              </h1>
              <p className="opacity-90">{data?.label}</p>
            </span>
          </div>
        ))}
      </div>
      <div className="w-full  p-3 rounded-lg flex flex-col md:flex-row">
        <Card bordered={false} className="w-[100%] md:w-[65%] pt-3 px-4">
          <div className="flex flex-row items-center justify-between">
            <div className="text-poppins text-lg font-normal text-[#030229]">Analytics</div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#2943D680] mr-1 rounded-full"></div>
                <div>Delivery Note</div></div>
              <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#FA365980] mr-1 rounded-full"></div><div>Purchase Order</div></div>
              <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#0FA91E80] mr-1 rounded-full"></div><div>Transactions</div></div>
            </div>
          </div>
          <div className="w-[100%] md:w-[66%] bg-white rounded-lg overflow-x-auto md:overflow-x-hidden">
            <BarChart
              width={950}
              height={300}
              data={data}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
              <Bar
                dataKey="po"
                fill="#acb8fc"
                barSize={7}
                radius={[5, 5, 0, 0]}
              />
              <Bar dataKey="dn" fill="#f4abb8" barSize={7} radius={[5, 5, 0, 0]} />
              <Bar
                dataKey="tr"
                fill="#9fef95c9"
                barSize={7}
                radius={[5, 5, 0, 0]}
              />
            </BarChart>

          </div>
        </Card>
        <div
          className="w-[100%] pt-3 overflow-x-auto md:overflow-x-hidden">
          <DateRangePicker
            onChange={handleSelect}
            showPreview
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[selectedDateRange]}
            direction="vertical"
            locale={enUS}
          />
        </div>

      </div>
      <div className=" w-full  p-3 rounded-lg">
        <Row className="w-full gap-4">
          <Card bordered={false} className="w-[100%] md:w-[65%] pt-3 px-4">
            <div className="text-poppins text-lg font-normal text-[#030229]">
              Transactions
            </div>
            <div>
              <Table columns={column} loading={finance.isFetching} dataSource={newData} scroll={{ x: true }} />
            </div>
          </Card>
          <Card bordered={false} className=" w-[100%] md:w-[33%]">
            <div>
              <List
                size="small"
                header={
                  <div className="flex flex-row justify-between px-4">
                    <div className="text-[#030229] font-bold">Recent P.O </div>
                    <div className="text-[#605BFF] cursor-pointer">View All</div>
                  </div>
                }
                bordered={false}
                dataSource={recentPos}
                renderItem={(item: any) => {
                  return (
                    <List.Item>
                      <div className="flex flex-row justify-between w-full">
                        <div>
                          <div className="text-[#605BFF]">{item?._id}</div>
                          <div>{item?.confirmationDate}</div>
                          <div>{item?.statuses[0]?.count} items</div>
                        </div>
                        <div>
                          <Tag color="green" className="rounded-md w-20 text-center">{item?.status}</Tag>
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>

          </Card>
        </Row>
      </div>
    </div>
  );
}
