import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../../components/HeaderChannel";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddItem from "../subscription/AddItems";
import { useDispatch, useSelector } from "react-redux";
import {
  getPricelistItemAction,
  getSubscriptionPricelistAction,
} from "../../../../store/channel/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import SearchInput from "../../../../components/buttons/SearchButton";
import SubProductGrid from "../../../../components/grids/subscriptionPriceList/SubProductGrid";
import { myChannelActions } from "../../../../store/channel";
import { mySubScritionPriceList } from "../../../../store/subscription/priceList";
import { Radio} from "antd";
import { handleSubscriptionPlanType } from "../../../../store/layout/actions";

const SubscriptionPriceList = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(myChannelActions.setSelectedPriceListItems(null));
    dispatch(mySubScritionPriceList.setUpdated(null));
  };
  const { auth, channel, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subChannelId } = useParams();
  const [selectedModelId, setSelectedModelId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const location = useLocation();
  const isSubsPage = () => {
    return ["/sub/"].some((path) => location.pathname.includes(path));
  };
  const isSubs = isSubsPage();

  const hasNotChannel = () => {
    return ["/subscription/devices"].some((path) =>
      location.pathname.includes(path)
    );
  };
  const isChannelNotExist = hasNotChannel();

  const handleSelectType = (value: any) => {
    handleSubscriptionPlanType(value.target.value)(dispatch);
  };
  React.useEffect(() => {
    if (auth?.token && (channel?.selected?._id || subChannelId)) {
      getPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}`
      )(dispatch);
    }
  }, [auth?.token, channel?.selected, dispatch, page, limit, subChannelId]);

  React.useEffect(() => {
    if (auth?.token && (channel?.selected?._id || subChannelId)) {
      getSubscriptionPricelistAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}&page=${page - 1
        }&limit=${limit}&type=${layout?.subscriptionType}`
      )(dispatch);
    }
  }, [
    auth?.token,
    channel?.selected,
    dispatch,
    page,
    limit,
    isSubs,
    isChannelNotExist,
    subChannelId,
    layout?.subscriptionType,
  ]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearch = () => {
    // getPricelistSearchAction(
    //   auth?.token,
    //   `?channel=${channel?.selected?._id}&sk=${searchKey}`
    // )(dispatch);
  };

  return (
    <Stack spacing={1} style={{ marginTop: "25px" }}>
      <div className="text-black items-center justify-between mt-7 my-auto flex px-4">
        <DetailsHeaderActionBar pageName="Plan" goBack={goBack} title=" " />
        {["admin", "finance", "finance-manager", "dev"]?.includes(
          auth?.user?.role?.toLowerCase()
        ) && (
            <HeaderComponent
              item="Price List"
              modelTitle="Add New Subscription Price List"
              isNotCollapse={true}
              ModelComponent={
                <AddItem
                  setSelectedModelId={setSelectedModelId}
                  onCancel={handleCancel}
                  selectePlanPlanType={layout?.subscriptionType}
                />
              }
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCancel={handleCancel}
            />
          )}
      </div>
      <div className="flex items-center justify-between px-4">
        {/* <PriceListFilter isModalOpen={isModalOpen} /> */}
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
        <div className="space-x-2">
          <Radio.Group
            defaultValue={layout?.subscriptionType}
            buttonStyle="solid"
            onChange={handleSelectType}
          >
            <Radio.Button value="B2B">Postpaid (B2B)</Radio.Button>
            <Radio.Button value="B2C">Prepaid (B2C)</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.subPriceList?.total}
        count={Math.ceil(channel?.subPriceList?.total / limit)}
      >
        {channel?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 15 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!channel.isFetching && (
          <div
            className={`flex flex-wrap gap-x-2 ${layout.isSideNavOpen ? "gap-y-2" : "gap-y-4"
              } items-start mt-5`}
          >
            <SubProductGrid data={channel?.subPriceList?.data} />
          </div>
        )}
      </ScrollableFrame>
    </Stack>
  );
};

export default SubscriptionPriceList;

