import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "../utils/converter";


const EbmRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  const user = getUser();

  const allowedRoles = ["admin", "hr", "dev","finance"];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role || user?.role;


  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Ebm".toLowerCase()
            )}
            // pages={pagesArray}
          />
        }
        selectedNav={10}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default EbmRoutes;