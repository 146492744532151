import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { name: "Unlocked", value: 800 },
  { name: "Locked", value: 200 },
];

const RenderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    payload,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  // Adjust the outerRadius calculation based on percent
  const increasedOuterRadius = outerRadius * (1 + 0.2 * percent);

  // Define an array of colors based on conditions
  const colors = ["#20DD8E", "#F1595C", "#F1595C"];
  // Determine fill color based on percent and conditions
  let fillColor;
  if (percent >= 0.5) {
    fillColor = colors[0];
  } else if (percent >= 0.3) {
    fillColor = colors[1];
  } else {
    fillColor = colors[2];
  }

  // Calculate the position of the content
  const textX = cx + increasedOuterRadius * cos - 55;
  const textY = cy + increasedOuterRadius * sin + 10;

  return (
    <g>
      {/* <text
        x={textX}
        y={textY}
        dy={8}
        textAnchor="middle"
        fill={fillColor}
        fontSize={18}
        style={{ backgroundColor: "green" }}
      >
        {payload.name}
      </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={increasedOuterRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fillColor}
      />
      <Sector
        cx={cx}
        cy={cx}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={increasedOuterRadius}
        fill={fillColor}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fillColor}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fillColor} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {` ${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const PieChrt = () => {
  const activeIndices = data.map((_, index) => index); // Array of all indices
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback((_: any, index: number) => {
    setActiveIndex(index);
  }, []);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className="container mx-auto"
    >
      <div style={{ position: "relative", display: "flex" }}>
        <PieChart width={450} height={310} className="mt-[-10rem] ml-[-10px]">
          <Pie
            activeIndex={activeIndices}
            activeShape={RenderActiveShape}
            data={data}
            cx={200}
            cy={200}
            innerRadius={55}
            outerRadius={75}
            fill="#20DD8E"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            <Tooltip />
          </Pie>
        </PieChart>
      </div>
    </ResponsiveContainer>
  );
};

export default PieChrt;
