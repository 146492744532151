import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";

export default function FilterDashboardTabsButton(props: any) {
  const { auth } = useSelector((state: any) => state);
  const isIkosoraDash = localStorage.getItem("isIkosoraDashboard");
  const selectedDashboard =
    isIkosoraDash !== null ? JSON.parse(isIkosoraDash) : false;

  const [value, setValue] = React.useState(selectedDashboard ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props?.setHasIkosora(newValue === 0 ? false : true);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      <Tab label="General Dashboard" />
      {props?.selectedOverView === "day" &&
        !["franchisee"]?.includes(auth?.user?.role) && (
          <Tab label="MTN Branded Dashboard" />
        )}
    </Tabs>
  );
}
