import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { getPricelistItemAction } from "../../store/channel/actions";
const { Option } = Select;

const PriceListFilter = (props: any) => {
  const { product, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);

  useEffect(() => {
    dispatch(
      myProductActions.setQuery(
        `?${type && `type=${type}${brand && `&brand=${brand}`}&`}`
      )
    );
  }, [brand, dispatch, type]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
    }
    if (props.isModalOpen === false) {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?limit=15&type=pos${
            channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
          }`
        )(dispatch);
    }
  };

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          product?.query + `limit=${product?.all?.total}&page=${page - 1}` ||
            "?"
        )(dispatch);
      auth?.token &&
        (await getAllAttributesAction(
          auth?.token,
          product?.query || "?"
        )(dispatch));
    };
    fetchingData();
  }, [auth, product?.query, dispatch, limit, page]);

  useEffect(() => {
    productId &&
      auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?product=${productId}${
          channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
        }`
      )(dispatch);
  }, [auth?.token, channel?.selected?._id, dispatch, productId]);

  useEffect(() => {
    if (productId === " ") {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${channel?.selected?._id}&limit=15&type=pos`
        )(dispatch);
    }
  }, [productId]);

  console.log("object::::::", product?.all?.total);

  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>

          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={product.isFetching}
                disabled={product.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setType(value);
                }}
              />
              {type && (
                <Select
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.attributes?.brands}
                  onChange={(value: any) => {
                    !value && setBrand("");
                    !value && setProductId(" ");
                    value && setBrand(value);
                  }}
                />
              )}
              {brand && props.isModalOpen === false && (
                <Select
                  loading={product.isFetching || channel.isFetching}
                  disabled={product.isFetching || channel.isFetching}
                  showSearch
                  placeholder="Filter By Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && setProductId(" ");
                    value && setProductId(value);
                  }}
                  // onSearch={onSearch}
                >
                  {product?.all?.data?.map((el: any) => (
                    <Option value={el?._id}>{el?.model}</Option>
                  ))}
                </Select>
              )}
            </Stack>
          )}
          {/* <h1 className="text-gray-400">
            Available Product{channel?.all?.result > 1 && "s"}:{" "}
            {product.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              product?.all?.result
            )}
          </h1> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default PriceListFilter;
