import React, { useEffect, useState } from "react";
import { Paper, Stack } from "@mui/material";
import { Table, Tag, notification } from "antd";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { searchValue } from "../../../../utils/setColor";
import RequestTransferForm from "../../../../components/forms/RequestTransferForm";
import TransferFilter from "../../../../components/filters/TransferFiltering";
import {
  getAllProductsAction,
  makeTransferAction,
} from "../../../../store/transfer/actions";

interface DataType {
  id: any;
  sn: any;
  imei: any;
  price: any;
  specification: any;
  status: any;
  data?: any;
}

const NewRequestTransfer = () => {
  const { auth, transfer } = useSelector((state: any) => state);
  const [prodId, setProdId] = useState();
  const [shopFrmId, setShopFrmId] = useState("");
  const [shopToId, setShopToId] = useState("");
  const [productsId, setProductId] = useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restState = () => {
    setShopFrmId("");
    setShopToId("");
    setProductId(null);
  };

  const handleSubmit = async () => {
    if (!shopToId) {
      notification.warning({
        message: "Please Select Shop",
      });
    } else if (shopFrmId === shopToId) {
      notification.warning({
        message: "You can't Transfer Within the same Shop",
      });
    } else {
      auth?.token &&
        (await makeTransferAction(auth?.token, {
          shopFrom: shopFrmId,
          shopTo: shopToId,
          list: [...productsId],
          createdBy: auth?.user?._id,
        })(dispatch));
      restState();
      navigate(-1);
    }
  };

  const handleSaveId = (id: any) => {
    const index = productsId.indexOf(id);
    if (index === -1) {
      setProductId([...productsId, id]);
    } else {
      const updatedIds = [...productsId];
      updatedIds.splice(index, 1);
      setProductId(updatedIds);
    }
  };

  useEffect(() => {
    auth?.token &&
      prodId &&
      getAllProductsAction(
        auth?.token,
        `?shop=${shopFrmId}&product=${prodId}&status=pending`
      )(dispatch);
  }, [auth?.token, dispatch, prodId, shopFrmId]);

  const columns = [
    {
      title: "Shop",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.shop?.name}</p>
          <p>{record?.data?.shop?.channel?.name}</p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <>
          <p>
            {record?.data?.requestedItem?.product?.product?.model +
              "~" +
              record?.data?.requestedItem?.product?.product?.type}
          </p>
        </>
      ),
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
    },
    { title: "Serial Number", dataIndex: "sn", key: "sn" },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.wareHouseItem?.imei1 || "N/A"}</p>
          <p>{record?.data?.wareHouseItem?.imei2}</p>
        </>
      ),
    },
    { title: "Price(Frw)", dataIndex: "price", key: "price" },
    {
      title: "Extended Warranty",
      dataIndex: "data",
      key: "data",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.requestedItem.product?.extendedWarranty || "0"}%</p>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: any) => (
        <LoadingButton
          style={{
            backgroundColor: productsId?.includes(record.id)
              ? "blue"
              : "inherit",
            color: productsId?.includes(record.id) && "white",
          }}
          onClick={() => handleSaveId(record.id)}
          loading={transfer?.isFetching}
          disabled={record?.isSelected}
          variant={record.isSelected ? "contained" : "outlined"}
        >
          {record.isSelected ? "Transfered" : "Add To Transfer"}
        </LoadingButton>
      ),
    },
  ];

  const data: DataType[] = [];
  const values = transfer?.shopProducts?.data;
  values &&
    values?.forEach((el: any) => {
      data.push({
        id: el._id,
        sn: el?.wareHouseItem?.serialNumber,
        imei: el?.wareHouseItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.wareHouseItem?.quotationItem?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });

  return (
    <Stack spacing={1}>
      <div className="mt-5 text-black">
        <DetailsHeaderActionBar pageName={"Channel"} title={"Transfer"} />
      </div>
      <RequestTransferForm
        setShopFrmId={setShopFrmId}
        setShopToId={setShopToId}
      />
      {shopFrmId && (
        <>
          <Paper elevation={0} style={{ marginBottom: "20px" }}>
            <Stack spacing={0}>
              <TransferFilter setProdId={setProdId} shopId={shopFrmId} />
              <Table
                columns={columns}
                dataSource={data}
                loading={transfer?.isFetching}
              />
            </Stack>
          </Paper>
          {productsId.length > 0 && (
            <Stack direction={"row"} spacing={2}>
              <LoadingButton
                loading={transfer?.isFetching}
                // loadingPosition="start"
                variant="contained"
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Transfer
              </LoadingButton>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default NewRequestTransfer;
