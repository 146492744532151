import React from "react";
import { Stack } from "@mui/material";
import { Divider } from "antd";
import { Table } from "antd";
import { MyPaymentcolumns, Mycolumns } from "../../../assets/data/data";

const POSTransactionDetails = (props: any) => {
  const billingdata = [
    {
      company: "Samphone",
      address: "Kigali City - Nyarugenge",
      email: "samphone@gmail.com",
      contact: "+25078888888",
    },
  ];

  const productToDisplay = {
    model:
      props?.dataToDisplay?.data?.shopStockItem?.requestedItem?.product?.product
        ?.model,
    specs: `${props?.dataToDisplay?.data?.shopStockItem?.requestedItem?.product?.specification
      ?.slice(2, 6)
      ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
      ?.join(", ")}`,
    imei: props?.dataToDisplay?.barCode,
    amountPaid: props?.dataToDisplay?.amount?.toLocaleString(),
  };

  const paymentDetailsDisplay =
    props?.dataToDisplay?.data?.cart?.autoPayments &&
    props?.dataToDisplay?.data?.cart?.autoPayments?.map((d: any) => {
      return {
        mode: d?.mode?.split("_")?.join(" "),
        amount: parseInt(d?.amount)?.toLocaleString(),
        phone: d?.phone,
        status: d?.status?.toLowerCase(),
      };
    });

  return (
    <div className="text-black  h-screen px-12">
      <div className="flex flex-row justify-between pt-2">
        <div className="w-[15rem]">
          <h2 className="text-base font-semibold mb-2">Billing Information</h2>
          {billingdata?.map((item: any) => (
            <div className="flex flex-col gap-1">
              <Stack>
                <p className="text-sm font-semibold">Company</p>
                <p className="text-sm">{item?.company}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Address</p>
                <p className="text-sm">{item?.address}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Email</p>
                <p className="text-sm">{item?.email}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Contact</p>
                <p className="text-sm">{item?.contact}</p>
              </Stack>
            </div>
          ))}
        </div>

        <div className="w-[14.7rem] pl-12">
          <h2 className="text-base font-semibold mb-2">Customer Information</h2>

          <div className="flex flex-col gap-1">
            <Stack>
              <p className="text-sm font-semibold">Name</p>
              <p className="text-sm">{props?.dataToDisplay?.customer}</p>
            </Stack>
            <Stack>
              <p className="text-sm font-semibold">Phone</p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.cart?.customer?.phone}
              </p>
            </Stack>
            <Stack>
              <p className="text-sm font-semibold">Email</p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.cart?.customer?.email}
              </p>
            </Stack>
          </div>
        </div>
      </div>
      <Divider dashed />

      <div className="text-lg font-semibold mb-1">
        <h2>Products</h2>{" "}
      </div>

      <div className=" w-full  pt-2">
        <Table
          columns={Mycolumns}
          dataSource={[productToDisplay]}
          pagination={false}
        />
      </div>
      <div className="flex flex-col mt-5 mb-2  float-right">
        <div className="flex  justify-between">
          <p className="text-[.9rem] font-light">Subtotal</p>
          <h2 className="text-[.9rem] font-semibold ">
            {`${props?.dataToDisplay?.amount?.toLocaleString()} RWF`}
          </h2>
        </div>
        <div className="flex justify-between">
          <p className="text-[.9rem] font-light">Tax</p>
          <h2 className="text-[.9rem] font-semibold ">0%</h2>
        </div>
        <div className="flex mt-2 justify-between gap-12">
          <p className="text-lg font-semibold">Total Amount</p>
          <h2 className="text-lg font-semibold float-right">
            {`${props?.dataToDisplay?.amount?.toLocaleString()} RWF`}
          </h2>
        </div>
      </div>
      <Divider dashed style={{ marginTop: "130px" }} />
      <Stack spacing={6}>
        <h2 className="text-base">Payment Details</h2>
        <div className=" w-full">
          <Table
            columns={MyPaymentcolumns}
            dataSource={paymentDetailsDisplay}
            pagination={false}
          />
        </div>
      </Stack>
    </div>
  );
};
export default POSTransactionDetails;
