import { Paper, Box, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import DeleteModal from "../Modals/DeleteModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuotToSupplierAction,
  deleteQuotationAction,
} from "../../store/quotation/actions";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isHasCancelled, setIsHasCancelled] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quotation, auth } = useSelector((state: any) => state);

  const deleteModal = (value: any) => {
    if (value?.quotation?.status?.toLowerCase() === "cancelled") {
      setIsHasCancelled(true);
    }
    setItemToDelete(value?._id);
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const deleteRequest = async () => {
    if (isHasCancelled) {
      await deleteQuotToSupplierAction(
        auth?.token,
        itemToDelete,
        {
          isDeleted: true,
        },
        "?limit=15&page=0"
      )(dispatch);
      setItemToDelete("");
      setIsHasCancelled(false);
    } else {
      await deleteQuotationAction(
        auth?.token,
        props?.data?.quotation?._id,
        `?isDraft=true&status=draft`
      )(dispatch);
    }
    setVisible(false);
  };

  const data = {
    name: props?.data?.supplier?.name || "New RFQ",
    status: props.data?.status || props.data?.quotation?.status,
    id: props.data?.quotation?.quotationId,
    createdBy:
      props.data?.createdBy?.names || props.data?.quotation?.createdBy?.names,
    createdAt: props.data?.createdAt || props.data?.quotation?.createdAt,
  };
  return (
    <div className="relative w-[250px] p-1">
      <Paper
        elevation={1}
        sx={{ p: 1 }}
        className="cardhover"
        onClick={() => {
          navigate(
            `/${props?.route === "purchase" ? "purchase" : "inventory"}/rfq/${
              data.id
            }${props?.data?._id ? `/${props?.data?._id}` : ""}`
          );
        }}
      >
        {/* <Checkbox {...label} color="success" /> */}
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack spacing={0}>
              <h1>{data?.name}</h1>
              <p className="text-xs" style={{ color: "blue" }}>
                {data?.id}
              </p>
            </Stack>
            <Tag
              color={searchValue(data.status)}
              style={{ minWidth: "4rem", textAlign: "center" }}
              className="capitalize"
            >
              {data?.status}
            </Tag>
          </Stack>
          {/* <Divider color="warning"/> */}
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0}>
                <p className="text-xs">
                  <b>Done By: </b>
                  {data?.createdBy}
                </p>
                <p className="text-xs">
                  <b>Date: </b>
                  {data?.createdAt.slice(0, 10)}
                </p>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Paper>
      {["draft", "cancelled"].includes(
        props?.data?.quotation?.status?.toLowerCase()
      ) && (
        <div className="absolute bottom-1 right-3">
          <IconButton aria-label="delete" size="small" style={{color:"red"}}>
            <DeleteIcon
              fontSize="inherit"
              onClick={() => {
                // setRowToDelete(record.key);
                deleteModal(props?.data);
              }}
            />
          </IconButton>
          <DeleteModal
            visible={visible}
            onOk={deleteRequest}
            isLoading={quotation?.isFetching}
            onCancel={handleCancel}
            itemName="Stock Item"
          />
        </div>
      )}
    </div>
  );
};

export default Component;
