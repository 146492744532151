import React from "react";
import * as convert from "../../assets/data/productExport";
import ChannelCard from "./ChannelCard";

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportChannelData(
      d.name,
      d.address,
      d.email,
      d.isActive,
      d.contact,
      d.isEnabled,
      d
    )
  );

  return data?.map((d: any) => <ChannelCard data={d} routes={props?.route} />);
};

export default App;
