import {ReactComponent as OverviewIcons} from "../icons/dashboard/sideNav/ri_dashboard-3-line.svg"
import {ReactComponent as ManageIcons} from "../../assets/icons/dashboard/vaults/icon-park-outline_devices.svg"

export const VaultData=[
    {
        id:1,
        title:<h1>OverView</h1>,
        icon:<OverviewIcons/>,
        activeTab:"overview"
    },
    {
    id:2,
        title:<h1>Manage Divices</h1>,
        icon:<ManageIcons />,
        dropDown:[
            {
                menu:"Add devices",
                icon:<ManageIcons/>,
                activeTab:"addDevice"
            },
            {
                menu:"Prepaid Devices",
                activeTab:"prePaidDevices"
            },
            {
                menu:"PostPaid Devices",
                activeTab:"postPaidDevices"
            },
            {
                menu:"Idle Devices",
                activeTab:"idleDevices"
            }
        ]
    }
]