import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createSubscriptionPriceListService = async (
  data: any,
  token: string
) => {
  return await HttpRequest.post(`${SERVER_URL}/plans`, data, token);
};

export const updateSubscriptionPriceListService = async (
  data: any,
  token: string,
  itemId?: string
) => {
  return await HttpRequest.update(`${SERVER_URL}/plans/update`, data, token);
};
