import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Select, Row, Col } from "antd";
import UploadComponent from "./Upload";
import { roles } from "../../utils/roles";
import { useSelector, useDispatch } from "react-redux";
import {
  createUserAction,
  getRoleAction,
  updateUserActions
} from "../../store/setting/actions";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { getAllShopAction } from "../../store/channel/actions";
import { createResetPasswordLinkAction } from "../../store/auth/authActions";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedAgentType, setSelectedAgentType] = useState<string>("");
  const { setting, auth, channel, roleAndPermission } = useSelector(
    (state: any) => state
  );
  const selectStyles = {
    control: {
      border: "none" 
    } 
  };
  const initialData = {
    ...props?.data,
    regions: props.data?.regions?.map((region: any) => {
      return { value: region?._id, label: region?.name };
    })
  };
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    auth?.token &&
      !props?.data &&
      (await createUserAction(auth?.token, { ...values, picture: images[0] })(
        dispatch
      ));
    auth?.token &&
      !props?.data &&
      (await createResetPasswordLinkAction({ ...values })(dispatch));
    auth?.token &&
      props?.data &&
      (await updateUserActions(
        props?.data?._id,
        {
          ...values,
          picture: images[0],
          isNotify: true,
          notification: {
            action: "Updated user profile",
            role: ["dev"],
            message: "Updated user profile",
            title: "Updated user profile"
          }
        },
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };

  useEffect(() => {
    auth?.token && getAllShopAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);

  const handleSelectedRole = (value: string) => {
    setSelectedRole(value);
  };
  const handleSelectedAgentType = (value: string) => {
    setSelectedAgentType(value);
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ width: "100%" }}
      layout="vertical"
      scrollToFirstError
    >
      <div className=" ml-[26rem] mb-2">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          default={
            props?.data && [
              {
                status: "done",
                url: props?.data?.picture
              }
            ]
          }
        />
      </div>
      <Row className="ml-20">
        <Col span={12}>
          <Form.Item
            name="names"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Name
              </span>
            }
            tooltip="Please enter  name of the Employee?"
            rules={[
              {
                required: true,
                message: "Name is required!",
                whitespace: true
              }
            ]}
          >
            <Input className="h-[42px] w-[342px]" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="email"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Email
              </span>
            }
            tooltip="Please enter email of the Employee?"
            rules={[{ required: true, message: "email is required!" }]}
          >
            <Input className="h-[42px] w-[342px]" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Phone
              </span>
            }
            tooltip="Please enter  phone of the Employee?"
            rules={[
              {
                required: true,
                message: "Phone is required!",
                whitespace: true
              }
            ]}
          >
            <Input
              placeholder="+1 (377) 317-1945"
              className="h-[42px] w-[342px]"
            />
          </Form.Item>
        </Col>

        {!props?.data && (
          <Col span={12}>
            <Form.Item
              name="password"
              label="password"
              tooltip="password"
              rules={[
                { required: true, message: "Please input password!" },
                {
                  min: 6,
                  message: "Password should be at least 6 characters long"
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]+$/,
                  message:
                    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                }
              ]}
            >
              <Input.Password
                placeholder="input password"
                className="h-[42px] w-[342px]"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </Col>
        )}

        <Col span={12}>
          <Form.Item
            name="nid"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                NID
              </span>
            }
            tooltip="NID"
            rules={[{ required: true, message: "NID is required!" }]}
          >
            <Input className="h-[42px] w-[342px]" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Address
              </span>
            }
            tooltip="Address"
          >
            <Input className="h-[42px] w-[342px]" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="bio"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Bio
              </span>
            }
            tooltip="Bio"
          >
            <Input className="h-[42px] w-[342px]" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="role"
            label={
              <span className="text-[#030229] pl-1 text-sm font-normal">
                Role
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please select role of the Employee!"
              }
            ]}
          >
            <Select
              dropdownMatchSelectWidth={false}
              style={selectStyles.control}
              showSearch
              placeholder="Select  Role of the Employee!"
              className="capitalize"
              onChange={handleSelectedRole}
            >
              {roles.map((type: any) => (
                <Option value={type.toLowerCase()} className="capitalize ">
                  {type.split("-").join(" ")}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {(["sales-agent"].includes(props?.data?.role) ||
          ["sales-agent"].includes(selectedRole))  && (
          <Col span={12}>
            <Form.Item
              name="tag"
              label={
                <span className="text-[#030229] pl-1 text-sm font-normal ">
                  Company
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please Select Company of Agent"
                }
              ]}
            >
              <Select
                dropdownMatchSelectWidth={false}
                style={selectStyles.control}
                showSearch
                value={selectedAgentType}
                placeholder="Please select agent company"
                className="capitalize"
                onChange={handleSelectedAgentType}
                options={[
                  {
                    value: "MTN",
                    label: "MTN"
                  },
                  {
                    value: "samphone",
                    label: "Samphone"
                  }
                ]}
              />
              {/* {roles.map((type: any) => (
                <Option value={type.toLowerCase()} className="capitalize ">
                  {type.split("-").join(" ")}
                </Option>
              ))}
            </Select> */}
            </Form.Item>
          </Col>
        )}

        {(["sales-supervisor"].includes(props?.data?.role) ||
          ["sales-supervisor"].includes(selectedRole)) && (
          <Col span={12}>
            <Form.Item
              name="regions"
              className="custom-select"
              label={
                <span className="text-[#030229] pl-1 text-sm font-normal">
                  Regions
                </span>
              }
              tooltip="Regions"
              rules={[
                {
                  required: true,
                  message: "Please select regions of the Employee!"
                }
              ]}
            >
              <Select
                mode="multiple"
                // style={{ width: "100%" }}
                className=" capitalize rounded-md h-[52px] w-[100%] custom-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
                placeholder="Please select"
                // onChange={handleChange}
                options={channel?.allShop?.data?.map((shop: any) => {
                  return { value: shop?._id, label: shop?.name };
                })}
              />
            </Form.Item>{" "}
          </Col>
        )}

        <Col span={20}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "75%" }}
              loading={setting?.isFetching}
            >
              {props.data ? "update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default App;
