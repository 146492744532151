import React from "react";
import { Drawer, Table, TableProps } from "antd";

interface DataType {
  key: string;
  name: string;
  no: number;
}

const ViewDetailsDrower: React.FC<{
  isOpen: boolean;
  onClose: any;
  data: any;
}> = ({ isOpen, onClose, data }) => {
  const columnsShops: TableProps<DataType>["columns"] = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Shop",
      dataIndex: "name",
      key: "name",
    },
  ];
  const columnsModel = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Model",
      dataIndex: "name",
      key: "name",
    },
  ];

  const shopData = data?.data?.shop?.map((el: any, index: number) => {
    return {
      key: el?._id,
      name: el?.name,
      no: index + 1,
    };
  });
  const shopModel = data?.data?.productModel?.map((el: any, index: number) => {
    return {
      name: el,
      no: index + 1,
    };
  });

  return (
    <Drawer
      width={720}
      onClose={onClose}
      open={isOpen}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <div className="flex flex-col space-y-6">
        <div className="flex items-center space-x-4 font-bold">
          <h1 className="text-lg">{data?.name}</h1>
          <h1 className="text-sm">{`${data?.percentage}%`}</h1>
        </div>
        {!data?.data?.isAllowedToAll && (
          <div className="pb-6">
            <h1 className="font-semibold text-sm pb-2">Shops</h1>
            <Table
              columns={columnsShops}
              dataSource={shopData}
              pagination={false}
            />
          </div>
        )}

        {data?.data?.productModel?.length > 0 && (
          <div>
            <h1 className="font-semibold text-sm pb-2">Models</h1>
            <Table
              columns={columnsModel}
              dataSource={shopModel}
              pagination={false}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ViewDetailsDrower;
