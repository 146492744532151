import React, { useState } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import SubscriptiondeliveryNoteFilter from "../../../../components/filters/SubscriptionDeliveryNoteFilter";
import { DatePicker, DatePickerProps } from "antd";
import { TbGridDots } from "react-icons/tb";
import { AiOutlineUnorderedList } from "react-icons/ai";
import DeliveryComponent from "./deliveryComponent";

const Delivery: React.FC = () => {
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [activeDeliveryNote, setActiveDeliveryNote] = useState(
    "deliveryNote as grid"
  );

  const handleIconClick = (iconType: string) => {
    setActiveDeliveryNote(iconType);
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  return (
    <div className="p-4 ">
      <div className="text-[#0F0F47] my-4">
        <DetailsHeaderActionBar pageName="Inv entory" title="Product" />
      </div>
      <div className="flex flex-wrap px-1 justify-between">
        <div className="flex flex-row items-center">
          <SubscriptiondeliveryNoteFilter
            setSelectedStatus={setSelectedStatus}
          />
        </div>
        <div className="flex flex-row items-center gap-4">
          <DatePicker onChange={onChange} className={"w-48"} />
          <div className="text-gray-500 flex flex-row px-4">
            <div
              style={{
                color:
                  activeDeliveryNote === "deliveryNote as grid"
                    ? "#3b82f6"
                    : "gray",
                cursor: "pointer",
              }}
            >
              <TbGridDots
                size={30}
                onClick={() => handleIconClick("deliveryNote as grid")}
              />
            </div>
            <AiOutlineUnorderedList
              size={30}
              onClick={() => handleIconClick("deliveryNote as table")}
              className={`cursor-pointer ${
                activeDeliveryNote === "deliveryNote as table"
                  ? "fill-blue-500"
                  : "fill-gray-500"
              }`}
            />
          </div>
        </div>
      </div>
      <DeliveryComponent />
    </div>
  );
};

export default Delivery;
