import React, { useState } from "react";
import { Stack } from "@mui/material";
import MetricsCard from "../../components/cards/purchase/MetricsCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import OverviewPosCard from "../../components/cards/pos/OverviewPosCard";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../store/purchase/actions";
import { getRecentPosOrdersAction } from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { LoadingButton } from "@mui/lab";
import SelectShopPosModal from "../../components/Modals/SelectShopPosModal";

const PageView = (props: any) => {
  const [openShopSelectModal, setOpenShopSelectModal] =
    useState<boolean>(false);
  const { pos, auth,layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleClose = () => setOpenShopSelectModal(false);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      getRecentPosOrdersAction(
        auth?.token,
        `?shop=${
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&status=In-Transit`
      )(dispatch);
  }, [
    auth?.token,
    auth?.user?.shop._id,
    auth?.user?.shop?.assigned,
    auth?.user?.shop?.shopId,
    dispatch,
  ]);

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-screen">
      <Stack spacing={3}>
        <SelectShopPosModal
          handleClose={handleClose}
          showModal={openShopSelectModal}
        />
        <div className="flex justify-between">
          <DatePicker onChange={onChange} className={"w-48"} />
          {auth?.user?.role?.toLowerCase() !== "sales-agent" && (
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={() => setOpenShopSelectModal(true)}
            >
              Change Shop
            </LoadingButton>
          )}
        </div>
        <div className={`grid ${layout?.isSideNavOpen? "sm:grid-cols-1 lg:grid-cols-2":"sm:grid-cols-2 lg:grid-cols-3"} xl:grid-cols-3 2xl:grid-cols-4 gap-4`}>
          {/* Overview card */}
          <MetricsCard
            title={"Total Products Received"}
            number={100}
            icon={<DoneAllIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Sold Products"}
            number={10}
            icon={<NotificationsOutlinedIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Unsold Products"}
            number={200}
            icon={<AutoGraphOutlinedIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Total Sales"}
            number={70}
            icon={<LocalAtmIcon style={{ color: "white" }} />}
          />
        </div>
        <div className="w-full bg-transparent p-3 rounded-lg space-y-4">
          <div className="flex gap-x-10">
            <p className="text-xl -ml-2 font-semibold">Recent Activities</p>
          </div>
          <div className="flex flex-wrap gap-10 ">
            {pos?.recent?.data?.map(
              (item: any, _index: number) =>
                item?.status === "In-Transit" && <OverviewPosCard item={item} />
            )}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
