import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Track",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    query: null,
    trActivities:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    settrActivities(state, action) {
      state.trActivities = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const myTrackActions = mySlice.actions;

export default mySlice.reducer;
