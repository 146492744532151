import { myRepairsActions } from ".";
import {
  getAllRepairsService,
  getOneRepairService,
  getUpdateService,
} from "./services";

export const getAllRepairsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairsActions.setIsFetching(true));
      const res = await getAllRepairsService(token, query);
      if (res?.status === 200) {
        dispatch(myRepairsActions.setAll(res));
        dispatch(myRepairsActions.setIsFetching(false));
      }
      dispatch(myRepairsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneRepairAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairsActions.setIsFetching(true));
      const res = await getOneRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myRepairsActions.setSelected(res?.data));
        dispatch(myRepairsActions.setIsFetching(false));
      }
      dispatch(myRepairsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateRepairStatusAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairsActions.setIsFetching(true));

      const res = await getUpdateService(itemId, data, token);

      if (res?.status === 200) {
        const resAll = await getAllRepairsService(token, "?");
        dispatch(myRepairsActions.setUpdated(res));
        dispatch(myRepairsActions.setAll(resAll));
        dispatch(myRepairsActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
