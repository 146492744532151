import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Col, Divider, Form, Input } from "antd";
import UploadComponent from '../../components/forms/Upload'
import { useSelector, useDispatch } from "react-redux";
import {
  createChannelAction,
  updateChannelAction,
} from "../../store/channel/actions";






const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    auth?.token &&
      !props?.channelId &&
      (await createChannelAction(auth?.token, { ...values, logo: images[0] })(dispatch));
    auth?.token &&
      props?.channelId &&
      (await updateChannelAction(
        props?.channelId,
        {
          ...values,
          logo: images[0],
        },
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}

      scrollToFirstError
    >
      <div className="px-4">
        <center className="mx-auto max-w-md">
          <div className="flex flex-col justify-center items-center">
            <Form.Item>
              <UploadComponent
                className="bg-blue-500"
                setPictures={setPictures}
                limit={1}
                defaultValue={
                  props?.data && props?.data?.logo
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="name"
              tooltip="Please enter  name of the Channel?"
              rules={[
                { required: true, message: "Channel is required!", whitespace: true },
              ]}
            >
              <Input style={{ width: "150%" }} />
            </Form.Item>
          </div>
          <div>
            <Form.List name="contact" {...formItemLayout}>
              {(fields: { [x: string]: any; key: any; name: any; }[], { add, remove }: any) => (
                <div>
                  {fields.map(({ key, name, ...restField }) => (

                    <div className="flex flex-row w-[100%] items-center">
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[{ required: true, message: "Missing names" }]} >
                          <Input placeholder="Names" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, "position"]}
                          rules={[{ required: true, message: "Missing position" }]}
                        >
                          <Input placeholder="Position" />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          {...restField}
                          name={[name, "phone"]}
                          rules={[{ required: true, message: "Missing Contacts" }]}
                        >
                          <Input placeholder="Contacts" />
                        </Form.Item>
                      </div>
                      <div className="mt-[-5px]">
                        <Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Form.Item>
                      </div>
                    </div>
                  ))}
                  <div className="text-left">
                    <Button onClick={() => add()} startIcon={<PlusOutlined />}>
                      Add Contacts
                    </Button>
                  </div>

                </div>
              )}
            </Form.List>
          </div>
          <div className="pt-4 text-left ">
            <LoadingButton
              className="w-full"
              type="submit"
              variant="contained"
              loading={channel?.isFetching}
            >
              {props.data ? "update" : "Save"}
            </LoadingButton>
          </div>
        </center>
      </div>



    </Form>
  );
};

export default App;
