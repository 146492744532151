import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Drawer,
  Row,
  Popconfirm,
  Modal,
  Input,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOneUserAction } from "../../../store/setting/actions";
import { LoadingButton } from "@mui/lab";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { getAllCartsAction } from "../../../store/pos/actions";

const { TextArea } = Input;

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const B2CIssuesDrower: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
}> = ({ open, onClose, data }) => {
  const { auth, setting, wareHouse, pos } = useSelector((state: any) => state);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [replacementReason, setReplacementReason] = useState(null);
  const dispatch = useDispatch();

  const handleOpenReplacementModal = () => {
    setIsReplacementModalOpen(true);
  };

  const handleCancelReplacementModel = () => {
    setIsReplacementModalOpen(false);
  };

  useEffect(() => {
    if (data?.data?.createdBy?._id && auth?.token) {
      getOneUserAction(data?.data?.createdBy?._id, auth?.token)(dispatch);
    }
  }, [auth?.token, data?.data?.createdBy?._id, dispatch]);

  const handleResolved = async () => {
    const res = await updateShopStockCartDetailsAction(auth?.token, data?.key, {
      hasIssue: false,
      issues: {
        isReplacementNeed: false,
      },
      isNotify: true,
      notification: {
        action: `Yellow financing, device's issue Resolved`,
        role: ["admin", "dev", "sales-supervisor", "technician", "sales-agent"],
        message: `Dear ${data?.agent} from ${data?.shop}, The issue regarding the device with the IMEI ${data?.imei} has been resolved.`,
        title: `Yellow financing, device with issues`,
      },
    })(dispatch);
    await getAllCartsAction(
      auth?.token,
      `?hasIssue=true&limit=15&page=0`
    )(dispatch);
    if (res) {
      onClose();
    }
  };

  const handleReplacement = async () => {
    if (replacementReason === null || replacementReason === "") {
      return notification.error({
        message: "PLease specify replacement reason",
      });
    }

    const res = await updateShopStockCartDetailsAction(auth?.token, data?.key, {
      hasIssue: false,
      issues: {
        isReplacementNeed: true,
        comment: replacementReason,
      },
      isNotify: true,
      notification: {
        action: `Yellow financing, device replacement`,
        role: ["admin", "dev", "sales-supervisor", "technician", "sales-agent"],
        message: `Dear ${data?.agent} from ${data?.shop} shop, The device with the IMEI ${data?.imei} needs to be replaced.`,
        title: `Yellow financing, device replacement`,
      },
    })(dispatch);
    handleCancelReplacementModel();
    auth?.token &&
      (await getAllCartsAction(
        auth?.token,
        `?hasIssue=true&limit=15&page=0`
      )(dispatch));
    if (res) {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        width={550}
        placement="right"
        closable={false}
        onClose={() => {
          onClose();
        }}
        open={open}
        style={{ color: "black" }}
      >
        {/* Customer */}
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Customer
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Names" content={data?.customer} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="National ID"
              content={data?.data?.customer?.nationalId}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Telephone"
              content={data?.data?.customer?.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Email"
              content={data?.data?.customer?.email}
            />
          </Col>
        </Row>
        <Divider />

        {/* agent */}
        <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
          Agent & Shop
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Names" content={data?.agent} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Telephone"
              content={setting?.selected?.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Shop" content={data?.shop} />
          </Col>
        </Row>
        <Divider />

        {/* Device */}
        <p
          className="site-description-item-profile-p font-bold text-lg"
          style={{ marginBottom: 10 }}
        >
          Device
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Type"
              content={
                data?.data?.list?.at(0)?.shopStockItem?.requestedItem?.product
                  ?.product?.type
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Brand"
              content={
                data?.data?.list?.at(0)?.shopStockItem?.requestedItem?.product
                  ?.product?.brand
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Model" content={data?.model} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="IMEI" content={data?.imei} />
          </Col>
        </Row>
        <Divider />
        <div className="bg-red-50 p-3">
          <p
            className="site-description-item-profile-p font-bold text-lg"
            style={{ marginBottom: 10 }}
          >
            Issues
          </p>

          <Row>
            <Col span={24}>
              <DescriptionItem title="Comment" content={data?.data?.comment} />
            </Col>
          </Row>
        </div>

        <Divider />

        <div className="space-x-4 absolute-">
          <Popconfirm
            title="Confirmation"
            description="Are you sure, Device's issues has been resolved?"
            onConfirm={handleResolved}
            okText="Yes"
            okButtonProps={{
              className: "bg-blue-400",
            }}
            cancelText="No"
          >
            <LoadingButton
              loading={wareHouse?.isFetching || pos?.isFetching}
              variant="contained"
              style={{ backgroundColor: "green" }}
            >
              Resolved
            </LoadingButton>
          </Popconfirm>
          <LoadingButton
            loading={wareHouse?.isFetching || pos?.isFetching}
            variant="contained"
            style={{ backgroundColor: "orangered" }}
            onClick={handleOpenReplacementModal}
          >
            Replacement
          </LoadingButton>
        </div>
      </Drawer>

      <Modal centered open={isReplacementModalOpen} footer={null}>
        <div>
          <h1 className="text-lg font-semibold pb-4">
            Are you sure the device needs to be replaced?
          </h1>
          <TextArea
            rows={4}
            placeholder="Reason...."
            onChange={(e: any) => setReplacementReason(e.target.value)}
          />
          <div className="space-x-2 pt-2">
            <LoadingButton
              loading={wareHouse?.isFetching || pos?.isFetching}
              variant="outlined"
              onClick={handleCancelReplacementModel}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              loading={wareHouse?.isFetching || pos?.isFetching}
              variant="contained"
              onClick={handleReplacement}
            >
              Confirm
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default B2CIssuesDrower;
