import React, { useEffect, useState } from "react";
import DevicesSummary from "./devicesSummary";
import { ReactComponent as VectorIcon } from "../../../../assets/icons/dashboard/vaults/Vector.svg";
import { Space, Table, Tag, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import SearchInput from "../../../../components/buttons/SearchButton";
import * as piIcons from "react-icons/pi";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDevicesAction,
  getDevicesStatusAction,
  getSearchDevicesAction,
} from "../../../../store/vault/actions";
import DeviceDetails from "./deviceDetails";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
interface DataType {
  key: string;
  imei: string;
  brand: string;
  model: string;
  lastChange: string;
  lastCheckIn: string;
  status: any;
}
const IdleDevices = () => {
  const { auth, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [viewDetail, setViewDetails] = useState(false);
  const [searchKey, setSearchKey] = useState();
  const [stateName, setStateName] = useState("Idle");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token &&
      getAllDevicesAction(
        auth?.token,
        `?pageSize=${limit}&pageNum=${page}&stateName=${stateName}`
      )(dispatch);
  }, [auth?.token, limit, page, dispatch]);

  useEffect(() => {
    auth?.token &&
      getDevicesStatusAction(
        auth?.token,
        `?stateName=ALL&serviceDetails=INVENTORY`
      )(dispatch);
  }, [auth?.token, dispatch]);

  console.log(vault?.statusCount?.Idlet, "fffffffffffffffffffff");

  const styles = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
  };
  // const IdleDeviceData = vault?.all?.data?.map((el: any, index: number) => {
  //   if (el?.enableVaultResponse?.serviceDetails?.toUpperCase() === "IDLE") {
  //     return {
  //       key: index,
  //       imei: el?.enableVaultResponse?.deviceUniqueID,
  //       brand: el?.enableVaultResponse?.tenantName,
  //       model: el?.enableVaultResponse?.deviceModel,
  //       lastChange: el?.enableVaultResponse?.lastChanged?.slice(0, 10),
  //       lastCheckIn: el?.enableVaultResponse?.lastCheckIn?.slice(0, 10),
  //       status: el?.enableVaultResponse?.stateInfo,
  //       data: el,
  //     };
  //   }
  //   return null;
  // });
  // const filteredIdleDeviceData = IdleDeviceData.filter(
  //   (item: any) => item !== null
  // );

  const IdleDeviceData = vault?.all?.data?.map((el: any, index: number) => {
    return {
      key: index,
      imei: el?.deviceUniqueID,
      brand: el?.tenantName,
      model: el?.deviceModel,
      lastChange: el?.lastChanged?.slice(0, 10),
      lastCheckIn: el?.lastCheckIn?.slice(0, 10),
      status: el?.stateInfo,
      data: el,
    };
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "BRAND",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "MODEL",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            className={`py-1 w-24 text-center rounded-full ${
              status === "active"
                ? "text-[#0FA91E] border border-[#0FA91E80]"
                : status === "locked"
                ? "text-[#FA3659] border border-[#FA365980]"
                : status === "released"
                ? "text-[#2943D6] border border-[#2943d622]"
                : status === "ready for use"
                ? "text-[#F19F1D] border border-[#2943d622]"
                : "text-[#2943D6] border border-[#2943d622]"
            } `}
          >
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "LAST CHANGE",
      dataIndex: "lastChange",
      key: "lastChange",
    },
    {
      title: "LAST CHECK-IN",
      dataIndex: "lastCheckIn",
      key: "lastCheckIn",
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => (
        <Space>
          <piIcons.PiEyeLight
            size={20}
            className="cursor-pointer"
            onClick={() => setViewDetails(true)}
          />
        </Space>
      ),
    },
  ];

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getSearchDevicesAction(
        auth?.token,
        `?serviceDetails=INVENTORY&serialNumber=${searchKey}`
      )(dispatch);
    }
  };

  return (
    <>
      {!viewDetail ? (
        <div>
          <DetailsHeaderActionBar
            // handleClickDelete={props?.handleClickDelete}
            // handleClickEdit={props?.handleClickEdit}
            // goBack={goBack}
            pageName={"Manage devices"}
            // title={layout?.userInfo?.names}
            title="Idle Devices"
          />
          <div className="w-full h-[119px] bg-white" style={styles}>
            <div className="flex gap-5 divide-x pt-3">
              <DevicesSummary
                status={"Idle"}
                value={vault?.all?.data?.length}
                customeIcon={<VectorIcon />}
                border={"[#2943d622]"}
              />
            </div>
            <div className="flex justify-between my-8">
              {/* <Select
                defaultValue="Filter By All"
                style={{ width: 300 }}
                onChange={handleChange}
                options={[
                  { value: "all", label: "Filter By All" },
                  { value: "Ready", label: "Ready for use" },
                  { value: "active", label: "Active" },
                  { value: "locked", label: "Locked" },
                  { value: "Realeased", label: "Released" },
                ]}
              /> */}
              <SearchInput
                onSearch={handleSearch}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSearchKey(e.target.value);
                }}
              />
            </div>
            <div>
              <ScrollableFrame
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                total={vault?.all?.total}
                count={Math.ceil(vault?.all?.total / limit)}
              >
                <Table
                  columns={columns}
                  loading={vault?.isFetching}
                  dataSource={IdleDeviceData}
                />
              </ScrollableFrame>
            </div>
          </div>
        </div>
      ) : (
        <DeviceDetails
          viewDetail={viewDetail}
          setViewDetails={setViewDetails}
        />
      )}
    </>
  );
};

export default IdleDevices;
