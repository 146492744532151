import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, notification, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateSubsDeliveryNoteAction,
  getOnesubDeliveryNoteAction,
} from "../../store/subscription/deliverynote/actions";
import { getOneSubsOrderAction } from "../../store/subscription/order/actions";

const AddDelivererForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { auth, orders, deliverynote } = useSelector((state: any) => state);
  const deliveryNoteId = orders?.selected?.account?.deliveryNote?._id;

  const onFinish = async (values: any) => {
    if (auth?.token) {
      const res = await updateSubsDeliveryNoteAction(
        deliveryNoteId as string,
        { deliverer: { ...values } },
        auth.token
      )(dispatch);
      if (res) {
        props?.onCancel();
        form.resetFields();
        notification.success({ message: "Deliverer added successfully" });
        await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
        await getOnesubDeliveryNoteAction(
          auth?.token,
          deliveryNoteId as string
        )(dispatch);
      }
    }
  };

  return (
    <>
      <div className="w-4/5 ml-10">
        <h1 className="text-center text-xl pb-4">Deliverer </h1>
        <Form
          onFinish={onFinish}
          form={form}
          className="w-96"
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Names"
            rules={[
              {
                required: true,
                message: "Names are required!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: "Phone is required!",
                whitespace: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="company"
            label="Company"
            rules={[
              {
                required: true,
                message: "Company name is required!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email is required!",
                whitespace: true,
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "60%" }}
            loading={orders?.isFetching || deliverynote?.isFetching}
          >
            Save
          </LoadingButton>
        </Form>
      </div>
    </>
  );
};

export default AddDelivererForm;
