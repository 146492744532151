import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { deactivateAgentToShopAction } from "../../store/setting/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DeactivateAgentForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, setting } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    await deactivateAgentToShopAction(
      props?.data?._id as string,
      "",
      auth?.token
    )(dispatch);
    form.resetFields();
    props?.onCancel();
    navigate(-1);
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          style={{ minWidth: 900 }}
          scrollToFirstError
        >
          <div className="">
            <Row style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="font-bold text-xl text-gray-900 pl-6">
                {`Deactivate Agent from the ${props?.data?.shop?.name}`}
              </h1>
              <p className="pl-16 py-4">{`Are you sure to Remove this Sales Agent form ${props?.data?.shop?.name}?`}</p>
              <Col
                span={24}
                className="w-2/5 flex items-center justify-between mx-32"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 200 }}
                  loading={setting.isFetching}
                >
                  Submit
                </LoadingButton>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DeactivateAgentForm;
