import React, { useEffect, useState } from "react";
import { Avatar, Card, List, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import TwoIconsCard from "../../../apps/EBM/components/cards/TwoIconsCard";
import { FaArrowTrendUp } from "react-icons/fa6";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import makerIcons from "./makericon.png";
import FrameIcon1 from "./assets/Frame 27.png";
import TrackingForm from "./map/TrackingForm";
import { MapSection } from "./map/MapSection";
import { useDispatch, useSelector } from "react-redux";
import { getAllTrackActivitiesAction } from "../../../store/delivery/actions";

interface Stat {
  label: string;
  value: number;
  change: string;
  text: string;
  icon: JSX.Element;
  color: string;
}

const stats: Stat[] = [
  {
    label: "In delivery",
    value: 360,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "delivered",
    value: 30,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-[#605BFF] bg-violet-300 text-xs ",
  },
  {
    label: "In transit",
    value: 112,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Ready to pick up",
    value: 60,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs",
  },
  {
    label: "Late delivery",
    value: 100,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Missed delivery",
    value: 2,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Late pick up",
    value: 72,
    change: "+",
    text: "2 new employees added!",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Return ready to pick up",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Total returns",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Returns delivered",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "pick up to MTN Store",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "ready to pick up at MTN warehouse",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Refused by client",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
  {
    label: "Refused by operator",
    value: 30,
    change: "+",
    text: "+3% Increase than yesterday",
    icon: <UserOutlined />,
    color: "text-green-500 bg-[#97CE71] text-xs ",
  },
];

const DeliverypageView: React.FC = () => {
  const { auth, tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [trackingId, setTrackingId] = useState<any>();

const [hasValidLocations, setHasValidLocations] = useState(false);

const handleTrackChange = (id: string, isValid: boolean) => {
  setTrackingId(id);
  setHasValidLocations(isValid);
};

  useEffect(() => {
    if (auth.token) {
      getAllTrackActivitiesAction(auth.token)(dispatch);
    }
  }, [auth.token, dispatch]);


  const data = tracking?.trActivities?.data;



  return (
    <div className="p-4">
      <div className="grid grid-cols-6 gap-5 mb-6">
        {stats.map((stat, index) => (
          <TwoIconsCard
            amount={`${stat.value}`}
            title={`${stat.label}`}
            name={`${stat.text}`}
            icon={
              <FaArrowTrendUp
                color="green"
                style={{
                  backgroundColor: "#97CE71",
                  borderRadius: "20px",
                  fontSize: "15px",
                  padding: "2",
                }}
              />
            }
            key={index}
          />
        ))}
      </div>

      <div className="grid grid-cols-2 gap-5">
        <Card className="h-[480px] overflow-y-auto">
          <h1 className="text-xl font-semibold mb-4">Activities</h1>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={` ${FrameIcon1 || item.picture}`} />}
                  title={<a href="https://ant.design">{item?.name}</a>}
                  description={item?.details}
                />
                <div className="felx flex-col align-center">
                  <Tag color="blue">{item?.status}</Tag>
                  <div>{item?.createdAt.slice(0, 10)}</div>
                </div>   
              </List.Item>
            )}
          />
        </Card>

        <div className="relative">
          <div className="w-[100%] flex flex-row items-center justify-between text-black">
            <div className="absolute top-0 right-0 text-red-500 w-[50%] z-10">
              <TrackingForm onTrackChange={handleTrackChange} />
            </div>
          </div>
          <MapSection trackingId={trackingId} hasValidLocations={hasValidLocations} />

        </div>
      </div>
    </div>
  );
};

export default DeliverypageView;
