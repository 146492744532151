import React from "react";
import Profile from "../../../../assets/images/profile.jpeg";
import User from "../../../../assets/images/accountsimg/user.png";

const images = [Profile, User];

const BillingStatus = (image: any) => {
  return (
    <div className="w-[35%] bg-[#fff] p-7 h-fit pb-10 container mx-auto">
      <div className="text-[#0F0F47] font-bold text-2xl mb-4">
        Billing Status
      </div>
      <div className="flex gap-8">
        <div className="flex -space-x-1 overflow-hidden">
          {images.map((image: any, index: any) => (
            <img
              key={index}
              className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
              src={image}
              alt={`Participant ${index + 1}`}
            />
          ))}
        </div>
        <div className="text-[#0F0F47] text-sm">
          {images.length} Participants
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          Paid
          <div className="w-full bg-green-100 rounded-full">
            <div
              className="bg-[#20DD8E] text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: "50%" }}
            >
              {" "}
              50%
            </div>
          </div>
        </div>
        <div>
          Past Due
          <div className="w-full bg-yellow-100 rounded-full">
            <div
              className="bg-[#F5C451] text-xs font-medium text-yellow-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: "90%" }}
            >
              {" "}
              90%
            </div>
          </div>
        </div>
        <div>
          Late
          <div className="w-full bg-red-200 rounded-full">
            <div
              className="bg-[#F1595C] text-xs font-medium text-red-200 text-center p-0.5 leading-none rounded-full"
              style={{ width: "25%" }}
            >
              {" "}
              25%
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-2xl text-[#0F0F47] font-normal mt-10">
        Upcoming Billing Date
      </div>
      <div className="text-center text-lg text-[#0F0F47] font-bold mt-4">
        12 September 2023
      </div>
      <div className=" text-2xl text-[#0F0F47] font-bold mt-10">
        Active payment request
      </div>
      <div className=" text-base text-[#0F0F47] font-semibold mt-5 mb-5">
        You requested RWF &nbsp;45,000 For Monthly dues
      </div>
      <div className="flex gap-8">
        <div className="flex -space-x-1 overflow-hidden">
          {images.map((image: any, index: any) => (
            <img
              key={index}
              className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
              src={image}
              alt={`Participant ${index + 1}`}
            />
          ))}
        </div>
        <div className="text-[#0F0F47] text-sm">
          {images.length} Participants . optional . Sept 12,2023
        </div>
      </div>
      <div className="w-full bg-green-100 rounded-full mt-6">
        <div
          className="bg-[#20DD8E] text-xs font-medium text-green-100 text-center p-0.5 leading-none rounded-full"
          style={{ width: "50%" }}
        >
          {" "}
          50%
        </div>
      </div>
      <div className="flex justify-between text-[#0F0F47] mt-6 mb-6">
        <div className="text-base font-semi-bold">RWF32,000 (75%)</div>
        <div>Goal: RWF45,000</div>
      </div>
    </div>
  );
};
export default BillingStatus;
