import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Spin } from "antd";
import React, { useState } from "react";
import UploadComponent from '../../components/forms/Upload'

import {
  getIntegrationAction,
  getOneUserAction,
  updateUserActions,
} from "../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import { createIntegrationAction } from "../../store/setting/integrations/actions";
const CreateIntegrationsForm = (props:any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  console.log("hereeeeee",props)
  const onFinish = async (values: any) => {
    // console.log(values, "--------------------------------");
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    auth?.token &&
      (await createIntegrationAction(auth?.token, { ...values,logo: images[0],api:[{url:values.url}]})(
        dispatch
      ));
      await getIntegrationAction(auth?.token,"?")(dispatch);
    form.resetFields();
    props?.onCancel();
  };
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%" }}
        onFinish={onFinish}
      >
        <div className="flex flex-col justify-center items-center mt-5">
        <Form.Item>
              <UploadComponent
                className="bg-blue-500"
                setPictures={setPictures}
                limit={1}
                defaultValue={
                  props?.data && props?.data?.logo
                }
              />
            </Form.Item>
            
          
        </div>
        <Form.Item
          name="name"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Name
            </span>
          }
          className="w-full"
        >
          <Input className="pl-4 h-[42px] w-[100%] text-base" />
        </Form.Item>
        <Form.Item
          name="url"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              url link
            </span>
          }
          className="w-full"
        >
          <Input
            placeholder="Please enter your old PIN"
            className="pl-4 h-[42px] w-[100%] "
          />
        </Form.Item>
        <Form.Item
      name="description"
      label={
        <span className="text-[#030229] pl-1 text-sm font-normal">
          Descriptions
        </span>
      }
      className="w-full"
      rules={[{ required: true, message: 'Please input new PIN!' }]}
      // initialValue={""}
    >
      <Input.TextArea className="pl-4 h-[62px] w-[100%] "/>
      
    </Form.Item>
        <div className="ml-24">
          <button
            type="submit"
           
            className={` border w-[402px] bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
          >
            {setting.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    color="white"
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
export default CreateIntegrationsForm;
