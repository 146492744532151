import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Form, Input, Select } from "antd";
import { createSupplierAction } from "../../store/supplier/actions";

import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { supplier, auth } = useSelector((state: any) => state);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    auth?.token && createSupplierAction(auth?.token, values)(dispatch);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Supplier Name"
        tooltip="Please enter  name of the Supplier?"
        rules={[
          { required: true, message: "Name is required!", whitespace: true },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Supplier Phone"
        tooltip="Please enter  phone of the Supplier?"
        rules={[
          { required: true, message: "Phone is required!", whitespace: true },
        ]}
      >
        <Input placeholder="+1 (377) 317-1945" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Order email"
        tooltip="Please enter  order email of the Supplier?"
        rules={[{ required: true, message: "email is required!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="tin"
        label="Supplier TIN"
        tooltip="Please enter  TIN of the Supplier?"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="address"
        label="Supplier Address / Location"
        tooltip="Where our supplier is located"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="type"
        label="Supplier Type"
        rules={[
          { required: true, message: "Please select type of the Supplier!" },
        ]}
      >
        <Select showSearch placeholder="Select  type of the Supplier!">
          {["International", "Local"].map((type: any) => (
            <Option value={type.toLowerCase()}>{type}</Option>
          ))}
        </Select>
      </Form.Item>

      <Divider orientation="left">Add Employee</Divider>

      <Form.List name="employees" {...formItemLayout}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Stack>
                <Stack direction={"row"}>
                  <Form.Item
                    {...restField}
                    name={[name, "names"]}
                    rules={[{ required: true, message: "Missing names" }]}
                    style={{ minWidth: 250 }}
                  >
                    <Input placeholder="Names" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "position"]}
                    rules={[{ required: true, message: "Missing position" }]}
                  >
                    <Input placeholder="Position" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "contact"]}
                    rules={[{ required: true, message: "Missing Contacts" }]}
                  >
                    <Input placeholder="Contacts" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Stack>
              </Stack>
            ))}
            <Form.Item>
              <Button onClick={() => add()} startIcon={<PlusOutlined />}>
                Add Employee
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "100%" }}
          loading={supplier?.isFetching}
        >
          Save
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;
