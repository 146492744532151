import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
interface drawerProps{
    open:any,
    onClose:any,
    data:any
}
const ActivityLogsDrawer= ({open,data,onClose}:drawerProps) => {
    console.log(">>>",data?.data?.useInfo)
    const customCloseIcon = (
        <div style={{ position: 'absolute', top: 10, right: 15, padding: '10px' }}>
          <CloseOutlined style={{ fontSize: '20px' }} onClick={onClose} />
        </div>
      );
  return (
    <>
 
      <Drawer title=<h1 className="text-[#0f0f47e1] capitalize pb-3 font-medium pl-3 text-lg"> Activity record for <span className="text-[#0f0f47a5] ">{(data?.data?.useInfo?.userName)? data?.data?.useInfo?.userName:"User"}</span></h1>  closeIcon={customCloseIcon}  width={700} onClose={onClose} open={open}>
      
          <div
            className={` mb-3 px-6 py-2 w-full h-full rounded-md`}
          >
            <div className="w-[90%] divide-y-2 ">
              <div className=" mb-2 font- normal py-2">
              <p className="text-[#0f0f47d6] pb-3 font-medium text-base">Date</p>
               
                <p className="text-[#0f0f475e]  text-[12px] pt-2">
                 <span>{data?.data?.createdAt?.slice(0,10)}</span> <span className="pl-10">{data?.data.createdAt?.slice(11,20)}</span> 
                </p>
              </div>
              <div className=" mt-4 pt-5">
              <p className="text-[#0f0f47d6] pb-3 font-medium text-base">Activity</p>
              <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                  {data?.data?.resMessage}
                </p>
                <p className="text-[#0f0f47] text-[12px] pt-2">
               Status: <span className="text-[#0F0F47B2] pt-1"> {data?.data?.resStatus}</span>
                </p>
              </div>
              <div className=" mt-4 pt-5">
                <p className="text-[#0f0f47d6] pb-3 font-medium text-base">Details & Description</p>
                <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                 BaseUrl: <span className="text-[#0F0F47B2] pt-1">{data?.data?.actionDetails?.Url}</span>
                </p>
                <p className="text-[#0f0f47] text-[12px] pt-2">
               Status: <span className="text-[#0F0F47B2] pt-1"> {data?.data?.resStatus}</span>
                </p>
              </div>
            </div>
          </div>
      </Drawer>
    </>
  );
};

export default ActivityLogsDrawer;