import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "overViewLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    allOrderHistory: { data: [] },
    allPaymentHistory: { data: [] },
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAllOrderHistory(state, action) {
      state.allOrderHistory = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllPaymentHistory(state, action) {
      state.allPaymentHistory = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
