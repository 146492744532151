import React, { useState, useEffect } from 'react';
import { Button } from 'antd';

const Timer = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = time.toLocaleTimeString();
  const formattedDate = time.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <div>
      
      <div >
       <p className="text-2xl font-light text-[#030229d7] font-poppins"> {formattedDate}</p>
      
          <p className="text-2xl font-light text-[#030229d7] font-poppins pt-2">{formattedTime}</p>  
      </div>
    </div>
  );
};

export default Timer;