import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { handlerSelectedAccountTypeAction } from "../../../../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";

export default function DisabledTabs(props: any) {
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handlerSelectedAccountTypeAction(newValue)(dispatch);
  };
  React.useEffect(() => {
    if (layout?.selectedAccountType) {
      props?.setSelectedType(
        layout?.selectedAccountType === 0
          ? ""
          : layout?.selectedAccountType === 1
          ? "b2b"
          : "b2c"
      );
    }
  }, [layout?.selectedAccountType]);

  return (
    <Tabs
      value={layout?.selectedAccountType}
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      <Tab label="All Accounts" />
      <Tab label="B2B Accounts" />
      <Tab label="B2C Accounts" />
    </Tabs>
  );
}
