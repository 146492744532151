import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllUnReadNotificationService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/notifications${query}`, token);
};

export const getAllNotificationService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/notifications/read?limit=15`, token);
};

export const updateNotificationService = async (data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/notifications`, data, token);
};
