import React, { useEffect } from "react";
import Subscription from "../../../../assets/images/subscription/sub.png";
import Active from "../../../../assets/images/subscription/active.png";
import Completed from "../../../../assets/images/subscription/completed.png";
import Pending from "../../../../assets/images/subscription/pending.png";
import Inactive from "../../../../assets/images/subscription/inactive.png";
import { getOrderStatusAction } from "../../../../store/subscription/order/actions";
import { useDispatch, useSelector } from "react-redux";
import Cards from "./Cards";
import { useMediaQuery } from "@mui/material";

const TopCards = (props: any) => {
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const { auth, orders } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getOrderStatusAction(auth?.token)(dispatch);
  }, [auth.token, dispatch]);

  return (
    <div
      // className={`grid ${
      //   isTablet ? "grid-cols-3 " : "grid-cols-5"
      // } justify-between gap-2 text-black`}
      className={`flex flex-wrap gap-x-4 text-black`}
    >
      <Cards
        cardIcon={Subscription}
        value={orders?.orderStatus?.data?.allAccounts}
        status="Total Accounts"
      />
      <Cards
        cardIcon={Active}
        value={orders?.orderStatus?.data?.activeAccounts}
        status="Active Accounts"
      />
      <Cards
        cardIcon={Inactive}
        value={orders?.orderStatus?.data?.inactiveAccounts}
        status="Inactive Accounts"
      />
      <Cards
        cardIcon={Pending}
        value={orders?.orderStatus?.data?.draftOrder}
        status="Draft Orders"
      />

      <Cards
        cardIcon={Completed}
        value={orders?.orderStatus?.data?.completedOrder}
        status="completed orders"
      />
    </div>
  );
};
export default TopCards;
