import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllConversationsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(`${SERVER_URL}/chat${query || ""}`, token);
};

export const getSingleConversationService = async (
  token: string,
  id: string
) => {
  return HttpRequest.get(`${SERVER_URL}/chat/one/${id}`, token);
};

export const sendMessageService = async (
  token: string,
  id: string,
  body: any
) => {
  return HttpRequest.update(`${SERVER_URL}/chat/one/${id}`, body, token);
};

export const startConversationService = async (token: string, body: Object) => {
  return HttpRequest.post(`${SERVER_URL}/chat`, body, token);
};
