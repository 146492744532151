export const regions = [
    "east-1",
              "east-2",
              "west-1",
              "west-2",
              "north",
              "north-east ",
              "north-west",
              "south-1",
              "south-2",
              "kigali (KN)",
              "kigali (KG)",
              "kigali (KK)",
]
export {};