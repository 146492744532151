import React from "react";
import { Table, Space, Tooltip } from "antd";
import { IconButton, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import type { ColumnsType, TableProps } from "antd/es/table";
import * as convert from "../../assets/data/productExport";
import SupplierCard from "./SupplierGridCard";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Type",
    dataIndex: "type",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Name",
    dataIndex: "name",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value: string, record:any) => record.name.indexOf(value) === 0,

    // sorter: (a, b) => a.model.length - b.model.length,
    // sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
    // sortDirections: ["descend"],
  },
  {
    title: "Email",
    dataIndex: "email",
    defaultSortOrder: "descend",
    // sorter: (a, b) => a.age - b.age,
  },
  {
    title: "Tin",
    dataIndex: "tin",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Status",
    dataIndex: "status",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <IconButton aria-label="delete" color="success">
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton aria-label="delete" color="secondary">
          <BorderColorIcon />
        </IconButton>
        <Tooltip placement="bottomLeft" title={"Delete"}>
          <IconButton aria-label="delete" color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Space>
    ),
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportSupplierData(
      d.type,
      d.name,
      d.phone,
      d.email,
      d.tin,
      d.isActive,
      d.employees,
      d
    )
  );

  return data.map((d: any) => <SupplierCard data={d} />);
};

export default App;
