import React from "react";
import * as piIcons from "react-icons/pi";
import * as ciIcons from "react-icons/ci";
import * as bsIcons from "react-icons/bs";
import * as mdIcons from "react-icons/md";
import Theme from "./theme";
import gray from "../../../../assets/images/setting/Columns.png";
import nealGray from "../../../../assets/images/setting/Wireframe (1).png";
import black from "../../../../assets/images/setting/Wireframe.png";
import topDark from "../../../../assets/images/setting/Column.png";
import sideDark from "../../../../assets/images/setting/Wiref.png";
import sideDarks from "../../../../assets/images/setting/Wirefram.png";
import { useDispatch, useSelector } from "react-redux";
import { activeSelectedTheme } from "../../../../store/layout/actions";
import { getPreferenceAction, updateThemeAction } from "../../../../store/setting/actions";
const MoreTheme=()=>{
    const {layout,auth,setting}=useSelector((state:any)=>state)
    const dispatch=useDispatch();
    const themeId=setting?.preference?.data?.themeId?._id;
    const handleThemeClick=async(ThemeName:any)=>{
      activeSelectedTheme(ThemeName)(dispatch)
      auth?.token && await updateThemeAction(auth.token, themeId, {name:ThemeName})(dispatch);
     await  getPreferenceAction(auth?.token)(dispatch)
    }
    return(
        <>
        <div className="flex gap-3 mt-5 ">
            <Theme
              name={"Vertical"}
              icon={mdIcons.MdVerticalDistribute}
              iconSize={18}
              iconColor="[#605BFF]"
              themeImage={gray}
              isSelected={layout?.selectedTheme==="vertical"}
              onClick={()=>handleThemeClick("vertical")}
            />
            <Theme
              name={"Horizontal"}
              icon={mdIcons.MdHorizontalSplit}
              iconColor="[#605BFF]"
              themeImage={nealGray}
              isSelected={layout?.selectedTheme==="horizontal"}
              onClick={()=>handleThemeClick("horizontal")}
            />
            <Theme
              name={"Two Column"}
              icon={ciIcons.CiViewColumn}
              iconColor="[#605BFF]"
              themeImage={black}
              isSelected={layout?.selectedTheme==="column"}
              onClick={()=>handleThemeClick("column")}
            />
          </div>
          <div className="mt-5">
            <h1 className="text-[#030229] text-base font-medium">Topbar color</h1>
            <p className="text-[#03022980] text-sm font-normal pt-3">Choose light or dark Color</p>
            <div className="flex gap-3 mt-5">
            <Theme
              name={"Light"}
              icon={ciIcons.CiLight}
              iconColor="[#605BFF]"
              themeImage={nealGray}
              isSelected={layout?.selectedTheme==="topLight"}
              onClick={()=>handleThemeClick("topLight")}
            />
            <Theme
              name={"Dark"}
              icon={ciIcons.CiDark}
              iconColor="[#605BFF]"
              themeImage={topDark}
              isSelected={layout?.selectedTheme==="topDark"}
              onClick={()=>handleThemeClick("topDark")}
            />
            </div>
          </div>
          <div className="mt-5">
            <h1 className="text-[#030229] text-base font-medium">SideBar Color color</h1>
            <p className="text-[#03022980] text-sm font-normal pt-3">Choose a Color for SideBar</p>
            <div className="flex gap-3 mt-5">
            <Theme
              name={"Light"}
              icon={ciIcons.CiLight}
              iconColor="[#605BFF]"
              themeImage={gray}
              isSelected={layout?.selectedTheme==="sideLight"}
              onClick={()=>handleThemeClick("sideLight")}
            />
            <Theme
              name={"Dark"}
              icon={ciIcons.CiDark}
              iconColor="[#605BFF]"
              themeImage={sideDark}
              isSelected={layout?.selectedTheme==="sideDark"}
              onClick={()=>handleThemeClick("sideDark")}
            />
            <Theme
              name={"Gradient"}
              icon={mdIcons.MdGradient}
              iconColor="[#605BFF]"
              themeImage={sideDarks}
              isSelected={layout?.selectedTheme==="sideGradient"}
              onClick={()=>handleThemeClick("sideGradient")}
            />
            </div>
          </div>
        </>
    )
}
export default MoreTheme;