import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import store from "store";
import { getUser } from "../utils/converter";
import { useSelector } from "react-redux";

export const hrDropDownRoutes = [
  {
    caption: "Overview",
    path: "/overview",
    onClick: () => {
      console.log("Overview:00");
    },
  },
  {
    caption: "Department",
    path: "department",
    onClick: () => {
      console.log("sale:00");
    },
  },
  {
    caption: "Attendance",
    path: "/attendance",
    onClick: () => {
      console.log("sale:00");
    },
  },
  {
    caption: "Payroll",
    path: "/payroll",
    onClick: () => {
      console.log("sale:00");
    },
  },
  {
    caption: "Careers",
    path: "/careers",
    onClick: () => {
      console.log("sale:00");
    },
  }
];

const DashRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  let authent = { token: true };
  const user = getUser();

  const allowedRoles = [
    "admin",
    "dev",
    "hr"
  ];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role || user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/repairs/overview",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Repairs",
      path: "/repairs",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Repairs".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={5}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DashRoutes;
