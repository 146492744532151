import { notification } from "antd";
import { chatActions } from ".";
import {
  getAllConversationsService,
  getSingleConversationService,
  sendMessageService,
  startConversationService,
} from "./services";
import { useSelector } from "react-redux";

export const getAllConversations = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      // dispatch(chatActions.setIsFetching(true));
      const res = await getAllConversationsService(token, query);
      if (res?.status === 200) {
        dispatch(chatActions.setConversations(res?.data));
        dispatch(chatActions.setIsFetching(false));
      }
      // dispatch(chatActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getSingleConversation = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(chatActions.setIsFetching(true));
      const res = await getSingleConversationService(token, id);
      if (res?.status == 200) {
        dispatch(chatActions.setSingleConversation(res?.data));
        dispatch(chatActions.setIsFetching(false));
      }
      dispatch(chatActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const sendMessage = (token: string, id: string, body: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(chatActions.setIsFetching(true));
      const res = await sendMessageService(token, id, {
        ...body,
        isNotify: true,
        notification: {
          action: `New message from ${body?.sender}`,
          message: "You have a new message in chat",
          title: "New message from " + body?.sender,
          receiverIds: [body?.receiverId],
        },
      });
      if (res?.status == 200) {
        dispatch(chatActions.setSingleConversation(res?.data));
        dispatch(chatActions.setIsFetching(false));
      }
      // dispatch(chatActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const startConversation = (token: string, body: Object) => {
  return async (dispatch: any) => {
    try {
      dispatch(chatActions.setIsFetching(true));
      const newChat = await startConversationService(token, body);
      if (newChat?.status == 201) {
        const res = await getSingleConversationService(token, newChat.data._id);
        if (res?.status == 200) {
          dispatch(chatActions.setSingleConversation(res?.data));
          dispatch(chatActions.setIsFetching(false));
        }
        dispatch(chatActions.setIsFetching(false));
      } else {
        if (newChat?.status == 409) {
          return notification.error({
            message: "Error",
            description: newChat?.error,
          });
        }

        notification.error({
          message: "Error",
          description: "Creating conversation failed",
        });
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
