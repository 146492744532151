import React from "react";
import { CiEdit } from "react-icons/ci";
import { Typography } from "@mui/material";

const UpdateAction = (props: any) => {
  return (
    <div style={{ display: "flex", gap: 6 }} onClick={() => props?.onUpdate()}>
      <CiEdit size={20} color="#2943D6" spacing={5} />
      <Typography sx={{ fontSize: 14 }}>Edit</Typography>
    </div>
  );
};
export default UpdateAction;
