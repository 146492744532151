import { Space, Table, Tag, Select } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import * as piIcons from "react-icons/pi";
import { flattenBy } from "@tanstack/react-table";
const PrepaidDeviceTable = (props: any) => {
  const { auth, vault } = useSelector((state: any) => state);
  interface DataType {
    key: string;
    imei: string;
    brand: string;
    model: string;
    lastChange: string;
    lastCheckIn: string;
    status: any;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "BRAND",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "MODEL",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            className={`py-1 w-24 text-center rounded-full ${
              status === "Active"
                ? "text-[#0FA91E] border border-[#0FA91E80]"
                : status === "Locked"
                ? "text-[#FA3659] border border-[#FA365980]"
                : status === "Released"
                ? "text-[#2943D6] border border-[#2943d622]"
                : status === "Ready For Use"
                ? "text-[#F19F1D] border border-[#2943d622]"
                : "text-[#2943D6] border border-[#2943d622]"
            } `}
          >
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "LAST CHANGE",
      dataIndex: "lastChange",
      key: "lastChange",
    },
    {
      title: "LAST CHECK-IN",
      dataIndex: "lastCheckIn",
      key: "lastCheckIn",
    },
    {
      title: "ACTION",
      key: "action",
      render: (record: any) => (
        <Space>
          <piIcons.PiEyeLight
            size={20}
            className="cursor-pointer"
            onClick={() => {
              console.log("yessss", record);
              props?.setData(record);
              props?.setViewDetails(true);
              return {};
            }}
          />
        </Space>
      ),
    },
  ];
  const data = vault?.all?.data?.map((el: any, index: number) => {
    return {
      key: index,
      imei: el?.deviceUniqueID,
      brand: el?.deviceMarketName,
      model: el?.deviceModel,
      lastChange: el?.lastChanged?.slice(0, 10),
      lastCheckIn: el?.lastCheckIn?.slice(0, 10),
      status: el?.stateInfo,
      data: el,
    };
  });

  return (
    <>
      <Table
        columns={columns}
        loading={vault?.isFetching}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};
export default PrepaidDeviceTable;
