import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import RequestGrid from "../../../../components/grids/RequestGrid";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { Spin } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { getActiveShop } from "../../../../utils/converter";
import {
  createRequestStockAction,
  getAllStockSentAction,
} from "../../../../store/channel/actions";

const PageView = () => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId = getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id;

  useEffect(() => {
    auth?.token &&
      shopId &&
      getAllStockSentAction(auth?.token, `?shopId=${shopId}`)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  console.log("shopId", shopId);

  return (
    <Stack spacing={1}>
      <div className="text-black pt-4 pb-2 flex justify-between items-center">
        <DetailsHeaderActionBar pageName="Pos" title="Request Stock" />
        <LoadingButton
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={async () => {
            if (auth?.token) {
              const res: any = await createRequestStockAction(auth?.token, {
                shopId: shopId,
                channel: auth?.user?.shop?.channel,
              })(dispatch);
              if (res) {
                navigate(`/pos/request/${res?.requestId}`);
              }
            }
          }}
          loading={channel?.isFetching}
        >
          Request Stock
        </LoadingButton>
      </div>
      <Box>
        <span className=" text-black font-medium">
          Stock Requested:
          {channel.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            channel?.reqStock?.result
          )}
        </span>
        <div className="flex flex-wrap gap-5 justify-start items-start">
          <RequestGrid data={channel?.selectedRfs?.data} />
        </div>
      </Box>
    </Stack>
  );
};

export default PageView;
