import { LoadingButton } from "@mui/lab";
import { Form, Input, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnePriceListItemAction,
  updatePriceListItemAction,
} from "../../store/channel/actions";
import { useEffect, useState } from "react";

const UpdatePriceForm: React.FC<{
  priceListItemId: string;
  dataToUpdate?: any;
  handleOpenUpdatePopover?: any;
}> = ({ priceListItemId, dataToUpdate, handleOpenUpdatePopover }) => {
  const { auth, channel } = useSelector((state: any) => state);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    auth.token &&
      priceListItemId &&
      getOnePriceListItemAction(auth.token, priceListItemId)(dispatch);
  }, [auth.token, dispatch, priceListItemId]);

  const onFinish = async (values: any) => {
    if (isActive) {
      auth?.token &&
        (await updatePriceListItemAction(
          auth?.token,
          priceListItemId,
          {
            ...values,
            isNotify: true,
            notification: {
              action: "Updated Price List",
              role: ["admin", "finance-manager", "sales-agent"],
              message:
                channel?.onePriceListItem?.data?.product?.model + " Is Updated",
              title: "Price changed",
            },
          },
          `?channel=${channel?.selected?._id}`
        )(dispatch));
        auth?.token && getOnePriceListItemAction(auth.token, priceListItemId)(dispatch);
    } else {
      auth?.token &&
        (await updatePriceListItemAction(
          auth?.token,
          priceListItemId,
          {
            ...values,
          },
          `?channel=${channel?.selected?._id}`
        )(dispatch));
        auth?.token && getOnePriceListItemAction(auth.token, priceListItemId)(dispatch);
    }
    handleOpenUpdatePopover(false);
    setIsActive(true);
  };
  const [form] = Form.useForm();

  const onChange = (checked: boolean) => {
    setIsActive(checked);
  };

  return (
    <div className="relative">
      <div className="absolute -top-8 right-2  space-x-2">
        <span className="mr-0.5">Notify</span>
        <Switch
          defaultChecked
          style={{ backgroundColor: isActive ? "blue" : "gray" }}
          onChange={onChange}
        />
      </div>
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={dataToUpdate}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
      >
        <Form.Item
          name="value"
          label="Product Price"
          // initialValue={dataToUpdate?.model}
          tooltip="Please enter Product price?"
          rules={[
            {
              required: true,
              message: "Price is required!",
              whitespace: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="extendedWarranty"
          label="Extended Warranty"
          // initialValue={dataToUpdate?.model}
          tooltip="Please enter Extended Warranty?"
          // rules={[
          //   {
          //     required: true,
          //     message: "Price is required!",
          //     whitespace: true,
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          {/* <Switch
          checkedChildren={"Update"}
          unCheckedChildren={"Update and Notify"}
        /> */}
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={channel?.isPriceListUpdating}
          >
            {"update"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdatePriceForm;
