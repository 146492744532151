import React from "react";
import { Table, Tag, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllStockActionByStatus,
  getAllStoclAction,
} from "../../store/pos/actions";
import { searchValue } from "../../utils/setColor";
import { getActiveShop } from "../../utils/converter";
import type { TableColumnsType } from "antd";
import { myPosActions } from "../../store/pos";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

interface ExpandedDataType {
  key: React.Key;
  specification: string;
  sn: string;
  imei: string;
  status: any;
  data: any;
  price: any;
}

const POSStockTable = (props: any) => {
  const dispatch = useDispatch();
  const { pos, auth } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const [checkedStatus, setCheckedStatus] = React.useState<any>("");
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const value = pos?.stock?.data;

  const [selectedRowStock, setSelectedRowStock] = React.useState<any>([]);
  const [selectedRowSold, setSelectedRowSold] = React.useState<any>([]);
  const [selectedRowTransfer, setSelectedRowTransfer] = React.useState<any>([]);
  const [rowDataId, setRowDataId] = React.useState<any>("");
  const handleCheckboxChange = (selectedType: string, selectedData: any) => {
    switch (selectedType) {
      case "pending":
        setSelectedRowStock([selectedData]);
        setSelectedRowSold([]);
        setSelectedRowTransfer([]);
        setCheckedStatus("pending");
        setRowDataId(selectedData);

        break;
      case "sold":
        setSelectedRowSold([selectedData]);
        setSelectedRowStock([]);
        setSelectedRowTransfer([]);
        setCheckedStatus("sold");
        setRowDataId(selectedData);
        break;
      case "transfer":
        setSelectedRowTransfer([selectedData]);
        setSelectedRowStock([]);
        setSelectedRowSold([]);
        setCheckedStatus("transfer");
        setRowDataId(selectedData);
        break;
      default:
        break;
    }
    expandedRowKey &&
      expandedRowKey !== selectedData &&
      setExpandedRowKey(null);
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Shop",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>{record?.data?.shop?.name}</p>
            <p>{record?.data?.shop?.channel?.name}</p>
          </>
        ),
      },
      {
        title: "Model",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>
              {record?.data?.requestedItem?.product?.product?.model +
                "~" +
                record?.data?.requestedItem?.product?.product?.type}
            </p>
          </>
        ),
      },
      {
        title: "Descriptions",
        dataIndex: "specification",
        key: "specification",
      },
      { title: "Serial Number", dataIndex: "sn", key: "sn" },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        render: (text, record) => (
          <>
            <p>{record?.data?.wareHouseItem?.imei1 || "N/A"}</p>
            <p>{record?.data?.wareHouseItem?.imei2}</p>
          </>
        ),
      },
      { title: "Price(Frw)", dataIndex: "price", key: "price" },
      {
        title: "Extended Warranty",
        dataIndex: "data",
        key: "data",
        render: (text, record) => (
          <>
            <p>
              {record?.data?.requestedItem.product?.extendedWarranty || "0"}%
            </p>
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
        ),
      },
    ];

    const productData: ExpandedDataType[] = [];
    pos?.stockByStatus?.data?.forEach((el: any) => {
      productData.push({
        key: el._id,
        sn: el?.wareHouseItem?.serialNumber,
        imei: el?.wareHouseItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.wareHouseItem?.quotationItem?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });
    if (selectedRowStock || selectedRowSold || selectedRowTransfer) {
      return (
        <Table columns={columns} dataSource={productData} pagination={false} />
      );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Model",
      dataIndex: "data",
      render: (text, record) => (
        <p>
          {record?.data?.productSpecs.product.model +
            "~" +
            record?.data?.productSpecs.product.type}
        </p>
      ),
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
    },
    {
      title: "Stock In",
      dataIndex: "data",
      key: "select",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowStock.includes(record.key)}
            onChange={() => handleCheckboxChange("pending", record?.key)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d.status?.toLowerCase() === "pending" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Sold",
      dataIndex: "data",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowSold.includes(record.key)}
            onChange={() => handleCheckboxChange("sold", record?.key)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d?.status?.toLowerCase() === "sold" && d?.count
            )}
          </p>
        </div>
      ),
    },
    {
      title: "Transfer",
      dataIndex: "data",
      render: (text, record) => (
        <div className="flex items-center gap-4">
          <Checkbox
            checked={selectedRowTransfer.includes(record.key)}
            onChange={() => handleCheckboxChange("transfer", record?.key)}
          />
          <p>
            {record?.data?.countsByStatus?.map(
              (d: any) => d.status?.toLowerCase() === "transfer" && d.count
            )}
          </p>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      getAllStoclAction(
        auth?.token,
        `?shop=${
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
          auth?.user?.shop?.shopId
        }`
      )(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      rowDataId &&
      checkedStatus &&
      getAllStockActionByStatus(
        auth?.token,
        `?shop=${
          getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&productPriceList=${rowDataId}&status=${checkedStatus}`
      )(dispatch);
  }, [auth?.token, checkedStatus, dispatch, rowDataId]);

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          id: el._id,
          key: el._id,
          specification: `${el?.productSpecs?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          price: el?.productSpecs?.prices
            ?.filter((item: any) => item.isActive)
            .map((d: any) => d?.value?.toLocaleString()),
          data: el,
        });
      });

    setformData(dataMapper);
  }, [pos?.stock]);

  React.useEffect(() => {
    rowDataId === "" && dispatch(myPosActions.setStockByStatus(null));
  }, [dispatch, rowDataId]);

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };
  return (
    <Table
      columns={columns}
      dataSource={formData}
      loading={pos?.isFetching}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: ["0"],
        onExpand: handleRowExpand,
        expandedRowKeys: [expandedRowKey],
      }}
    />
  );
};

export default POSStockTable;
