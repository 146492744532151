import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceUser = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/user/register`, data, token);
};
export const getAllServiceUser = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/users${query}`, token);
};
export const getUserStatisticsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/agentCounts${query}`, token);
};

export const getOneServiceUser = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/one/${itemId}`, token);
};

export const updateServiceUser = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceUser = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/one/${itemId}`,
    data,
    token
  );
};

export const getOneServiceAgent = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign/one/${itemId}`, token);
};

export const assignAgentToShop = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/assign/agent/${itemId}`,
    data,
    token
  );
};
export const deactivateAgentFromShop = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/assign/deactivate/${itemId}`,
    data,
    token
  );
};

export const getAgentShopService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign${query}`, token);
};

export const getTransactionServiceAgent = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem${query}`, token);
};
export const changePasswordService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/user/reset/pwd`, data, token);
};
export const updatePasswordService = async (
  query: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/password${query}`,
    data,
    token
  );
};

export const getIntegrationService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/integration${query}`, token);
};
export const getRoleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/role${query}`, token);
};
export const getPreferencesService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/preference`, token);
};
export const getThemeService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/theme`, token);
};
export const getOneThemeService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/settings/theme/one/${id}`, token);
};
export const updatePreferenceService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/preference/one/${itemId}`,
    data,
    token
  );
};
export const updateThemeService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/settings/theme/one/${itemId}`,
    data,
    token
  );
};
export const getActivityLogsService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/Logs`, token);
};
export const getUserLogedInLogsService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/Logs/userLogs`, token);
};
export const getSelectedUserLogsService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/Logs/userLogs/${id}`, token);
};